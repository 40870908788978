import {
  CASE_PROGRESS,
  getCaseIndexForNB,
} from "../../reducers/products/helpers/companyProfileConsts";
/**
 * Retrieves the PCIS_ID of the producer if it exists
 * @param {Objec} ownerAssignment - contains the different owner arrays,
 *                                  uses the producer array
 */
const getPCISCode = (ownerAssignment) => {
  const { producer } = ownerAssignment;
  if (producer.length > 0) {
    return producer[0].PCIS_ID ? `${producer[0].PCIS_ID}` : "";
  }
  return "";
};

const getCurrentCarrier = (currentMedicalCoverage, otherCarrierName, nameOfCarrier) => {
  let currentCarrier = "";
  if(currentMedicalCoverage === "OTHER"){
    currentCarrier = otherCarrierName || nameOfCarrier || "";
  }
  else{
    currentCarrier = nameOfCarrier || currentMedicalCoverage
  }
  return currentCarrier;
};

// Generates a random number between low and high
function generateRandomNumber(low, high) {
  return Math.floor(Math.random() * (high - low) + low);
}

/**
 * Updates the ownerAssignment object with data from the enrollment reducer
 * This will be removed when the redux is refactored
 * @param {Object} ownerAssignment - ownerAssignment object from the reviewCards reducer
 * @param {Object} producerEnroll - producer object from the enrollment reducer
 * @returns an updated ownerAssignment object
 */
const getOwnerAssignment = (ownerAssignment, producerEnroll, caseProgress) => {
  let gaName0 = null;
  let gaPcis0 = null;
  let producerFirstName0 = null;
  let producerLastName0 = null;
  let producerPcis0 = null;
  if(producerEnroll?.generalAgent !== undefined){
    gaName0 = producerEnroll.generalAgent?.gaName0
    gaPcis0 = producerEnroll.generalAgent?.gaPcis0
  }
  if(producerEnroll?.producer !== undefined){
    producerFirstName0 = producerEnroll.producer?.producerFirstName0
    producerLastName0 = producerEnroll.producer?.producerLastName0
    producerPcis0 = producerEnroll.producer?.producerPcis0
  }
  let newOwnerAssignment = { ...ownerAssignment };
  if (caseProgress !== "Complete" && caseProgress !== "Processing in error") {
    return newOwnerAssignment;
  }
  if (!gaName0 && !producerFirstName0) {
    return newOwnerAssignment;
  }
  if (gaName0) {
    const generalAgent = [
      {
        generalAgentName: gaName0,
        PCIS_ID: gaPcis0,
      },
    ];
    newOwnerAssignment = {
      ...newOwnerAssignment,
      generalAgent,
    };
  }
  if (producerFirstName0) {
    const producerName = `${producerFirstName0} ${producerLastName0}`;
    const producer = [
      {
        producerName,
        PCIS_ID: producerPcis0,
      },
    ];
    newOwnerAssignment = {
      ...newOwnerAssignment,
      producer,
    };
  }
  return newOwnerAssignment;
};

/**
 * Creates a payload object to be sent to the service layer solaris api
 * @param {array} medicalPlans - array of medical plans
 * @param {array} dentalPlans - array of dental plans
 * @param {array} visionPlans - array of vision plans
 * @param {array} stdPlans - array of std plans
 * @param {array} ltdPlans - array of ltd plans
 * @param {array} suppLifePlans - array of supplife plans
 * @param {Object} state - the current redux state
 * @param {array} res - (optional) return from the saveOrUpdateCaseTracking call,
 *                      default is empty array
 * @param {string} status - (optional) the status of the quote Quoting, Enrolling, etc,
 *                          defaults to empty string
 * @returns - solaris payload as a json object
 */
const solarisPayload = (
  medicalPlans,
  dentalPlans,
  visionPlans,
  lifePlans,
  lifeDepPlans,
  stdPlans, 
  ltdPlans,
  suppLifePlans,
  state,
  res = [],
  status = "",
  
) => {
  let {
    reviewCards: { ownerAssignment: reviewOwnerAssignment, directSale } = {},
    caseTracking: {
      caseId,
      caseTrack: stateCaseTrack,
      platform,
      currentQuoteId,
    } = {},
    caseTracking,
    enrollment: {
      application: {
        eligibility: {
          contribution: {
            medicalEmployerContribution = "",
          } = {},
          currentCarrierInformation: { 
            otherCarrierName = "",
            currentMedicalCoverage = "",
            nameOfCarrier = ""
          } = {},
          eligibilityInformation: {
            continuationStatus: continuation = "",
            primaryOption: primaryPayer = "",
          } = {},
          participation: { numEligibleEmployees = "" } = {},
        } = {},
        producer,
        producer: {
          generalAgent: {
            gaCommunicationEmail0: generalAgentCommEmail = "",
            gaEmail0: generalAgentEmail = "",
            gaFranchiseCode0: gaFranchiseCode = "",
          } = {},
          producer: {
            producerCommunicationEmail0: brokerCommEmail = "",
            producerEmail0: brokerEmail = "",
          } = {},
        } = {},
        employer: {
          additionalGeneralInformation: {
            domesticPartnerCoverage: domPartnerCoverage = "",
            workersComp: aoCoverage = "",
          },
          generalInformation: { taxID: groupTIN = "" },
          primaryContactInformation: enrollmentPrimaryContact,
          primaryLocation: {streetAddress: groupAddress}
        } = {},
        installation: { memGroupID: memberGroupId },
      },
    },
    paymentBilling: { billing: { method: billingOption = "" } } = {},
    solarisReducer: { status: solarisStatus } = {},
    companyProfile: {
      primaryContact,
      effectiveDate,
      locations: stateLocations,
      companyName,
      employees,
      sicCode,
      isMicroGroup,
      cannabisEmp = "",
      isNiceCirrusIndicator,
      cirrusMemberGroupId,
      memGroupID
    } = {},
    quotes: { filterCriteria: { ratingMethod } } = {},
    renewals: { loginRequest, roleName: caseSubmitterRole } = {},
    user: { userType, userName: caseSubmitterName } = {},
    overall: {
      quickQuoteRequest: {
        zipObj: { stateCode },
        otherDetails: { selectedProducts },
      },
    } = {},
  } = state;

  const resIndex = getCaseIndexForNB(caseTracking);
  let caseTracked = stateCaseTrack[resIndex];

  if (res.length > 0) {
    caseTracked = res[resIndex];
  }

  let { proposalId = "" } = caseTracked;
  const { createdDate } = caseTracked;

  if (!proposalId || proposalId === "") {
    const uniqueId = new Date()
      .getTime()
      .toString()
      .concat(generateRandomNumber(0, 99));
    proposalId = `SAMX-NB-E-${uniqueId}`;
  }

  let caseProgress = status;

  if (status === "") {
    caseProgress = solarisStatus;
  }
  if (status === CASE_PROGRESS.SUBMITTED) {
    caseProgress = "Complete";
  }
  if (status === CASE_PROGRESS.PENDING) {
    caseProgress = isMicroGroup
      ? "Business Validation rule"
      : "Processing in error";
  }

  const ownerAssignment = getOwnerAssignment(
    reviewOwnerAssignment,
    producer,
    caseProgress
  );

  const { totalEligEmps: locationParticipation } = stateLocations[0];

  let locations = stateLocations;

  if (
    numEligibleEmployees !== "" &&
    locationParticipation !== numEligibleEmployees
  ) {
    const location = stateLocations[0];
    location.totalEligEmps = numEligibleEmployees;
    locations = [location];
  }
  
  let totalPlans = [...medicalPlans , ...dentalPlans , ...visionPlans , ...lifePlans , ...lifeDepPlans];

  if(stateCode === "CA" && platform === "DUAL"){
    let isPRIME = totalPlans.find((plan) => plan?.installPlatform === "PRIME");
    if(isPRIME){
      platform = "PRIME/NICE";
    }else{
      platform = "CIRRUS/NICE"
    }
  }else if(stateCode !== "CA" && platform === "DUAL"){
    platform = "PRIME/CIRRUS";
  }

  if(stateCode === "CA" && platform === "NICE" && (status === CASE_PROGRESS.SUBMITTED || status === CASE_PROGRESS.PENDING)){
    caseProgress = "Case Accepted";
  }
  if( state.enrollment?.application?.employer?.primaryLocation?.streetAddressln2 !== ""){
    groupAddress =  groupAddress + " " + state.enrollment?.application?.employer?.primaryLocation?.streetAddressln2;
  }
  
  return {
    pcisCode: getPCISCode(ownerAssignment),
    caseId: caseId || (res[0] && res[0].caseId ? res[0].caseId : ""),
    caseProgress,
    memberGroupId: isNiceCirrusIndicator? cirrusMemberGroupId : memberGroupId,
    primaryContact,
    effectiveDate,
    franchiseCode: gaFranchiseCode,
    currentCarrier: getCurrentCarrier(currentMedicalCoverage, otherCarrierName, nameOfCarrier),
    ownerAssignment,
    directSale,
    locations,
    companyName,
    employees,
    createdDate: createdDate,
    quoteCreator: loginRequest,
    sicCode,
    ratingMethod,
    medicalPlans,
    dentalPlans,
    visionPlans,
    stdPlans,
    ltdPlans,
    suppLifePlans,
    empContributionEE: medicalEmployerContribution,
    proposalId: proposalId,
    quoteNumber: currentQuoteId,
    continuation,
    primaryPayer,
    generalAgentCommEmail,
    generalAgentEmail,
    brokerCommEmail,
    brokerEmail,
    domPartnerCoverage,
    billingOption,
    aoCoverage,
    lifePlans,
    lifeDepPlans,
    platform,
    cannabisEmp,
    stateCode,
    selectedProducts,
    groupTIN,
    userType,
    enrollmentPrimaryContact,
    caseSubmitterName,
    caseSubmitterRole,
    groupAddress,
  };
};

export default solarisPayload;
