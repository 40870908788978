import { getRelevantAppState, makeAppStateNested } from './getRelevantAppState';
import { checkIfObjectMeetsConditions } from './findObjectThatMeetsConditions';
import { fiftyStates } from '../../../../reducers/rules/JSONconsts';
import { identifyPlatform } from '../../../activities/Renewal/Review/reviewUtils';
import { isEmpty } from '../../../../utils/formatters/strings';
import { isUndefinedOrNullOrEmpty } from '../../../../utils/validation/commonValidations';
import { checkValidationsOnBlur } from './validationCheckers';
import { newPcpAssignmentObject } from '../Components/PcpTable/pcpAssignmentHelper';
import deepCopy from '../../../../utils/deepCopy';

const Excel = require('exceljs');

/**
 *
 * @param {object} overallState the redux state of the "overall" reducer
 * @param {object} enrollmentState state.rules.enrollment
 *
 * @returns {array} list of selected products
 * // TODO: should we change this to check the ENROLLED products?
 * (e.g. what if they searched dental but didn't choose any)
 */
export const checkSelectedProducts = (overallState, enrollmentState) => {
    if (overallState.quickQuoteRequest.otherDetails
        && overallState.quickQuoteRequest.otherDetails.selectedProducts) {
        let selectedProducts = overallState.quickQuoteRequest.otherDetails.selectedProducts 
            .map((product) => {
                if (product === 'life') return 'basicLife'; // the enrollment form DB looks for basicLife. TODO: handle other life types
                return product;
            }) 
             if (selectedProducts.includes('basicLife') && enrollmentState.enrolledLifePlans && enrollmentState.enrolledSuppLifePlans){
                selectedProducts.push('suppLife')
            };
        // Check that at least 1 plan of each type is actually enrolled

        const {
            enrolledPlans: enrolledPlansMap,
            enrolledDentalPlans,
            enrolledVisionPlans,
            enrolledLifePlans,
            enrolledSuppLifePlans,
            enrolledSTDPlans,
            enrolledLTDPlans
        } = enrollmentState;
        
        selectedProducts = selectedProducts.filter((product) => {
            switch (product) {
                case 'medical': {
                    const enrolledPackagePlans = Object.values(enrolledPlansMap);
                    for (let i = 0; i < enrolledPackagePlans.length; i++) {
                        const plansEnrolledInPackage = enrolledPackagePlans[i];
                        if (plansEnrolledInPackage.length > 0) {
                            return true;
                        }
                    }
                    return false;
                }
                case 'dental':
                    return enrolledDentalPlans.length > 0;
                case 'vision':
                    return enrolledVisionPlans.length > 0;
                case 'basicLife':
                    return enrolledLifePlans.length > 0;
                case 'suppLife':
                    return enrolledSuppLifePlans.length > 0;
                case 'std':
                    return enrolledSTDPlans.length > 0;
                case 'ltd':
                    return enrolledLTDPlans.length > 0;
                default:
                    // TODO: handle other product types
                    return true;
            }
        });

        return selectedProducts;
    }
    return [''];
};

export const checkSelectedProductsTemplate = (workbook) => {
    if (isEmpty(workbook)) {
        return [];
    }

    return new Promise((resolve) => {
        // Set table data here based on props.workbook
        const wb = new Excel.Workbook();
        const reader = new FileReader();

        reader.readAsArrayBuffer(workbook);
        reader.onload = () => {
            const buffer = reader.result;
            wb.xlsx.load(buffer)
                .then((wkbk) => {
                    const enrollInfo = wkbk.getWorksheet('Enrollment Info');

                    const selectedProducts = [];

                    enrollInfo.eachRow((row, rowNumber) => {
                        if (rowNumber > 3) {
                            const typeCell = row.getCell(1).value;

                            if (isEmployee(typeCell)) {
                                const medicalPlans = row.getCell(11).value;
                                const dentalPlans = row.getCell(16).value;
                                const visionPlans = row.getCell(18).value;

                                if (productTypeSelected(medicalPlans)) {
                                    if (selectedProducts.indexOf('medical') === -1) {
                                        selectedProducts.push('medical');
                                    }
                                }

                                if (productTypeSelected(dentalPlans)) {
                                    if (selectedProducts.indexOf('dental') === -1) {
                                        selectedProducts.push('dental');
                                    }
                                }

                                if (productTypeSelected(visionPlans)) {
                                    if (selectedProducts.indexOf('vision') === -1) {
                                        selectedProducts.push('vision');
                                    }
                                }
                            }
                        }
                    });

                    resolve(selectedProducts);
                });
        };
    });
};

const isEmployee = (typeCell) => {
    if (typeCell.result) {
        return isEmployee(typeCell.result);
    }

    if (typeCell === 'Employee') {
        return true;
    }

    return false;
};

export const isEnrollmentPage = (sourcePage) => {
    if (sourcePage === 'Census Table' || sourcePage === 'censusTable') {
        return true;
    }

    return false;
};

const productTypeSelected = (productCell) => {
    if (productCell === null) {
        return false;
    }

    if (typeof productCell === 'object') {
        if (productCell.result) {
            if (productCell.result === 'N/A') {
                return false;
            }

            return true;
        }

        return false;
    }

    return true;
};

/**
 *
 * @param {object} enrolledPlansMap - an object whose keys are package names
 *                                  (e.g., "standalone", "MA025", etc.) and whose
 *                                  key values are arrays of plans
 *
 * this is found in the enrollmentRulesReducer and is called "enrolledPlans" there
 *
 * @returns {bool} true - if at least one enrolled plan has
 *                 "hsa" = true or "hra" = true, false otherwise
 */

export const checkForHSAorHRAEnrolled = (enrolledPlansMap) => {
    if (enrolledPlansMap) {
        const packages = Object.keys(enrolledPlansMap);
        for (let i = 0; i < packages.length; i++) {
            const pkg = enrolledPlansMap[packages[i]];
            for (let j = 0; j < pkg.length; j++) {
                const plan = pkg[j];
                if (plan.hra || plan.hsa || plan.medicalPlanCategory?.includes("HSA") || plan.medicalPlanCategory?.includes("HRA")) {
                    return true;
                }
            }
        }
    }
    return false;
};

export const checkCannabisEmp = (fullProps) => {
    const cannabisEmp = fullProps && fullProps.companyProfile && fullProps.companyProfile.cannabisEmp ? fullProps.companyProfile.cannabisEmp : 'no';
    if (cannabisEmp === 'yes') {
        return true;
    }
    return false;
};

export const checkHNY = (fullProps) => {
    const standalonePlans = fullProps && fullProps.rules && fullProps.rules.enrollment && fullProps.rules.enrollment.enrolledPlans && fullProps.rules.enrollment.enrolledPlans.standalone;
    if(standalonePlans){
        for(const plan of standalonePlans){
            if(plan.healthyNYPlan===true){
                return true
            }
        }
    }
    return false;
}
// For Renewals Flow
export const checkForHSAEnrolledRL = (enrolledPlansMap) => {
    if (enrolledPlansMap) {
        const packages = Object.keys(enrolledPlansMap);
        for (let i = 0; i < packages.length; i++) {
            const pkg = enrolledPlansMap[packages[i]];
            for (let j = 0; j < pkg.length; j++) {
                const plan = pkg[j];
                if (plan.hsa === true || plan.hsa === 'TRUE' || plan.medicalPlanCategory?.includes("HSA")) {
                    return true;
                }
            }
        }
    }
    return false;
};
export const checkForHRAEnrolledRL = (enrolledPlansMap) => {
    if (enrolledPlansMap) {
        const packages = Object.keys(enrolledPlansMap);
        for (let i = 0; i < packages.length; i++) {
            const pkg = enrolledPlansMap[packages[i]];
            for (let j = 0; j < pkg.length; j++) {
                const plan = pkg[j];
                if (plan.hra === true || plan.hra === 'TRUE' || plan.medicalPlanCategory?.includes("HRA")) {
                    return true;
                }
            }
        }
    }
    return false;
};

// Determines if IBR has HRA/HSA plans selected when HRA/HSA enrolled in order to display Consumer Driven Health Plan Options ER app form section
export const checkHsaOrHraAdd = (enrolledPlans, ibrPlans) => {
    const hsaEnrolled = checkForHSAEnrolledRL(enrolledPlans);
    const hraEnrolled = checkForHRAEnrolledRL(enrolledPlans);
    let hsaSelectedInIbr = false;
    let hraSelectedInIbr = false;

    if (hsaEnrolled || hraEnrolled) {
        for (let plan of ibrPlans) {
            for (let planOption of plan.options.planOption) {
                if (planOption.planOptionID === "EligiblePlanInd") {
                    if (planOption.planOptionValueID.includes("HRA")) hraSelectedInIbr = true;
                    if (planOption.planOptionValueID.includes("HSA")) hsaSelectedInIbr = true;
                }
            }
        }
    } else return false;

    // HRA or HSA add
    if ((hsaEnrolled && !hsaSelectedInIbr) || (hraEnrolled && !hsaSelectedInIbr)) return true;
    else return false;
}

export const checkHasSIC = (renewalData) => {
    if (renewalData && renewalData.memberGroup && renewalData.memberGroup.industryCode === '') {
        return false;
    }
    return true;
};

/**
 *
 * @param {array} selectedProducts state.overall.quickQuoteRequest.otherDetails.selectedProducts
 * @param {array} enrolledDentalPlans state.rules.enrollment.enrolledDentalPlans
 */
export const checkDentalContributionType = (selectedProducts, enrolledDentalPlans) => {
    if (!selectedProducts || !selectedProducts.includes('dental')) {
        return '';
    }
    if (!enrolledDentalPlans || enrolledDentalPlans.length === 0) {
        return '';
    }

    // For now, we just check the contribution type of the FIRST plan
    // TODO: establish hierarchy of contribution type if multiple are enrolled
    const dentalPlan = enrolledDentalPlans[0];

    const { fundingType: contributionType } = dentalPlan;

    return contributionType.toLowerCase();
};

export const checkLifeContributionType = (selectedProducts, enrolledLifePlans) => {
    if (!selectedProducts || !selectedProducts.includes('basicLife')) {
        return '';
    }
    if (!enrolledLifePlans || enrolledLifePlans.length === 0) {
        return '';
    }
    const LifePlan = enrolledLifePlans[0];

    const { fundingType: contributionType } = LifePlan;

    return contributionType && contributionType.toLowerCase();
};
/**
 *
 * @param {array} selectedProducts state.overall.quickQuoteRequest.otherDetails.selectedProducts
 * @param {array} enrolledVisionPlans state.rules.enrollment.enrolledVisionPlans
 */
export const checkVisionContributionType = (selectedProducts, enrolledVisionPlans) => {
    if (!selectedProducts || !selectedProducts.includes('vision')) {
        return '';
    }
    if (!enrolledVisionPlans || enrolledVisionPlans.length === 0) {
        return '';
    }

    // For now, we just check the contribution type of the FIRST plan
    // TODO: establish hierarchy of contribution type if multiple are enrolled
    const visionPlan = enrolledVisionPlans[0];

    const { visionPlanTypeDescription: contributionType } = visionPlan;

    switch (contributionType.toLowerCase()) {
        case '100% er paid':
            return 'employer-paid';
        case '50/50':
            return '50/50';
        case 'voluntary':
            return 'voluntary';
        case 'ee core/vol dep': // TODO: Figure out the EE Core case
            return 'ee-core';
        default:
            return 'voluntary';
    }
};

export const checkAllDentalContributionTypes = (selectedProducts, enrolledDentalPlans) => {
    let contributionTypes = []
    if (!selectedProducts || !selectedProducts.includes('dental')) {
        return [];
    }
    if (!enrolledDentalPlans || enrolledDentalPlans.length === 0) {
        return [];
    }

    enrolledDentalPlans.forEach(dentalPlan => {
        const { fundingType: contributionType } = dentalPlan;
        contributionTypes.push(contributionType.toLowerCase())
    })

    return contributionTypes
};

export const findExistingDentalContributionType = (renewalData) => {
    const planOfferingData = renewalData?.Ibr?.quote?.planOfferingData
    const existingContributionTypes = []
    if (planOfferingData && planOfferingData.length > 0) {
        const ibrRenewingPlans = planOfferingData[0]?.newPlanPkg?.plans?.plan
        if (ibrRenewingPlans && ibrRenewingPlans.length > 0) {
            ibrRenewingPlans.forEach(plan => {
                if (plan.productLineType === 'DENTAL') {
                    const planOptions = plan?.options?.planOption
                    if (planOptions && planOptions.length > 0) {
                        planOptions.forEach(option => {
                            if (option.planOptionID === 'PlanFundingDescription' && option.planOptionValueID) {
                                existingContributionTypes.push(option.planOptionValueID.toLowerCase())
                            }
                        })
                    }
                }
            })
        }
    }
    return existingContributionTypes
}

export const findExistingVisionContributionType = (renewalData) => {
    const planOfferingData = renewalData?.Ibr?.quote?.planOfferingData
    const existingContributionTypes = []
    if (planOfferingData && planOfferingData.length > 0) {
        const ibrRenewingPlans = planOfferingData[0]?.newPlanPkg?.plans?.plan
        if (ibrRenewingPlans && ibrRenewingPlans.length > 0) {
            ibrRenewingPlans.forEach(plan => {
                if (plan.productLineType === 'VISION') {
                    const clientDefinedData = plan?.clientDefinedData?.data
                    if (clientDefinedData && clientDefinedData.length > 0) {
                        clientDefinedData.forEach(data => {
                            if (data.key === 'PlanCategory' && data.value) {
                                existingContributionTypes.push(data.value.split('/')[0].toLowerCase())
                            }
                        })
                    }
                }
            })
        }
    }
    return existingContributionTypes
}

export const findExistingLifeContributionType = (renewalData) => {
    const planOfferingData = renewalData?.Ibr?.quote?.planOfferingData
    let existingContributionType = '';
    if (planOfferingData && planOfferingData.length > 0) {
        const ibrRenewingPlans = planOfferingData[0]?.newPlanPkg?.plans?.plan
        if (ibrRenewingPlans && ibrRenewingPlans.length > 0) {
            ibrRenewingPlans.forEach(plan => {
                if (plan.productLineType === 'BASIC_LIFE_EE') {
                    const clientDefinedData = plan?.clientDefinedData?.data
                    if (clientDefinedData && clientDefinedData.length > 0) {
                        clientDefinedData.forEach(data => {
                            if (data.key === 'basicLifeContribution' && data.value) {
                                existingContributionType = data.value.toLowerCase()
                            }
                        })
                    }
                }
            })
        }
    }
    if(!existingContributionType) {
        const lifePlanDetails = renewalData?.lifePlanDetails;
        if (lifePlanDetails && lifePlanDetails.length > 0) {
            lifePlanDetails.forEach(plan =>{
                if(plan.fundingType) {
                    existingContributionType = plan.fundingType.toLowerCase();
                }
            })
        }
    }
    return existingContributionType
}


// if contribution type is changing than only we need to show contribution section
export const checkIsDentalContributionTypeChanged = (renewalData, selectedProducts, enrolledPlans) => {
    let renewingContributionTypes = checkAllDentalContributionTypes(selectedProducts, enrolledPlans)
    let existingContributionTypes = findExistingDentalContributionType(renewalData)
    if (renewingContributionTypes.includes('contributory') && existingContributionTypes.includes('contributory')) {
        return false
    } else if (enrolledPlans && enrolledPlans.length > 0 && (renewingContributionTypes.includes('contributory') || existingContributionTypes.includes('contributory'))) {
        return true
    } 
    return false
}

// if contribution type is changing than only we need to show contribution section
export const checkIsVisionContributionTypeChanged = (renewalData, selectedProducts, enrolledPlans) => {
    let renewingContributionType = checkVisionContributionType(selectedProducts, enrolledPlans)
    let existingContributionTypes = findExistingVisionContributionType(renewalData)
    if ((renewingContributionType === 'employer-paid' || renewingContributionType === '50/50' || renewingContributionType === '100% er paid' ) && 
-        (existingContributionTypes.includes('employer-paid') || existingContributionTypes.includes('50/50') || existingContributionTypes.includes('100% er paid'))) {
        return false
    } else if (enrolledPlans && enrolledPlans.length > 0 && ((renewingContributionType === 'employer-paid' || renewingContributionType === '50/50' || renewingContributionType === '100% er paid') ||
                (existingContributionTypes.includes('employer-paid') || existingContributionTypes.includes('50/50') || existingContributionTypes.includes('100% er paid')))) {
        return true
    } 
    return false
}
export const checkIsLifeContributionTypeChanged = (renewalData, selectedProducts, enrolledPlans) => {
    let renewingContributionType = checkLifeContributionType(selectedProducts, enrolledPlans)
    let existingContributionType = findExistingLifeContributionType(renewalData)
    if (enrolledPlans && enrolledPlans.length > 0 && ((
        (existingContributionType === 'non-contributory'|| existingContributionType === 'non-contributary') &&  (renewingContributionType === 'contributary'|| renewingContributionType === 'contributory')) 
        || ((existingContributionType === 'contributary'|| existingContributionType === 'contributory') &&  (renewingContributionType === 'non-contributory'|| renewingContributionType === 'non-contributary')))) {
        return true
    }
    return false
}

export const checkIsNiceToCirrusInstall = (custType ,totalEnrolledPlans) => {
    let isNiceToCirrusMedAdd = false;
    let isNiceToCirrusSplty = false;
    if(custType === "NICE" && totalEnrolledPlans && totalEnrolledPlans.length > 0){
        totalEnrolledPlans.forEach(plan =>{
            if(plan.planType === "Medical" && plan.renewalPlanType !== "renewing" && plan.platform === "PRIME"){
                isNiceToCirrusMedAdd = true;
            }
            if(plan.planType === "Dental" || plan.planType === "Vision" || plan.planType === "Life") {
                isNiceToCirrusSplty = true;
            }
        })
    }
    return {isNiceToCirrusMedAdd,isNiceToCirrusSplty};
}

/**
 *
 * @param {array} selectedProducts state.overall.quickQuoteRequest.otherDetails.selectedProducts
 * @param {array} enrolledSTDPlans state.rules.enrollment.enrolledSTDPlans
 */
export const checkSTDContributionType = (selectedProducts, enrolledSTDPlans) => {
  if (!selectedProducts || !selectedProducts.includes('std')) {
      return '';
  }

  if (!enrolledSTDPlans || enrolledSTDPlans.length === 0) {
      return '';
  }

  // For now, we just check the contribution type of the FIRST plan
  // TODO: establish hierarchy of contribution type if multiple are enrolled
  const stdPlan = enrolledSTDPlans[0];

  const { contributionType } = stdPlan;

  switch (contributionType.toLowerCase()) {
      case 'non-contributory':
          return 'non-contributory';
      case 'contributory':
          return 'contributory';
      case 'voluntary':
          return 'voluntary';
      default:
          return 'voluntary';
  }
};

/**
 *
 * @param {array} selectedProducts state.overall.quickQuoteRequest.otherDetails.selectedProducts
 * @param {array} enrolledLTDPlans state.rules.enrollment.enrolledLTDPlans
 */
 export const checkLTDContributionType = (selectedProducts, enrolledLTDPlans) => {
  if (!selectedProducts || !selectedProducts.includes('ltd')) {
      return '';
  }

  if (!enrolledLTDPlans || enrolledLTDPlans.length === 0) {
      return '';
  }

  // For now, we just check the contribution type of the FIRST plan
  // TODO: establish hierarchy of contribution type if multiple are enrolled
  const ltdPlan = enrolledLTDPlans[0];

  const { contributionType } = ltdPlan;

  switch (contributionType.toLowerCase()) {
      case 'non-contributory':
          return 'non-contributory';
      case 'contributory':
          return 'contributory';
      case 'voluntary':
          return 'voluntary';
      default:
          return 'voluntary';
  }
};

/**
 *
 * @param {object} overallState state.overall
 * @param {object} quotesState state.quotes
 * @param {object} enrollmentState state.rules.enrollment
 */
export const getConditionEvaluators = (
    overallState,
    enrollmentState,
    fullProps,
) => {
    const {
        enrolledPlans: enrolledPlansMap,
        enrolledDentalPlans,
        enrolledVisionPlans,
        enrolledSTDPlans,
        enrolledLTDPlans,
        enrolledSuppLifePlans,
    } = enrollmentState;

    const {totalEligEmps} = fullProps.companyProfile.locations[0]

    const selectedProducts = checkSelectedProducts(overallState, enrollmentState);

    return {
        platform: overallState.platform,
        selectedProducts,
        quotedHSAorHRA: checkForHSAorHRAEnrolled(enrolledPlansMap),
        hsaOrHraAdd: checkHsaOrHraAdd(enrolledPlansMap, fullProps.renewals?.renewalData?.Ibr?.quote?.planOfferingData[0]?.existingPlanPkg?.plans?.plan || []),
        hasCannabisEmp: checkCannabisEmp(fullProps),
        isHNY: checkHNY(fullProps),
        dentalContributionType: checkDentalContributionType(selectedProducts, enrolledDentalPlans),
        visionContributionType: checkVisionContributionType(selectedProducts, enrolledVisionPlans),
        stdContributionType : checkSTDContributionType(selectedProducts, enrolledSTDPlans),
        ltdContributionType :checkLTDContributionType(selectedProducts, enrolledLTDPlans),
    };
};

export const getConditionEvaluatorsRenewals = (
    rulesEnrollmentState,
    renewalEnrollmentState,
    ibrPlans
) => {
    const {
        enrolledDentalPlans,
        enrolledVisionPlans,
        enrolledLifePlans,
        selectedProducts,
        newProducts,
        renewalData,
    } = renewalEnrollmentState;
    const {
        enrolledPlans,
    } = rulesEnrollmentState;

    const custType = rulesEnrollmentState?.renewalCaseData?.custType;
    const enrolledMedical = [].concat(...Object.values(enrolledPlans)) || []
    const totalEnrolledPlans = [...enrolledMedical,...enrolledDentalPlans,...enrolledVisionPlans,...enrolledLifePlans];
    const {isNiceToCirrusMedAdd, isNiceToCirrusSplty} = checkIsNiceToCirrusInstall(custType,totalEnrolledPlans);
    return {
        platform: identifyPlatform(renewalData),
        selectedProducts,
        newProducts,
        hsaOrHraAdd: checkHsaOrHraAdd(enrolledPlans, ibrPlans),
        quotedHSAorHRA: checkForHSAorHRAEnrolled(enrolledPlans),
        quotedHRA: checkForHRAEnrolledRL(enrolledPlans),
        quotedHSA: checkForHSAEnrolledRL(enrolledPlans),
        hasSIC: checkHasSIC(renewalData),
        dentalContributionType: checkDentalContributionType(selectedProducts, enrolledDentalPlans),
        visionContributionType: checkVisionContributionType(selectedProducts, enrolledVisionPlans),
        lifeContributuionType: checkLifeContributionType(selectedProducts, enrolledLifePlans),
        isDentalContributionTypeChanged: checkIsDentalContributionTypeChanged(renewalData, selectedProducts, enrolledDentalPlans),
        isVisionContributionTypeChanged: checkIsVisionContributionTypeChanged(renewalData, selectedProducts, enrolledVisionPlans),
        isLifeContributionTypeChanged: checkIsLifeContributionTypeChanged(renewalData,selectedProducts,enrolledLifePlans),
        isNiceToCirrusMedAdd,
        isNiceToCirrusSplty
    };
};

export const checkAdditionalContactsPresent = (additionalContacts) => {
    if (additionalContacts && Object.keys(additionalContacts).includes('additionalContactFirstName2')) {
        return 3;
    } if (additionalContacts && Object.keys(additionalContacts).includes('additionalContactFirstName1')) {
        return 2;
    } if (additionalContacts && Object.keys(additionalContacts).includes('additionalContactFirstName0')) {
        return 1;
    }
    return 0;
};

const titleList = ['Miss', 'Mr.', 'Mrs.', 'Ms.'];
const phoneTypeList = ['', 'Business', 'Mobile', 'Other'];

// Temporarily removing roles
// const roleList = ['Benefit Administrator', 'Billing Contact', 'Banking Contact', 'Owners', 'Partner', 'Third Party Administrator', 'Vendor', 'SBC Contact']
const roleList = ['Benefit Administrator', 'Billing Contact'];

export const checkCompletionAdditionalContacts = (
    application, conditionEvaluators, appStateProps, fullProps,
) => {
    let completed = false;
    const inCompletePageNames = [];
    const inCompleteSectionNames = [];
    const { employer: { additionalContacts } } = application;
    const { existingNumAdditionalContacts } = application.employer.additionalContacts;
    const numContacts = checkAdditionalContactsPresent(additionalContacts);

    if (numContacts > 0) {
        for (let i = 0; i < numContacts; i++) {
            const rowCells = [
                {
                    fieldName: 'additionalContactFirstName',
                    fieldNameDisplay: 'First Name',
                    fieldType: 'textbox',
                    isRequired: true,
                },
                {
                    fieldName: 'additionalContactLastName',
                    fieldNameDisplay: 'Last Name',
                    fieldType: 'textbox',
                    isRequired: true,
                },
                {
                    fieldName: 'additionalContactTitle',
                    fieldNameDisplay: 'Title Name',
                    fieldType: 'dropdown',
                    dropdownOptions: [
                        {
                            options: titleList,
                        },
                    ],
                },
                {
                    fieldName: 'additionalContactEmail',
                    fieldNameDisplay: 'Email',
                    fieldType: 'textbox',
                    validations: [
                        {
                            validationType: 'email',
                        },
                    ],
                    isRequired: true,
                },
                {
                    fieldName: 'additionalContactTelephone',
                    fieldNameDisplay: 'Telephone',
                    fieldType: 'textbox',
                    validations: [
                        {
                            validationType: 'phone',
                        },
                    ],
                    isRequired: true,
                },
                {
                    fieldName: 'additionalContactExt',
                    fieldNameDisplay: 'Ext.',
                    fieldType: 'textbox',
                },
                {
                    fieldName: 'additionalContactPhoneType',
                    fieldNameDisplay: 'Phone Type',
                    fieldType: 'dropdown',
                    dropdownOptions: [
                        {
                            options: phoneTypeList,
                        },
                    ],
                    isRequired: true,
                },
                {
                    fieldName: 'additionalContactRole',
                    fieldNameDisplay: 'Role',
                    fieldType: 'dropdown',
                    dropdownOptions: [
                        {
                            options: roleList,
                        },
                    ],
                    isRequired: true,
                },
                {
                    fieldName: 'additionalContactPreferredContactMethod',
                    fieldNameDisplay: 'Preferred Contact Method',
                    fieldType: 'dropdown',
                    dropdownOptions: [
                        {
                            options: ['Telephone', 'Email'],
                        },
                    ],
                    isRequired: true,
                },
                {
                    fieldName: 'additionalContactSameAsEmployerPrimary',
                    fieldNameDisplay: 'Is address same as Employer Primary Address?',
                    fieldType: 'dropdown',
                    dropdownOptions: [
                        {
                            options: ['Yes', 'No'],
                        },
                    ],
                    isRequired: true,
                },
                {
                    fieldName: 'additionalContactStreetAddress',
                    fieldNameDisplay: 'Street Address',
                    fieldType: 'textbox',
                    isRequired: true,
                    showIf: {
                        requiredConditions: 'all',
                        conditions: [
                            {
                                conditionType: 'appState',
                                appState: {
                                    stateKey: 'additionalContacts',
                                    stateSubKey: `additionalContactSameAsEmployerPrimary${i}`,
                                    logic: 'is',
                                    stateValue: 'No',
                                },
                            },
                        ],
                    },
                },
                {
                    fieldName: 'additionalContactStreetAddressln2',
                    fieldNameDisplay: 'Street Address Line 2',
                    fieldType: 'textbox',
                    showIf: {
                        requiredConditions: 'all',
                        conditions: [
                            {
                                conditionType: 'appState',
                                appState: {
                                    stateKey: 'additionalContacts',
                                    stateSubKey: `additionalContactSameAsEmployerPrimary${i}`,
                                    logic: 'is',
                                    stateValue: 'No',
                                },
                            },
                        ],
                    },
                },
                {
                    fieldName: 'additionalContactCity',
                    fieldNameDisplay: 'City',
                    fieldType: 'textbox',
                    isRequired: true,
                    showIf: {
                        requiredConditions: 'all',
                        conditions: [
                            {
                                conditionType: 'appState',
                                appState: {
                                    stateKey: 'additionalContacts',
                                    stateSubKey: `additionalContactSameAsEmployerPrimary${i}`,
                                    logic: 'is',
                                    stateValue: 'No',
                                },
                            },
                        ],
                    },
                },
                {
                    fieldName: 'additionalContactState',
                    fieldNameDisplay: 'State',
                    fieldType: 'dropdown',
                    dropdownOptions: [
                        {
                            options: fiftyStates,
                        },
                    ],
                    isRequired: true,
                    showIf: {
                        requiredConditions: 'all',
                        conditions: [
                            {
                                conditionType: 'appState',
                                appState: {
                                    stateKey: 'additionalContacts',
                                    stateSubKey: `additionalContactSameAsEmployerPrimary${i}`,
                                    logic: 'is',
                                    stateValue: 'No',
                                },
                            },
                        ],
                    },
                },
                {
                    fieldName: 'additionalContactZipCode',
                    fieldNameDisplay: 'Zip Code',
                    fieldType: 'zipCode',
                    isRequired: true,
                    validations: [
                        {
                            validationType: 'zip',
                        },
                    ],
                    showIf: {
                        requiredConditions: 'all',
                        conditions: [
                            {
                                conditionType: 'appState',
                                appState: {
                                    stateKey: 'additionalContacts',
                                    stateSubKey: `additionalContactSameAsEmployerPrimary${i}`,
                                    logic: 'is',
                                    stateValue: 'No',
                                },
                            },
                        ],
                    },
                },
                {
                    fieldName: 'additionalContactZipCode4',
                    fieldNameDisplay: 'Zip Code + 4',
                    fieldType: 'textbox',
                    isRequired: false,
                    validations: [
                        {
                            validationType: 'zip4',
                        },
                    ],
                    showIf: {
                        requiredConditions: 'all',
                        conditions: [
                            {
                                conditionType: 'appState',
                                appState: {
                                    stateKey: 'additionalContacts',
                                    stateSubKey: `additionalContactSameAsEmployerPrimary${i}`,
                                    logic: 'is',
                                    stateValue: 'No',
                                },
                            },
                        ],
                    },
                },
            ];
            // old method
            // for (let j = 0; j < rowCells.length; j++) {
            //     const ruleField = rowCells[j];
            //     if (ruleField.isRequired) {
            //         if (ruleField.showIf) {
            //             // check if required field is shown
            //             let appState = getRelevantAppState(application, 'employer', 'additionalContacts', ruleField);
            //             appState = makeAppStateNested(appStateProps);
            //             const shouldShow = checkIfObjectMeetsConditions(
            //                 'additionalContacts',
            //                 ruleField.showIf, {
            //                 ...conditionEvaluators,
            //                 appState,
            //             },
            //             );
            //             if (!additionalContacts[ruleField.fieldName + i]
            //                 && shouldShow === true) {
            //                 completed = false;
            //                 break;
            //             } else {
            //                 completed = true;
            //             }
            //         } else if (!additionalContacts[ruleField.fieldName + i]) {
            //             completed = false;
            //             break;
            //         } else {
            //             completed = true;
            //         }
            //     }
            // }
            for (const ruleField of rowCells) {
                const fieldValue = application.employer.additionalContacts[ruleField.fieldName + i];
                const isFieldRequired = ruleField.isRequired ? ruleField.isRequired : false;
                let isFieldValidationsError = false;
                const fieldHasShowIf = ruleField.showIf ? ruleField.showIf : false;
                let isFieldVisible = true;
                let validationErrors = null;
                
                if (!isUndefinedOrNullOrEmpty(fieldValue) && ruleField.validations) {
                    validationErrors = checkValidationsOnBlur(fieldValue, ruleField.validations, isFieldRequired, fullProps);
                }
                if (validationErrors !== null) {
                    isFieldValidationsError = true;
                }

                if (fieldHasShowIf) {
                    let appState = getRelevantAppState(application, 'employer', 'additionalContacts', ruleField);
                    appState = makeAppStateNested(appState);
                    isFieldVisible = checkIfObjectMeetsConditions(
                        'additionalContacts',
                        ruleField.showIf, {
                        ...conditionEvaluators,
                        appState,
                    },
                        fullProps.companyProfile,
                        fullProps.rules.enrollment
                    );
                }
                if (isFieldVisible) {
                    if (isFieldRequired) {
                        if (fieldHasShowIf) {
                            if (isUndefinedOrNullOrEmpty(fieldValue) || isFieldValidationsError === true) {
                                if (existingNumAdditionalContacts) {
                                    if (existingNumAdditionalContacts < (i + 1)) {
                                        completed = false;
                                        inCompletePageNames.push('employer');
                                        inCompleteSectionNames.push('additionalContacts');
                                    }
                                } else {
                                    completed = false;
                                    inCompletePageNames.push('employer');
                                    inCompleteSectionNames.push('additionalContacts');
                                }
                            }
                        } else if (isUndefinedOrNullOrEmpty(fieldValue) || isFieldValidationsError === true) {
                            if (existingNumAdditionalContacts) {
                                if (i + 1 > existingNumAdditionalContacts) {
                                    completed = false;
                                    inCompletePageNames.push('employer');
                                    inCompleteSectionNames.push('additionalContacts');
                                }
                            } else {
                                completed = false;
                                inCompletePageNames.push('employer');
                                inCompleteSectionNames.push('additionalContacts');
                            }
                        }
                    }
                }
                
            }
        }
    } else {
        completed = true;
    }
    return {
        inCompletePageNames,
        inCompleteSectionNames,
    };
};

export const checkProducersPresent = (producers) => {
    let num = 0;
    const lastChars = [];
    const prodKeys = producers ? Object.keys(producers) : [];

    prodKeys.forEach((property) => {
        lastChars.push(property.slice(-1));
    });

    if (lastChars.includes('3')) {
        num = 4;
    } else if (lastChars.includes('2')) {
        num = 3;
    } else if (lastChars.includes('1')) {
        num = 2;
    } else if (lastChars.includes('0')) {
        num = 1;
    } else {
        num = 0;
    }
    return num;
};

export const checkCompletionProducers = (application, conditionEvaluators, appStateProps, fullProps) => {
    // let completed = false;
    let completed = true;
    const inCompletePageNames = [];
    const inCompleteSectionNames = [];
    const { producer: { producer } } = application;
    const numContacts = checkProducersPresent(producer);
    const totalCommissionPercentage = 0;

    if (numContacts > 0) {
        for (let i = 0; i < numContacts; i++) {
            const producerFields = [
                {
                    fieldName: 'producerFirstName',
                    fieldNameDisplay: 'First Name',
                    fieldType: 'textbox',
                    isRequired: true,
                },
                {
                    fieldName: 'producerMi',
                    fieldNameDisplay: 'MI',
                    fieldType: 'textbox',
                },
                {
                    fieldName: 'producerLastName',
                    fieldNameDisplay: 'Last Name',
                    fieldType: 'textbox',
                    isRequired: true,
                },
                {
                    fieldName: 'producerSuffix',
                    fieldNameDisplay: 'Suffix',
                    fieldType: 'textbox',
                },
                {
                    fieldName: 'producerSsn',
                    fieldNameDisplay: 'TIN or SSN',
                    fieldType: 'textbox',
                    isRequired: false,
                    validations: [
                        {
                            validationType: 'ssn',
                        },
                    ],
                },
                // {
                //     fieldName: 'producerlicense',
                //     fieldNameDisplay: 'Producer License',
                //     fieldType: 'textbox',
                //     isRequired: true,
                // },
                {
                    fieldName: 'producerAddressLine1',
                    fieldNameDisplay: 'Address Line 1',
                    fieldType: 'textbox',
                    isRequired: true,
                    validations: [
                        {
                            validationType: 'noSpecialCharacterAddress',
                        },
                    ],                    
                },
                {
                    fieldName: 'producerAddressLine2',
                    fieldNameDisplay: 'Address Line 2',
                    fieldType: 'textbox',
                    validations: [
                        {
                            validationType: 'noSpecialCharacterAddress',
                        },
                    ],                    
                },
                {
                    fieldName: 'producerCity',
                    fieldNameDisplay: 'City',
                    fieldType: 'textbox',
                    isRequired: true,
                },
                {
                    fieldName: 'producerState',
                    fieldNameDisplay: 'State',
                    fieldType: 'dropdown',
                    dropdownOptions: [
                        {
                            options: fiftyStates,
                        },
                    ],
                    isRequired: true,
                },
                {
                    fieldName: 'producerZipCode',
                    fieldNameDisplay: 'Zip Code',
                    fieldType: 'textbox',
                    isRequired: true,
                    validations: [
                        {
                            validationType: 'zip',
                        },
                    ],
                },
                {
                    fieldName: 'producerZipCode4',
                    fieldNameDisplay: 'Zip Code + 4',
                    fieldType: 'textbox',
                    isRequired: false,
                    validations: [
                        {
                            validationType: 'zip4',
                        },
                    ],
                },
                {
                    fieldName: 'producerEmail',
                    fieldNameDisplay: 'Email',
                    fieldType: 'textbox',
                    validations: [
                        {
                            validationType: 'email',
                        },
                    ],
                    isRequired: true,
                },
                {
                    fieldName: 'producerTelephone',
                    fieldNameDisplay: 'Telephone',
                    fieldType: 'textbox',
                },
                {
                    fieldName: 'producerCommunicationEmail',
                    fieldNameDisplay: 'Communication Email Address',
                    fieldType: 'textbox',
                    validations: [
                        {
                            validationType: 'email',
                        },
                    ],
                },
                {
                    fieldName: 'producerCommissionPercent',
                    fieldNameDisplay: 'Commission %',
                    fieldType: 'textbox',
                    validations: [
                        {
                            validationType: 'digitsOnly',
                        },
                        {
                            validationType: 'max',
                            value: '100',
                        },
                        {
                            validationType: 'totalCommission',
                            value: '100',
                        },
                        {
                            validationType: 'MinCommission',
                        },
                    ],
                    isRequired: true,
                },
                {
                    fieldName: 'producerCommissionPayableTo',
                    fieldNameDisplay: 'Commission Payable To',
                    fieldType: 'textbox',
                    isRequired: true,
                },
                {
                    fieldName: 'producerCommissionPayableToID',
                    fieldNameDisplay: 'Commission Payable To ID',
                    fieldType: 'textbox',
                    isRequired: true,
                    validations: [
                        {
                            validationType: 'digitsOnly',
                        },
                    ],
                },
                {
                    fieldName: 'producerPcis',
                    isRequired: true,
                },
            ];
            // old
            // for (let j = 0; j < producerFields.length; j++) {
            //     const ruleField = producerFields[j];
            //     if (ruleField.isRequired) {
            //         if (!producer[ruleField.fieldName + i]) {
            //             completed = false;
            //             break;
            //         } else {
            //             completed = true;
            //         }
            //         if (ruleField.fieldName === 'producerCommissionPercent') {
            //             totalCommissionPercentage = parseInt(totalCommissionPercentage, 10)
            //                 + parseInt(producer[ruleField.fieldName + i], 10);
            //         }
            //     }
            // }
            for (const ruleField of producerFields) {
                const fieldValue = application.producer.producer[ruleField.fieldName + i];
                const isFieldRequired = ruleField.isRequired ? ruleField.isRequired : false;
                let isFieldValidationsError = false;
                const fieldHasShowIf = ruleField.showIf ? ruleField.showIf : false;
                let isFieldVisible = true
                let validationErrors = null;
                
                if (!isUndefinedOrNullOrEmpty(fieldValue) && ruleField.validations) {
                    validationErrors = checkValidationsOnBlur(fieldValue, ruleField.validations, isFieldRequired, fullProps);
                }
                if (validationErrors !== null) {
                    isFieldValidationsError = true;
                }

                if (fieldHasShowIf) {
                    let appState = getRelevantAppState(application, 'producer', 'producer', ruleField);
                    appState = makeAppStateNested(appState);
                    isFieldVisible = checkIfObjectMeetsConditions(
                        'producer',
                        ruleField.showIf, {
                        ...conditionEvaluators,
                        appState,
                    },
                        fullProps.companyProfile,
                        fullProps.rules.enrollment
                    );
                }
                if (isFieldVisible) {
                    if (isFieldRequired) {
                        if (fieldHasShowIf) {
                            if (isUndefinedOrNullOrEmpty(fieldValue) || isFieldValidationsError === true) {
                                completed = false;
                                inCompletePageNames.push('producer');
                                inCompleteSectionNames.push('producer');
                            }
                        } else if (isUndefinedOrNullOrEmpty(fieldValue) || isFieldValidationsError === true) {
                            completed = false;
                            inCompletePageNames.push('producer');
                            inCompleteSectionNames.push('producer');
                        }
                    } else if (!isUndefinedOrNullOrEmpty(fieldValue)) {
                        if (isFieldValidationsError === true) {
                            completed = false;
                            inCompletePageNames.push('producer');
                            inCompleteSectionNames.push('producer');
                        }
                    }
                }
                
            }
        }
        // completed = !!((completed && totalCommissionPercentage === 100));
    }
    // return completed;
    //  For UHC if we have a GA assigned then we can mark producer as complete
    else if (fullProps.reviewCards.directSale === false) {
        completed = false;
        inCompletePageNames.push('producer');
        inCompleteSectionNames.push('producer');
        return {
            inCompletePageNames,
            inCompleteSectionNames,
        };
    }
    return {
        inCompletePageNames,
        inCompleteSectionNames,
    };
};

export const checkGeneralAgentsPresent = (generalAgents) => {
    let num = 0;
    const lastChars = [];
    const gaKeys = generalAgents ? Object.keys(generalAgents) : [];

    gaKeys.forEach((property) => {
        lastChars.push(property.slice(-1));
    });

    if (lastChars.includes('3')) {
        num = 4;
    } else if (lastChars.includes('2')) {
        num = 3;
    } else if (lastChars.includes('1')) {
        num = 2;
    } else if (lastChars.includes('0')) {
        num = 1;
    } else {
        num = 0;
    }

    return num;
};

export const checkCompletionGeneralAgents = (application, conditionEvaluators, appStateProps, fullProps) => {
    // let completed = false;
    let completed = true;
    const inCompletePageNames = [];
    const inCompleteSectionNames = [];
    const { producer: { generalAgent } } = application;
    const numContacts = checkGeneralAgentsPresent(generalAgent);
    if (numContacts > 0) {
        for (let i = 0; i < numContacts; i++) {
            const generalAgentFields = [
                {
                    fieldName: 'gaName',
                    fieldNameDisplay: 'General Agent Name',
                    fieldType: 'textbox',
                    isRequired: true,
                },
                {
                    fieldName: 'gaFranchiseCode',
                    fieldNameDisplay: 'General Agent Franchise Code',
                    fieldType: 'textbox',
                    isRequired: true,
                    validations: [
                        {
                            validationType: 'noSpecialCharacter',
                        },
                        {
                            validationType: 'digitsOnly',
                        },
                        {
                            validationType: 'franchiseCode',
                        }
                    ],
                    showIf: {
                        requiredConditions: 'all',
                        conditions: [{
                            conditionType: 'platforms',
                            platforms: ['PRIME'],
                        }],
                    },
                },
                {
                    fieldName: 'gaEmail',
                    fieldNameDisplay: 'General Agent Email Address',
                    fieldType: 'textbox',
                    validations: [
                        {
                            validationType: 'email',
                        },
                    ],
                    isRequired: true,
                },
                {
                    fieldName: 'gaCommunicationEmail',
                    fieldNameDisplay: 'Communication Email Address',
                    fieldType: 'textbox',
                    validations: [
                        {
                            validationType: 'email',
                        },
                    ],
                },
                {
                    fieldName: 'gaPcis',
                    isRequired: true,
                },
            ];
            // old
            // for (let j = 0; j < generalAgentFields.length; j++) {
            //     const ruleField = generalAgentFields[j];
            //     if (ruleField.isRequired) {
            //         if (!generalAgent[ruleField.fieldName + i]) {
            //             completed = false;
            //             break;
            //         } else {
            //             completed = true;
            //         }
            //     }
            // }
            for (const ruleField of generalAgentFields) {
                const fieldValue = application.producer.generalAgent[ruleField.fieldName + i];
                const isFieldRequired = ruleField.isRequired ? ruleField.isRequired : false;
                let isFieldValidationsError = false;
                const fieldHasShowIf = ruleField.showIf ? ruleField.showIf : false;
                let isFieldVisible = true;
                let validationErrors = null;
                
                if (!isUndefinedOrNullOrEmpty(fieldValue) && ruleField.validations) {
                    validationErrors = checkValidationsOnBlur(fieldValue, ruleField.validations, isFieldRequired, fullProps);
                }
                if (validationErrors !== null) {
                    isFieldValidationsError = true;
                }

                if (fieldHasShowIf) {
                    let appState = getRelevantAppState(application, 'producer', 'generalAgent', ruleField);
                    appState = makeAppStateNested(appState);
                    isFieldVisible = checkIfObjectMeetsConditions(
                        'producer',
                        ruleField.showIf, {
                        ...conditionEvaluators,
                        appState,
                    },
                        fullProps.companyProfile,
                        fullProps.rules.enrollment
                    );
                }
                if (isFieldVisible) {
                    if (isFieldRequired) {
                        if (fieldHasShowIf) {
                            if (isUndefinedOrNullOrEmpty(fieldValue) || isFieldValidationsError === true) {
                                completed = false;
                                inCompletePageNames.push('producer');
                                inCompleteSectionNames.push('generalAgent');
                            }
                        } else if (isUndefinedOrNullOrEmpty(fieldValue) || isFieldValidationsError === true) {
                            completed = false;
                            inCompletePageNames.push('producer');
                            inCompleteSectionNames.push('generalAgent');
                        }
                    } else if (!isUndefinedOrNullOrEmpty(fieldValue)) {
                        if (isFieldValidationsError === true) {
                            completed = false;
                            inCompletePageNames.push('producer');
                            inCompleteSectionNames.push('generalAgent');
                        }
                    }
                }
                
            }
        }
    }
    // return completed;
    return {
        inCompletePageNames, inCompleteSectionNames,
    };
};

export const checkCompletionEmployerAttestation = (attestationType, application, conditionEvaluators, appStateProps, fullProps) => {
    let completed = true;
    const inCompletePageNames = [];
    const inCompleteSectionNames = [];
    const { attestation: { attestation, employeeAttestation } } = application;
    let sectionName1; let sectionName2; let
        attestationPageName;
    if (attestationType === 'employer-attestation') {
        attestationPageName = 'employerAttestation';
        sectionName1 = 'employerAttestation1';
        sectionName2 = 'employerAttestation2';
    } else if (attestationType === 'employee-attestation') {
        attestationPageName = 'employeeAttestation';
        sectionName1 = 'employeeAttestation1';
        sectionName2 = 'employeeAttestation2';
    }

    const attestationRules = [
        {
            sectionName: sectionName1,
            fieldName: 'termsAgreement',
            fieldNameDisplay: 'I agree with the above terms and conditions ',
            fieldType: 'checkbox',
            isRequired: true,
        },
        {
            sectionName: sectionName1,
            fieldName: 'termsSignature',
            fieldType: 'textbox',
            isRequired: true,
        },
        {
            sectionName: sectionName2,
            fieldName: 'completedFormReceived',
            fieldNameDisplay: 'I agree that I have received and retained a record of the employer\'s completed application form which includes the employer\'s signature and date.',
            fieldType: 'checkbox',
            isRequired: true,
        },
        {
            sectionName: sectionName2,
            fieldName: 'formReceivedSignature',
            fieldType: 'textbox',
            isRequired: true,
        },

    ];
    for (const ruleField of attestationRules) {
        let fieldValue; let
            applicationSectionName;
        if (attestationType === 'employer-attestation') {
            fieldValue = application.attestation.attestation[ruleField.fieldName];
            applicationSectionName = 'attestation';
        } else if (attestationType === 'employee-attestation') {
            fieldValue = application.attestation.employeeAttestation[ruleField.fieldName];
            applicationSectionName = 'employeeAttestation';
        }
        const isFieldRequired = ruleField.isRequired ? ruleField.isRequired : false;
        let isFieldValidationsError = false;
        const fieldHasShowIf = ruleField.showIf ? ruleField.showIf : false;
        let isFieldVisible = true;
        let validationErrors = null;
        
        if (!isUndefinedOrNullOrEmpty(fieldValue) && ruleField.validations) {
            validationErrors = checkValidationsOnBlur(fieldValue, ruleField.validations, isFieldRequired, fullProps);
        }
        if (validationErrors !== null) {
            isFieldValidationsError = true;
        }

        if (fieldHasShowIf) {
            let appState = getRelevantAppState(application, 'attestation', applicationSectionName, ruleField);
            appState = makeAppStateNested(appState);
            isFieldVisible = checkIfObjectMeetsConditions(
                applicationSectionName,
                ruleField.showIf, {
                ...conditionEvaluators,
                appState,
            },
                fullProps.companyProfile,
                fullProps.rules.enrollment
            );
        }
        if (isFieldVisible) {
            if (isFieldRequired) {
                if (fieldHasShowIf) {
                        if (isUndefinedOrNullOrEmpty(fieldValue) || isFieldValidationsError === true) {
                            completed = false;
                            inCompletePageNames.push(attestationPageName);
                            inCompleteSectionNames.push(ruleField.sectionName);
                        }
                } else if (ruleField.fieldType === 'checkbox') {
                    if (isUndefinedOrNullOrEmpty(fieldValue) || isFieldValidationsError === true || !fieldValue) {
                        completed = false;
                        inCompletePageNames.push(attestationPageName);
                        inCompleteSectionNames.push(ruleField.sectionName);
                    }
                } else {
                    // checking if signature matches username while attesting
                    let userName = '';
                    if (fullProps.renewals.fullName) {
                        userName = fullProps.renewals.fullName;
                    } else if (fullProps.renewals.logInData) {
                        userName = fullProps.renewals.logInData[0].userName;
                    }
                    if (isUndefinedOrNullOrEmpty(fieldValue) || isFieldValidationsError === true || (fieldValue && fieldValue.toLowerCase() !== userName.toLowerCase())) {
                        completed = false;
                        inCompletePageNames.push(attestationPageName);
                        inCompleteSectionNames.push(ruleField.sectionName);
                    }
                }
            } else if (!isUndefinedOrNullOrEmpty(fieldValue)) {
                if (isFieldValidationsError === true) {
                    completed = false;
                    inCompletePageNames.push(attestationPageName);
                    inCompleteSectionNames.push(ruleField.sectionName);
                }
            }
        }
        
    }
    return {
        inCompletePageNames, inCompleteSectionNames,
    };
};

export const checkForTableCellsFilled = (ruleField, rulesSection, parentReducer, isCensus) => {
    let tableCellsCompleted = true;
    let tableData = [];
    if (isCensus) tableData = parentReducer[rulesSection.sectionName] && parentReducer[rulesSection.sectionName].censusDetails;
    else tableData = parentReducer[rulesSection.sectionName] && parentReducer[rulesSection.sectionName].workLocations;
    if (tableData && tableData.length > 0) {
        tableData.map((data) => {
            if (data[ruleField.fieldName] === '') {
                tableCellsCompleted = false;
            }
        });
        return tableCellsCompleted;
    }
    return false;
};

export const checkIfRequiredTabelCompleted = (section, rulesSection, parentReducer, isCensus) => {
    let completed = true;
    section.map((ruleField) => {
        if (ruleField.isRequired) {
            completed = completed && checkForTableCellsFilled(ruleField, rulesSection, parentReducer, isCensus);
        }
    });
    return completed;
};

export const checkIfRequiredTableSectionAttestationCompleted = (section, rulesSection, parentReducer, rulesSectionSecond) => {
    let completed = true;
    section.fields.map((ruleField) => {
        const attestationData = parentReducer[rulesSection.sectionName] && parentReducer[rulesSection.sectionName][rulesSectionSecond.sectionName] && parentReducer[rulesSection.sectionName][rulesSectionSecond.sectionName][ruleField.fieldName];
        if (ruleField.isRequired && attestationData === '') {
            completed = false;
        }
    });
    return completed;
};

export const checkForRequiredAvgNumberEmployeesFilled = (ruleField, rulesSection, parentReducer, rulesSectionSecond, rulesSectionThird) => {
    let tableCellsCompleted = true;
    const tableData = parentReducer[rulesSection.sectionName] && parentReducer[rulesSection.sectionName][rulesSectionSecond.sectionName] && parentReducer[rulesSection.sectionName][rulesSectionSecond.sectionName][rulesSectionThird.fieldName];
    if (tableData && tableData.length > 0) {
        tableData.map((data) => {
            if (data[ruleField.fieldName] === '') {
                tableCellsCompleted = false;
            }
        });
        return tableCellsCompleted;
    }
    return false;
};

export const checkIfRequiredAvgNumberEmployeesCompleted = (section, rulesSection, parentReducer, rulesSectionSecond, rulesSectionThird) => {
    let completed = true;
    section.map((ruleField) => {
        if (ruleField.isRequired) {
            completed = completed && checkForRequiredAvgNumberEmployeesFilled(ruleField, rulesSection, parentReducer, rulesSectionSecond, rulesSectionThird);
        }
    });
    return completed;
};

export const handlePlanSelections = (companyProfile, enrollmentRules) => {
    const selectablePlans = [];
    const { employees } = companyProfile;
    const combinedPreviousProductSelection = [];
    let resetIndicator = false;
    const handlePlanResult = {
        companyProfile: {},
        validSelections: true,
    };
    let numbMedicalSelectionOptions = 0;
    let numbDentalSelectionOptions = 0;
    let numbVisionSelectionOptions = 0;
    let numbLifeSelectionOptions = 0;
    let numbLifeDepSelectionOptions = 0;

    const medicalPlanOptions = Object.keys(enrollmentRules.enrolledPlans).map((key) => enrollmentRules.enrolledPlans[key]).flat() || [];
    const dentalPlanOptions = enrollmentRules.enrolledDentalPlans || [];
    const visionPlanOptions = enrollmentRules.enrolledVisionPlans || [];
    const lifePlanOptions = enrollmentRules.enrolledLifePlans || [];
    const dependentLifePlanOptions = enrollmentRules.enrolledDependentLifePlans || [];

    // Create array of all valid plans that can be selected in enrollment
    if (medicalPlanOptions.length > 0) {
        medicalPlanOptions.forEach((plan) => {
            selectablePlans.push(plan.codeDisplay);
            numbMedicalSelectionOptions++;
        });
    }

    if (dentalPlanOptions.length > 0) {
        dentalPlanOptions.forEach((plan) => {
            selectablePlans.push(plan.code);
            numbDentalSelectionOptions++;
        });
    }

    if (visionPlanOptions.length > 0) {
        visionPlanOptions.forEach((plan) => {
            selectablePlans.push(plan.code);
            numbVisionSelectionOptions++;
        });
    }

    if (lifePlanOptions.length > 0) {
        lifePlanOptions.forEach((plan) => {
            selectablePlans.push(plan.code);
            numbLifeSelectionOptions++;
        });
    }

    if (dependentLifePlanOptions.length > 0) {
        dependentLifePlanOptions.forEach((plan) => {
            selectablePlans.push(plan.code);
            numbLifeDepSelectionOptions++;
        });
    }

    employees.forEach((employee, locationIndex) => {
        employee.forEach((empArray, employeeIndex) => {
            if (Array.isArray(empArray.productSelection) && empArray.productSelection.length > 0) {
                const selectionObject = {
                    locationIndex,
                    employeeIndex,
                    resetProductSelection: false,
                    enrolleePreviousProductSelection: [],
                    previousProductSelectionSubmittedCensus: []
                };

                // If products were previously selected, build out comparison array for each enrollee
                empArray.productSelection.forEach((enrollee, index) => {
                    if (enrollee.hasOwnProperty('medical') && !isEmpty(enrollee.medical)) {
                        if (!selectionObject.enrolleePreviousProductSelection.includes(enrollee.medical)) {
                            selectionObject.enrolleePreviousProductSelection.push(enrollee.medical);
                        }
                        selectionObject.previousProductSelectionSubmittedCensus.push(enrollee.medical);
                    }

                    if (enrollee.hasOwnProperty('dental') && !isEmpty(enrollee.dental)) {
                        if (!selectionObject.enrolleePreviousProductSelection.includes(enrollee.dental)) {
                            selectionObject.enrolleePreviousProductSelection.push(enrollee.dental);
                        }
                        selectionObject.previousProductSelectionSubmittedCensus.push(enrollee.dental);
                    }

                    if (enrollee.hasOwnProperty('vision') && !isEmpty(enrollee.vision)) {
                        if (!selectionObject.enrolleePreviousProductSelection.includes(enrollee.vision)) {
                            selectionObject.enrolleePreviousProductSelection.push(enrollee.vision);
                        }
                        selectionObject.previousProductSelectionSubmittedCensus.push(enrollee.vision);
                    }

                    if (enrollee.hasOwnProperty('life') && !isEmpty(enrollee.life)) {
                        if (!selectionObject.enrolleePreviousProductSelection.includes(enrollee.life)) {
                            selectionObject.enrolleePreviousProductSelection.push(enrollee.life);
                        }
                        selectionObject.previousProductSelectionSubmittedCensus.push(enrollee.life);
                    }
                    // for dependent life enrollees are dependents only(index > 0 excludes employee)
                    if (index > 0 && enrollee.hasOwnProperty('life') && !isEmpty(enrollee.life)) {
                        if (!selectionObject.enrolleePreviousProductSelection.includes(enrollee.life)) {
                            selectionObject.enrolleePreviousProductSelection.push(enrollee.life);
                        }
                        selectionObject.previousProductSelectionSubmittedCensus.push(enrollee.life);
                    }
                });

                combinedPreviousProductSelection.push(selectionObject);
            }
        });

        // Compare the previous selections to the new ones, and if any invalid selections, set a trigger for resetting 'productSelection'
        combinedPreviousProductSelection.forEach((combinedSelection) => {
            combinedSelection.enrolleePreviousProductSelection.forEach((previousSelection) => {
                if (previousSelection !== 'waive') {
                    // Only check against employee/dependent plan selection if there is a plan (not "select")
                    if (!selectablePlans.includes(previousSelection) && previousSelection !== 'select' && previousSelection !== 'No Election') {
                        combinedSelection.resetProductSelection = true;
                        resetIndicator = true;
                    }
                }
            });
        });
    });

    // If trigger set, find employee instances of 'productSelection' that need to be reset
    if (resetIndicator) {
        const updatedCompanyProfile = companyProfile;

        combinedPreviousProductSelection.forEach((previousSelection) => {
            if (previousSelection.resetProductSelection === true) {
                updatedCompanyProfile.employees[previousSelection.locationIndex][previousSelection.employeeIndex].productSelection = [];

                // Check if all products will have defaulted options
                const allSelectionsWillDefaultIndictor = allSelectionsWillDefault(numbMedicalSelectionOptions, numbDentalSelectionOptions,
                    numbVisionSelectionOptions, numbLifeSelectionOptions, numbLifeDepSelectionOptions);

                // If all products will be defaulted, we set those defaulted plans in companyProfile -> productSelection for the employee and their dependents
                if (allSelectionsWillDefaultIndictor) {
                    const dependentsCount = updatedCompanyProfile.employees[previousSelection.locationIndex][previousSelection.employeeIndex].dependents.length;
                    const defaultedSelections = setDefaultProductSelection(medicalPlanOptions, dentalPlanOptions, visionPlanOptions,
                        lifePlanOptions, dependentLifePlanOptions, dependentsCount);

                    updatedCompanyProfile.employees[previousSelection.locationIndex][previousSelection.employeeIndex].productSelection = defaultedSelections;
                }
                if (updatedCompanyProfile.subSequentSubmittedCensusUploads) {
                    const dependentsCount = updatedCompanyProfile.employees[previousSelection.locationIndex][previousSelection.employeeIndex].dependents.length;
                    const defaultedSelections = setDefaultProductSelectionSubmittedCensus(medicalPlanOptions, dentalPlanOptions, visionPlanOptions,
                        lifePlanOptions, dependentLifePlanOptions, dependentsCount, previousSelection, selectablePlans,allSelectionsWillDefaultIndictor);

                    updatedCompanyProfile.employees[previousSelection.locationIndex][previousSelection.employeeIndex].productSelection = defaultedSelections;

                }

                if (!allSelectionsWillDefaultIndictor) {
                    updatedCompanyProfile.employees[previousSelection.locationIndex][previousSelection.employeeIndex].status = 'In Progress';
                }
            }
        });

        handlePlanResult.companyProfile = updatedCompanyProfile;
        handlePlanResult.validSelections = false;
    }

    return handlePlanResult;
};

const allSelectionsWillDefault = (numbMedicalSelectionOptions, numbDentalSelectionOptions, numbVisionSelectionOptions, numbLifeSelectionOptions, numbLifeDepSelectionOptions) => {
    let allSelectionsWillDefault = false;
    const productTypeSelectionTracker = [];

    // Checking if the values are not zero indicates if that product type was quoted or not
    if (numbMedicalSelectionOptions > 0) {
        productTypeSelectionTracker.push(numbMedicalSelectionOptions);
    }

    if (numbDentalSelectionOptions > 0) {
        productTypeSelectionTracker.push(numbDentalSelectionOptions);
    }

    if (numbVisionSelectionOptions > 0) {
        productTypeSelectionTracker.push(numbVisionSelectionOptions);
    }

    if (numbLifeSelectionOptions > 0) {
        productTypeSelectionTracker.push(numbLifeSelectionOptions);
    }

    if (numbLifeDepSelectionOptions > 0) {
        productTypeSelectionTracker.push(numbLifeDepSelectionOptions);
    }

    // Check if all the product types only have a single plan options
    const productTypesWithSingleOption = productTypeSelectionTracker.filter((productType) => productType === 1);

    if (productTypeSelectionTracker.length === productTypesWithSingleOption.length) {
        allSelectionsWillDefault = true;
    }

    return allSelectionsWillDefault;
};

const setDefaultProductSelection = (medicalPlanOptions, dentalPlanOptions, visionPlanOptions, lifePlanOptions,
    dependentLifePlanOptions, dependentsCount) => {
    const newProductSelection = [];
    let numberOfDefaultedEnrollees = 1 + dependentsCount;
    for (let memberIndex = 0; memberIndex < numberOfDefaultedEnrollees; memberIndex++) {
        const productSelection = {};

        // Set the defaulted plan option for each plan type
        if (medicalPlanOptions.length > 0) {
            productSelection.medical = medicalPlanOptions[0].codeDisplay;
        }

        if (dentalPlanOptions.length > 0) {
            productSelection.dental = dentalPlanOptions[0].code;
        }

        if (visionPlanOptions.length > 0) {
            productSelection.vision = visionPlanOptions[0].code;
        }

        // For employee set life and for dependents set dependent life
        if (memberIndex === 0) {
            if (lifePlanOptions.length > 0) {
                productSelection.life = lifePlanOptions[0].code;
            }
        } else {
            if (dependentLifePlanOptions.length > 0) {
                productSelection.life = dependentLifePlanOptions[0].code;
            }
        }
        newProductSelection.push(productSelection);
    }
    return newProductSelection;
};

const setDefaultProductSelectionSubmittedCensus = (medicalPlanOptions, dentalPlanOptions, visionPlanOptions, lifePlanOptions,
    dependentLifePlanOptions, dependentsCount, previousSelection, selectablePlans,allSelectionsWillDefaultIndictor) => {
    const newProductSelection = [];
    let numberOfDefaultedEnrollees = 1 + dependentsCount;
    const productSelection = {};

    //medical 
    if (previousSelection.previousProductSelectionSubmittedCensus[0] && selectablePlans.includes(previousSelection.previousProductSelectionSubmittedCensus[0])) {
        productSelection.medical = previousSelection.previousProductSelectionSubmittedCensus[0]
    }
    else if (previousSelection.previousProductSelectionSubmittedCensus[0] && previousSelection.previousProductSelectionSubmittedCensus[0] === 'waive') {
        productSelection.medical = 'waive'
    }
    // Set the defaulted plan option for each plan type
    else if (medicalPlanOptions.length > 0 && allSelectionsWillDefaultIndictor) {
        productSelection.medical = medicalPlanOptions[0].codeDisplay;
    }

    //dental 
    if (previousSelection.previousProductSelectionSubmittedCensus[1] && selectablePlans.includes(previousSelection.previousProductSelectionSubmittedCensus[1])) {
        productSelection.dental = previousSelection.previousProductSelectionSubmittedCensus[1]
    }
    else if (previousSelection.previousProductSelectionSubmittedCensus[1] && previousSelection.previousProductSelectionSubmittedCensus[1] === 'waive') {
        productSelection.dental = 'waive'
    }
    // Set the defaulted plan option for each plan type
    else if (dentalPlanOptions.length > 0 && allSelectionsWillDefaultIndictor) {
        productSelection.dental = dentalPlanOptions[0].code;
    }

    //vision 
    if (previousSelection.previousProductSelectionSubmittedCensus[2] && selectablePlans.includes(previousSelection.previousProductSelectionSubmittedCensus[2])) {
        productSelection.vision = previousSelection.previousProductSelectionSubmittedCensus[2]
    }
    else if (previousSelection.previousProductSelectionSubmittedCensus[2] && previousSelection.previousProductSelectionSubmittedCensus[2] === 'waive') {
        productSelection.vision = 'waive'
    }
    // Set the defaulted plan option for each plan type
    else if (visionPlanOptions.length > 0 && allSelectionsWillDefaultIndictor) {
        productSelection.vision = visionPlanOptions[0].code;
    }


    //life 
    if (previousSelection.previousProductSelectionSubmittedCensus[3] && selectablePlans.includes(previousSelection.previousProductSelectionSubmittedCensus[3])) {
        productSelection.life = previousSelection.previousProductSelectionSubmittedCensus[3]
    }
    else if (previousSelection.previousProductSelectionSubmittedCensus[3] && previousSelection.previousProductSelectionSubmittedCensus[3] === 'waive') {
        productSelection.life = 'waive'
    }
    // Set the defaulted plan option for each plan type
    else if (lifePlanOptions.length > 0 && allSelectionsWillDefaultIndictor) {
        productSelection.life = lifePlanOptions[0].code;
    }

    //dependent life 
    if (previousSelection.previousProductSelectionSubmittedCensus[3] && selectablePlans.includes(previousSelection.previousProductSelectionSubmittedCensus[3])) {
        productSelection.life = previousSelection.previousProductSelectionSubmittedCensus[3]
    }
    else if (previousSelection.previousProductSelectionSubmittedCensus[3] && previousSelection.previousProductSelectionSubmittedCensus[3] === 'waive') {
        productSelection.life = 'waive'
    }
    // Set the defaulted plan option for each plan type
    else if (dependentLifePlanOptions.length > 0 && allSelectionsWillDefaultIndictor) {
        productSelection.life = dependentLifePlanOptions[0].code;
    }
    // Create an instance of defaulted selections for the employee and each of their dependents
    while (numberOfDefaultedEnrollees > 0) {
        newProductSelection.push(productSelection);
        numberOfDefaultedEnrollees--;
    }

    return newProductSelection;
};

export const updatePcpSelectionForEmployees = (companyProfile, enrolledMedicalPlans) => {
    const updatedCompanyProfile = deepCopy(companyProfile);
    const { employees: employeesLocation } = updatedCompanyProfile;
    const flatMedicalPlans = Object.keys(enrolledMedicalPlans).map((key) => enrolledMedicalPlans[key]).flat() || [];
    employeesLocation.forEach((employees) => {
        employees.forEach((employee) => {
            if (flatMedicalPlans.length > 0) {
                if (employee.pcpAssignment && Array.isArray(employee.pcpAssignment) && employee.pcpAssignment.length > 0) {
                    if (employee.productSelection && Array.isArray(employee.productSelection) && employee.productSelection[0] && employee.productSelection[0].medical) {
                        const selectedMedicalPlanCode = employee.productSelection[0].medical;
                        const selectedMedicalPlan = flatMedicalPlans.find(plan => plan.code === selectedMedicalPlanCode || plan.codeDisplay === selectedMedicalPlanCode);
                        if (selectedMedicalPlan && !selectedMedicalPlan.pcpRequired) {
                            const dependentCount = (employee.dependents || []).length;
                            const initialPcpState = generateInitialPcpState(dependentCount);
                            employee.pcpAssignment = initialPcpState;
                        }
                    }
                }
            } else {
                const dependentCount = (employee.dependents || []).length;
                const initialPcpState = generateInitialPcpState(dependentCount);
                employee.pcpAssignment = initialPcpState;
            }
        })
    })
    updatedCompanyProfile.employees = employeesLocation;
    return updatedCompanyProfile;
}

export const removeUnselectedProductsFromProductSelection = (employeesLocation, selectedProducts) => {
    const updatedEmployeesLocation = deepCopy(employeesLocation);
    updatedEmployeesLocation.forEach((employees) => {
        employees.forEach((employee) => {
            if (employee.productSelection && Array.isArray(employee.productSelection) && employee.productSelection.length > 0) {
                const updatedProductSelection = deepCopy(employee.productSelection);
                updatedProductSelection.forEach(memberSelectedProduct => {
                    Object.keys(selectedProducts).forEach(product => {
                        if (!selectedProducts[product]) {
                            delete memberSelectedProduct[product];
                        }
                    })
                })
                employee.productSelection = updatedProductSelection;
            }
        })
    })
    return updatedEmployeesLocation;
}

export const generateInitialPcpState = (dependentCount) => {
    const allMembersCount = dependentCount + 1
    const newState = [];
    for(let i = 0; i < allMembersCount; i++) {
        newState.push(newPcpAssignmentObject)
    }
    return newState;
}

export const checkIfLifePlanIsNonContributory = (enrolledLifePlans, classingOpted) => {
    if (enrolledLifePlans.length > 0) {
        if (classingOpted) {
            return true;
        }
        const contributionType = [...new Set(enrolledLifePlans.map((plan) => (plan.fundingType || '').toLowerCase()))]
        if (contributionType.includes('non-contributory') || contributionType.includes('non-contributary')){
            return true;
        }
        return false;
    }
    return false;
};

export const stdLTDContributionCheck = (enrolledPlans) => {
    let contributionType
    if (enrolledPlans.length > 0) {
        contributionType = [...new Set(enrolledPlans.map(plan => (plan.contributionType || '').toLowerCase()))]
        if (contributionType.includes('non-contributory')) {
            return({values : [100,100], readOnly : true})
        } else if (contributionType.includes('contributory')) {
            return({values : [25,99], readOnly : false})
        } else if (contributionType.includes('voluntary')) {
            return ({values : [0,24], readOnly : false})
        }
    }
    return ({values : [0,0], readOnly : false});
};

// Capitated DHMO plans exclude all SMC - DC plans and any plan codes that start with S
export const checkDhmoDentalPlan = (enrolledDentalPlans) => {
    if (!enrolledDentalPlans || enrolledDentalPlans.length === 0) {
        return false;
    }

    let enrolledDhmo = false;
    enrolledDentalPlans.forEach(plan => {
        if ((plan.dentalPlanTypeCode === "DHMO" || plan.dentalPlanTypeCode === 'DHMO/Managed Care') && plan.code && plan.code[0] !== "S") {
            enrolledDhmo = true
        }
    });
    
    return enrolledDhmo;
}
