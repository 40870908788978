import * as types from './actionTypes';
import { axios } from '../utils/api/axiosConfig';
import { CASE_PROGRESS, PEND_ACCEPTANCE_REASON } from '../reducers/products/helpers/companyProfileConsts';
import { updateCaseProgress } from '../actions/companyProfileActions';
import { saveOrUpdateCaseTracking, saveOrUpdateRLCaseTracking } from '../actions/caseTrackAction';
import { updateRenewalCaseProgress } from '../actions/renewalActions';
import { isUhcRenewals } from '../utils/businessLogic/platformUtils';
import { logError } from '../utils/errorLogging/logError';
import { KEY_NAME, UHC_REN_APIS } from '../utils/errorLogging/logKeys';
import sendToSolaris from '../actions/solarisConnectActions';
import moment from 'moment';
import { updateAcceptedDate, updateCaseStatus } from '../actions/reviewActions';
import { isoStringToMMDDYYYYFormat } from '../utils/formatters/dates';
import { identifyMicroGroup } from '../components/activities/Enrollment/EmployeeEnroll/helpers/isMicroGroupHelper';

const apiRoutes = require('../utils/api/apiRouteConfig');

export const getFinalizedCaseSummary = (caseId, platform, isMicroGroup, quoteId, overallPlatform, callback = () => {}) => (dispatch, getState) => {
    const url = `${apiRoutes.quickQuotes}/get-finalize-case-summary?caseId=${caseId}&platform=${platform}&isMicroGroup=${isMicroGroup}&quoteId=${quoteId}&overallPlatform=${overallPlatform}`;
    const installPlatform = getState().caseTracking.platform;
    const caseState =getState().overall.uhcStateConfig.stateCode;

    dispatch({
        type: types.REQUEST_FINALIZE_CASE_SUMMARY,
    });

    axios.get(url)
        .then((res) => {
            // Platform must be included for each CIRRUS & PRIME stage to be maintained separately for DUAL scenarios
            let updatedData = res.data;
            let updatedCaseSummary = [];
            for (let stage of res.data.summaryData) {
                let currentStage = stage;
                currentStage.platform = platform;
                updatedCaseSummary.push(currentStage);
            }
            updatedData.caseSummary = updatedCaseSummary;

            dispatch({
                type: types.RECEIVE_FINALIZE_CASE_SUMMARY,
                payload: updatedData,
                platform: platform,
            });
            callback(res.data);
        })
        .catch((err) => {
            console.log("Error", err)
            if (err.response) {
                // dispatch({
                //     type: types.ERROR_RECEIVE_FINALIZE_CASE_SUMMARY,
                //     payload: err.response.data.message,
                // });
            }
        });
};

export const getRenewalFinalizedCaseSummary = (quoteId,platform,isMMRCase,isMicroGroup, isDualPlatform = false, identifyCirrusOrPrimeDual = '') => (dispatch , getState) => {
    const state = getState().renewals?.renewalCaseData?.situsState;
    const effectiveDate = getState().renewals?.renewalCaseData?.effectiveDate;
    const isPriorYear = getState().renewals?.priorYearDataSelected === 'Y' || false;
    const isNiceCirrusIndicator = getState().companyProfile?.isNiceCirrusIndicator === true || false;
    if(isMMRCase){
        const {companyProfile:{employees,selectedProducts},renewals}=getState();
   const stateCode=renewals?.renewalCaseData?.situsState;
   isMicroGroup=identifyMicroGroup(employees,selectedProducts,stateCode);
}
    if (isDualPlatform){
        const urlPrime = `${apiRoutes.quickQuotes}/get-renewal-finalize-case-summary?quoteId=${quoteId}&platform=PRIME&isMMRCase=${isMMRCase}&isMicroGroup=${isMicroGroup}&effectiveDate=${effectiveDate}&isPriorYear=${isPriorYear}`;
        const urlCirrus = `${apiRoutes.quickQuotes}/get-renewal-finalize-case-summary?quoteId=${quoteId}&platform=CIRRUS&isMMRCase=${isMMRCase}&isMicroGroup=${isMicroGroup}&effectiveDate=${effectiveDate}&isPriorYear=${isPriorYear}&isNiceCirrusIndicator=${isNiceCirrusIndicator}`;
        const urlNice = `${apiRoutes.quickQuotes}/get-renewal-finalize-case-summary?quoteId=${quoteId}&platform=NICE&isMMRCase=${isMMRCase}&isMicroGroup=${isMicroGroup}&effectiveDate=${effectiveDate}&isPriorYear=${isPriorYear}`;

        dispatch({
            type: types.REQUEST_RENEWAL_FINALIZE_CASE_SUMMARY,
        });

        var urlPromises = [];
        if(state === "CA"){
            if(identifyCirrusOrPrimeDual === 'PRIME'){
                urlPromises.push(axios.get(urlPrime));
            }else{
                urlPromises.push(axios.get(urlCirrus));
            }
            urlPromises.push(axios.get(urlNice));
        }
        else{
            urlPromises.push(axios.get(urlPrime));
            urlPromises.push(axios.get(urlCirrus));
        }

        Promise.all(urlPromises).then((results) => {

            var data = null;
            if(state === "CA"){
                if(platform==='PRIME' || platform==='CIRRUS'){
                    data = results[0].data;
                }else{
                    data = results[1].data;
                }
            }else{
                if (platform==='PRIME'){
                    data = results[0].data;
                } else if (platform==='CIRRUS') {
                    data = results[1].data;
                }
            }

            var res0 = results[0].data.summaryData.find(
                (sdata) => sdata.caseStage === 'Case Accepted'
            );
            var res1 = results[1].data.summaryData.find(
                (sdata) => sdata.caseStage === 'Case Accepted'
            );

            if (res0 && res1 && res0.stageStatus.toLowerCase().indexOf("completed")!=-1 &&
                res1.stageStatus.toLowerCase().indexOf("completed")!=-1
            ) {
                data.summaryData[data.summaryData.length-1].isDualCompleted = true;
            }

            dispatch({
                    type: types.RECEIVE_RENEWAL_FINALIZE_CASE_SUMMARY,
                    payload: data,
            });

            })
            .catch((err) => {
                console.log("Error", err)
                if (err.response) {
                    // dispatch({
                    //     type: types.ERROR_RECEIVE_FINALIZE_CASE_SUMMARY,
                    //     payload: err.response.data.message,
                    // });
                }
                if(isUhcRenewals()) {
                    const reqBody = {
                        quoteId, platform, isMMRCase
                    }
                    logError(KEY_NAME, UHC_REN_APIS.GET_RENEWAL_FINALIZE_CASE_SUMMARY, 'finalizeCaseSummaryActions.getRenewalFinalizedCaseSummary', reqBody, err);
                }
            });
    } else {

        platform = isMMRCase ? 'CIRRUS' : platform;
        const url = `${apiRoutes.quickQuotes}/get-renewal-finalize-case-summary?quoteId=${quoteId}&platform=${platform}&isMMRCase=${isMMRCase}&isMicroGroup=${isMicroGroup}&effectiveDate=${effectiveDate}&isPriorYear=${isPriorYear}&isNiceCirrusIndicator=${isNiceCirrusIndicator}`;

        dispatch({
            type: types.REQUEST_RENEWAL_FINALIZE_CASE_SUMMARY,
        });

        axios.get(url)
            .then((res) => {
                if(res.data && isUhcRenewals()){
                    let caseAccStage = res?.data?.summaryData.find(stage => stage.caseStage === 'Case Accepted');
                    if(caseAccStage && (caseAccStage.stageStatus === 'Case Completed - SAMx' || caseAccStage.stageStatus === 'Case Completed - User')){
                        dispatch(updateRenewalCaseProgress({caseStatus: "Accepted"}))
                        const query = {
                            caseStatus: 'Accepted',
                        };
                        dispatch(saveOrUpdateRLCaseTracking('finalise-Thankyou',query.caseStatus,0));
                        dispatch(updateCaseStatus(query));
                    }    
                }
                dispatch({
                    type: types.RECEIVE_RENEWAL_FINALIZE_CASE_SUMMARY,
                    payload: res.data,
                });
            })
            .catch((err) => {
                console.log("Error", err)
                if (err.response) {
                    // dispatch({
                    //     type: types.ERROR_RECEIVE_FINALIZE_CASE_SUMMARY,
                    //     payload: err.response.data.message,
                    // });
                }
                if(isUhcRenewals()) {
                    const reqBody = {
                        quoteId, platform, isMMRCase
                    }
                    logError(KEY_NAME, UHC_REN_APIS.GET_RENEWAL_FINALIZE_CASE_SUMMARY, 'finalizeCaseSummaryActions.getRenewalFinalizedCaseSummary', reqBody, err);
                }
            });
    }
};

// Sets the pending acceptance reason
export function updatePendingAcceptanceReason(caseId, pendingAcceptanceReason, caseProgress, callback = () => {}) {
    return (dispatch, getState) => {
        const state = getState();
        const endpoint = `${apiRoutes.quickQuotes}/update-pending-acceptance-reason`;
        const reqBody = {
            caseId,
            pendingAcceptanceReason,
            installPlatform: state.caseTracking.platform,
        };
        axios.post(endpoint, reqBody)
            .then((res) => {
                dispatch({
                    type: types.UPDATE_PEND_ACCEPTANCE_REASON,
                    pendingAcceptanceReason,
                });
                dispatch(saveOrUpdateCaseTracking('FinalizeCase', caseProgress));
                callback();
            })
            .catch((err) => {
                console.log("Error", err)
            });
    }
};

// Trigger manual installation and update case status to 'Case Accepted'
export const triggerManualInstallation = (installPlatform, overallPlatform, caseId, customerNumber, username, quoteId, isMicroGroup, finalizeCaseSummaryData, isDualPlatform = false) => {
    return (dispatch) => {
        let dualStatus = "";
        if (isDualPlatform) {
            if (finalizeCaseSummaryData) {
                const niceCaseAcceptedStage = finalizeCaseSummaryData.find((data) => data.caseStage === 'NCA');

                const primeCaseAcceptedStage = finalizeCaseSummaryData.find((data) => data.caseStage === 'CAP' && data.platform === 'PRIME');
                const cirrusCaseAcceptedStage = finalizeCaseSummaryData.find((data) => data.caseStage === 'CAP' && data.platform === 'CIRRUS');
                if (niceCaseAcceptedStage?.stageStatus === "COMPLETED" || primeCaseAcceptedStage?.stageStatus?.includes("COMPLETED") || cirrusCaseAcceptedStage?.stageStatus?.includes("COMPLETED")) {
                    dualStatus = "complete";
                } else {
                    dualStatus = "incomplete";
                }
            } else {
                dualStatus = "incomplete";
            }
        }
        
        const url = `${apiRoutes.quickQuotes}/install/prime/manual-installation`;
        const reqBody = {
            installPlatform,
            overallPlatform,
            caseId,
            customerNumber,
            username,
            quoteId,
            dualStatus: dualStatus // Passed as "" for PRIME only and "complete" or "incomplete" for DUAL
        };

        axios.post(url, reqBody)
            .then(async (res) => {
                let body = {
                    caseId,
                    caseProgress: ["Processing in error"]
                }
                let { data: logExist } = await axios.post(`${apiRoutes.quickQuotes}/getSolarisLogs`, body)
                dispatch(updatePendingAcceptanceReason(caseId, PEND_ACCEPTANCE_REASON.COMPLETED, isDualPlatform ? dualStatus === "complete" ? CASE_PROGRESS.ACCEPTED : "NICE INCOMPLETE" : CASE_PROGRESS.ACCEPTED, () => {
                    dispatch(getFinalizedCaseSummary(caseId, installPlatform === "DUAL" ? "PRIME" : installPlatform, isMicroGroup, quoteId, overallPlatform))
                }));
                // DUAL updates case progress to complete only when NICE is already installed manually
                if (isDualPlatform) {
                    if (dualStatus === "complete"){
                        if (!logExist) {
                            dispatch(sendToSolaris('Enrollment', CASE_PROGRESS.ACCEPTED));
                        }
                        dispatch(updateCaseProgress(CASE_PROGRESS.ACCEPTED, 'NB'));
                    }
                } else {
                    dispatch(updateCaseProgress(CASE_PROGRESS.ACCEPTED, 'NB'));
                }
            })
            .catch((err) => {
                console.log('Error', err);
            });
    }
};

// Trigger NB manual installation for NICE
export const triggerNiceManualInstallation = (platform, finalizeCaseSummaryData, caseId = '') => {
    return async (dispatch) => {
        let body = {
            caseId,
            caseProgress: ["Processing in error"]
        }
        let { data: logExist } = await axios.post(`${apiRoutes.quickQuotes}/getSolarisLogs`, body)
        if (platform === "DUAL") {
            if (finalizeCaseSummaryData) {
                const primeCaseAcceptedStage = finalizeCaseSummaryData.find((data) => data.caseStage === 'CAP');
                if (primeCaseAcceptedStage.stageStatus === "COMPLETED" || primeCaseAcceptedStage.stageStatus === "COMPLETED - USER"){
                    if (!logExist) {
                        dispatch(sendToSolaris('Enrollment', CASE_PROGRESS.ACCEPTED));
                    }
                    dispatch(updateCaseProgress(CASE_PROGRESS.ACCEPTED, 'NB'));
                } 
            }
        } else {
            if (!logExist) {
                dispatch(sendToSolaris('Enrollment', CASE_PROGRESS.ACCEPTED));
            }
            dispatch(updateCaseProgress(CASE_PROGRESS.ACCEPTED, 'NB'));
        }
    }
}

// Trigger manual installation and update case status to 'Case Accepted'
export const triggerRenewalManualInstallation = (installPlatform,username,quoteId,memberGroupId,isMMRCase, isDualPlatform, isMicroGroup, isNiceCirrusIndicator) => {
    return (dispatch) => {
        const url = `${apiRoutes.quickQuotes}/install/prime/renewal-manual-installation`;
        const reqBody = {
            installPlatform,
            username,
            quoteId,
            memberGroupId,
            isMMRCase,
            isMicroGroup,
            isDualPlatform,
            isNiceCirrusIndicator
        };

        axios.post(url, reqBody)
            .then((res) => {
                if(installPlatform !== 'DUAL'){
                    dispatch(getRenewalFinalizedCaseSummary(quoteId, installPlatform,isMMRCase,isMicroGroup, isDualPlatform))
                }
                if (!isDualPlatform || (res?.data?.status && res.data.status === 'Accepted' )) {
                    dispatch(updateRenewalCaseProgress({caseStatus:'Accepted'})); 
                    dispatch(updateAcceptedDate(memberGroupId, isoStringToMMDDYYYYFormat(new Date().toISOString())))
                }
            })
            .catch((err) => {
                console.log('Error', err);
            });
    }
};

export const triggerRenewalNiceManualInstallation=()=>{
    return (dispatch)=>{
            dispatch(updateRenewalCaseProgress({caseStatus:'Accepted'}));
    }
}

// Update boolean for REVIEWED or FIXED field in Mongo for a specific member error
export const updateMemberReviewedOrFixedStatus = (caseId, type, error) => (dispatch) => {
    const reqBody = {
        caseId,
        type,
        error,
    };

    const url = `${apiRoutes.quickQuotes}/update-reviewed-or-fixed-status`;

    axios.post(url, reqBody)
        .then((res) => {
            dispatch({
                type: types.RECEIVE_FINALIZE_CASE_SUMMARY,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log(err);
        });
}; 

export const resetFinalizeFlag = (resetVal) => {
    return (dispatch) => {
        dispatch({
            type: types.RESET_FINALIZE_FLAG,
            payload: resetVal,
        });
    };
}

        
        export const setRenewalNiceCaseSummary = (userName, caseSummaryType, previousDateTime) => {
    const currentDateTime = moment(new Date()).format('MM/DD/YYYY HH:mm:ss');
    // Upon initialization, NICE cases display Group and Member Processing stages as "Manual Installation"
    const niceCaseSummary = [
        {
            caseStage: "Group Processing",
            caseStageDesc: "Group Processing",
            timeStamp: caseSummaryType === "initial" ? currentDateTime : previousDateTime,
            seq: 101,
            stageStatus: "Manual Installation",
            userName: userName
        },
        {

            caseStage: "Member Processing",
            timeStamp: caseSummaryType === "initial" ? currentDateTime : previousDateTime,
            seq: 102,
            stageStatus: "Manual Installation",
            userName: userName
        }
    ];
    // Upon initialization, Case Accepted stage is "Not Started"
    if (caseSummaryType === "initial") {
        niceCaseSummary.push({
            caseStage: "Case Accepted",
            timeStamp: "",
            seq: 103,

            stageStatus: "Not Started",
            userName: ""
        });
    }
    // Upon Manual Installation, Case Accepted stage is "Case Completed - User"
    if (caseSummaryType === "manualInstallation") {
        niceCaseSummary.push({

            caseStage: "Case Accepted",
            timeStamp: currentDateTime,
            seq: 103,
            stageStatus: "Case Completed - User",
            userName: userName
        });
    }
    return (dispatch) => {
        dispatch({
            type:types.SET_RENEWAL_NICE_CASE_SUMMARY,
            payload: niceCaseSummary,
        });
    }
}
        
        export const setNiceCaseSummary = (userName, caseSummaryType, previousDateTime) => {
    const currentDateTime = moment(new Date()).format('MM/DD/YYYY HH:mm:ss');

    // Upon initialization, NICE cases display Group and Member Processing stages as "Manual Installation"
    const niceCaseSummary = [
        {
            caseStage: "NGP",
            caseStageDesc: "Group Processing",
            dateTime: caseSummaryType === "initial" ? currentDateTime : previousDateTime,
            order: 101,
            stageStatus: "MANUAL_INSTALLATION",
            stageStatusDesc: "Manual Installation",
            userName: userName
        },
        {
            caseStage: "NMP",
            caseStageDesc: "Member Processing",
            dateTime: caseSummaryType === "initial" ? currentDateTime : previousDateTime,
            order: 102,
            stageStatus: "MANUAL_INSTALLATION",
            stageStatusDesc: "Manual Installation",
            userName: userName
        }
    ];

    // Upon initialization, Case Accepted stage is "Not Started"
    if (caseSummaryType === "initial") {
        niceCaseSummary.push({
            caseStage: "NCA",
            caseStageDesc: "Case Accepted",
            dateTime: "",
            order: 103,
            stageStatus: "NOT_STARTED",
            stageStatusDesc: "Not Started",
            userName: ""
        });
    }

    // Upon Manual Installation, Case Accepted stage is "Case Completed - User"
    if (caseSummaryType === "manualInstallation") {
        niceCaseSummary.push({
            caseStage: "NCA",
            caseStageDesc: "Case Accepted",
            dateTime: currentDateTime,
            order: 103,
            stageStatus: "COMPLETED",
            stageStatusDesc: "Case Completed - User",
            userName: userName
        });
    }

    return (dispatch) => {
        dispatch({
            type: types.SET_NICE_CASE_SUMMARY,
            payload: niceCaseSummary,
            niceStatus: caseSummaryType
        });
    }
}

  export function insertNiceCaseSummary() {
    return (dispatch, getState) => new Promise((resolve, reject) => {
        const caseId = getState().caseTracking.caseId;
        const caseName = getState().companyProfile.companyName;
        const quoteTrackingNumber = getState().caseTracking.currentQuoteId;
        const niceCaseSummary = getState().finalizeCase.finalizeCaseSummary.summaryData;

        axios.post(`${apiRoutes.mongo}/niceCaseSummary?quoteTrackingNumber=${quoteTrackingNumber}&caseId=${caseId}&caseName=${caseName}`, {summaryData: niceCaseSummary})
            .then(
                (result) => {
                    resolve(result.data);
                },
            ).catch((err) => {
                reject(err);
                console.log("Error inserting NICE case summary:", err);
            });
    });
}
  
  export function insertRenewalNiceCaseSummary() {
    return (dispatch, getState) => new Promise((resolve, reject) => {
        const caseId = getState().caseTracking.caseId;
        const caseName = getState().companyProfile.companyName;
        const quoteTrackingNumber = getState().caseTracking.currentQuoteId;
        const niceCaseSummary = getState().renewalFinalizeCase.finalizeCaseSummary.summaryData;

        axios.post(`${apiRoutes.mongo}/renewalniceCaseSummary?quoteTrackingNumber=${quoteTrackingNumber}&caseId=${caseId}&caseName=${caseName}`, {summaryData: niceCaseSummary})
            .then(
                (result) => {
                    resolve(result.data);
                },
            ).catch((err) => {
                reject(err);
                console.log("Error inserting NICE case summary:", err);
            });
    });
}
      
      export function getRenewalNiceCaseSummary() {
    return (dispatch, getState) => {
        const quoteTrackingNumber = getState().caseTracking.currentQuoteId;
        const query = {quoteTrackingNumber: quoteTrackingNumber}

        dispatch({
            type: types.REQUEST_RENEWAL_FINALIZE_CASE_SUMMARY,
        });    
        axios.get(`${apiRoutes.mongo}/renewalniceCaseSummary`, { params: query })
            .then((res) => {
                const niceCaseSummary = res?.data.niceCaseSummary;

                dispatch({
                    type: types.SET_RENEWAL_NICE_CASE_SUMMARY,
                    payload: niceCaseSummary,
                });
            }).catch(err => {
                console.log("Error fetching NICE case summary:", err);
            })
    };
}
      
    export function getNiceCaseSummary() {
    return (dispatch, getState) => {
        const quoteTrackingNumber = getState().caseTracking.currentQuoteId;
        const query = {quoteTrackingNumber: quoteTrackingNumber}

        axios.get(`${apiRoutes.mongo}/niceCaseSummary`, { params: query })
            .then((res) => {
                const niceCaseSummary = res?.data.niceCaseSummary;

                dispatch({
                    type: types.SET_NICE_CASE_SUMMARY,
                    payload: niceCaseSummary,
                });
            }).catch(err => {
                console.log("Error fetching NICE case summary:", err);
            })
    };
}

    
      export function updateNiceCaseSummary() {
    return (dispatch, getState) => {
        const quoteTrackingNumber = getState().caseTracking.currentQuoteId;
        const niceCaseSummary = getState().finalizeCase.finalizeCaseSummary.summaryData;

        axios.put(`${apiRoutes.mongo}/niceCaseSummary?quoteTrackingNumber=${quoteTrackingNumber}`, {summaryData: niceCaseSummary})
            .catch(err => {
                console.log("Error inserting NICE case summary:", err);
            })
    };
} 
      
      export function updateRenewalNiceCaseSummary() {
    return (dispatch, getState) => {
        const quoteTrackingNumber = getState().caseTracking.currentQuoteId;
        const niceCaseSummary = getState().renewalFinalizeCase.finalizeCaseSummary.summaryData;

        axios.put(`${apiRoutes.mongo}/renewalniceCaseSummary?quoteTrackingNumber=${quoteTrackingNumber}`, {summaryData: niceCaseSummary})
            .catch(err => {
                console.log("Error inserting NICE case summary:", err);
            })
    };
}