import { isoStringToMMDDYYYY, getZeroPaddedString } from "../../../../../utils/formatters/dates";
import { displayTieredCoverageTypeViaProducts } from "../../RenewalsCommonComponents/saveDetails";
import { getMarketType, MARKET_TYPES, isMmr, isUhcRenewals } from "../../../../../utils/businessLogic/platformUtils";
import { axios } from '../../../../../utils/api/axiosConfig';
import * as types from '../../../../../actions/actionTypes';
import { logError } from "../../../../../utils/errorLogging/logError";
import { KEY_NAME, UHC_REN_APIS } from "../../../../../utils/errorLogging/logKeys";

import { store } from '../../../../../index';
import ltd from "../../../../../reducers/products/ltdReducer";
const apiRoutes = require('../../../../../utils/api/apiRouteConfig');

const rateAdjustmentRenewals = (monthlyPremium, specialtyCriteria) => {
    const census = specialtyCriteria
    const { employee, empAndSpouse, empAndChild, empAndFamily } = monthlyPremium;
    const eeCount = census.employeeOnly !== "" ? parseInt(census.employeeOnly) : 0;
    const esCount = census.employeeWithSpouse !== "" ? parseInt(census.employeeWithSpouse) : 0;
    const ecCount = census.employeeWithChild !== "" ? parseInt(census.employeeWithChild) : 0;
    const efCount = census.employeeWithFamily !== "" ? parseInt(census.employeeWithFamily) : 0;
    const totalMonthlyPremiumRenewals = eeCount * parseFloat(employee) +
        esCount * parseFloat(empAndSpouse) +
        ecCount * parseFloat(empAndChild) +
        efCount * parseFloat(empAndFamily);
    return totalMonthlyPremiumRenewals
}

export const findHighestCoverage = (coverageLevelArray) => {
    if (coverageLevelArray.includes('FAM') || coverageLevelArray.includes('EE/FAM'))
        return 'FAM'
    if (coverageLevelArray.includes('EF'))
        return 'EF'
    else if (coverageLevelArray.includes('EC'))
        return 'EC'
    else if (coverageLevelArray.includes('ECH') || (coverageLevelArray.includes('EE/CH')))
        return 'ECH'
    else if (coverageLevelArray.includes('ES'))
        return 'ES'
    else if ((coverageLevelArray.includes('ESP') || coverageLevelArray.includes('EE/SP')))
        return 'ESP'
    else if (coverageLevelArray.includes('EO'))
        return 'EO'
    else if ((coverageLevelArray.includes('EMP') || coverageLevelArray.includes('EE')))
        return 'EMP'
    else return 'EMP'
}

const covTypes = {
    'EMP': 'employee',
    'ESP': 'empAndSpouse',
    'ECH': 'empAndChild',
    'FAM': 'empAndFamily',
};
export const getAge = (dateString, effectiveDate) => {
    const marketType = getMarketType();
    let date;
    if (marketType === 'UHC') {
        date = new Date(effectiveDate); // Use effective date for UHC
    } else {
        date = new Date(); // Use current date for Oxford
    }
    const birthDate = new Date(dateString);
    let age = date.getFullYear() - birthDate.getFullYear();
    const m = date.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && date.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}

export const formatCompositeRates = (rates) => {
    let compositeRates = [];
    let labelMap;
    let labelMap1;

    if (rates) {
        if (Object.keys(rates).length ===2){
            labelMap={
                tier1: "Under 21",
                tier2: "21 & Over",
            };
            labelMap1={
                tier1: "Under 21",
                tier2: "21 & Over",
            };
        }
        else{
            labelMap = {
                tier1: "Employee Only",
                tier2: "Employee and Spouse",
                tier3: "Employee and Child",
                tier4: "Employee and Family"
            };
            labelMap1 = {
                tier1: "EMPLOYEE",
                tier2: "EE+SP",
                tier3: "EE+CH",
                tier4: "EE+FAM"
            };
        }
        Object.keys(rates).forEach(rate => {
            compositeRates.push({
                employeeType: labelMap1 && labelMap1[rate] ? labelMap1[rate] : labelMap[rate],
                employeeTypeDisplay: labelMap[rate],
                rate: rates[rate]
            })
        })
    }
    return compositeRates
}

export const formatAgeBand = (rates) => {
    let ratesNew = [];
    for (let i = 0; i < rates.length; i++) {
        ratesNew[i] = {
            minAge: rates[i].minAge,
            maxAge: rates[i].maxAge,
            total: parseFloat(rates[i].rates.tier1)
        }
    }
    return ratesNew;
}
export const formatDate = (date) => {
    if (date > 0 && date < 10) {
        date = '0' + date;
    }
    return date;
}

const mapRate = (rateInd) => {
    if(rateInd === "1"){
        return "24"
    }else if(rateInd === "2"){
        return "12"
    }else{
        return "0"
    }
}

const getNiceCustomerNumber = (ibr) => {
    const groupCdd = ibr.group.clientDefinedData.data;

    for(let i=0; i<groupCdd.length; i++) {
        if(groupCdd[i].key === 'NICEPRIMECustNbr') return groupCdd[i].value;
    }
}

export const FilterIBR = async (ibrData, reviewData = null, generateAlternate = false) => {
    const planOfferingData = ibrData.quote.planOfferingData;
    const subGroups = ibrData?.quote?.subgroups?.subgroup;
    const empCensus = ibrData.quote.census.employee;
    const stateCode = ibrData.group.primaryLocation.address.state;
    const situsState = ibrData.group.primaryLocation.clientDefinedData?.data?.find(cdd=> cdd.key === 'ContractIssueStCode')?.value || '';
    const groupSize = ibrData.group.primaryLocation.totalEligible;
    const effectiveDate = `${ibrData.quote.quoteEffDate.month}/${ibrData.quote.quoteEffDate.day}/${ibrData.quote.quoteEffDate.year}`;
    const Ibr = {};
    const ibrPlans = [];
    const alternate = [];
    const alternatePlanCodes = [];
    const spPlans = [];
    const chPlans = [];
    let currentMedicalQuote = 0;
    const SEE_BENEFIT_SUMMARY = 'See Benefit Summary';

    let niceCustomerNumber = '';
    if (
        reviewData &&
        reviewData.renewalCaseData &&
        reviewData.renewalCaseData.custType === "NICE"
    )
        niceCustomerNumber = getNiceCustomerNumber(ibrData);

    let planMatch = [];

    if(!generateAlternate) {
        for (const planOfferings of planOfferingData) {
            for (const item in planOfferings) {
                if (item === 'planMatch') {
                    let count = 0;
                    for (let planSet of planOfferings[item]) {
                        planMatch.push({id: count, ...planSet});
                        count++;
                    }
                    break;
                }
            }
        }
    }

    for (const planOfferings of planOfferingData) {
        for (const item in planOfferings) {
            if(!generateAlternate) {
                if (item === 'existingPlanPkg') {
                    const existingPlans = planOfferings[item].plans.plan;

                    for (const existingPlan of existingPlans) {
                        if (existingPlan.productLineType.includes('MEDICAL')) {
                            ibrToMedical(existingPlan, 'current', '', empCensus, effectiveDate)
                        }
                        if (existingPlan.productLineType.includes('DENTAL')) {
                            await ibrToDental(existingPlan, 'current', '', empCensus)
                        }
                        if (existingPlan.productLineType.includes('VISION')) {
                            await ibrToVision(existingPlan, 'current', '', empCensus)
                        }
                        if (existingPlan.productLineType.includes("BASIC_LIFE_EE")) {
                            await ibrToBasicLife(existingPlan, 'current', reviewData, empCensus , planOfferings.planOfferingXref , subGroups)
                        }
                        if (existingPlan.productLineType.includes("BASIC_LIFE_SPOUSE") || existingPlan.productLineType.includes("BASIC_LIFE_CHILD")) {
                            await ibrToDependentLife(existingPlan, 'current', reviewData, empCensus)
                        }
                        if (existingPlan.productLineType.includes("SUP_LIFE_EE")) {
                            await ibrToSuppLife(existingPlan, 'current', reviewData, empCensus, existingPlans)
                        }
                        // if (existingPlan.productLineType.includes("SUP_LIFE_CHILD")) {
                        //     await ibrToSuppLife(existingPlan, 'current', reviewData, empCensus, existingPlans)
                        // }
                        // if (existingPlan.productLineType.includes("SUP_LIFE_SPOUSE")) {
                        //     await ibrToSuppLife(existingPlan, 'current', reviewData, empCensus, existingPlans)
                        // }

                        if (existingPlan.productLineType.includes("LTD")) {
                            await ibrToLTD(existingPlan, 'current', reviewData, empCensus, planOfferings.planOfferingXref , subGroups)
                        }
                        // if (existingPlan.productLineType.includes("BASIC_LIFE_CHILD")) {
                        //     ibrToDependentLife(existingPlan, 'current', 'ch_', empCensus)
                        // }
                        if(existingPlan?.productLineType.includes('STD')){
                            await ibrToShortTermDisability(existingPlan, 'current', reviewData, empCensus,  planOfferings.planOfferingXref , subGroups)
                        }
                    }
                }
                if (item === 'newPlanPkg') {

                    const newPlans = planOfferings[item].plans.plan;

                    for (const newPlan of newPlans) {
                        if (newPlan.productLineType.includes('MEDICAL')) {
                            ibrToMedical(newPlan, 'renewing', '', empCensus, effectiveDate);
                        }
                        if (newPlan.productLineType.includes('DENTAL')) {
                            await ibrToDental(newPlan, 'renewing', '', empCensus)
                        }
                        if (newPlan.productLineType.includes('VISION')) {
                            await ibrToVision(newPlan, 'renewing', '', empCensus)
                        }
                        if (newPlan.productLineType.includes("BASIC_LIFE_EE")) {
                            await ibrToBasicLife(newPlan, 'renewing', reviewData, empCensus , planOfferings.planOfferingXref , subGroups)
                        }
                        if (newPlan.productLineType.includes("BASIC_LIFE_SPOUSE") || newPlan.productLineType.includes("BASIC_LIFE_CHILD")) {
                            await ibrToDependentLife(newPlan, 'renewing', reviewData, empCensus)
                        }
                        if (newPlan.productLineType.includes("SUP_LIFE_EE")) {
                            await ibrToSuppLife(newPlan, 'renewing', reviewData, empCensus, newPlans)
                        }
                        if (newPlan.productLineType.includes("LTD")) {
                            await ibrToLTD(newPlan, 'renewing', reviewData, empCensus, planOfferings.planOfferingXref , subGroups)
                        }
                        // if (newPlan.productLineType.includes("BASIC_LIFE_CHILD")) {
                        //     ibrToDependentLife(newPlan, 'renewing', 'ch_', empCensus)
                        // }
                        if(newPlan?.productLineType.includes('STD')){
                            await ibrToShortTermDisability(newPlan,'renewing',reviewData,empCensus, planOfferings.planOfferingXref , subGroups)
                        }
                    }
                }
            }
            if (item === 'alternatePlanPkg') {

                const alternatePlans = planOfferings[item].plans.plan;

                for (const alternatePlan of alternatePlans) {
                    if (alternatePlan.productLineType.includes('MEDICAL')) {
                        ibrToMedical(alternatePlan, 'alternate', reviewData, empCensus, effectiveDate)
                    }
                    if (alternatePlan.productLineType.includes('DENTAL')) {
                        await ibrToDental(alternatePlan, 'alternate', reviewData, empCensus)
                    }
                    if (alternatePlan.productLineType.includes('VISION')) {
                        await ibrToVision(alternatePlan, 'alternate', reviewData, empCensus)
                    }
                    if (alternatePlan.productLineType.includes("BASIC_LIFE_EE")) {
                        await ibrToBasicLife(alternatePlan, 'alternate',  reviewData, empCensus ,"" , [])
                    }
                    if(alternatePlan?.productLineType.includes('STD')){
                        await ibrToShortTermDisability(alternatePlan,'alternate',reviewData,empCensus)
                    }
                    if (alternatePlan.productLineType.includes("LTD")) {
                        await ibrToLTD(alternatePlan, 'alternate',  reviewData, empCensus)
                    }
                }
            }
        }
    }
    function calculatePercentage(ibrPlans, renewingQuote, planType) {
        let currentQuote = ibrPlans.filter(plan=> plan.renewalPlanType === 'current' && plan.planType===planType);
        if(currentQuote.length>0) {
            currentQuote = currentQuote[0].finalMonthlyPremium
            let difference = 0
            let percentage = 0

            if ((currentQuote === 0.00 && renewingQuote === 0.00) || currentQuote === renewingQuote) {
                percentage = parseFloat(0).toFixed(2)
            }
            else if (currentQuote === 0) {
                percentage = parseFloat(100).toFixed(2)
            }
            else if (renewingQuote === 0) {
                percentage = parseFloat(-100).toFixed(2)
            }
            else {
                difference = renewingQuote - currentQuote
                percentage = ((parseFloat(difference) / parseFloat(currentQuote)) * 100).toFixed(2)
            }
            return percentage;
        }
    }
        function checkBelonging(renewalPlanType,employee,existingPlan,exists,planType = "")
    {
        let currPlans = employee?.electedPlans?.plan;
        let renPlans = employee?.renewingPlans?.plan;
        if(isUhcRenewals() && planType === "LIFE_DEP_BASIC")
        {
            // for depPlans the values are stores in coverageElection
            currPlans=employee.coverageElections.plan;
            renPlans= employee.renewingCoverageElections.plan;
        }
        let altPlans = employee?.alternatePlans?.plan;
        if(renewalPlanType === "current" && currPlans){
            for(let i=0;i<currPlans.length;i++)
            {
                if(currPlans[i].planID === existingPlan.planID)
                    exists = true;
            }
    }
        if(renewalPlanType === "renewing" && renPlans){
            for(let i=0;i<renPlans.length;i++)
            {
                if(renPlans[i].planID === existingPlan.planID)
                    exists = true;
            }
    }
        if(renewalPlanType === "alternate" && altPlans){
            for(let i=0;i<altPlans.length;i++)
            {
                if(altPlans[i].planID === existingPlan.planID)
                    exists = true;
            }
        }
        return exists;
    }

    function filterChars (rxTiers) {
        if (!rxTiers) {
            return '0/0/0/0'
        }
        return rxTiers.split('').filter(c => (c >= '0' && c <= '9') || c === '$' || c === '/' || c === '%').join('')
    }

    function findRxTiers (rxBenCopayAmt) {
        if (rxBenCopayAmt === '0% 4 TIERS E' || rxBenCopayAmt === 'RX APPLIES TO DEDUCTIBLE' || !rxBenCopayAmt) {
            return '0/0/0/0'
        }
        if(rxBenCopayAmt === '50% ALL TIERS E'){
            return '50%/50%/50%/50%'
        }
        if(rxBenCopayAmt.includes("MIN")){
            
            let rxTiers=rxBenCopayAmt.split("/").map((element)=>element.slice(0,3)).join("/")
            return filterChars(rxTiers)
        }
        if(rxBenCopayAmt.includes("MAX")){
            if(rxBenCopayAmt.includes("ALL TIERS")){
                let rx = rxBenCopayAmt.slice(0,3);
                let rxTiers=rx+"/"+rx+"/"+rx+"/"+rx
                return filterChars(rxTiers);
            }else{
                if(rxBenCopayAmt.includes("D")){
                    let rxTiers = rxBenCopayAmt.slice(6,35).split("/").filter((tier)=>tier.charAt(0)!=="S").join("/");
                    return filterChars(rxTiers);
                }
                else{
                    let rxTiers = rxBenCopayAmt.slice(0,rxBenCopayAmt.length).split("/").filter((tier)=>tier.charAt(0)!=="S").join("/");
                    return filterChars(rxTiers);
                }
                
            }
           
        }
        //if RxBenCopayAmt in format A%B%C%D%
        else if (!rxBenCopayAmt.includes("/")){
             let rx= rxBenCopayAmt.split("%").filter((tier)=>tier.charAt(0)!=="S").join("%/")
             let rxTiers= rx.charAt(rx.length-1) === "E" ? rx.substring(0,rx.length-2) : rx.substring(0,rx.length-1);
             
             return filterChars(rxTiers)

        }
        else{

       
        let rxTiersUpdatedArr = rxBenCopayAmt.split("/").filter((tier)=>tier.charAt(0)!=="S").join("/");
        let rxTiersArr = rxTiersUpdatedArr.split(' ')
        let rxTiers = rxTiersArr[rxTiersArr.length - 1]
        return filterChars(rxTiers)
    }
    }

    function ibrToMedical(existingPlan, renewalPlanType, reviewData = null, empCensus, effectiveDate) {

        const existingPlanOptions = existingPlan.options.planOption;
        const existingPlanRates = existingPlan.rates.compositeRates || existingPlan.rates.ageRates;
        const existingPlanClientDefinedData = existingPlan.clientDefinedData.data;
        let rxComplete = ""
        let planObj = {
            planType: 'Medical',
            rx: {
                tiers: []
            },
            platform: 'PRIME',
            code: existingPlan.planXREF,
            rxPlanCode: existingPlan.planID.split('/')[1],
            medicalPlanCode: existingPlan.planID,
            renewalPlanType: renewalPlanType === 'renewing' ? 'renewing' : renewalPlanType === 'current' ? 'current' : 'alternate',
            renewalPlan: renewalPlanType === 'renewing',
            deductible: {},
            oopMax: {},
            member:[],
            hospitalCoinsurance: {},
            hraContribution: {
                min: '',
                max: '',
            },
            ageBands: [],
            packageId: existingPlan.planFamilyName ? existingPlan.planFamilyName : '',
        }
        planObj.totalEligibleEmployees = empCensus.length

        if(niceCustomerNumber) planObj['niceCustomerNumber'] = niceCustomerNumber;

        for (const clientDefinedData of existingPlanClientDefinedData) {
            if(clientDefinedData.key === "CostDifferentialPercentage" && renewalPlanType === 'renewing') {
                planObj["renewalChangePercent"] =  clientDefinedData.value;
            }
            if (clientDefinedData.key === 'PlanCategory') {
                planObj["medicalPlanCategory"] = clientDefinedData.value;
            }
            if((renewalPlanType === 'renewing' || renewalPlanType === 'alternate') & clientDefinedData.key === "OISABITName") {
                planObj['OISABITName'] = clientDefinedData.value ? (clientDefinedData.value.toUpperCase() === 'UNITEDHEALTHCARE' ? '0100CG' :clientDefinedData.value.toUpperCase() === 'MID-ATLANTIC HP' ? '0400CG':''): '';
            }
            if (clientDefinedData.key === 'ProductInsuranceLicenseType') {
                planObj["licenseType"] = clientDefinedData.value;
            }
            if (clientDefinedData.key === 'RxDeductible') {
                planObj.rx["deductible"] = clientDefinedData.value;
            }
            if (clientDefinedData.key === 'MetalTier') {
                planObj["metal"] = clientDefinedData.value;
            }
            if (clientDefinedData.key === 'EmployeeEnrollCount') {
                planObj["enrolledCount"] = clientDefinedData.value;
            }
            if (clientDefinedData.key === 'RenewalRateID') {
                if (existingPlan.rates.ageRates) {
                    planObj["platformAgeBandedRatingID"] = clientDefinedData.value;
                } else {
                    planObj["platformCompositeRatingID"] = clientDefinedData.value;
                }
            }
            if (clientDefinedData.key === 'underwriterExceptionCode1') {
                planObj["underwriterExceptionCode"] = clientDefinedData.value;
            }
            if (clientDefinedData.key === 'UhcRewardsInd' && clientDefinedData.value !== 'N') {
                planObj["uhcRewards"] = clientDefinedData.value === 'C' ? 'uhcRewardsCore' : 'uhcRewardsPremium';
            }
            if (clientDefinedData.key === 'PlatformIdentifier') {
                planObj["platform"] = clientDefinedData.value ? clientDefinedData.value.toUpperCase() : "PRIME";
            }
            if(clientDefinedData.key === 'PolicyNumber') {
                planObj["primePolicyNumber"] = clientDefinedData.value ? clientDefinedData.value : "";
            }
            if(clientDefinedData.key === 'NICEPRIMEPolnbr') {
                planObj["nicePolicyNumber"] = clientDefinedData.value ? clientDefinedData.value : "";
            }
        }
        for (const planOption of existingPlanOptions) {

            if(planOption.planOptionID === 'PrimaryCarePhyRequiredInd') {
                planObj["pcpRequired"] = planOption.planOptionValueID === 'Y';
            }

            if (planOption.planOptionID === 'IndInNetdeductibleAmount') {
                planObj.deductible["individualIn"] =
                  planOption.planOptionValueID === "NA"
                    ? "N/A"
                    : planOption.planOptionValueID;
            }
            if (planOption.planOptionID === 'IndOutNetDeductibleAmount') {
                planObj.deductible["individualOut"] =
                  planOption.planOptionValueID === "NA"
                    ? "N/A"
                    : planOption.planOptionValueID;
            }
            if (planOption.planOptionID === 'FamilyInNetDeductibleAmount') {
                planObj.deductible["familyIn"] =
                  planOption.planOptionValueID === "NA"
                    ? "N/A"
                    : planOption.planOptionValueID;
            }
            if (planOption.planOptionID === 'FamilyOutNetDeductibleAmount') {
                planObj.deductible["familyOut"] =
                  planOption.planOptionValueID === "NA"
                    ? "N/A"
                    : planOption.planOptionValueID;
            }

            if (planOption.planOptionID === 'IndividualInNetOopMaxAmount') {
                planObj.oopMax["individualIn"] =
                  planOption.planOptionValueID === "NA"
                    ? "N/A"
                    : planOption.planOptionValueID;
            }
            if (planOption.planOptionID === 'IndividualOutNetOopMaxAmount') {
                planObj.oopMax["individualOut"] =
                  planOption.planOptionValueID === "NA"
                    ? "N/A"
                    : planOption.planOptionValueID;
            }
            if (planOption.planOptionID === 'FamilyInNetOopMaxAmount') {
                planObj.oopMax["familyIn"] =
                  planOption.planOptionValueID === "NA"
                    ? "N/A"
                    : planOption.planOptionValueID;
            }
            if (planOption.planOptionID === 'FamilyOutNetOopMaxAmount') {
                planObj.oopMax["familyOut"] =
                  planOption.planOptionValueID === "NA"
                    ? "N/A"
                    : planOption.planOptionValueID;
            }

            //TODO: For Hospital In/Out if NA then 0 in NB, what should be the case in renewals?
            if (planOption.planOptionID === 'HospitalInNetCoInsurancePct') {
                planObj.hospitalCoinsurance["in"] = planOption.planOptionValueID;
            }
            if (planOption.planOptionID === 'HospitalOutNetCoInsurancePct') {
                planObj.hospitalCoinsurance["out"] = planOption.planOptionValueID;
            }
            if (planOption.planOptionID === 'RxBenCopayAmt') {
                if (isUhcRenewals()) {
                    rxComplete = findRxTiers(planOption.planOptionValueID)
                } else {
                    rxComplete = planOption.planOptionValueID
                }
            }

            if (planOption.planOptionID === 'PlanFundingDescription') {
                planObj['customPlanName'] = planOption.planOptionValueID;
            }

            if (planOption.planOptionID === 'PhysicianCopayAmount') {
                planObj["pcpCopay"] =
                  planOption.planOptionValueID === "NA"
                    ? "N/A"
                    : planOption.planOptionValueID;
                planObj["pcpCopayOut"] =
                    planOption.planOptionValueID === "NA"
                      ? "N/A"
                      : planOption.planOptionValueID;
            }

            if (planOption.planOptionID === 'SpecialistCopayAmount') {
                planObj["specialistCopay"] =
                  planOption.planOptionValueID === "NA"
                    ? "N/A"
                    : planOption.planOptionValueID;
                planObj["specialistCopayOut"] =
                  planOption.planOptionValueID === "NA"
                    ? "N/A"
                    : planOption.planOptionValueID;
            }

            if (planOption.planOptionID === 'HraPlanAvailableInd') {
                planObj["hra"] = planOption.planOptionValueID === 'Y';
            }

            if (planOption.planOptionID === 'HraPlanContributionMaxAmount') {
                planObj["hraContribution"].max = planOption.planOptionValueID;
            }

            if (planOption.planOptionID === 'HraPlanContributionMinAmount') {
                planObj["hraContribution"].min = planOption.planOptionValueID;
            }
            if (planOption.planOptionID === 'MedicalPlanType') {
                planObj["medicalPlanType"] = planOption.planOptionValueID;
                planObj["planGroup"] = planOption.planOptionValueID;
            }

            if (planOption.planOptionID === 'CombinedMedRxDeductibleOOPInd') {
                planObj["combinedMedRxDeductible"] = planOption.planOptionValueID === 'Y';
            }

            if (planOption.planOptionID === 'NonEmbeddedDedAndOOP') {
                planObj["nonEmbeddedDeductible"] = planOption.planOptionValueID === 'Y';
            }

            if (planOption.planOptionID === 'EligiblePlanInd') {
                planObj["hsa"] = planOption.planOptionValueID === 'HSA';
            }

            if(planOption.planOptionID === 'EmerVisitCopayAmount'){
                planObj["emergencyRoomCopay"] = planOption.planOptionValueID
            }

            if(planOption.planOptionID === 'InNetUccCopayAmount'){
                planObj["urgentCareCopay"] = planOption.planOptionValueID
            }

        }
        planObj.packages = existingPlan.planFamilyName ? [existingPlan.planFamilyName] : []
        // planObj["medicalProductType"] = '100';
        planObj.rx["specialty"] = false

        const rxCopay = rxComplete.split("/");
        if (isUhcRenewals()) {
            planObj.rx.tiers = rxCopay.map((copay, index) => ({ tierNum: index + 1, copay }));
        } else {
            planObj.rx.tiers[0] = {
                tierNum: 1,
                copay: rxCopay[0]
            }
            planObj.rx.tiers[1] = {
                tierNum: 2,
                copay: rxCopay[1]
            }
            planObj.rx.tiers[2] = {
                tierNum: 3,
                copay: rxCopay[2]
            }
            planObj.rx.tiers[3] = {
                tierNum: 4,
                copay: rxCopay[3].includes("%") ? parseInt(rxCopay[3])+"%" : rxCopay[3]
            }
        }

        planObj.codeDisplay = existingPlan.planXREF


        // planObj["ooa"] = ""  //Not found in IBR
        planObj["areaCoverage"] = "No"  // National Access on UI, not found in IBR


        
        // planObj["emergencyRoomCopay"] = ""   //Not in IBR, not shown on UI
        planObj["emergencyRoomCopayOut"] = ""   //Not in IBR, not shown on UI
        // planObj["urgentCareCopay"] = ""         //Not in IBR, not shown on UI
        planObj["urgentCareCopayOut"] = ""       //Not in IBR, not shown on UI
        // planObj["physicianCoinsurance"] = ""     //Not in IBR, not shown on UI
        // planObj["physicianCoinsuranceOut"] = ""    //Not in IBR, not shown on UI

        //DEDUCTIBLE
        // planObj["healthyNYPlan"] = "healthyNYPlan"  //Not in IBR, not shown on UI
        // planObj["hiosID"] = "hiosID"                 //Not in IBR, not shown on UI
        // planObj["HISOID"] = "HISOID"                  //Not in IBR, not shown on UI
        // planObj["riderAge29"] = false                 //Not in IBR, not shown on UI
        // planObj["riderDomesticPartner"] = false       //Not in IBR, not shown on UI
       // if (existingPlan.rates.ageRates) {

        const getBillType = (tierStructureId) => {
            switch (tierStructureId) {
                case 'A':
                case 'B':
                case 'C': 
                case '3': 
                case '4':
                case '6':
                case '7':
                case '8':
                case '9':
                return 'Tier';
                default:
                return 'Age';
            }
        };

        planObj['tierStructureId'] = existingPlan.tierStructureID;

        let ratingType = getBillType(existingPlan.tierStructureID)
        if (isUhcRenewals() ? ratingType === 'Age' : existingPlan.rates.ageRates) {
            planObj["ageBands"] = formatAgeBand(existingPlanRates.ageBand)
            planObj["isAgeBanded"] = true
            planObj['defaultRatingType'] = 'ageBands'
            planObj['ibrRatingType'] = 'ageBands'
            // planObj["premiumTotalAgeBandedMonthly"] = 100      //Not Shown on UI
            // planObj["premiumTotalAgeBandedAnnual"] = 200       //Not Shown on UI
        } else {
            planObj["compositeRates"] = formatCompositeRates(existingPlanRates)
            planObj['compositeTypeName'] = 'Composite Rates'
            planObj["isComposite"] = true
            planObj['defaultRatingType'] = 'composite'
            planObj['ibrRatingType'] = 'composite'
            // planObj["premiumTotalCompositeMonthly"] = planRate.rateTotals[0].total
            // planObj["premiumTotalCompositeAnnual"] = planRate.rateTotals[1].total
        }

        if(isUhcRenewals()){
                planObj["renewalChangePercent"] =  planObj["renewalChangePercent"] !== "" ? planObj["renewalChangePercent"] : SEE_BENEFIT_SUMMARY;
                planObj["medicalPlanCategory"] = planObj["medicalPlanCategory"] !== "" ? planObj["medicalPlanCategory"] : SEE_BENEFIT_SUMMARY;
                planObj['OISABITName'] = planObj["OISABITName"] !== "" ? planObj["OISABITName"] : SEE_BENEFIT_SUMMARY;
                planObj["licenseType"] = planObj["licenseType"] !== "" ? planObj["licenseType"] : SEE_BENEFIT_SUMMARY;
                planObj.rx["deductible"] = planObj.rx["deductible"] !== "" ? planObj.rx["deductible"] : SEE_BENEFIT_SUMMARY;
                planObj["metal"] =planObj["metal"] !== "" ? planObj["metal"] : SEE_BENEFIT_SUMMARY;
                planObj["enrolledCount"] =planObj["enrolledCount"] !== "" ? planObj["enrolledCount"] : SEE_BENEFIT_SUMMARY;
                planObj["platformAgeBandedRatingID"] =planObj["platformAgeBandedRatingID"] !== "" ? planObj["platformAgeBandedRatingID"] : SEE_BENEFIT_SUMMARY;
                planObj["underwriterExceptionCode"] =planObj["underwriterExceptionCode"] !== "" ? planObj["underwriterExceptionCode"] : SEE_BENEFIT_SUMMARY;
                planObj["primePolicyNumber"] = planObj["primePolicyNumber"] !== "" ? planObj["primePolicyNumber"] : SEE_BENEFIT_SUMMARY;
                planObj["nicePolicyNumber"] = planObj["nicePolicyNumber"] !== "" ? planObj["nicePolicyNumber"] : SEE_BENEFIT_SUMMARY;                
    
                planObj.deductible["individualIn"] = planObj.deductible["individualIn"] !== "" ? planObj.deductible["individualIn"] : SEE_BENEFIT_SUMMARY; 
                planObj.deductible["individualOut"] = planObj.deductible["individualOut"] !== "" ? planObj.deductible["individualOut"] : SEE_BENEFIT_SUMMARY; 
                planObj.deductible["familyIn"] = planObj.deductible["familyIn"] !== "" ? planObj.deductible["familyIn"] : SEE_BENEFIT_SUMMARY; 
                planObj.deductible["familyOut"] = planObj.deductible["familyOut"] !== "" ? planObj.deductible["familyOut"] : SEE_BENEFIT_SUMMARY; 
                planObj.oopMax["individualIn"] = planObj.oopMax["individualIn"] !== "" ? planObj.oopMax["individualIn"] : SEE_BENEFIT_SUMMARY; 
                planObj.oopMax["individualOut"] = planObj.oopMax["individualOut"] !== "" ? planObj.oopMax["individualOut"] : SEE_BENEFIT_SUMMARY; 
                planObj.oopMax["familyIn"] = planObj.oopMax["familyIn"] !== "" ? planObj.oopMax["familyIn"] : SEE_BENEFIT_SUMMARY; 
                planObj.oopMax["familyOut"] = planObj.oopMax["familyOut"] !== "" ? planObj.oopMax["familyOut"] : SEE_BENEFIT_SUMMARY; 
                planObj.hospitalCoinsurance["in"] = planObj.hospitalCoinsurance["in"] !== "" ? planObj.hospitalCoinsurance["in"] : SEE_BENEFIT_SUMMARY; 
                planObj.hospitalCoinsurance["out"] = planObj.hospitalCoinsurance["out"] !== "" ? planObj.hospitalCoinsurance["out"] : SEE_BENEFIT_SUMMARY; 
                planObj['customPlanName'] =  planObj["customPlanName"] !== "" ? planObj["customPlanName"] : SEE_BENEFIT_SUMMARY; 
                planObj["pcpCopay"] =   planObj["pcpCopay"] !== "" ? planObj["pcpCopay"] : SEE_BENEFIT_SUMMARY; 
                planObj["pcpCopayOut"] = planObj["pcpCopayOut"] !== "" ? planObj["pcpCopayOut"] : SEE_BENEFIT_SUMMARY; 
                planObj["specialistCopay"] =   planObj["specialistCopay"] !== "" ? planObj["specialistCopay"] : SEE_BENEFIT_SUMMARY; 
                planObj["specialistCopayOut"] =planObj["specialistCopayOut"] !== "" ? planObj["specialistCopayOut"] : SEE_BENEFIT_SUMMARY; 
                planObj["hraContribution"].max = planObj["hraContribution"].max !== "" ? planObj["hraContribution"].max : SEE_BENEFIT_SUMMARY; 
                planObj["hraContribution"].min = planObj["hraContribution"].min !== "" ? planObj["hraContribution"].min : SEE_BENEFIT_SUMMARY; 
                planObj["medicalPlanType"] = planObj["medicalPlanType"] !== "" ? planObj["medicalPlanType"] : SEE_BENEFIT_SUMMARY; 
                planObj["planGroup"] = planObj["planGroup"] !== "" ? planObj["planGroup"] : SEE_BENEFIT_SUMMARY; 
                planObj["emergencyRoomCopay"] = planObj["emergencyRoomCopay"] !== "" ? planObj["emergencyRoomCopay"] : SEE_BENEFIT_SUMMARY; 
                planObj["urgentCareCopay"] = planObj["urgentCareCopay"] !== "" ? planObj["urgentCareCopay"] : SEE_BENEFIT_SUMMARY; 
                
        }
        
        empCensus.map(employee =>
            (
                checkBelonging(renewalPlanType,employee,existingPlan,false) ?
                    planObj.member.push({
                        employeeId: employee.xref,
                        ssn: employee.ssn,
                        zipCode: employee.homeZip ? employee.homeZip : '',
                        contactInfo: {
                            streetAddress: employee.clientDefinedData.data.find(({ key }) => key === 'EmployeeAddr1') ? employee.clientDefinedData.data.find(({ key }) => key === 'EmployeeAddr1').value : '',
                            city: employee.clientDefinedData.data.find(({ key }) => key === 'EmployeeCity') ? employee.clientDefinedData.data.find(({ key }) => key === 'EmployeeCity').value : '',
                            state: employee.clientDefinedData.data.find(({ key }) => key === 'State') ? employee.clientDefinedData.data.find(({ key }) => key === 'State').value : '',
                            zipCode: employee.clientDefinedData.data.find(({ key }) => key === 'Zip') ? employee.clientDefinedData.data.find(({ key }) => key === 'Zip').value : '',
                        },
                        firstName: employee.first,
                        lastName: employee.last,
                        outOfArea: 'No',
                        gender: employee.genderType === 'M'?'Male':'Female',
                        dob: formatDate(employee.dateOfBirth.month) + '/' + formatDate(employee.dateOfBirth.day) + '/' + employee.dateOfBirth.year,
                        age: getAge(formatDate(employee.dateOfBirth.month) + '/' + formatDate(employee.dateOfBirth.day) + '/' + employee.dateOfBirth.year, effectiveDate),
                        status: employee.status,
                        coverages: {
                            medical: 
                                planObj.renewalPlanType === "current" ? employee.electedPlans.plan.filter(plan => plan.planID === existingPlan.planID)[0]?.coverageType :
                                    planObj.renewalPlanType === "renewing" ? employee.renewingPlans.plan.filter(plan => plan.planID === existingPlan.planID)[0]?.coverageType :
                                        employee.alternatePlans.plan.filter(plan => plan.planId === existingPlan.planId)[0]?.coverageType
                        },
                        ooaPremium: 0,
                        total:
                            planObj.renewalPlanType === "current" ? employee.electedPlans.plan.filter(plan => plan.planID === existingPlan.planID)[0]?.rate :
                                planObj.renewalPlanType === "renewing" ? employee.renewingPlans.plan.filter(plan => plan.planID === existingPlan.planID)[0]?.rate  :
                                    employee.alternatePlans.plan.filter(plan => plan.planID === existingPlan.planID)[0]?.rate 
                            ,
                        dependents: employee?.dependents?.dependent.map(dependentOne =>
                        ({
                            firstName: dependentOne.first,
                            lastName: dependentOne.last,
                            relationship: dependentOne.depType === 'C'?'Child':'S'?'Spouse':"",
                            age: getAge(formatDate(dependentOne.dateOfBirth.month) + '/' + formatDate(dependentOne.dateOfBirth.day) + '/' + dependentOne.dateOfBirth.year, effectiveDate),
                            dob: formatDate(dependentOne.dateOfBirth.month) + '/' + formatDate(dependentOne.dateOfBirth.day) + '/' + dependentOne.dateOfBirth.year,
                            gender: employee.genderType === 'M'? 'Male' : 'Female',
                            streetAddress: employee.clientDefinedData.data.find(({ key }) => key === 'EmployeeAddr1') ? employee.clientDefinedData.data.find(({ key }) => key === 'EmployeeAddr1').value : '',
                            streetAddressln2: '',
                            city: employee.clientDefinedData.data.find(({ key }) => key === 'EmployeeCity') ? employee.clientDefinedData.data.find(({ key }) => key === 'EmployeeCity').value : '',
                            state: employee.clientDefinedData.data.find(({ key }) => key === 'State') ? employee.clientDefinedData.data.find(({ key }) => key === 'State').value : '',
                            zipCode: employee.clientDefinedData.data.find(({ key }) => key === 'Zip') ? employee.clientDefinedData.data.find(({ key }) => key === 'Zip').value : '',
                            ssn: dependentOne.ssn
                        })) || []
                    })
                : ""
            )
        )

        planObj["quote"] = existingPlan.planRate
        planObj["finalMonthlyPremium"] = existingPlan.planRate

        planObj["selected"] = false
        if (
            renewalPlanType === "alternate" &&
            reviewData && reviewData.medical &&
            reviewData.medical.selectedPlans &&
            reviewData.medical.selectedPlans.length > 0
        ) {
            let planSelected = reviewData.medical.selectedPlans.find(
                (plan) => {
                    if(!plan.packageId || plan.packageId.toLowerCase() === 'single') {
                        return plan.code === planObj.code;
                    } else {
                        return plan.code === planObj.code && plan.packageId === planObj.packageId;
                    }
                }
            );
            if (planSelected) {
                planObj["selected"] = true;
            }
        }
  
        let byCensusRates = []
        if (empCensus) {
            if (renewalPlanType !== 'alternate') {
                empCensus.map(emp => {
                    if (renewalPlanType === 'current') {
                        let empId = emp.xref
                        let planRate = emp.electedPlans.plan.find(plan => plan.planID === planObj.medicalPlanCode && plan.coverageType !== 'WAV')
                        if (planRate) {
                            byCensusRates.push({
                                planCode: existingPlan.planXREF,
                                employeeId: empId,
                                total: planRate.rate
                            })
                        }
                    }
                    else if (renewalPlanType === 'renewing') {
                        let empId = emp.xref
                        let planRate = emp.renewingPlans.plan.find(plan => plan.planID === planObj.medicalPlanCode && plan.coverageType !== 'WAV')
                        if (planRate) {
                            byCensusRates.push({
                                planCode: existingPlan.planXREF,
                                employeeId: empId,
                                total: planRate.rate
                            })
                        }
                    }
                })
            }
            else {
                empCensus.map(emp => {
                    let empId = emp.xref
                    let planRate = emp.alternatePlans && emp.alternatePlans.plan && emp.alternatePlans.plan.find(plan => plan.planID === planObj.medicalPlanCode && plan.coverageType !== 'WAV')
                    if (planRate) {
                        byCensusRates.push({
                            planCode: existingPlan.planXREF,
                            employeeId: empId,
                            total: planRate.rate
                        })
                    }
                })
            }
        }
        planObj.employeeRates = byCensusRates


        if (renewalPlanType === 'alternate') {
            alternate.push(planObj);
            alternatePlanCodes.push(planObj.code);
        } else {
            ibrPlans.push(planObj);
        }

    }

    async function ibrToDental(existingPlan, renewalPlanType, reviewData = null, empCensus) {
        const existingPlanOptions = existingPlan.options.planOption;
        const existingPlanRates = existingPlan.rates.compositeRates;
        const existingPlanClientDefinedData = existingPlan.clientDefinedData.data;
        let monthlyPremium = {
            employee: parseFloat(existingPlanRates.tier1),
            empAndSpouse: parseFloat(existingPlanRates.tier2),
            empAndChild: parseFloat(existingPlanRates.tier3),
            empAndFamily: parseFloat(existingPlanRates.tier4)
        }
        // commented until further clarification on PRIME Alternates rates
        // let reconciledSpecCensusDental, quoteDentalRate;
        // if (renewalPlanType === 'alternate') {
        //     reconciledSpecCensusDental = filterReconciledCensus(reviewData).dentalCensus
        //     quoteDentalRate = rateAdjustmentRenewals(monthlyPremium, reconciledSpecCensusDental)
        // }

        let obj = {
            planType: "Dental",
            renewalPlanType: renewalPlanType,
            classShiftDescription: SEE_BENEFIT_SUMMARY,////NOT FOUND
            code: existingPlan.planID || existingPlan.planXREF,
            planXREF: existingPlan.planXREF,
            dedicatedLine: SEE_BENEFIT_SUMMARY,////NOT FOUND
            deductibleWaiver: 'N/A',
            dentalNetworkType: SEE_BENEFIT_SUMMARY,
            includeCMM: SEE_BENEFIT_SUMMARY,
            includeImplants: SEE_BENEFIT_SUMMARY,
            includeOrtho: "NA",
            includePMM: SEE_BENEFIT_SUMMARY,
            installationType: "Available via SAMx Submission",
            isMultiStatePlan: "NA",
            // isPreferredPlan: false,
            // outCoins: "NA / NA / NA / NA",
            planName: SEE_BENEFIT_SUMMARY,
            platform: "PRIME",
            ucr: "",
            rateGuarantee: '',

            waitingPeriodMajor: '',
            dentalPlanTypeCode: '',
            waitingPeriodMonths: "N/A",////NOT FOUND
            deductible: {},

            coinsurance: {},
            maxBenefit: {},
            endodonticServices: {},
            orthodontics: {},
            simpleExtractions: {
                inSimpleExtractions: SEE_BENEFIT_SUMMARY,//not found
                oonSimpleExtractions: SEE_BENEFIT_SUMMARY,//not found
            },
            monthlyPremium: monthlyPremium,
            // commented until further clarification on PRIME Alternates rates
            // quote: renewalPlanType === 'alternate' ? quoteDentalRate : parseFloat(existingPlan.planRate),
            quote: parseFloat(existingPlan.planRate),
            finalRates: monthlyPremium,
            // finalMonthlyPremium: renewalPlanType === 'alternate' ? quoteDentalRate : parseFloat(existingPlan.planRate),
            // byCensus: { finalMonthlyPremium: renewalPlanType === 'alternate' ? quoteDentalRate : parseFloat(existingPlan.planRate) },//NOT FOUND
            finalMonthlyPremium: parseFloat(existingPlan.planRate),
            byCensus: { finalMonthlyPremium: parseFloat(existingPlan.planRate) },//NOT FOUND
            copayDetails: {
                isCopay: false
            },
            inoDetails: {
                isINO: false
            },
            selected: false,
            favorited: false,
            
        }
        obj.tierStructureId = existingPlan.tierStructureID
        obj.member = []
        for (const clientDefinedData of existingPlanClientDefinedData) {

            if(clientDefinedData.key === "CostDifferentialPercentage" && renewalPlanType === 'renewing') {
                obj["renewalChangePercent"] =  clientDefinedData.value ;
            }

            if (clientDefinedData.key === 'PlanCategory') {
                obj.dentalPlanType = clientDefinedData.value;
                obj.productType = clientDefinedData.value;
                //obj.dentalPlanTypeCode = clientDefinedData.value;///////NOT FOUND
                //   obj.style = clientDefinedData.value;////NOT FOUND
            }
            if (clientDefinedData.key === 'EmployeeEnrollCount') {
                if (renewalPlanType !== 'alternate')
                    obj.enrolledCount = clientDefinedData.value;
                else
                    obj.enrolledCount = "0";
            }
            if(isUhcRenewals()) {
                obj.rateGuarantee = "12"
            }
            else {
                if (clientDefinedData.key === 'RateGuaranteePeriod') {
                    (obj.rateGuarantee = clientDefinedData.value)
                }
            }
            if (clientDefinedData.key === 'underwriterExceptionCode1') {
                obj["underwriterExceptionCode"] = clientDefinedData.value;
            }
            if (clientDefinedData.key === 'LegalName') {
                obj.legalName = clientDefinedData.value
            }
        }

        for (const planOption of existingPlanOptions) {

            if (planOption.planOptionID === 'PlanFundingDescription') {
                obj.fundingType = planOption.planOptionValueID;
            }
            if (planOption.planOptionID === 'UsualCustomaryPercentile') {
                obj.ucr = planOption.planOptionValueID;
            }
            //Deductibles
            //Individual
            if (planOption.planOptionID === 'IndNetDeductibleAmount') {
                obj.deductible.individualIn = planOption.planOptionValueID;
            }
            if (planOption.planOptionID === 'IndOutNetDeductibleAmount') {
                obj.deductible.individualOut = planOption.planOptionValueID;
            }
            //Family
            if (planOption.planOptionID === 'FamilyInNetDeductibleAmount') {
                obj.deductible.familyIn = planOption.planOptionValueID;
            }
            if (planOption.planOptionID === 'FamilyOutNetDeductibleAmount') {
                obj.deductible.familyOut = planOption.planOptionValueID;
            }
            //Services
            //P&D
            if (planOption.planOptionID === 'PreventiveInNetCoInsurancePct') {
                obj.coinsurance.preventiveIn = planOption.planOptionValueID;
            }
            if (planOption.planOptionID === 'PreventiveOutNetCoInsurancePct') {
                obj.coinsurance.preventiveOut = planOption.planOptionValueID;
            }
            //Basic
            if (planOption.planOptionID === 'BasicServInNetCoinsurancePct') {
                obj.coinsurance.basicIn = planOption.planOptionValueID;
            }
            if (planOption.planOptionID === 'BasicServOutNetCoinsurancePct') {
                obj.coinsurance.basicOut = planOption.planOptionValueID;
            }
            //Major
            if (planOption.planOptionID === 'MajServicesInNetCoInsurancePct') {
                obj.coinsurance.majorIn = planOption.planOptionValueID;
            }
            if (planOption.planOptionID === 'MajServiceOutNetCoInsurancePct') {
                obj.coinsurance.majorOut = planOption.planOptionValueID;
            }
            //waitingPeriodMjor
            if (planOption.planOptionID === 'WaitingPeriod' || planOption.planOptionID === 'WaitingPeriodMajor') {
                obj.waitingPeriodMajor = planOption.planOptionValueID;
            }
            // if(planOption)
            //Orthodontia
            if (planOption.planOptionID === 'OrthoInNetCoinsurancePct') {
                obj.coinsurance.orthodonticsIn = planOption.planOptionValueID;
                obj.orthodontics.inCovered = planOption.planOptionValueID;
            }
            if (planOption.planOptionID === 'OrthodonticsCoveredOutNetInd') {
                obj.coinsurance.orthodonticsOut = planOption.planOptionValueID;
                obj.orthodontics.outCovered = planOption.planOptionValueID;
            }
            if (planOption.planOptionID === 'LifetimeOrthoInNetBenAmount') {
                obj.orthodontics.inLifetimeBenefit = planOption.planOptionValueID;
            }
            if (planOption.planOptionID === 'LifetimeOrthoOutNetBenAmount') {
                obj.orthodontics.outLifetimeBenefit = planOption.planOptionValueID;
            }
            //Endodontics Periodontic & Oral
            if (planOption.planOptionID === 'EndoPerioOralInnetPct') {
                const [endoIn, perioIn, oralIn] = planOption.planOptionValueID.split('/');
                obj.endodonticServices.inEndo = endoIn;
                obj.endodonticServices.inEndoPulpotomy = '-';
                obj.coinsurance.periodonticsIn = perioIn;
                obj.coinsurance.coinsuranceOralIn = oralIn;
            }
            if (planOption.planOptionID === 'EndoPerioOralOutnetPct') {
                const [endoOut, perioOut, oralOut] = planOption.planOptionValueID.split('/');
                obj.endodonticServices.oonEndo = endoOut;
                obj.endodonticServices.oonEndoPulpotomy = '-';
                obj.coinsurance.periodonticsOut = perioOut;
                obj.coinsurance.coinsuranceOralOut = oralOut;
            }
            //Implant
            if (planOption.planOptionID === 'ImplantINPercent') {
                obj.coinsurance.implantIn = planOption.planOptionValueID;
            }
            if (planOption.planOptionID === 'ImplantOOOPercent') {
                obj.coinsurance.implantOut = planOption.planOptionValueID;
            }

            //Simple
            // if (planOption.planOptionID === 'simpleIn') {
            //     obj.simpleExtractions.inSimpleExtractions = SEE_BENEFIT_SUMMARY;
            // }
            // if (planOption.planOptionID === 'simpleOut') {
            //     obj.simpleExtractions.oonSimpleExtractions = SEE_BENEFIT_SUMMARY;
            // }

            //Annual Max
            //Individual
            if (planOption.planOptionID === 'InNetAnnualMaxBenefitAmount') {
                obj.maxBenefit.in = planOption.planOptionValueID;
            }
            if (planOption.planOptionID === 'OutNetAnnualMaxBenefitAmount') {
                obj.maxBenefit.out = planOption.planOptionValueID;
            }
            if (obj.maxBenefit.in && obj.maxBenefit.out) {
                obj.maxBenefit.maxBenefitText = obj.maxBenefit.in + '/' + obj.maxBenefit.out;
            }
            else if (obj.maxBenefit.in && (obj.maxBenefit.out && obj.maxBenefit.out == null)) {
                obj.maxBenefit.maxBenefitText = obj.maxBenefit.in + '/' + "NA";
            }
            else if (obj.maxBenefit.in && obj.maxBenefit.in && obj.maxBenefit.out) {
                obj.maxBenefit.maxBenefitText = "NA" + '/' + obj.maxBenefit.out;
            }

        }

        if(isUhcRenewals()){
            obj["renewalChangePercent"] = obj["renewalChangePercent"] === "" ? SEE_BENEFIT_SUMMARY : obj["renewalChangePercent"];
            obj.dentalPlanType = obj.dentalPlanType === "" ? SEE_BENEFIT_SUMMARY : obj.dentalPlanType;
            obj.productType = obj.productType === "" ? SEE_BENEFIT_SUMMARY : obj.productType;
            obj.enrolledCount = obj.enrolledCount === "" ? SEE_BENEFIT_SUMMARY : obj.enrolledCount;
            obj["underwriterExceptionCode"] = obj["underwriterExceptionCode"] === "" ? SEE_BENEFIT_SUMMARY : obj["underwriterExceptionCode"];
            obj.legalName = obj.legalName === "" ? SEE_BENEFIT_SUMMARY : obj.legalName;
            obj.fundingType = obj.fundingType === "" ? SEE_BENEFIT_SUMMARY : obj.fundingType;
            obj.ucr = obj.ucr === "" ? SEE_BENEFIT_SUMMARY : obj.ucr;
            obj.deductible.individualIn = obj.deductible.individualIn === "" ? SEE_BENEFIT_SUMMARY : obj.deductible.individualIn;
            obj.deductible.individualOut = obj.deductible.individualOut === "" ? SEE_BENEFIT_SUMMARY : obj.deductible.individualOut;
            obj.deductible.familyIn = obj.deductible.familyIn === "" ? SEE_BENEFIT_SUMMARY : obj.deductible.familyIn;
            obj.deductible.familyOut = obj.deductible.familyOut === "" ? SEE_BENEFIT_SUMMARY : obj.deductible.familyOut;  

            obj.coinsurance.preventiveIn = obj.coinsurance.preventiveIn  === "" ? SEE_BENEFIT_SUMMARY : obj.coinsurance.preventiveIn ;
            obj.coinsurance.preventiveOut = obj.coinsurance.preventiveOut  === "" ? SEE_BENEFIT_SUMMARY : obj.coinsurance.preventiveOut ;
            obj.coinsurance.basicIn = obj.coinsurance.basicIn  === "" ? SEE_BENEFIT_SUMMARY : obj.coinsurance.basicIn ;
            obj.coinsurance.basicOut = obj.coinsurance.basicOut  === "" ? SEE_BENEFIT_SUMMARY : obj.coinsurance.basicOut ;
            obj.coinsurance.majorIn = obj.coinsurance.majorIn  === "" ? SEE_BENEFIT_SUMMARY : obj.coinsurance.majorIn ;
            obj.coinsurance.majorOut = obj.coinsurance.majorOut  === "" ? SEE_BENEFIT_SUMMARY : obj.coinsurance.majorOut ;

            obj.waitingPeriodMajor = obj.waitingPeriodMajor === "" ? SEE_BENEFIT_SUMMARY : obj.waitingPeriodMajor;
            obj.coinsurance.orthodonticsIn = obj.coinsurance.orthodonticsIn === "" ? SEE_BENEFIT_SUMMARY : obj.coinsurance.orthodonticsIn;
            obj.orthodontics.inCovered = obj.orthodontics.inCovered === "" ? SEE_BENEFIT_SUMMARY : obj.orthodontics.inCovered;
            obj.coinsurance.orthodonticsOut = obj.coinsurance.orthodonticsOut === "" ? SEE_BENEFIT_SUMMARY : obj.coinsurance.orthodonticsOut;

            obj.orthodontics.outCovered = obj.orthodontics.outCovered === "" ? SEE_BENEFIT_SUMMARY : obj.orthodontics.outCovered;
            obj.orthodontics.inLifetimeBenefit = obj.orthodontics.inLifetimeBenefit === "" ? SEE_BENEFIT_SUMMARY : obj.orthodontics.inLifetimeBenefit;
            obj.orthodontics.outLifetimeBenefit = obj.orthodontics.outLifetimeBenefit === "" ? SEE_BENEFIT_SUMMARY : obj.orthodontics.outLifetimeBenefit;
            obj.endodonticServices.inEndo = obj.endodonticServices.inEndo === "" ? SEE_BENEFIT_SUMMARY : obj.endodonticServices.inEndo;
            obj.coinsurance.periodonticsIn = obj.coinsurance.periodonticsIn === "" ? SEE_BENEFIT_SUMMARY : obj.coinsurance.periodonticsIn;
            obj.coinsurance.coinsuranceOralIn = obj.coinsurance.coinsuranceOralIn === "" ? SEE_BENEFIT_SUMMARY : obj.coinsurance.coinsuranceOralIn;
            obj.endodonticServices.oonEndo = obj.endodonticServices.oonEndo === "" ? SEE_BENEFIT_SUMMARY : obj.endodonticServices.oonEndo;
            obj.coinsurance.periodonticsOut =  obj.coinsurance.periodonticsOut === "" ? SEE_BENEFIT_SUMMARY : obj.coinsurance.periodonticsOut;
            obj.coinsurance.coinsuranceOralOut = obj.coinsurance.coinsuranceOralOut === "" ? SEE_BENEFIT_SUMMARY : obj.coinsurance.coinsuranceOralOut;
            obj.coinsurance.implantIn = obj.coinsurance.implantIn === "" ? SEE_BENEFIT_SUMMARY : obj.coinsurance.implantIn;
            obj.coinsurance.implantOut = obj.coinsurance.implantOut === "" ? SEE_BENEFIT_SUMMARY : obj.coinsurance.implantOut;

            obj.maxBenefit.in = obj.maxBenefit.in === "" ? SEE_BENEFIT_SUMMARY : obj.maxBenefit.in;
            obj.maxBenefit.out = obj.maxBenefit.out === "" ? SEE_BENEFIT_SUMMARY : obj.maxBenefit.out;
            obj.maxBenefit.maxBenefitText = obj.maxBenefit.in + '/' + obj.maxBenefit.out;
        }

        empCensus.map(employee =>
            (checkBelonging(renewalPlanType,employee,existingPlan,false)?
            obj.member.push({
                employeeId: employee.xref,
                ssn: employee.ssn,
                zipCode: employee.homeZip ? employee.homeZip : '',
                contactInfo: {
                    streetAddress: employee.clientDefinedData.data.find(({ key }) => key === 'EmployeeAddr1') ? employee.clientDefinedData.data.find(({ key }) => key === 'EmployeeAddr1').value : '',
                    city: employee.clientDefinedData.data.find(({ key }) => key === 'EmployeeCity') ? employee.clientDefinedData.data.find(({ key }) => key === 'EmployeeCity').value : '',
                    state: employee.clientDefinedData.data.find(({ key }) => key === 'State') ? employee.clientDefinedData.data.find(({ key }) => key === 'State').value : '',
                    zipCode: employee.clientDefinedData.data.find(({ key }) => key === 'Zip') ? employee.clientDefinedData.data.find(({ key }) => key === 'Zip').value : '',
                },
                firstName: employee.first,
                lastName: employee.last,
                outOfArea: 'No',
                gender: employee.genderType === 'M'?'Male':'Female',
                dob: formatDate(employee.dateOfBirth.month) + '/' + formatDate(employee.dateOfBirth.day) + '/' + employee.dateOfBirth.year,
                age: getAge(formatDate(employee.dateOfBirth.month) + '/' + formatDate(employee.dateOfBirth.day) + '/' + employee.dateOfBirth.year, effectiveDate),
                status: employee.status,
                coverages: {
                    dental: 
                        obj.renewalPlanType === "current" ? employee.electedPlans.plan.filter(plan => plan.planID === existingPlan.planID)[0]?.coverageType :
                            obj.renewalPlanType === "renewing" ? employee.renewingPlans.plan.filter(plan => plan.planID === existingPlan.planID)[0]?.coverageType :
                                employee.alternatePlans.plan.filter(plan => plan.planId === existingPlan.planId)[0]?.coverageType
                },
                ooaPremium: 0,
                total:
                    obj.renewalPlanType === "current" ? employee.electedPlans.plan.filter(plan => plan.planID === existingPlan.planID)[0]?.rate :
                        obj.renewalPlanType === "renewing" ? employee.renewingPlans.plan.filter(plan => plan.planID === existingPlan.planID)[0]?.rate  :
                            employee.alternatePlans.plan.filter(plan => plan.planID === existingPlan.planID)[0]?.rate 
                    ,
                dependents: employee?.dependents?.dependent.map(dependentOne =>
                ({
                    firstName: dependentOne.first,
                    lastName: dependentOne.last,
                    relationship: dependentOne.depType === 'C'?'Child':'S'?'Spouse':"",
                    age: getAge(formatDate(dependentOne.dateOfBirth.month) + '/' + formatDate(dependentOne.dateOfBirth.day) + '/' + dependentOne.dateOfBirth.year, effectiveDate),
                    dob: formatDate(dependentOne.dateOfBirth.month) + '/' + formatDate(dependentOne.dateOfBirth.day) + '/' + dependentOne.dateOfBirth.year,
                    gender: employee.genderType === 'M'? 'Male' : 'Female',
                    streetAddress: employee.clientDefinedData.data.find(({ key }) => key === 'EmployeeAddr1') ? employee.clientDefinedData.data.find(({ key }) => key === 'EmployeeAddr1').value : '',
                    streetAddressln2: '',
                    city: employee.clientDefinedData.data.find(({ key }) => key === 'EmployeeCity') ? employee.clientDefinedData.data.find(({ key }) => key === 'EmployeeCity').value : '',
                    state: employee.clientDefinedData.data.find(({ key }) => key === 'State') ? employee.clientDefinedData.data.find(({ key }) => key === 'State').value : '',
                    zipCode: employee.clientDefinedData.data.find(({ key }) => key === 'Zip') ? employee.clientDefinedData.data.find(({ key }) => key === 'Zip').value : '',
                    ssn: dependentOne.ssn
                })) || []
            })
            :""))

        let byCensusRates = []
        if (empCensus) {
            if (renewalPlanType !== 'alternate') {
                empCensus.map(emp => {
                    if (renewalPlanType === 'current') {
                        let empId = emp.xref
                        let planRate = emp.electedPlans.plan.find(plan => plan.planID === obj.code && plan.coverageType !== 'WAV')
                        if (planRate) {
                            byCensusRates.push({
                                employeeId: empId,
                                Total: planRate.rate
                            })
                        }
                    }
                    else if (renewalPlanType === 'renewing') {
                        let empId = emp.xref
                        let planRate = emp.renewingPlans.plan.find(plan => plan.planID === obj.code && plan.coverageType !== 'WAV')
                        if (planRate) {
                            byCensusRates.push({
                                employeeId: empId,
                                Total: planRate.rate
                            })
                        }
                    }
                })
            }
            else {
                empCensus.map(emp => {
                    let empId = emp.xref
                    let planRate = emp.alternatePlans.plan.find(plan => plan.planID === obj.code && plan.coverageType !== 'WAV')
                    if (planRate) {
                        byCensusRates.push({
                            employeeId: empId,
                            Total: planRate.rate
                        })
                    }
                })
            }
        }
        obj.byCensus.employeeRates = byCensusRates

        // if(obj.isMultiStatePlan === 'NA'){
        //     // const planDetailsResponse = await getStepwisePlanDetails(getMarketType() === MARKET_TYPES.OXFORD ? 'cirrus' : 'prime', stateCode, groupSize, effectiveDate, 'dental', obj.code);
        //     const planDetailsResponse = await getStepwisePlanDetails(getMarketType() === MARKET_TYPES.OXFORD ? 'cirrus' : 'prime', stateCode, groupSize, effectiveDate, 'dental', 'X1234');
        //     if(planDetailsResponse && planDetailsResponse.isMultiStatePlan !== null){
        //         obj.isMultiStatePlan = planDetailsResponse.isMultiStatePlan
        //     }
        // }

        
        if (renewalPlanType === 'alternate') {
            alternate.push(obj);
        } else {
            ibrPlans.push(obj);
        }

    }

    async function ibrToVision(existingPlan, renewalPlanType, reviewData = null, empCensus) {

        const existingPlanOptions = existingPlan.options.planOption;
        const existingPlanRates = existingPlan.rates.compositeRates;
        const existingPlanClientDefinedData = existingPlan.clientDefinedData.data;
        const isUhcRenewal = isUhcRenewals()
        let monthlyPremium = {
            employee: parseFloat(existingPlanRates.tier1),
            empAndSpouse: parseFloat(existingPlanRates.tier2),
            empAndChild: parseFloat(existingPlanRates.tier3),
            empAndFamily: parseFloat(existingPlanRates.tier4)
        }
        // commented until further clarification on PRIME Alternates rates
        // let reconciledSpecCensusVision, quoteVisionRate;
        // if (renewalPlanType === 'alternate') {
        //     reconciledSpecCensusVision = filterReconciledCensus(reviewData).visionCensus
        //     quoteVisionRate = rateAdjustmentRenewals(monthlyPremium, reconciledSpecCensusVision)
        // }
        let frameAllowanceValue="See Benefit Summary"
        if(isUhcRenewal)
        {
            for (const planOption of existingPlanOptions) {
                if(planOption.planOptionID === 'OutNetFramesMaxAmount')
                {
                    frameAllowanceValue="Up to $"+planOption.planOptionValueID.replace(/[^0-9.,]+/,'')         
                }
            }
        }
        let obj = {
            planType: "Vision",//??????????????????
            renewalPlanType: renewalPlanType,
            code: existingPlan.planID,
            planXREF: existingPlan.planXREF,
            exam: {
                secondExamForDiabeticsCopay: SEE_BENEFIT_SUMMARY
            },
            lenses: {
                lensOption: SEE_BENEFIT_SUMMARY,//pd
            },
            frames: {
                //allowanceOut:'See Plan Benefit Summary'//pd
            },
            contactLenses: {
                //formularyContactLensesFittingEvaluation:'See Plan Benefit Summary',//pd
                //allowanceOut:'See Plan Benefit Summary'//pd
            },
            allowance: {
                contactLensFittingAllowance: SEE_BENEFIT_SUMMARY,
                nonformularyCLAllowance: SEE_BENEFIT_SUMMARY,//pd
                oon: {
                    contactLensAllowance: SEE_BENEFIT_SUMMARY,
                    frameAllowance: isUhcRenewal ? frameAllowanceValue : SEE_BENEFIT_SUMMARY,
                    //  examAllowance:SEE_BENEFIT_SUMMARY,//pd
                    //    singleLensAllowance:SEE_BENEFIT_SUMMARY,//pd
                    //    bifocalAllowance:SEE_BENEFIT_SUMMARY,//pd
                    //  trifocalAllowance:SEE_BENEFIT_SUMMARY,//pd
                    //  necessaryCLAllowance:SEE_BENEFIT_SUMMARY//pd
                }

            },
            monthlyPremium: monthlyPremium,
            // commented until further clarification on PRIME Alternates rates
            // byCensus: { finalMonthlyPremium: renewalPlanType === 'alternate' ? quoteVisionRate : parseFloat(existingPlan.planRate) },
            // quote: renewalPlanType === 'alternate' ? quoteVisionRate : parseFloat(existingPlan.planRate),
            byCensus: { finalMonthlyPremium: parseFloat(existingPlan.planRate) },
            quote: parseFloat(existingPlan.planRate),
            finalRates: monthlyPremium,
            // commented until further clarification on PRIME Alternates rates
            // finalMonthlyPremium: renewalPlanType === 'alternate' ? quoteVisionRate : parseFloat(existingPlan.planRate),
            finalMonthlyPremium: parseFloat(existingPlan.planRate),
            copayDetails: {
                isCopay: false
            },
            inoDetails: {
                isINO: false
            },
            selected: false,
            favorited: false,
            planName: 'NA',//NOT FOUND
            platform: 'PRIME',
            orderOfPreference: 'NA',
            isPreferredPlan: 'NA',
            copayDiabeticsRetinalScreening: 'NA',
            necessaryContactLenses: 'NA',
            frequency: 'NA',
            copays: 'NA',
            installationType: 'NA',
            isMultiStatePlan: 'NA',
            productSuite: 'NA',

            platformRatingID: 'NA',
            rateURL: 'NA',//NOT FOUND

            //for plan details check
            rateGuarantee: isUhcRenewal ? 24 : '',
            //copayDiabeticsRetinalScreening:'See Plan Benefit Summary',//pd
            //necessaryContactLenses:'See Plan Benefit Summary'//pd

        }
        obj.tierStructureId = existingPlan.tierStructureID
        obj.member = [];

        empCensus.map(employee =>
            (checkBelonging(renewalPlanType,employee,existingPlan,false)?
            obj.member.push({
                employeeId: employee.xref,
                ssn: employee.ssn,
                zipCode: employee.homeZip ? employee.homeZip : '',
                contactInfo: {
                    streetAddress: employee.clientDefinedData.data.find(({ key }) => key === 'EmployeeAddr1') ? employee.clientDefinedData.data.find(({ key }) => key === 'EmployeeAddr1').value : '',
                    city: employee.clientDefinedData.data.find(({ key }) => key === 'EmployeeCity') ? employee.clientDefinedData.data.find(({ key }) => key === 'EmployeeCity').value : '',
                    state: employee.clientDefinedData.data.find(({ key }) => key === 'State') ? employee.clientDefinedData.data.find(({ key }) => key === 'State').value : '',
                    zipCode: employee.clientDefinedData.data.find(({ key }) => key === 'Zip') ? employee.clientDefinedData.data.find(({ key }) => key === 'Zip').value : '',
                },
                firstName: employee.first,
                lastName: employee.last,
                outOfArea: 'No',
                gender: employee.genderType === 'M'?'Male':'Female',
                dob: formatDate(employee.dateOfBirth.month) + '/' + formatDate(employee.dateOfBirth.day) + '/' + employee.dateOfBirth.year,
                age: getAge(formatDate(employee.dateOfBirth.month) + '/' + formatDate(employee.dateOfBirth.day) + '/' + employee.dateOfBirth.year, effectiveDate),
                status: employee.status,
                coverages: {
                    vision: 
                        obj.renewalPlanType === "current" ? employee.electedPlans.plan.filter(plan => plan.planID === existingPlan.planID)[0]?.coverageType :
                            obj.renewalPlanType === "renewing" ? employee.renewingPlans.plan.filter(plan => plan.planID === existingPlan.planID)[0]?.coverageType :
                                employee.alternatePlans.plan.filter(plan => plan.planId === existingPlan.planId)[0]?.coverageType
                },
                ooaPremium: 0,
                total:
                    obj.renewalPlanType === "current" ? employee.electedPlans.plan.filter(plan => plan.planID === existingPlan.planID)[0]?.rate :
                        obj.renewalPlanType === "renewing" ? employee.renewingPlans.plan.filter(plan => plan.planID === existingPlan.planID)[0]?.rate  :
                            employee.alternatePlans.plan.filter(plan => plan.planID === existingPlan.planID)[0]?.rate 
                    ,
                dependents: employee?.dependents?.dependent.map(dependentOne =>
                ({
                    firstName: dependentOne.first,
                    lastName: dependentOne.last,
                    relationship: dependentOne.depType === 'C'?'Child':'S'?'Spouse':"",
                    age: getAge(formatDate(dependentOne.dateOfBirth.month) + '/' + formatDate(dependentOne.dateOfBirth.day) + '/' + dependentOne.dateOfBirth.year, effectiveDate),
                    dob: formatDate(dependentOne.dateOfBirth.month) + '/' + formatDate(dependentOne.dateOfBirth.day) + '/' + dependentOne.dateOfBirth.year,
                    gender: employee.genderType === 'M'? 'Male' : 'Female',
                    streetAddress: employee.clientDefinedData.data.find(({ key }) => key === 'EmployeeAddr1') ? employee.clientDefinedData.data.find(({ key }) => key === 'EmployeeAddr1').value : '',
                    streetAddressln2: '',
                    city: employee.clientDefinedData.data.find(({ key }) => key === 'EmployeeCity') ? employee.clientDefinedData.data.find(({ key }) => key === 'EmployeeCity').value : '',
                    state: employee.clientDefinedData.data.find(({ key }) => key === 'State') ? employee.clientDefinedData.data.find(({ key }) => key === 'State').value : '',
                    zipCode: employee.clientDefinedData.data.find(({ key }) => key === 'Zip') ? employee.clientDefinedData.data.find(({ key }) => key === 'Zip').value : '',
                    ssn: dependentOne.ssn
                })) || []
            })
            :""))


        for (const clientDefinedData of existingPlanClientDefinedData) {

            if(clientDefinedData.key === "CostDifferentialPercentage" && renewalPlanType === 'renewing') {
                obj["renewalChangePercent"] =  clientDefinedData.value ;
            }

            if (clientDefinedData.key === 'PlanCategory') {
                const [ER, DEP] = clientDefinedData.value.split('/');
                obj.visionPlanTypeDescription = ER;
            }
            if (clientDefinedData.key === 'EmployeeEnrollCount') {
                if (renewalPlanType !== 'alternate')
                    obj.enrolledCount = clientDefinedData.value;
                else
                    obj.enrolledCount = "0";
            }
            if (clientDefinedData.key === 'RateGuaranteePeriod') {
                if (isUhcRenewals()) {
                    if (clientDefinedData.value.toString() === '2' || clientDefinedData.value.toString() === '12') {
                        obj.rateGuarantee = 12
                    }else if(clientDefinedData.value.toString() === '0'){
                        obj.rateGuarantee = 0
                    }
                    else {
                        obj.rateGuarantee = 24
                    }
                } else {
                    obj.rateGuarantee = clientDefinedData.value
                }
            }
            if (clientDefinedData.key === 'ForcedUrgedIndicator' && clientDefinedData.value === 'F' && renewalPlanType === 'current') {
                obj.isDiscontinued = true;
            }
            if (clientDefinedData.key === 'underwriterExceptionCode1') {
                obj["underwriterExceptionCode"] = clientDefinedData.value;
            }
            if (clientDefinedData.key === 'LegalName') {
                obj.legalName = clientDefinedData.value
            }
        }

        for (const planOption of existingPlanOptions) {

            if (planOption.planOptionID === 'VisionPlanType') {
                isUhcRenewals() ? (obj.visionPlanTypeCode=(existingPlan.planID.charAt(0)==="S"&& existingPlan.planID.charAt(1)==="F")|| existingPlan.planID.charAt(0)==="V" ?  'V/L Plan':'S Plan'):obj.visionPlanTypeCode=planOption.planOptionValueID;
            }   
            //Copays
            if (planOption.planOptionID === 'InNetExamCopayAmount') {
                obj.exam.copayIn = planOption.planOptionValueID;
            }
            if (planOption.planOptionID === 'InNetMaterialCopayAmount') {
                obj.materialCopayIn = planOption.planOptionValueID;
            }
            //Frequency
            if (planOption.planOptionID === 'NumofMonthsBetweenCoveredExam') {
                obj.exam.coveredMonthsInterval = planOption.planOptionValueID;
            }
            if (planOption.planOptionID === 'LensesBenefitFrequency') {
                obj.lenses.coveredMonthsInterval = planOption.planOptionValueID;
            }
            if (planOption.planOptionID === 'FramesBenefitFrequency') {
                obj.frames.coveredMonthsInterval = planOption.planOptionValueID;
            }
            //Allowances
            if (planOption.planOptionID === 'RetailFrameAllowance') {
                obj.frames.allowanceRetail = planOption.planOptionValueID;
            }
            if (planOption.planOptionID === 'InnetworkContactLensAllowance' && !isUhcRenewals()) {
                obj.contactLenses.allowanceIn = planOption.planOptionValueID;
                obj.allowance.oon.bifocalAllowance =  planOption.planOptionValueID;
                obj.allowance.oon.necessaryCLAllowance = planOption.planOptionValueID; 
                obj.allowance.oon.trifocalAllowance =  planOption.planOptionValueID;
            }
            if (planOption.planOptionID === 'InnetworkContactLensAllowance' && isUhcRenewals()) {
                obj.allowance.oon.bifocalAllowance= "See Benefit Summary";
                obj.allowance.oon.necessaryCLAllowance=210;
                obj.allowance.oon.trifocalAllowance =  "See Benefit Summary"
                if((existingPlan.planID.charAt(0)==="S" && existingPlan.planID.charAt(1)==="F")|| existingPlan.planID.charAt(0)==="V" ){
                    obj.allowance.nonformularyCLAllowance=planOption.planOptionValueID;  
                    obj.contactLenses.formularyContactLensesFittingEvaluation=obj.materialCopayIn ? obj.materialCopayIn :"See Benefit Summary";
                    obj.contactLenses.allowanceIn="N/A" ;
                    obj.allowance.contactLensFittingAllowance="N/A";             
                }
                else{
                    obj.contactLenses.allowanceIn = planOption.planOptionValueID;
                    obj.allowance.contactLensFittingAllowance = "See Benefit Summary";
                    obj.allowance.nonformularyCLAllowance="N/A";
                    obj.contactLenses.formularyContactLensesFittingEvaluation="N/A"
                }               
            }
            // if(planOption.planOptionID==='LensesBenefitFrequency'){
            // obj.allowance.contactLensFittingAllowance = SEE_BENEFIT_SUMMARY;
            // }

            //oon in plan details
            if (planOption.planOptionID === 'OutNetExamMaxAmount') {
                obj.allowance.oon.examAllowance = isUhcRenewals()?("Up to $"+planOption.planOptionValueID):planOption.planOptionValueID;
            }
            if (planOption.planOptionID === 'OutNetSingleVisionLensesMaxAmt') {
                obj.allowance.oon.singleLensAllowance = planOption.planOptionValueID;
            }
            // if (planOption.planOptionID === 'InnetworkContactLensAllowance') {
            //     obj.allowance.oon.bifocalAllowance = planOption.planOptionValueID;
            // }
            // if (planOption.planOptionID === 'InnetworkContactLensAllowance') {
            //     obj.allowance.oon.trifocalAllowance = planOption.planOptionValueID;
            // }
            if (planOption.planOptionID === 'OutNetFramesMaxAmount') {
                obj.frames.allowanceOut = isUhcRenewals()?("Up to $"+planOption.planOptionValueID):planOption.planOptionValueID;


            }
            if (planOption.planOptionID === 'OutNetContactLensesMaxAmount') {
                obj.contactLenses.allowanceOut = planOption.planOptionValueID;
            }
            // if (planOption.planOptionID === 'InnetworkContactLensAllowance') {
            //     obj.allowance.oon.necessaryCLAllowance = planOption.planOptionValueID;
            // }

        }

        if(isUhcRenewals()){
            obj["renewalChangePercent"] =  obj["renewalChangePercent"] === "" ? SEE_BENEFIT_SUMMARY : obj["renewalChangePercent"];
            obj.visionPlanTypeDescription = obj.visionPlanTypeDescription === "" ? SEE_BENEFIT_SUMMARY : obj.visionPlanTypeDescription;
            obj.enrolledCount = obj.enrolledCount === "" ? SEE_BENEFIT_SUMMARY : obj.enrolledCount;
            obj.rateGuarantee = obj.rateGuarantee === "" ? SEE_BENEFIT_SUMMARY : obj.rateGuarantee;
            obj["underwriterExceptionCode"] =  obj["underwriterExceptionCode"] === "" ? SEE_BENEFIT_SUMMARY : obj["underwriterExceptionCode"];
            obj.legalName =  obj.legalName === "" ? SEE_BENEFIT_SUMMARY : obj.legalName;

            obj.exam.copayIn = obj.exam.copayIn === "" ? SEE_BENEFIT_SUMMARY : obj.exam.copayIn;
            obj.materialCopayIn = obj.materialCopayIn === "" ? SEE_BENEFIT_SUMMARY : obj.materialCopayIn;
            obj.exam.coveredMonthsInterval = obj.exam.coveredMonthsInterval === "" ? SEE_BENEFIT_SUMMARY : obj.exam.coveredMonthsInterval;
            obj.lenses.coveredMonthsInterval = obj.lenses.coveredMonthsInterval === "" ? SEE_BENEFIT_SUMMARY : obj.lenses.coveredMonthsInterval;
            obj.frames.coveredMonthsInterval = obj.frames.coveredMonthsInterval === "" ? SEE_BENEFIT_SUMMARY : obj.frames.coveredMonthsInterval;
            obj.frames.allowanceRetail = obj.frames.allowanceRetail === "" ? SEE_BENEFIT_SUMMARY : obj.frames.allowanceRetail;

            obj.allowance.oon.bifocalAllowance =  obj.allowance.oon.bifocalAllowance === "" ? SEE_BENEFIT_SUMMARY : obj.allowance.oon.bifocalAllowance;
            obj.allowance.oon.necessaryCLAllowance = obj.allowance.oon.necessaryCLAllowance === "" ? SEE_BENEFIT_SUMMARY : obj.allowance.oon.necessaryCLAllowance; 
            obj.allowance.oon.trifocalAllowance =  obj.allowance.oon.trifocalAllowance === "" ? SEE_BENEFIT_SUMMARY : obj.allowance.oon.trifocalAllowance;
            obj.allowance.nonformularyCLAllowance=obj.allowance.nonformularyCLAllowance === "" ? SEE_BENEFIT_SUMMARY : obj.allowance.nonformularyCLAllowance;

            obj.contactLenses.formularyContactLensesFittingEvaluation= obj.contactLenses.formularyContactLensesFittingEvaluation === "" ? SEE_BENEFIT_SUMMARY : obj.contactLenses.formularyContactLensesFittingEvaluation;
            obj.contactLenses.allowanceIn=obj.contactLenses.allowanceIn === "" ? SEE_BENEFIT_SUMMARY : obj.contactLenses.allowanceIn;
            obj.allowance.contactLensFittingAllowance = obj.allowance.contactLensFittingAllowance === "" ? SEE_BENEFIT_SUMMARY : obj.allowance.contactLensFittingAllowance;
            obj.allowance.oon.examAllowance = obj.allowance.oon.examAllowance === "Up to $" ? SEE_BENEFIT_SUMMARY : obj.allowance.oon.examAllowance;
            obj.allowance.oon.singleLensAllowance = obj.allowance.oon.singleLensAllowance === "" ? SEE_BENEFIT_SUMMARY : obj.allowance.oon.singleLensAllowance;
            obj.allowance.oon.frameAllowance = obj.allowance.oon.frameAllowance === "Up to $" ? SEE_BENEFIT_SUMMARY : obj.allowance.oon.frameAllowance;
            obj.frames.allowanceOut = obj.frames.allowanceOut === "Up to $" ? SEE_BENEFIT_SUMMARY : obj.frames.allowanceOut;
            obj.contactLenses.allowanceOut = obj.contactLenses.allowanceOut ==="" ? SEE_BENEFIT_SUMMARY : obj.contactLenses.allowanceOut; 
        }

        let byCensusRates = []
        if (empCensus) {
            if (renewalPlanType !== 'alternate') {
                empCensus.map(emp => {
                    if (renewalPlanType === 'current') {
                        let empId = emp.xref
                        let planRate = emp.electedPlans.plan.find(plan => plan.planID === obj.code && plan.coverageType !== 'WAV')
                        if (planRate) {
                            byCensusRates.push({
                                employeeId: empId,
                                Total: planRate.rate
                            })
                        }
                    }
                    else if (renewalPlanType === 'renewing') {
                        let empId = emp.xref
                        let planRate = emp.renewingPlans.plan.find(plan => plan.planID === obj.code && plan.coverageType !== 'WAV')
                        if (planRate) {
                            byCensusRates.push({
                                employeeId: empId,
                                Total: planRate.rate
                            })
                        }
                    }
                })
            }
            else {
                empCensus.map(emp => {
                    let empId = emp.xref
                    let planRate = emp.alternatePlans.plan.find(plan => plan.planID === obj.code && plan.coverageType !== 'WAV')
                    if (planRate) {
                        byCensusRates.push({
                            employeeId: empId,
                            Total: planRate.rate
                        })
                    }
                })
            }
        }
        obj.byCensus.employeeRates = byCensusRates

        // if(obj.isMultiStatePlan === 'NA'){
        //     const planDetailsResponse = await getStepwisePlanDetails(getMarketType() === MARKET_TYPES.OXFORD ? 'cirrus' : 'prime', stateCode, groupSize, effectiveDate, 'vision', obj.code);
        //     if(planDetailsResponse && planDetailsResponse.isMultiStatePlan !== null){
        //         obj.isMultiStatePlan = planDetailsResponse.isMultiStatePlan
        //     }
        // }
        
        if (renewalPlanType === 'alternate') {
            alternate.push(obj);
        } else {
            ibrPlans.push(obj);
        }
    }

    async function ibrToBasicLife(existingPlan, renewalPlanType, reviewData, empCensus , xrefData , subGroups) {
        const existingPlanOptions = existingPlan.options.planOption;
        const existingPlanRates = existingPlan.rates.compositeRates;
        const existingPlanClientDefinedData = existingPlan.clientDefinedData.data;
        const isUhcRenewal = isUhcRenewals()
        let obj = {
            planType: "Life",
            selectedClass : subGroups && subGroups.length > 0 ? subGroups.find((group) => group.planOfferingXref === xrefData)?.classDescription || '' : "",
            renewalPlanType: renewalPlanType,
            code: existingPlan.planID,
            primePlanCode: existingPlan.planID,
            productType: existingPlan.productLineType.replace(/_/g, ' '),
            planXREF: existingPlan.planXREF,
            lifeBenefitType: '', //NOT FOUND
            ADandDBenefitType: SEE_BENEFIT_SUMMARY,
            lifeExpectancy: SEE_BENEFIT_SUMMARY,
            EliminationPeriod: SEE_BENEFIT_SUMMARY,
            fundingType : SEE_BENEFIT_SUMMARY,
            suicideLimitBenefit: SEE_BENEFIT_SUMMARY,
            coverageTermination: SEE_BENEFIT_SUMMARY,
            eliminationPeriod: SEE_BENEFIT_SUMMARY,
            lifeBenefits: {
                waiverofPremium: {
                    eligibility: SEE_BENEFIT_SUMMARY,
                    duration: SEE_BENEFIT_SUMMARY
                }
            },
            rateType: SEE_BENEFIT_SUMMARY,
            rateGuarantee: isUhcRenewal ? 24 : SEE_BENEFIT_SUMMARY,
            guaranteedIssue: SEE_BENEFIT_SUMMARY,
            adAndDBenefit: {
                lossperiod: SEE_BENEFIT_SUMMARY,
                hemiplegiaPercent: SEE_BENEFIT_SUMMARY,
                paraplegiaPercent: SEE_BENEFIT_SUMMARY,
                lossPeriod: SEE_BENEFIT_SUMMARY,
                seatBeltBenefit: SEE_BENEFIT_SUMMARY,
                seatBeltAirBagBenefit: SEE_BENEFIT_SUMMARY,
                lossofLifePercent: SEE_BENEFIT_SUMMARY,
                quadriplegiaPercent: SEE_BENEFIT_SUMMARY,
                onehandPercent: SEE_BENEFIT_SUMMARY,
                oneFootPercent: SEE_BENEFIT_SUMMARY,
                handsAndFeetPercent: SEE_BENEFIT_SUMMARY,
                oneeyePercent: SEE_BENEFIT_SUMMARY,
                sightBothEyesPercent: SEE_BENEFIT_SUMMARY,
                oneHandOneEyePercent: SEE_BENEFIT_SUMMARY,
                oneFootOneEyePercent: SEE_BENEFIT_SUMMARY,
                thumbIndexFingerPercent: SEE_BENEFIT_SUMMARY,
                speechPercent: SEE_BENEFIT_SUMMARY,
                hearingPercent: SEE_BENEFIT_SUMMARY,
                coverageTermination: SEE_BENEFIT_SUMMARY,
            },

            ADandDCode: SEE_BENEFIT_SUMMARY,
            quote: {
                totalMonthlyPremium: parseFloat(existingPlan.planRate),
                ADDTotVolAmount: '0',
                aDndTotalPremium: '0'
            },
            byCensus: { finalMonthlyPremium: parseFloat(existingPlan.planRate) },
            monthlyPremium: {
                employee: parseFloat(existingPlanRates.tier1),
                empAndSpouse: parseFloat(existingPlanRates.tier2),
                empAndChild: parseFloat(existingPlanRates.tier3),
                empAndFamily: parseFloat(existingPlanRates.tier4)
            },
            finalMonthlyPremium: parseFloat(existingPlan.planRate),
            selected: false,
            favorited: false,
            finalRates: {
                code: existingPlan.planID,
                combinedCodes: existingPlan.planID + "--" + SEE_BENEFIT_SUMMARY,
                employeeRate: [],
                totalAnnualPremium: parseFloat(existingPlan.planRate) * 12,
                totalMonthlyPremium: parseFloat(existingPlan.planRate),
            },
            minimumParticipationPercentage: SEE_BENEFIT_SUMMARY,
            planMax: SEE_BENEFIT_SUMMARY,
            ageBenefitReductionSchedule: SEE_BENEFIT_SUMMARY,
            acceleratedDeathBenefit: SEE_BENEFIT_SUMMARY,
            conversion: SEE_BENEFIT_SUMMARY,
            portability: SEE_BENEFIT_SUMMARY,
            waiverOfPremium: SEE_BENEFIT_SUMMARY,

            pairedAdnDDetails: {
                benefitAmount: SEE_BENEFIT_SUMMARY,
                adAndDBenefit: {
                    lossPeriod: SEE_BENEFIT_SUMMARY,
                    ageBenefitReductionSchedule: SEE_BENEFIT_SUMMARY,
                    seatBeltAirBagBenefit: SEE_BENEFIT_SUMMARY,
                    lossofLifePercent: SEE_BENEFIT_SUMMARY,
                    quadriplegiaPercent: SEE_BENEFIT_SUMMARY,
                    paraplegiaPercent: SEE_BENEFIT_SUMMARY,
                    speechPercent: SEE_BENEFIT_SUMMARY,
                    hearingPercent: SEE_BENEFIT_SUMMARY,
                },
                code: SEE_BENEFIT_SUMMARY,
                productType: SEE_BENEFIT_SUMMARY,
                fundingType: SEE_BENEFIT_SUMMARY,//NOT FOUND
                minimumParticipationPercentage: SEE_BENEFIT_SUMMARY,//not found
                guaranteedIssue: SEE_BENEFIT_SUMMARY,//not found,
                planMax: SEE_BENEFIT_SUMMARY,//not found
                ageBenefitReductionSchedule: SEE_BENEFIT_SUMMARY,//not found
                acceleratedDeathBenefit: SEE_BENEFIT_SUMMARY,//not found
                conversion: SEE_BENEFIT_SUMMARY,//not found
                portability: SEE_BENEFIT_SUMMARY,//not found
                waiverOfPremium: SEE_BENEFIT_SUMMARY,//not found,

            }
        }

        obj.tierStructureId = existingPlan.tierStructureID
        obj.member = [];

        empCensus.map(employee =>
            (checkBelonging(renewalPlanType,employee,existingPlan,false)?
            obj.member.push({
                employeeId: employee.xref,
                ssn: employee.ssn,
                zipCode: employee.homeZip ? employee.homeZip : '',
                contactInfo: {
                    streetAddress: employee.clientDefinedData.data.find(({ key }) => key === 'EmployeeAddr1') ? employee.clientDefinedData.data.find(({ key }) => key === 'EmployeeAddr1').value : '',
                    city: employee.clientDefinedData.data.find(({ key }) => key === 'EmployeeCity') ? employee.clientDefinedData.data.find(({ key }) => key === 'EmployeeCity').value : '',
                    state: employee.clientDefinedData.data.find(({ key }) => key === 'State') ? employee.clientDefinedData.data.find(({ key }) => key === 'State').value : '',
                    zipCode: employee.clientDefinedData.data.find(({ key }) => key === 'Zip') ? employee.clientDefinedData.data.find(({ key }) => key === 'Zip').value : '',
                },
                firstName: employee.first,
                lastName: employee.last,
                optedLifeClass : obj?.selectedClass,
                outOfArea: 'No',
                gender: employee.genderType === 'M'?'Male':'Female',
                dob: formatDate(employee.dateOfBirth.month) + '/' + formatDate(employee.dateOfBirth.day) + '/' + employee.dateOfBirth.year,
                age: getAge(formatDate(employee.dateOfBirth.month) + '/' + formatDate(employee.dateOfBirth.day) + '/' + employee.dateOfBirth.year, effectiveDate),
                status: employee.status,
                coverages: {
                    life: 
                        obj.renewalPlanType === "current" ? employee.electedPlans.plan.filter(plan => plan.planID === existingPlan.planID)[0]?.coverageType :
                            obj.renewalPlanType === "renewing" ? employee.renewingPlans.plan.filter(plan => plan.planID === existingPlan.planID)[0]?.coverageType :
                                employee.alternatePlans.plan.filter(plan => plan.planId === existingPlan.planId)[0]?.coverageType
                },
                ooaPremium: 0,
                total:
                    obj.renewalPlanType === "current" ? employee.electedPlans.plan.filter(plan => plan.planID === existingPlan.planID)[0]?.rate :
                        obj.renewalPlanType === "renewing" ? employee.renewingPlans.plan.filter(plan => plan.planID === existingPlan.planID)[0]?.rate  :
                            employee.alternatePlans.plan.filter(plan => plan.planID === existingPlan.planID)[0]?.rate 
                    ,
                dependents: employee?.dependents?.dependent.map(dependentOne =>
                ({
                    firstName: dependentOne.first,
                    lastName: dependentOne.last,
                    relationship: dependentOne.depType === 'C'?'Child':'S'?'Spouse':"",
                    age: getAge(formatDate(dependentOne.dateOfBirth.month) + '/' + formatDate(dependentOne.dateOfBirth.day) + '/' + dependentOne.dateOfBirth.year, effectiveDate),
                    dob: formatDate(dependentOne.dateOfBirth.month) + '/' + formatDate(dependentOne.dateOfBirth.day) + '/' + dependentOne.dateOfBirth.year,
                    gender: employee.genderType === 'M'? 'Male' : 'Female',
                    streetAddress: employee.clientDefinedData.data.find(({ key }) => key === 'EmployeeAddr1') ? employee.clientDefinedData.data.find(({ key }) => key === 'EmployeeAddr1').value : '',
                    streetAddressln2: '',
                    city: employee.clientDefinedData.data.find(({ key }) => key === 'EmployeeCity') ? employee.clientDefinedData.data.find(({ key }) => key === 'EmployeeCity').value : '',
                    state: employee.clientDefinedData.data.find(({ key }) => key === 'State') ? employee.clientDefinedData.data.find(({ key }) => key === 'State').value : '',
                    zipCode: employee.clientDefinedData.data.find(({ key }) => key === 'Zip') ? employee.clientDefinedData.data.find(({ key }) => key === 'Zip').value : '',
                    ssn: dependentOne.ssn
                })) || []
            })
            :""))

        for (const clientDefinedData of existingPlanClientDefinedData) {

            if(clientDefinedData.key === "CostDifferentialPercentage" && renewalPlanType === 'renewing') {
                obj["renewalChangePercent"] =  clientDefinedData.value ;
            }

            // if (clientDefinedData.key === 'PlanCategory') {
            //     obj.productType = clientDefinedData.value;
            // }
            if (clientDefinedData.key === 'EmployeeEnrollCount') {
                if (renewalPlanType !== 'alternate')
                    obj.enrolledCount = clientDefinedData.value;
                else
                    obj.enrolledCount = "0";
            }
            if(isUhcRenewal){
                if (clientDefinedData.key === 'basicLifeAddContribution') {
                    obj.pairedAdnDDetails.fundingType = clientDefinedData.value
                }
                if (clientDefinedData.key === 'basicLifeAddGiAmount') {
                    obj.pairedAdnDDetails.guaranteedIssue = clientDefinedData.value;
                }
                if (clientDefinedData.key === 'basicLifeAddPlanCode') {
                    obj.ADandDCode = clientDefinedData.value;
                    obj.pairedAdnDDetails.code = clientDefinedData.value;
                }
                if (clientDefinedData.key === 'basicLifeContribution') {
                    obj.fundingType = clientDefinedData.value
                }
                if (clientDefinedData.key === 'basicLifeGiAmount') {
                    obj.guaranteedIssue = clientDefinedData.value;
                }
                if (clientDefinedData.key === 'basicLifePlanCode') {
                    obj.code = clientDefinedData.value;
                }
                if (clientDefinedData.key === 'RateGuaranteePeriod') {
                    if (clientDefinedData.value.toString() === '2' || clientDefinedData.value.toString() === '12') {
                        obj.rateGuarantee = 12
                    } else if (clientDefinedData.value.toString() === '0') {
                        obj.rateGuarantee = 0
                    } else {
                        obj.rateGuarantee = 24
                    }
                }
            }
            if (clientDefinedData.key === 'LegalName') {
                obj.legalName = clientDefinedData.value
            }
        }

        for (const planOption of existingPlanOptions) {

            // if (planOption.planOptionID === 'LifeProductDescription') {
            //     obj.fundingType = planOption.planOptionValueID;
            //     obj.pairedAdnDDetails.fundingType = planOption.planOptionValueID
            // }
            //Benefits
            if (planOption.planOptionID === 'LifeBenefitType') {
                obj.lifeBenefitType = planOption.planOptionValueID;
            }

            if (planOption.planOptionID === 'LifeBenefitAmount') {
                obj.benefitAmount = planOption.planOptionValueID;
                obj.pairedAdnDDetails.benefitAmount = planOption.planOptionValueID
                obj.ADandDBenefitAmount = planOption.planOptionValueID;
                obj.finalRates.ADDTotVolAmount = planOption.planOptionValueID;
                obj.quote.ADDTotVolAmount = planOption.planOptionValueID
            }
            if (planOption.planOptionID === 'TotalLifeVolume') {
                obj.quote.BasicLifeTotVolAmount = planOption.planOptionValueID;
                obj.finalRates.BasicLifeTotVolAmount = planOption.planOptionValueID;
            }
            //
            if (planOption.planOptionID === 'CalRatePerDollar1000Life') {
                obj.quote.basicLifeRateVol = planOption.planOptionValueID;
                obj.finalRates.basicLifeRateVol = planOption.planOptionValueID;
            }
            if (planOption.planOptionID === 'CalRatePerDollar1000ADD') {
                obj.quote.aDnDLifeRateVol = planOption.planOptionValueID;
                obj.finalRates.aDnDLifeRateVol = planOption.planOptionValueID;
            }
            if (planOption.planOptionID === 'LifeAddRatePer1000') {
                obj.quote.totalLifeRateVol = planOption.planOptionValueID;
                obj.finalRates.totalLifeRateVol = planOption.planOptionValueID;
            }
            if (planOption.planOptionID === 'MonthlyLifePremium') {
                obj.finalRates.basicLifeTotalPremium = planOption.planOptionValueID;
            }
            if (planOption.planOptionID === 'MonthlyADDPremium') {
                obj.quote.aDndTotalPremium = planOption.planOptionValueID
                obj.finalRates.aDndTotalPremium = planOption.planOptionValueID;
            }
            if (planOption.planOptionID === 'FlatPlanRatePerEmployee') {
                let emp_Rate = planOption.planOptionValueID;
                obj.finalRates.employeeRate.push(emp_Rate)
            }
            if(isUhcRenewals() && planOption.planOptionID === 'LifePlanType'){
                obj.lifePlanType = planOption.planOptionValueID;
            }
        }

        if(isUhcRenewals() && obj.lifePlanType){
            if(obj.lifePlanType === "DOLLAR"){
                obj.benefitAmount = "$" + obj.benefitAmount;
            }
            else if(obj.lifePlanType === "MULTIPLE"){
                obj.benefitAmount = obj.benefitAmount+"X Salary";
            }
        }

        if(isUhcRenewals()){
                obj["renewalChangePercent"] =  obj["renewalChangePercent"] === "" ? SEE_BENEFIT_SUMMARY : obj["renewalChangePercent"]; 
                obj.enrolledCount = obj.enrolledCount === "" ? SEE_BENEFIT_SUMMARY : obj.enrolledCount;
                obj.pairedAdnDDetails.fundingType =obj.pairedAdnDDetails.fundingType === "" ? SEE_BENEFIT_SUMMARY : obj.pairedAdnDDetails.fundingType;
                obj.pairedAdnDDetails.guaranteedIssue =obj.pairedAdnDDetails.guaranteedIssue === "" ? SEE_BENEFIT_SUMMARY : obj.pairedAdnDDetails.guaranteedIssue;
                obj.ADandDCode =obj.ADandDCode === "" ? SEE_BENEFIT_SUMMARY : obj.ADandDCode;
                obj.pairedAdnDDetails.code =obj.pairedAdnDDetails.code === "" ? SEE_BENEFIT_SUMMARY : obj.pairedAdnDDetails.code;
                obj.fundingType =obj.fundingType === "" ? SEE_BENEFIT_SUMMARY : obj.fundingType;
                obj.guaranteedIssue =obj.guaranteedIssue === "" ? SEE_BENEFIT_SUMMARY : obj.guaranteedIssue;
                obj.rateGuarantee = obj.rateGuarantee === "" ? SEE_BENEFIT_SUMMARY : obj.rateGuarantee;
                obj.legalName =obj.legalName === "" ? SEE_BENEFIT_SUMMARY : obj.legalName;
                
                obj.lifeBenefitType = obj.lifeBenefitType === "" ? SEE_BENEFIT_SUMMARY : obj.lifeBenefitType;
                obj.benefitAmount = obj.benefitAmount === "" ? SEE_BENEFIT_SUMMARY : obj.benefitAmount;
                obj.pairedAdnDDetails.benefitAmount = obj.pairedAdnDDetails.benefitAmount === "" ? SEE_BENEFIT_SUMMARY : obj.pairedAdnDDetails.benefitAmount;
                obj.ADandDBenefitAmount = obj.ADandDBenefitAmount === "" ? SEE_BENEFIT_SUMMARY : obj.ADandDBenefitAmount;
                obj.finalRates.ADDTotVolAmount = obj.finalRates.ADDTotVolAmount === "" ? SEE_BENEFIT_SUMMARY : obj.finalRates.ADDTotVolAmount;
                obj.quote.ADDTotVolAmount = obj.quote.ADDTotVolAmount === "" ? SEE_BENEFIT_SUMMARY : obj.quote.ADDTotVolAmount;
                obj.quote.BasicLifeTotVolAmount = obj.quote.BasicLifeTotVolAmount === "" ? SEE_BENEFIT_SUMMARY : obj.quote.BasicLifeTotVolAmount;
                obj.finalRates.BasicLifeTotVolAmount = obj.finalRates.BasicLifeTotVolAmount === "" ? SEE_BENEFIT_SUMMARY : obj.finalRates.BasicLifeTotVolAmount;
                obj.quote.basicLifeRateVol = obj.quote.basicLifeRateVol === "" ? SEE_BENEFIT_SUMMARY : obj.quote.basicLifeRateVol;
                obj.finalRates.basicLifeRateVol = obj.finalRates.basicLifeRateVol === "" ? SEE_BENEFIT_SUMMARY : obj.finalRates.basicLifeRateVol;
                obj.quote.aDnDLifeRateVol = obj.quote.aDnDLifeRateVol === "" ? SEE_BENEFIT_SUMMARY : obj.quote.aDnDLifeRateVol;
                obj.finalRates.aDnDLifeRateVol = obj.finalRates.aDnDLifeRateVol  === "" ? SEE_BENEFIT_SUMMARY : obj.finalRates.aDnDLifeRateVol ;
                obj.quote.totalLifeRateVol = obj.quote.totalLifeRateVol === "" ? SEE_BENEFIT_SUMMARY : obj.quote.totalLifeRateVol;
                obj.finalRates.totalLifeRateVol = obj.finalRates.totalLifeRateVol === "" ? SEE_BENEFIT_SUMMARY : obj.finalRates.totalLifeRateVol;
                obj.finalRates.basicLifeTotalPremium = obj.finalRates.basicLifeTotalPremium === "" ? SEE_BENEFIT_SUMMARY : obj.finalRates.basicLifeTotalPremium;
                obj.quote.aDndTotalPremium = obj.quote.aDndTotalPremium === "" ? SEE_BENEFIT_SUMMARY : obj.quote.aDndTotalPremium;
                obj.finalRates.aDndTotalPremium = obj.finalRates.aDndTotalPremium === "" ? SEE_BENEFIT_SUMMARY : obj.finalRates.aDndTotalPremium;
        }

        let byCensusRates = []
        if (empCensus) {
            if (renewalPlanType !== 'alternate') {
                empCensus.map(emp => {
                    if (renewalPlanType === 'current') {
                        let empId = emp.xref
                        let planRate = emp.electedPlans.plan.find(plan => plan.planID === obj.planXREF && plan.coverageType !== 'WAV')
                        if (planRate) {
                            byCensusRates.push({
                                employeeId: empId,
                                Total: obj.finalRates.employeeRate[0]  !== "NA" ? obj.finalRates.employeeRate[0] : planRate?.rate
                            })
                        }
                    }
                    else if (renewalPlanType === 'renewing') {
                        let empId = emp.xref
                        let planRate = emp.renewingPlans.plan.find(plan => plan.planID === obj.planXREF && plan.coverageType !== 'WAV')
                        if (planRate) {
                            byCensusRates.push({
                                employeeId: empId,
                                Total: obj.finalRates.employeeRate[0]  !== "NA" ? obj.finalRates.employeeRate[0] : planRate?.rate
                            })
                        }
                    }
                })
            }
            else {
                empCensus.map(emp => {
                    let empId = emp.xref
                    let planRate = emp.alternatePlans.plan.find(plan => plan.planID === obj.planXREF && plan.coverageType !== 'WAV')
                    if (planRate) {
                        byCensusRates.push({
                            employeeId: empId,
                            Total: planRate.rate
                        })
                    }
                })
            }
        }
        obj.byCensus.employeeRates = byCensusRates

        if(!isUhcRenewals()) {
            if(obj.code === obj.primePlanCode){
                const planDetailsResponse = await getStepwisePlanDetails('cirrus', stateCode, groupSize, effectiveDate, 'life', obj.primePlanCode);
                if(planDetailsResponse && !planDetailsResponse.err){
                    obj.code = planDetailsResponse.planCode;
                    obj.name =  planDetailsResponse.planName
                    obj.isMultiStatePlan = planDetailsResponse.isMultiStatePlan
                    obj.ADandDCode = planDetailsResponse.ADandDCode
                    obj.pairedAdnDDetails.code = planDetailsResponse.ADandDCode
                    obj.fundingType = planDetailsResponse.fundingType
                    obj.productType = planDetailsResponse.productType
                }
            }
        } else {
            const lifePlanDetails = reviewData && reviewData.renewalData && 
                reviewData.renewalData.lifePlanDetails ? reviewData.renewalData.lifePlanDetails : [];

            const planDetails = getLifePlanDetails(lifePlanDetails, stateCode, groupSize, effectiveDate, 'life', obj.primePlanCode , true , obj.code,obj.selectedClass);
            if(planDetails.primePlanCode) {
                if(obj.selectedClass){
                    obj.code = planDetails.planCode;
                    obj.ADandDCode = planDetails.ADandDCode
                }   
                obj.fundingType = planDetails?.fundingType
                obj.pairedAdnDDetails.fundingType = planDetails?.fundingType
                obj.name =  planDetails.planName
                obj.isMultiStatePlan = planDetails.isMultiStatePlan
                // obj.pairedAdnDDetails.code = planDetails.ADandDCode
                //obj.fundingType = planDetails.fundingType
                obj.productType = planDetails.productType
            }
        }

        if (renewalPlanType === 'alternate') {
            alternate.push(obj);
        } else {
            ibrPlans.push(obj);
        }

    }

    async function ibrToDependentLife(existingPlan, renewalPlanType, reviewData, empCensus) {

        const existingPlanOptions = existingPlan.options.planOption;
        const existingPlanRates = existingPlan.rates.compositeRates;
        const existingPlanClientDefinedData = existingPlan.clientDefinedData.data;

        const isMarketTypeOxford = getMarketType() === MARKET_TYPES.OXFORD;

        let obj = {
            planType: "LIFE_DEP_BASIC",
            renewalPlanType: renewalPlanType,
            code: existingPlan.planID,
            primePlanCode: existingPlan.planID,
            planXREF: existingPlan.planXREF,
            ADandDCode: SEE_BENEFIT_SUMMARY,
            quote: {
                totalMonthlyPremium: parseFloat(existingPlan.planRate),

            },
            byCensus: { finalMonthlyPremium: parseFloat(existingPlan.planRate) },
            monthlyPremium: {
                employee: parseFloat(existingPlanRates.tier1),
                empAndSpouse: parseFloat(existingPlanRates.tier2),
                empAndChild: parseFloat(existingPlanRates.tier3),
                empAndFamily: parseFloat(existingPlanRates.tier4)
            },
            finalMonthlyPremium: parseFloat(existingPlan.planRate),
            selected: false,
            favorited: false,
            minimumParticipationPercentage: SEE_BENEFIT_SUMMARY,//not found
            guaranteedIssue: SEE_BENEFIT_SUMMARY,//not found,
            planMax: SEE_BENEFIT_SUMMARY,//not found
            ageBenefitReductionSchedule: SEE_BENEFIT_SUMMARY,//not found
            acceleratedDeathBenefit: SEE_BENEFIT_SUMMARY,//not found
            conversion: SEE_BENEFIT_SUMMARY,//not found
            portability: SEE_BENEFIT_SUMMARY,//not found
            waiverOfPremium: SEE_BENEFIT_SUMMARY,//not found

            pairedAdnDDetails: {
                code: SEE_BENEFIT_SUMMARY,
                fundingType: SEE_BENEFIT_SUMMARY,
                minimumParticipationPercentage: SEE_BENEFIT_SUMMARY,//not found
                planMax: SEE_BENEFIT_SUMMARY,//not found
                guaranteedIssue: SEE_BENEFIT_SUMMARY,//not found,
                ageBenefitReductionSchedule: SEE_BENEFIT_SUMMARY,//not found
                acceleratedDeathBenefit: SEE_BENEFIT_SUMMARY,//not found
                conversion: SEE_BENEFIT_SUMMARY,//not found
                portability: SEE_BENEFIT_SUMMARY,//not found
                waiverOfPremium: SEE_BENEFIT_SUMMARY,//not found,
            }
        }

        obj.tierStructureId = existingPlan.tierStructureID
        obj.member = [];

        empCensus.map(employee =>
        {
            let doExist = false
            let totalDepPremium = '0.00';
            if (isUhcRenewals()) {        
                //for depPlans we need to pass dependents so that in checkBelongingFunction dependentPlan will be compared correctly      
                for (let i = 0; i < employee?.dependents?.dependent.length; i++) {
                    let dep = employee.dependents.dependent[i];
                    totalDepPremium = obj.renewalPlanType === "current" ? (dep.coverageElections?.plan?.find(plan => plan.planID === existingPlan.planID && plan.coverageType !== 'WAV')?.rate || '0.00' ):
                        obj.renewalPlanType === "renewing" ? (dep.renewingCoverageElections?.plan?.find(plan => plan.planID === existingPlan.planID && plan.coverageType !== 'WAV')?.rate || '0.00' ):
                            dep.alternateCoverageElections?.plan?.find(plan => plan.planID === existingPlan.planID && plan.coverageType !== 'WAV')?.rate || '0.00'
                    if (checkBelonging(renewalPlanType,employee.dependents.dependent[i], existingPlan, false, "LIFE_DEP_BASIC")) {
                        doExist = true
                        break;
                    }
                }
            }
            else {
                doExist = checkBelonging(renewalPlanType,employee, existingPlan, false)
            }
            if (doExist) {
                obj.member.push({
                    employeeId: employee.xref,
                    ssn: employee.ssn,
                    zipCode: employee.homeZip ? employee.homeZip : '',
                    contactInfo: {
                        streetAddress: employee.clientDefinedData.data.find(({ key }) => key === 'EmployeeAddr1') ? employee.clientDefinedData.data.find(({ key }) => key === 'EmployeeAddr1').value : '',
                        city: employee.clientDefinedData.data.find(({ key }) => key === 'EmployeeCity') ? employee.clientDefinedData.data.find(({ key }) => key === 'EmployeeCity').value : '',
                        state: employee.clientDefinedData.data.find(({ key }) => key === 'State') ? employee.clientDefinedData.data.find(({ key }) => key === 'State').value : '',
                        zipCode: employee.clientDefinedData.data.find(({ key }) => key === 'Zip') ? employee.clientDefinedData.data.find(({ key }) => key === 'Zip').value : '',
                    },
                    firstName: employee.first,
                    lastName: employee.last,
                    outOfArea: 'No',
                    gender: employee.genderType === 'M'?'Male':'Female',
                    dob: formatDate(employee.dateOfBirth.month) + '/' + formatDate(employee.dateOfBirth.day) + '/' + employee.dateOfBirth.year,
                    age: getAge(formatDate(employee.dateOfBirth.month) + '/' + formatDate(employee.dateOfBirth.day) + '/' + employee.dateOfBirth.year, effectiveDate),
                    status: employee.status,
                    coverages: {
                        dependentLife:
                            obj.renewalPlanType === "current" ? employee.electedPlans.plan.filter(plan => plan.planID === existingPlan.planID)[0]?.coverageType :
                                obj.renewalPlanType === "renewing" ? employee.renewingPlans.plan.filter(plan => plan.planID === existingPlan.planID)[0]?.coverageType :
                                    employee.alternatePlans.plan.filter(plan => plan.planId === existingPlan.planId)[0]?.coverageType
                    },
                    ooaPremium: 0,
                    total: isUhcRenewals() ? totalDepPremium :
                        obj.renewalPlanType === "current" ? employee.electedPlans.plan.filter(plan => plan.planID === existingPlan.planID)[0]?.rate :
                            obj.renewalPlanType === "renewing" ? employee.renewingPlans.plan.filter(plan => plan.planID === existingPlan.planID)[0]?.rate  :
                                employee.alternatePlans.plan.filter(plan => plan.planID === existingPlan.planID)[0]?.rate
                    ,
                    dependents: employee?.dependents?.dependent.map(dependentOne =>
                    ({
                        firstName: dependentOne.first,
                        lastName: dependentOne.last,
                        relationship: dependentOne.depType === 'C'?'Child':'S'?'Spouse':"",
                        age: getAge(formatDate(dependentOne.dateOfBirth.month) + '/' + formatDate(dependentOne.dateOfBirth.day) + '/' + dependentOne.dateOfBirth.year, effectiveDate),
                        dob: formatDate(dependentOne.dateOfBirth.month) + '/' + formatDate(dependentOne.dateOfBirth.day) + '/' + dependentOne.dateOfBirth.year,
                        gender: employee.genderType === 'M'? 'Male' : 'Female',
                        streetAddress: employee.clientDefinedData.data.find(({ key }) => key === 'EmployeeAddr1') ? employee.clientDefinedData.data.find(({ key }) => key === 'EmployeeAddr1').value : '',
                        streetAddressln2: '',
                        city: employee.clientDefinedData.data.find(({ key }) => key === 'EmployeeCity') ? employee.clientDefinedData.data.find(({ key }) => key === 'EmployeeCity').value : '',
                        state: employee.clientDefinedData.data.find(({ key }) => key === 'State') ? employee.clientDefinedData.data.find(({ key }) => key === 'State').value : '',
                        zipCode: employee.clientDefinedData.data.find(({ key }) => key === 'Zip') ? employee.clientDefinedData.data.find(({ key }) => key === 'Zip').value : '',
                        ssn: dependentOne.ssn
                    })) || []
                })
            }
        })

        if(!isMarketTypeOxford) {
            obj.productType = 'Basic Dep Life';
        }

        for (const clientDefinedData of existingPlanClientDefinedData) {

            if(clientDefinedData.key === "CostDifferentialPercentage" && renewalPlanType === 'renewing') {
                obj["renewalChangePercent"] =  clientDefinedData.value ;
            }

            if (clientDefinedData.key === 'PlanCategory' && isMarketTypeOxford) {
                obj.productType = clientDefinedData.value.substr(0, 7);
            }
            if (clientDefinedData.key === 'DependentEnrollCount') {
                obj.enrolledCount = clientDefinedData.value;
            }

            if(isUhcRenewals()){
                if (clientDefinedData.key === 'dependentLifePlanCode') {
                    obj.code = clientDefinedData.value;
                    obj.depLifeCode = clientDefinedData.value;
                }
                if (clientDefinedData.key === 'dependentLifeGiAmount') {
                    obj.guaranteedIssue = clientDefinedData.value;
                    obj.pairedAdnDDetails.guaranteedIssue = clientDefinedData.value;
                }
                if (clientDefinedData.key === 'dependentLifeContribution') {
                    obj.fundingType = clientDefinedData.value
                }
                if (clientDefinedData.key === 'RateGuaranteePeriod') {
                    if (clientDefinedData.value.toString() === '2' || clientDefinedData.value.toString() === '12') {
                        obj.rateGuarantee = 12
                    } else if (clientDefinedData.value.toString() === '0') {
                        obj.rateGuarantee = 0
                    } else {
                        obj.rateGuarantee = 24
                    }
                }
            }
            if (clientDefinedData.key === 'LegalName') {
                obj.legalName = clientDefinedData.value
            }
        }

        for (const planOption of existingPlanOptions) {

            if (planOption.planOptionID === 'LifeProductDescription') {
                obj.ibrFundingType = planOption.planOptionValueID;
            }
            //Benefits
            if (planOption.planOptionID === 'LifeBenefitAmount') {
                obj.benefitAmount = planOption.planOptionValueID;
                obj.ADandDBenefitAmount = planOption.planOptionValueID;
            }
            if (planOption.planOptionID === 'DependentLifeRate') {
                obj.dependentLifeRate = planOption.planOptionValueID;

            }
            if (planOption.planOptionID === 'LifeBenefitType') {
                obj.lifeBenefitType = planOption.planOptionValueID;
            }

            // // if (planOption.planOptionID === 'TotalLifeVolume') {
            //     obj.quote.BasicLifeTotVolAmount = "planOption.planOptionValueID";
            // // }

            //
            // if (planOption.planOptionID === 'CalRatePerDollar1000Life') {
            //     obj.quote.basicLifeRateVol = planOption.planOptionValueID;
            // }
            // if (planOption.planOptionID === 'CalRatePerDollar1000ADD') {
            //     obj.quote.aDnDLifeRateVol = planOption.planOptionValueID;
            // }
            // if (planOption.planOptionID === 'LifeAddRatePer1000') {
            //     obj.quote.totalLifeRateVol = planOption.planOptionValueID;
            // }

        }
        // if(renewalPlanType==='alternate'){
        //     alternate.push(obj);
        // }else{
        //     ibrPlans.push(obj);
        // }

        let byCensusRates = []
        if (empCensus) {
            empCensus.map(emp => {
                if (renewalPlanType === 'current') {
                    let empId = emp.xref                    
                    if (emp.dependents && emp.dependents.dependent) {
                        const newRate = new Set()
                        for (let dep of emp.dependents.dependent) {
                            let planRate = dep.coverageElections.plan.find(plan => plan.planID === obj.planXREF && plan.coverageType !== 'WAV')
                            if (planRate) {
                                newRate.add(planRate.rate)
                            }
                        }
                        for (let item of newRate) {
                            byCensusRates.push({
                                employeeId: empId,
                                Total: item
                            })
                        }
                    }
                    else {
                        // let planRate = emp.electedPlans.plan.find(plan => plan.planID === obj.code && plan.coverageType !== 'WAV')
                        // if (planRate) {
                        //     byCensusRates.push({
                        //         employeeId: empId,
                        //         Total: 0
                        //     })
                        // }
                        byCensusRates.push({
                            employeeId: empId,
                            Total: 0
                        })
                    }
                }
                else if (renewalPlanType === 'renewing') {
                    let empId = emp.xref
                    if (emp.dependents && emp.dependents.dependent) {
                        const newRate = new Set()
                        for (let dep of emp.dependents.dependent) {
                            let planRate = dep.renewingCoverageElections.plan.find(plan => plan.planID === obj.planXREF && plan.coverageType !== 'WAV')
                            if (planRate) {
                                newRate.add(planRate.rate)
                            }
                        }
                        for (let item of newRate) {
                            byCensusRates.push({
                                employeeId: empId,
                                Total: item
                            })
                        }
                    }
                    else {
                        // let planRate = emp.renewingPlans.plan.find(plan => plan.planID === obj.code && plan.coverageType !== 'WAV')
                        // if (planRate) {
                        //     byCensusRates.push({
                        //         employeeId: empId,
                        //         Total: planRate.rate
                        //     })
                        // }
                        byCensusRates.push({
                            employeeId: empId,
                            Total: 0
                        })
                    }
                }
            })
        }
        obj.byCensus.employeeRates = byCensusRates

        if(!isUhcRenewals()) {
            if(obj.code === obj.primePlanCode){
                const planDetailsResponse = await getStepwisePlanDetails('cirrus', stateCode, groupSize, effectiveDate, 'depLife', obj.primePlanCode);
                if(planDetailsResponse && !planDetailsResponse.err){
                    obj.code = planDetailsResponse.planCode;
                    obj.name =  planDetailsResponse.planName
                    obj.isMultiStatePlan = planDetailsResponse.isMultiStatePlan
                    obj.fundingType = planDetailsResponse.fundingType
                    obj.productType = planDetailsResponse.productType
                    obj.lifeBenefitType = planDetailsResponse.benefitType
                    obj.ADandDCode = planDetailsResponse.ADandDCode
                    obj.pairedAdnDDetails.code = planDetailsResponse.AdandDCode
                }
            }
        } else {
            const lifePlanDetails = reviewData && reviewData.renewalData && 
                reviewData.renewalData.lifePlanDetails ? reviewData.renewalData.lifePlanDetails : [];

            const planDetails = getLifePlanDetails(lifePlanDetails, stateCode, groupSize, effectiveDate, 'depLife', obj.primePlanCode,false);
            if(planDetails.primePlanCode) {
                //obj.code = planDetails.planCode;
                obj.name =  planDetails.planName
                obj.isMultiStatePlan = planDetails.isMultiStatePlan
                obj.ADandDCode = planDetails.ADandDCode
                obj.pairedAdnDDetails.code = planDetails.ADandDCode
                //obj.fundingType = planDetails.fundingType
                obj.productType = planDetails.productType
            }
        }

        if (obj.ibrFundingType === 'SP LIFE') {
            spPlans.push(obj);
        }
        if (obj.ibrFundingType === 'CH LIFE') {
            chPlans.push(obj);
        }
    }

    async function ibrToSuppLife(existingPlan, renewalPlanType, reviewData, empCensus, plans) {
        const existingPlanOptions = existingPlan.options.planOption;
        const existingPlanRates = existingPlan.rates?.compositeRates || existingPlan.rates?.ageRates;
        const existingPlanClientDefinedData = existingPlan.clientDefinedData.data;
        const isUhcRenewal = isUhcRenewals()
        let employeeADDLife = {};
        let childLife = {};
        let childADDLife = {};
        let spouseLife = {};
        let spouseADDLife = {};
        for (const plan of plans) {
            if (plan.productLineType === "SUP_ADD_EE") {
                employeeADDLife = plan
            }
            else if (plan.productLineType === "SUP_LIFE_SPOUSE") {
                spouseLife = plan
            }
            else if (plan.productLineType === "SUP_ADD_SPOUSE") {
                spouseADDLife = plan
            }
            else if (plan.productLineType === "SUP_LIFE_CHILD") {
                childLife = plan
            }
            else if (plan.productLineType === "SUP_ADD_CHILD") {
                childADDLife = plan
            }
        }
    

        let obj = {
              // fields added      
            suicideLimitBenefit:'',
            SLEEADDPlan:{
                minimumParticipationPercentage: employeeADDLife.options?.planOption.find(({planOptionID}) => planOptionID === "RequiredParticipation") ? employeeADDLife.options.planOption.find(({planOptionID}) => planOptionID === "RequiredParticipation").planOptionValueID : '' ,
                planMax: employeeADDLife.options?.planOption.find(({planOptionID}) => planOptionID === "BenefitMaximum") ? employeeADDLife.options.planOption.find(({planOptionID}) => planOptionID === "BenefitMaximum").planOptionValueID : '' ,
                portability: employeeADDLife.options?.planOption.find(({planOptionID}) => planOptionID === "portability") ? employeeADDLife.options.planOption.find(({planOptionID}) => planOptionID === "portability").planOptionValueID : '' ,
                rateType : employeeADDLife.rates?.compositeRates ? 'composite' : 'ageBands',
                benefitAmount: employeeADDLife.options?.planOption.find(({planOptionID}) => planOptionID === "BenefitAmount") ? employeeADDLife.options.planOption.find(({planOptionID}) => planOptionID === "BenefitAmount").planOptionValueID : '' ,
                guaranteedIssue: employeeADDLife.options?.planOption.find(({planOptionID}) =>  planOptionID === "GuaranteedIssue") ? employeeADDLife.options.planOption.find(({planOptionID}) =>  planOptionID === "GuaranteedIssue").planOptionValueID : '' ,
                code : employeeADDLife.clientDefinedData?.data.find((cdd) =>  cdd.key === "SULADDplanCode") ? employeeADDLife.clientDefinedData?.data.find((cdd) =>  cdd.key === "SULADDplanCode")?.value : '' ,
                piaPlanCode: employeeADDLife.planXREF ? employeeADDLife.planXREF :""
            },
            SLEEPlan:{
                minimumParticipationPercentage: existingPlan.options?.planOption.find(({planOptionID}) => planOptionID === "RequiredParticipation") ? existingPlan.options.planOption.find(({planOptionID}) => planOptionID === "RequiredParticipation").planOptionValueID : '' ,
                planMax: existingPlan.options?.planOption.find(({planOptionID}) => planOptionID === "BenefitMaximum") ? existingPlan.options.planOption.find(({planOptionID}) => planOptionID === "BenefitMaximum").planOptionValueID : '' ,
                portability: existingPlan.options?.planOption.find(({planOptionID}) => planOptionID === "portability") ? existingPlan.options.planOption.find(({planOptionID}) => planOptionID === "portability").planOptionValueID : '' ,
                rateType : existingPlan.rates?.compositeRates ? 'composite' : 'ageBands',
                benefitAmount: existingPlan.options?.planOption.find(({planOptionID}) =>  planOptionID === "BenefitAmount") ? existingPlan.options.planOption.find(({planOptionID}) => planOptionID === "BenefitAmount").planOptionValueID : '' ,
                guaranteedIssue: existingPlan.options?.planOption.find(({planOptionID}) => planOptionID === "GuaranteedIssue") ? existingPlan.options.planOption.find(({planOptionID}) =>  planOptionID === "GuaranteedIssue").planOptionValueID : '' ,
                code : existingPlan.clientDefinedData?.data.find((cdd) =>  cdd.key === "SULplanCode") ? existingPlan.clientDefinedData?.data.find((cdd) =>  cdd.key === "SULplanCode")?.value : '' ,
                piaPlanCode: existingPlan.planXREF ? existingPlan.planXREF :""
            },

            SLChADDPlan: {
                minimumParticipationPercentage: childADDLife.options?.planOption.find(({planOptionID}) => planOptionID === "RequiredParticipation") ? childADDLife.options.planOption.find(({planOptionID}) => planOptionID === "RequiredParticipation").planOptionValueID : '' ,
                planMax: childADDLife.options?.planOption.find(({planOptionID}) => planOptionID === "BenefitMaximum") ? childADDLife.options.planOption.find(({planOptionID}) => planOptionID === "BenefitMaximum").planOptionValueID : '' ,
                portability: childADDLife.options?.planOption.find(({planOptionID}) => planOptionID === "portability") ? childADDLife.options.planOption.find(({planOptionID}) => planOptionID === "portability").planOptionValueID : '' ,
                rateType : childADDLife.rates?.compositeRates ? 'composite' : 'ageBands',
                guaranteedIssue: childADDLife.options?.planOption.find(({planOptionID}) => planOptionID === "GuaranteedIssue") ? childADDLife.options.planOption.find(({planOptionID}) =>  planOptionID === "GuaranteedIssue").planOptionValueID : '' ,
                benefitAmount: childADDLife.options?.planOption.find(({planOptionID}) => planOptionID === "BenefitAmount") ? childADDLife.options.planOption.find(({planOptionID}) =>  planOptionID === "BenefitAmount").planOptionValueID : '' ,
                lifeBenefits: { portability: { benefit: '' } },
                code : childADDLife.clientDefinedData?.data.find((cdd) =>  cdd.key === "SULADDplanCode") ? childADDLife.clientDefinedData?.data.find((cdd) =>  cdd.key === "SULADDplanCode")?.value : '' ,
                piaPlanCode:childADDLife.planXREF ? childADDLife.planXREF :""
            },
            SLChPlan: {
                conversion : SEE_BENEFIT_SUMMARY,
                rateGuarantee : childLife.clientDefinedData?.data.find((cdd) =>  cdd.key === "RateGuaranteePeriod") ? mapRate(childLife.clientDefinedData?.data.find((cdd) =>  cdd.key === "RateGuaranteePeriod")?.value) : '' ,
                productType : "SLC",
                fundingType : SEE_BENEFIT_SUMMARY,
                minimumParticipationPercentage: childLife.options?.planOption.find(({planOptionID}) => planOptionID === "RequiredParticipation") ? childLife.options.planOption.find(({planOptionID}) => planOptionID === "RequiredParticipation").planOptionValueID : '' ,
                planMax: childLife.options?.planOption.find(({planOptionID}) => planOptionID === "BenefitMaximum") ? childLife.options.planOption.find(({planOptionID}) => planOptionID === "BenefitMaximum").planOptionValueID : '' ,
                portability: childLife.options?.planOption.find(({planOptionID}) => planOptionID === "portability") ? childLife.options.planOption.find(({planOptionID}) => planOptionID === "portability").planOptionValueID : SEE_BENEFIT_SUMMARY ,
                rateType : childLife.rates?.compositeRates ? 'composite' : 'ageBands',
                guaranteedIssue: childLife.options?.planOption.find(({planOptionID}) =>  planOptionID === "GuaranteedIssue") ? childLife.options.planOption.find(({planOptionID}) => planOptionID === "GuaranteedIssue").planOptionValueID : '' ,
                benefitAmount: childLife.options?.planOption.find(({planOptionID}) => planOptionID === "BenefitAmount") ? childLife.options.planOption.find(({planOptionID}) => planOptionID === "BenefitAmount").planOptionValueID : '' ,
                lifeBenefits: { portability: { benefit: '' } },
                code : childLife.clientDefinedData?.data.find((cdd) =>  cdd.key === "SULplanCode") ? childLife.clientDefinedData?.data.find((cdd) =>  cdd.key === "SULplanCode")?.value : '' ,
                selected : childLife.planXREF ? true : false,
                primePlanCode: childLife.clientDefinedData?.data.find((cdd) =>  cdd.key === "SULplanCode") ? childLife.clientDefinedData?.data.find((cdd) =>  cdd.key === "SULplanCode")?.value : '' ,
                piaPlanCode:childLife.planXREF ? childLife.planXREF :""
            },

            SLSpADDPlan: {
                code : spouseADDLife.clientDefinedData?.data.find((cdd) =>  cdd.key === "SULADDplanCode") ? spouseADDLife.clientDefinedData?.data.find((cdd) =>  cdd.key === "SULADDplanCode")?.value : '' ,
                minimumParticipationPercentage: spouseADDLife.options?.planOption.find(({planOptionID}) => planOptionID === "RequiredParticipation") ? spouseADDLife.options.planOption.find(({planOptionID}) => planOptionID === "RequiredParticipation").planOptionValueID : '' ,
                planMax: spouseADDLife.options?.planOption.find(({planOptionID}) => planOptionID === "BenefitMaximum") ? spouseADDLife.options.planOption.find(({planOptionID}) => planOptionID === "BenefitMaximum").planOptionValueID : '' ,
                portability: spouseADDLife.options?.planOption.find(({planOptionID}) => planOptionID === "portability") ? spouseADDLife.options.planOption.find(({planOptionID}) => planOptionID === "portability").planOptionValueID : '' ,
                rateType : spouseADDLife.rates?.compositeRates ? 'composite' : 'ageBands',
                guaranteedIssue: spouseADDLife.options?.planOption.find(({planOptionID}) =>  planOptionID === "GuaranteedIssue") ? spouseADDLife.options.planOption.find(({planOptionID}) => planOptionID === "GuaranteedIssue").planOptionValueID : '' ,
                benefitAmount: spouseADDLife.options?.planOption.find(({planOptionID}) => planOptionID === "BenefitAmount") ? spouseADDLife.options.planOption.find(({planOptionID}) =>  planOptionID === "BenefitAmount").planOptionValueID : '' ,
                lifeBenefits: { portability: { benefit: '' } },
                piaPlanCode: spouseADDLife.planXREF ? spouseADDLife.planXREF : ""
            },
            SLSpPlan: {
                conversion : SEE_BENEFIT_SUMMARY,
                fundingType : SEE_BENEFIT_SUMMARY,
                rateGuarantee : spouseLife.clientDefinedData?.data.find((cdd) =>  cdd.key === "RateGuaranteePeriod") ? mapRate(spouseLife.clientDefinedData?.data.find((cdd) =>  cdd.key === "RateGuaranteePeriod")?.value) : '' ,
                productType : "SLS",
                code : spouseLife.clientDefinedData?.data.find((cdd) =>  cdd.key === "SULplanCode") ? spouseLife.clientDefinedData?.data.find((cdd) =>  cdd.key === "SULplanCode")?.value : '' ,
                minimumParticipationPercentage: spouseLife.options?.planOption.find(({planOptionID}) => planOptionID === "RequiredParticipation") ? spouseLife.options.planOption.find(({planOptionID}) => planOptionID === "RequiredParticipation").planOptionValueID : '' ,
                planMax: spouseLife.options?.planOption.find(({planOptionID}) => planOptionID === "BenefitMaximum") ? spouseLife.options.planOption.find(({planOptionID}) => planOptionID === "BenefitMaximum").planOptionValueID : '' ,
                portability: spouseLife.options?.planOption.find(({planOptionID}) => planOptionID === "portability") ? spouseLife.options.planOption.find(({planOptionID}) => planOptionID === "portability").planOptionValueID : SEE_BENEFIT_SUMMARY ,

                rateType : spouseLife.rates?.compositeRates ? 'composite' : 'ageBands',
                guaranteedIssue: spouseLife.options?.planOption.find(({planOptionID}) =>  planOptionID === "GuaranteedIssue") ? spouseLife.options.planOption.find(({planOptionID}) =>  planOptionID === "GuaranteedIssue").planOptionValueID : '' ,
                benefitAmount: spouseLife.options?.planOption.find(({planOptionID}) => planOptionID === "BenefitAmount") ? spouseLife.options.planOption.find(({planOptionID}) =>  planOptionID === "BenefitAmount").planOptionValueID : '' ,
                lifeBenefits: { portability: { benefit: '' } },
                selected : spouseLife.planXREF ? true : false,
                primePlanCode: spouseLife.clientDefinedData?.data.find((cdd) =>  cdd.key === "SULplanCode") ? spouseLife.clientDefinedData?.data.find((cdd) =>  cdd.key === "SULplanCode")?.value : '' ,
                piaPlanCode: spouseLife.planXREF ? spouseLife.planXREF :""
            },

            SLEEPlanCode: existingPlan.clientDefinedData?.data.find((cdd) =>  cdd.key === "SULplanCode") ? existingPlan.clientDefinedData?.data.find((cdd) =>  cdd.key === "SULplanCode")?.value : '' ,
            SLEEADDPlanCode: employeeADDLife.clientDefinedData?.data.find((cdd) =>  cdd.key === "SULADDplanCode") ? employeeADDLife.clientDefinedData?.data.find((cdd) =>  cdd.key === "SULADDplanCode")?.value : '' ,
            SLSpPlanCode: spouseLife.clientDefinedData?.data.find((cdd) =>  cdd.key === "SULplanCode") ? spouseLife.clientDefinedData?.data.find((cdd) =>  cdd.key === "SULplanCode")?.value : '' ,
            SLSpADDPlanCode: spouseADDLife.clientDefinedData?.data.find((cdd) =>  cdd.key === "SULADDplanCode") ? spouseADDLife.clientDefinedData?.data.find((cdd) =>  cdd.key === "SULADDplanCode")?.value : '' ,
            SLChPlanCode: childLife.clientDefinedData?.data.find((cdd) =>  cdd.key === "SULplanCode") ? childLife.clientDefinedData?.data.find((cdd) =>  cdd.key === "SULplanCode")?.value : '' ,
            SLChADDPlanCode: childADDLife.clientDefinedData?.data.find((cdd) =>  cdd.key === "SULADDplanCode") ? childADDLife.clientDefinedData?.data.find((cdd) =>  cdd.key === "SULADDplanCode")?.value : '' ,

            finalRates: {
                byCensus: {
                    slEEADnDRate: employeeADDLife.rates?.compositeRates.tier1,
                    slSPADnDRate: spouseADDLife.rates?.compositeRates.tier1,
                    slCHADnDRate: childADDLife.rates?.compositeRates.tier1,
                    slCHRate: childLife.rates?.compositeRates.tier1,

                    totalSPMonthlyPremium: spouseLife.planRate,
                    totalCHMonthlyPremium: childLife.planRate,
                    totalEEMonthlyPremium : existingPlan.planRate,
                    slSPAgeBands: spouseLife.rates?.ageRates?.ageBand,
                    slEEAgeBands: existingPlan.rates?.ageRates?.ageBand,

                    totalSlEEPremium : existingPlan.planRate,
                    totalSlEEADnDPremium : employeeADDLife.planRate,
                    totalSlSPPremium : spouseLife.planRate,
                    totalSlSPADnDPremium : spouseADDLife.planRate,
                    totalSlCHPremium : childLife.planRate,
                    totalSlCHADnDPremium : childADDLife.planRate,

                    employeePremium : {}
                },

                code: existingPlan.planID,
                combinedCodes: existingPlan.planID + "--" + SEE_BENEFIT_SUMMARY,
                employeeRate: [],
                totalAnnualPremium: parseFloat(existingPlan.planRate) * 12,
                totalMonthlyPremium: parseFloat(existingPlan.planRate),
            },

            benefitDetails:{
                benefitType: existingPlan.clientDefinedData.data.find(({key}) =>  key === "PlanCategory") ? existingPlan.clientDefinedData.data.find(({key}) => key === "PlanCategory").value : '' 
            },
            SLsp:existingPlan.rates?.ageRates?.ageBand,
            planType: "Supplement Life",
            renewalPlanType: renewalPlanType,
            code: existingPlan.planID,
            primePlanCode: existingPlan.planID,
            productType: existingPlan.productLineType.replace(/_/g, ' '),
            planXREF: existingPlan.planXREF,
            suppLifeBenefitType: '', //NOT FOUND
            ADandDBenefitType: SEE_BENEFIT_SUMMARY,
            suppLifeExpectancy: SEE_BENEFIT_SUMMARY,
            EliminationPeriod: SEE_BENEFIT_SUMMARY,
            suicideLimitBenefit: SEE_BENEFIT_SUMMARY,
            coverageTermination: SEE_BENEFIT_SUMMARY,
            eliminationPeriod: SEE_BENEFIT_SUMMARY,
            lifeBenefits: {
                waiverofPremium: {
                    eligibility: SEE_BENEFIT_SUMMARY,
                    duration: SEE_BENEFIT_SUMMARY
                },
                portability:{
                    benefit:''
                }
            },
            rateType: 'Unisex Unitobacco Age-banded per $1,000 of coverage',
            rateGuarantee: isUhcRenewal ? 24 : SEE_BENEFIT_SUMMARY,
            guaranteedIssue: existingPlan.options.planOption.find(({planOptionID}) => planOptionID === "GuaranteedIssue") ? existingPlan.options.planOption.find(({planOptionID}) =>  planOptionID === "GuaranteedIssue").planOptionValueID : '' ,
            adAndDBenefit: {
                lossperiod: SEE_BENEFIT_SUMMARY,
                hemiplegiaPercent: SEE_BENEFIT_SUMMARY,
                paraplegiaPercent: SEE_BENEFIT_SUMMARY,
                lossPeriod: SEE_BENEFIT_SUMMARY,
                seatBeltBenefit: SEE_BENEFIT_SUMMARY,
                seatBeltAirBagBenefit: SEE_BENEFIT_SUMMARY,
                lossofLifePercent: SEE_BENEFIT_SUMMARY,
                quadriplegiaPercent: SEE_BENEFIT_SUMMARY,
                onehandPercent: SEE_BENEFIT_SUMMARY,
                oneFootPercent: SEE_BENEFIT_SUMMARY,
                handsAndFeetPercent: SEE_BENEFIT_SUMMARY,
                oneeyePercent: SEE_BENEFIT_SUMMARY,
                sightBothEyesPercent: SEE_BENEFIT_SUMMARY,
                oneHandOneEyePercent: SEE_BENEFIT_SUMMARY,
                oneFootOneEyePercent: SEE_BENEFIT_SUMMARY,
                thumbIndexFingerPercent: SEE_BENEFIT_SUMMARY,
                speechPercent: SEE_BENEFIT_SUMMARY,
                hearingPercent: SEE_BENEFIT_SUMMARY,
                coverageTermination: SEE_BENEFIT_SUMMARY,
            },//NOT FOUND

            ADandDCode: SEE_BENEFIT_SUMMARY,// not found
            quote: {
                totalMonthlyPremium: parseFloat(existingPlan.planRate),
                totalMonthlyPremiumDep: parseFloat(existingPlan.planRate) + parseFloat(spouseLife.planRate) + parseFloat(childLife.planRate),
                //    aDndTotalPremium:SEE_BENEFIT_SUMMARY,//NOT FOUND
                ADDTotVolAmount: '0',//NOT FOUND
                aDndTotalPremium: '0'//NOT FOUND
                //monthlyERContribution:parseFloat(existingPlan.planRate),
            },
            byCensus: { finalMonthlyPremium: parseFloat(existingPlan.planRate) },
            monthlyPremium: {
                employee: existingPlan.productLineType === "SUPP_LIFE_EE" ? parseFloat(existingPlanRates?.ageBand) : '',
                employeeADD: existingPlan.productLineType === "SUPP_ADD_EE" ? parseFloat(existingPlanRates?.tier1) : '',
                spouse: existingPlan.productLineType === "SUPP_LIFE_SPOUSE" ? parseFloat(existingPlanRates?.ageBand) : '',
                suppSpouseADD: existingPlan.productLineType === "SUPP_ADD_SPOUSE" ? parseFloat(existingPlanRates?.tier1) : '',
                child: existingPlan.productLineType === "SUPP_LIFE_CHILD" ? parseFloat(existingPlanRates?.ageBand) : '',
                suppChildADD: existingPlan.productLineType === "SUPP_ADD_CHILD" ? parseFloat(existingPlanRates?.tier1) : '',
            },
            finalMonthlyPremium: parseFloat(existingPlan.planRate) + parseFloat(spouseLife.planRate) + parseFloat(childLife.planRate),
            selected: false,
            favorited: false,
            
            minimumParticipationPercentage: existingPlan.options?.planOption.find(({planOptionID}) => planOptionID === "RequiredParticipation") ? existingPlan.options.planOption.find(({planOptionID}) => planOptionID === "RequiredParticipation").planOptionValueID : '' ,
            planMax: existingPlan.options?.planOption.find(({planOptionID}) => planOptionID === "BenefitMaximum") ? existingPlan.options.planOption.find(({planOptionID}) => planOptionID === "BenefitMaximum").planOptionValueID : '' ,
            ageBenefitReductionSchedule: SEE_BENEFIT_SUMMARY,//not found
            acceleratedDeathBenefit: SEE_BENEFIT_SUMMARY,//not found
            conversion: SEE_BENEFIT_SUMMARY,//not found
            portability: existingPlan.options?.planOption.find(({planOptionID}) => planOptionID === "portability") ? existingPlan.options.planOption.find(({planOptionID}) => planOptionID === "portability").planOptionValueID : '' ,
            waiverOfPremium: SEE_BENEFIT_SUMMARY,//not found,

            pairedAdnDDetails: {
                benefitAmount: SEE_BENEFIT_SUMMARY,//NOT FOUND
                adAndDBenefit: {
                    lossPeriod: SEE_BENEFIT_SUMMARY,
                    ageBenefitReductionSchedule: SEE_BENEFIT_SUMMARY,
                    seatBeltAirBagBenefit: SEE_BENEFIT_SUMMARY,
                    lossofLifePercent: SEE_BENEFIT_SUMMARY,
                    quadriplegiaPercent: SEE_BENEFIT_SUMMARY,
                    paraplegiaPercent: SEE_BENEFIT_SUMMARY,
                    speechPercent: SEE_BENEFIT_SUMMARY,
                    hearingPercent: SEE_BENEFIT_SUMMARY,
                },
                code: SEE_BENEFIT_SUMMARY,
                productType: SEE_BENEFIT_SUMMARY,
                minimumParticipationPercentage: SEE_BENEFIT_SUMMARY,//not found
                guaranteedIssue: SEE_BENEFIT_SUMMARY,//not found,
                planMax: SEE_BENEFIT_SUMMARY,//not found
                ageBenefitReductionSchedule: SEE_BENEFIT_SUMMARY,//not found
                acceleratedDeathBenefit: SEE_BENEFIT_SUMMARY,//not found
                conversion: SEE_BENEFIT_SUMMARY,//not found
                portability: SEE_BENEFIT_SUMMARY,//not found
                waiverOfPremium: SEE_BENEFIT_SUMMARY,//not found,
            }
        }

        obj.tierStructureId = existingPlan.tierStructureID
        obj.member = []

        empCensus.map(employee =>
            (checkBelonging(renewalPlanType,employee,existingPlan,false)?
            obj.member.push({
                employeeId: employee.xref,
                ssn: employee.ssn,
                zipCode: employee.homeZip ? employee.homeZip : '',
                contactInfo: {
                    streetAddress: employee.clientDefinedData.data.find(({ key }) => key === 'EmployeeAddr1') ? employee.clientDefinedData.data.find(({ key }) => key === 'EmployeeAddr1').value : '',
                    city: employee.clientDefinedData.data.find(({ key }) => key === 'EmployeeCity') ? employee.clientDefinedData.data.find(({ key }) => key === 'EmployeeCity').value : '',
                    state: employee.clientDefinedData.data.find(({ key }) => key === 'State') ? employee.clientDefinedData.data.find(({ key }) => key === 'State').value : '',
                    zipCode: employee.clientDefinedData.data.find(({ key }) => key === 'Zip') ? employee.clientDefinedData.data.find(({ key }) => key === 'Zip').value : '',
                },
                firstName: employee.first,
                lastName: employee.last,
                outOfArea: 'No',
                gender: employee.genderType === 'M'?'Male':'Female',
                dob: formatDate(employee.dateOfBirth.month) + '/' + formatDate(employee.dateOfBirth.day) + '/' + employee.dateOfBirth.year,
                age: getAge(formatDate(employee.dateOfBirth.month) + '/' + formatDate(employee.dateOfBirth.day) + '/' + employee.dateOfBirth.year, effectiveDate),
                status: employee.status,
                coverages: {
                    suppLife: 
                        obj.renewalPlanType === "current" ? employee.electedPlans.plan.filter(plan => plan.planID === existingPlan.planID)[0]?.coverageType :
                            obj.renewalPlanType === "renewing" ? employee.renewingPlans.plan.filter(plan => plan.planID === existingPlan.planID)[0]?.coverageType :
                                employee.alternatePlans.plan.filter(plan => plan.planId === existingPlan.planId)[0]?.coverageType
                },
                ooaPremium: 0,
                total:
                    obj.renewalPlanType === "current" ? employee.electedPlans.plan.filter(plan => plan.planID === existingPlan.planID)[0]?.rate :
                        obj.renewalPlanType === "renewing" ? employee.renewingPlans.plan.filter(plan => plan.planID === existingPlan.planID)[0]?.rate  :
                            employee.alternatePlans.plan.filter(plan => plan.planID === existingPlan.planID)[0]?.rate
                    ,
                dependents: employee?.dependents?.dependent.map(dependentOne =>
                ({
                    firstName: dependentOne.first,
                    lastName: dependentOne.last,
                    relationship: dependentOne.depType === 'C'?'Child':'S'?'Spouse':"",
                    age: getAge(formatDate(dependentOne.dateOfBirth.month) + '/' + formatDate(dependentOne.dateOfBirth.day) + '/' + dependentOne.dateOfBirth.year, effectiveDate),
                    dob: formatDate(dependentOne.dateOfBirth.month) + '/' + formatDate(dependentOne.dateOfBirth.day) + '/' + dependentOne.dateOfBirth.year,
                    gender: employee.genderType === 'M'? 'Male' : 'Female',
                    streetAddress: employee.clientDefinedData.data.find(({ key }) => key === 'EmployeeAddr1') ? employee.clientDefinedData.data.find(({ key }) => key === 'EmployeeAddr1').value : '',
                    streetAddressln2: '',
                    city: employee.clientDefinedData.data.find(({ key }) => key === 'EmployeeCity') ? employee.clientDefinedData.data.find(({ key }) => key === 'EmployeeCity').value : '',
                    state: employee.clientDefinedData.data.find(({ key }) => key === 'State') ? employee.clientDefinedData.data.find(({ key }) => key === 'State').value : '',
                    zipCode: employee.clientDefinedData.data.find(({ key }) => key === 'Zip') ? employee.clientDefinedData.data.find(({ key }) => key === 'Zip').value : '',
                    ssn: dependentOne.ssn
                })) || []
            })
            :""))

            obj["fundingType"] =  'See Benefit Summary'
            obj["pairedAdnDDetails"]["fundingType"] = 'See Benefit Summary'
            obj["benefitAmount"] =  'See Benefit Summary'
            obj["pairedAdnDDetails"]["benefitAmount"] =  'See Benefit Summary'
            obj["ADandDBenefitAmount"] =  'See Benefit Summary'
            obj["finalRates"]["ADDTotVolAmount"] =  'See Benefit Summary'
            obj["quote"]["ADDTotVolAmount"] =  'See Benefit Summary'
            obj["enrolledCount"] =  'See Benefit Summary'
            obj["rateGuarantee"] =  'See Benefit Summary'
            obj["code"] =  'See Benefit Summary'
            obj["codeDisplay"] =  'See Benefit Summary'
            obj["conversion"] =  'See Benefit Summary'
            obj["portability"] =  'See Benefit Summary'

        for (const clientDefinedData of existingPlanClientDefinedData) {

            if(clientDefinedData.key === "CostDifferentialPercentage" && renewalPlanType === 'renewing') {
                obj["renewalChangePercent"] =  clientDefinedData.value ;
            }

            // if (clientDefinedData.key === 'PlanCategory') {
            //     obj.productType = clientDefinedData.value;
            // }
            if (clientDefinedData.key === 'EmployeeEnrollCount') {
                if (renewalPlanType !== 'alternate')
                    obj.enrolledCount = clientDefinedData.value;
                else
                    obj.enrolledCount = "0";
            }
    
            if (isUhcRenewal) {
                if (clientDefinedData.key === 'PlanCategory') {

                }
                if (clientDefinedData.key === 'RateGuaranteePeriod') {
                    obj.rateGuarantee = mapRate(clientDefinedData.value)
                }
            }

            //SUL - Emp/Child/Spouse
            if (clientDefinedData.key === 'SULplanCode') {
                console.log("In Rate Guarantee")
                obj["code"] = clientDefinedData.value  
                obj["codeDisplay"] = clientDefinedData.value          
            }

            //SUL - ADD Emp/ADD Child/ADD Spouse
            if (clientDefinedData.key === 'SULADDplanCode') {
                console.log("In Rate Guarantee")
                obj["code"] = clientDefinedData.value  
                obj["codeDisplay"] = clientDefinedData.value          
            }
        }
        
        
        for (const planOption of existingPlanOptions) {

            // if (planOption.planOptionID === 'LifeProductDescription') {
            //     obj.fundingType = planOption.planOptionValueID;
            //     obj.pairedAdnDDetails.fundingType = planOption.planOptionValueID
            // }
            //Benefits
            if (planOption.planOptionID === 'PlanFundingDescription') {
                obj.fundingType = planOption.planOptionValueID;
                obj.pairedAdnDDetails.fundingType = planOption.planOptionValueID;
            }

            if (planOption.planOptionID === 'BenefitAmount') {
                obj.benefitAmount = planOption.planOptionValueID;
                obj.pairedAdnDDetails.benefitAmount = planOption.planOptionValueID
                obj.ADandDBenefitAmount = planOption.planOptionValueID;
                obj.finalRates.ADDTotVolAmount = planOption.planOptionValueID;
                obj.quote.ADDTotVolAmount = planOption.planOptionValueID
            }
            if (planOption.planOptionID === 'BenefitMaximum') {
            }
            if (planOption.planOptionID === 'GuaranteedIssue') {
               
            }
        }

        let byCensusRates = []
        if (empCensus) {
            if (renewalPlanType !== 'alternate') {
                empCensus.map(emp => {
                    if (renewalPlanType === 'current') {
                        let empId = emp.xref
                        let planRate = emp.electedPlans.plan.find(plan => plan.planID === obj.planXREF && plan.coverageType !== 'WAV')
                        if (planRate) {
                            byCensusRates.push({
                                employeeId: empId,
                                Total: planRate.rate
                            })
                        }
                    }
                    else if (renewalPlanType === 'renewing') {
                        let empId = emp.xref
                        let planRate = emp.renewingPlans.plan.find(plan => plan.planID === obj.planXREF && plan.coverageType !== 'WAV')
                        if (planRate) {
                            byCensusRates.push({
                                employeeId: empId,
                                Total: planRate.rate
                            })
                        }
                    }
                })
            }
            else {
                empCensus.map(emp => {
                    let empId = emp.xref
                    let planRate = emp.alternatePlans.plan.find(plan => plan.planID === obj.planXREF && plan.coverageType !== 'WAV')
                    if (planRate) {
                        byCensusRates.push({
                            employeeId: empId,
                            Total: planRate.rate
                        })
                    }
                })
            }
        }
        obj.byCensus.employeeRates = byCensusRates

        if(isUhcRenewals()) {
            // const suppLifePlanDetails = reviewData && reviewData.renewalData && 
            //     reviewData.renewalData.suppLifePlanDetails ? reviewData.renewalData.suppLifePlanDetails : [];

            obj.SLSpPlan.fundingType = obj.fundingType
            obj.SLChPlan.fundingType = obj.fundingType

            // const planDetails = getLifePlanDetails(suppLifePlanDetails, stateCode, groupSize, effectiveDate, 'suppLife', obj.primePlanCode);
            // if(planDetails.primePlanCode) {
            //     //obj.code = planDetails.planCode;
            //     obj.name =  planDetails.planName
            //     obj.isMultiStatePlan = planDetails.isMultiStatePlan
            //     // obj.ADandDCode = planDetails.ADandDCode
            //     // obj.pairedAdnDDetails.code = planDetails.ADandDCode
            //     //obj.fundingType = planDetails.fundingType
            //     obj.productType = planDetails.productType
            // }
        }

        if (renewalPlanType === 'alternate') {
            alternate.push(obj);
        } else {
            ibrPlans.push(obj);
        }

    }

    async function ibrToLTD(existingPlan, renewalPlanType, reviewData = null, empCensus, xrefData , subGroups) {
        const existingPlanOptions = existingPlan.options?.planOption;
        const existingPlanRates = existingPlan.rates?.compositeRates || existingPlan.rates?.ageRates;
        const existingPlanClientDefinedData = existingPlan.clientDefinedData.data;

        let monthlyPremium = existingPlan?.rates?.compositeRates ? {
            employee: parseFloat(existingPlanRates?.tier1),
        } : null;

        let rxComplete = ""
        let planObj = {
            planType: 'LTD',
            platform: 'PRIME',
            selectedClass : subGroups && subGroups.length > 0 && subGroups.find((group) => group.xref === 'ALLL02') ? subGroups.find((group) => group.planOfferingXref === xrefData)?.classDescription || '' : "",
            code: existingPlan.planXREF,
            planXREF: existingPlan.planID,
            piaPlanCode: existingPlan.planXREF,
            renewalPlanType: renewalPlanType === 'renewing' ? 'renewing' : renewalPlanType === 'current' ? 'current' : 'alternate',
            renewalPlan: renewalPlanType === 'renewing',
            member:[],
            ageBands: [],
            selected: false,
            finalRates: {},
            byCensus: { finalMonthlyPremium: parseFloat(existingPlan.planRate) },
        }

        planObj["enrolledCount"] = 'See Benefit Summary'; 
        planObj['recurrent'] = 'See Benefit Summary'; 
        planObj['earningTest'] = 'See Benefit Summary';  
        planObj['rehab'] = 'See Benefit Summary'; 
        planObj['transplant'] = 'See Benefit Summary'; 
        planObj['workIncentive'] = 'See Benefit Summary';
        planObj['mentalIllnessLimit'] = 'See Benefit Summary';
        planObj['ownOccDefination'] = 'See Benefit Summary'; 
        planObj['rateGuarantee'] = '0'; 
        planObj['customPlanName']  = 'See Benefit Summary'; 
        planObj['contributionType'] = 'See Benefit Summary'; 
        planObj['lumpSum'] = 'See Benefit Summary'; 
        planObj['defOfDis'] = 'See Benefit Summary'; 
        planObj['weeklyBenefitAmount'] = 'See Benefit Summary'; 
        planObj['benifitDuration'] = 'See Benefit Summary'; 
        planObj['eliminationPeriod'] = 'See Benefit Summary';
        planObj['preEx'] = 'See Benefit Summary'; 
        planObj['ssOffset'] = 'See Benefit Summary'; 
        planObj['finalRates']['maximumWeeklyBenefit'] = 'See Benefit Summary'; 

        planObj.totalEligibleEmployees = empCensus.length

        for (const clientDefinedData of existingPlanClientDefinedData) {
            if(clientDefinedData.key === "PlanCategory") {
                planObj.productType = clientDefinedData.value;
            }
            if (clientDefinedData.key === 'EmployeeEnrollCount') {
                planObj["enrolledCount"] = clientDefinedData.value;
            }
            if (clientDefinedData.key === 'DependentEnrollCount') {
                
            }
            if (clientDefinedData.key === 'StepwiseQuoteID') {
                
            }
            if (clientDefinedData.key === 'DiscountRatio') {
                
            }
            if (clientDefinedData.key === 'CostDifferentialPercentage' && renewalPlanType === 'renewing') {
                planObj["renewalChangePercent"] =  clientDefinedData.value;
            }
            if (clientDefinedData.key === 'RateGuaranteePeriod') {
                planObj["rateGuarantee"] = mapRate(clientDefinedData.value)
            }
            if (clientDefinedData.key === 'EligibleEmployeeCount') {
                
            }
            if (clientDefinedData.key === 'MinimumWorkHours') {
                
            }
            if (clientDefinedData.key === 'TotalMonthlyCoveredPayroll') {
                planObj['finalRates']['totalMCP'] = clientDefinedData.value; 
            }
            if(clientDefinedData.key === 'PolicyNumber') {
                planObj["primePolicyNumber"] = clientDefinedData.value ? clientDefinedData.value : "";
            }
            if (clientDefinedData.key === 'LTDplanCode') {
                planObj["code"] = clientDefinedData.value  
                planObj["codeDisplay"] = clientDefinedData.value          
            }
        }
        for (const planOption of existingPlanOptions) {

            if(planOption.planOptionID === 'BenefitAmount') {
               planObj['weeklyBenefitAmount'] = planOption.planOptionValueID;   
            }
            if(planOption.planOptionID === 'Duration') {
                planObj['benifitDuration'] = planOption.planOptionValueID;   
            }
            if(planOption.planOptionID === 'EliminationPeriodAccident') {
                planObj['eliminationPeriod'] = planOption.planOptionValueID;   
            }
            if(planOption.planOptionID === 'DefinitionOfDisability') {
                planObj['defOfDis'] = planOption.planOptionValueID;   
            }
            if(planOption.planOptionID === 'PreExistingCondition') {
                planObj['preEx'] = planOption.planOptionValueID;   
            }
            if(planOption.planOptionID === 'RecurrentDisability') {
                planObj['recurrent'] = planOption.planOptionValueID;   
            }
            if(planOption.planOptionID === 'ssintegration') {
                planObj['ssOffset'] = planOption.planOptionValueID;   
            }
            if(planOption.planOptionID === 'survivor') {
                planObj['lumpSum'] = planOption.planOptionValueID;   
            }
            if(planOption.planOptionID === 'MonthlyMaximum') {
                planObj['finalRates']['maximumWeeklyBenefit'] = planOption.planOptionValueID; 
            }
            
            if (planOption.planOptionID === 'RequiredParticipation') {
                
            }
            if (planOption.planOptionID === 'EarningsTest') {//added for MMR not in IBR
                planObj['earningTest'] = planOption.planOptionValueID;  
            }
            if(planOption.planOptionID === 'RehabilitationServices'){//added for MMR not in IBR
                planObj['rehab'] = planOption.planOptionValueID;
            }
            if(planOption.planOptionID === 'TransplantBenefit'){//added for MMR not in IBR
                planObj['transplant'] = planOption.planOptionValueID;
            }
            if(planOption.planOptionID === 'WorkIncentiveBenefit'){//added for MMR not in IBR not populaing for NB
                planObj['workIncentive'] = planOption.planOptionValueID;
            }
            if (planOption.planOptionID === 'PlanFundingDescription') {
                planObj['customPlanName'] = planOption.planOptionValueID;
                planObj['contributionType'] = planOption.planOptionValueID;
            }
        }
        
       // planObj.rx["specialty"] = false
        planObj['earningTest'] = planObj['earningTest'] ? planObj['earningTest'] : '';
        planObj['rehab'] = planObj['rehab'] ? planObj['rehab'] : '';
        planObj['transplant'] = planObj['transplant'] ? planObj['transplant'] : '';
        planObj['workIncentive'] = planObj['workIncentive'] ? planObj['workIncentive'] : '';
        //planObj['rateType'] = '';
        //planObj.codeDisplay = existingPlan.planXREF

        const getBillType = (tierStructureId) => {
            switch (tierStructureId) {
                case 'A':
                case 'B':
                case 'C': 
                case '3': 
                case '4':
                case '6':
                case '7':
                case '8':
                case '9':
                return 'Tier';
                default:
                return 'Age';
            }
        };

        planObj['tierStructureId'] = existingPlan.tierStructureID;

        let ratingType = getBillType(existingPlan.tierStructureID)
        // planObj['rateType'] = existingPlan.rates?.ageRates ? 'Age-banded per $100 of monthly covered payroll' : 'Composite per $100 of monthly covered payroll';
        if (existingPlan.rates.ageRates) {
            planObj['finalRates']["ageBands"] = formatAgeBand(existingPlanRates.ageBand)
            planObj["ageBands"] = formatAgeBand(existingPlanRates.ageBand)
            planObj["isAgeBanded"] = true
            planObj['defaultRatingType'] = 'ageBands'
            planObj['ibrRatingType'] = 'ageBands'
            planObj['rateType'] = 'Age-banded per $100 of monthly covered payroll'
        } else {
            planObj['finalRates']["compositeRates"] = formatCompositeRates(existingPlanRates)
            planObj['finalRates']['employee'] = monthlyPremium?.employee;
            planObj['finalRates']['monthlyPremium'] = monthlyPremium;
            planObj["compositeRates"] = formatCompositeRates(existingPlanRates)
            planObj['compositeTypeName'] = 'Composite Rates'
            planObj["isComposite"] = true
            planObj['defaultRatingType'] = 'composite'
            planObj['ibrRatingType'] = 'composite'
            planObj['finalRates']['timesRatesPer100ofMCP'] = existingPlan.rates?.compositeRates?.tier1 || '';
            planObj['rateType'] = 'Composite per $100 of monthly covered payroll'
        }
        
        empCensus.map(employee =>
            (
                checkBelonging(renewalPlanType,employee,existingPlan,false) ?
                    planObj.member.push({
                        employeeId: employee.xref,
                        ssn: employee.ssn,
                        zipCode: employee.homeZip ? employee.homeZip : '',
                        contactInfo: {
                            streetAddress: employee.clientDefinedData.data.find(({ key }) => key === 'EmployeeAddr1') ? employee.clientDefinedData.data.find(({ key }) => key === 'EmployeeAddr1').value : '',
                            city: employee.clientDefinedData.data.find(({ key }) => key === 'EmployeeCity') ? employee.clientDefinedData.data.find(({ key }) => key === 'EmployeeCity').value : '',
                            state: employee.clientDefinedData.data.find(({ key }) => key === 'State') ? employee.clientDefinedData.data.find(({ key }) => key === 'State').value : '',
                            zipCode: employee.clientDefinedData.data.find(({ key }) => key === 'Zip') ? employee.clientDefinedData.data.find(({ key }) => key === 'Zip').value : '',
                        },
                        firstName: employee.first,
                        lastName: employee.last,
                        outOfArea: 'No',
                        gender: employee.genderType === 'M'?'Male':'Female',
                        dob: formatDate(employee.dateOfBirth.month) + '/' + formatDate(employee.dateOfBirth.day) + '/' + employee.dateOfBirth.year,
                        age: getAge(formatDate(employee.dateOfBirth.month) + '/' + formatDate(employee.dateOfBirth.day) + '/' + employee.dateOfBirth.year, effectiveDate),
                        status: employee.status,
                        coverages: {
                            LTD: 
                                planObj.renewalPlanType === "current" ? employee.electedPlans.plan.filter(plan => plan.planID === existingPlan.planID)[0]?.coverageType :
                                    planObj.renewalPlanType === "renewing" ? employee.renewingPlans.plan.filter(plan => plan.planID === existingPlan.planID)[0]?.coverageType :
                                        employee.alternatePlans.plan.filter(plan => plan.planId === existingPlan.planId)[0]?.coverageType
                        },
                        ooaPremium: 0,
                        total:
                            planObj.renewalPlanType === "current" ? employee.electedPlans.plan.filter(plan => plan.planID === existingPlan.planID)[0]?.rate :
                                planObj.renewalPlanType === "renewing" ? employee.renewingPlans.plan.filter(plan => plan.planID === existingPlan.planID)[0]?.rate  :
                                    employee.alternatePlans.plan.filter(plan => plan.planID === existingPlan.planID)[0]?.rate 
                            ,
                        dependents: employee?.dependents?.dependent.map(dependentOne =>
                        ({
                            firstName: dependentOne.first,
                            lastName: dependentOne.last,
                            relationship: dependentOne.depType === 'C'?'Child':'S'?'Spouse':"",
                            age: getAge(formatDate(dependentOne.dateOfBirth.month) + '/' + formatDate(dependentOne.dateOfBirth.day) + '/' + dependentOne.dateOfBirth.year, effectiveDate),
                            dob: formatDate(dependentOne.dateOfBirth.month) + '/' + formatDate(dependentOne.dateOfBirth.day) + '/' + dependentOne.dateOfBirth.year,
                            gender: employee.genderType === 'M'? 'Male' : 'Female',
                            streetAddress: employee.clientDefinedData.data.find(({ key }) => key === 'EmployeeAddr1') ? employee.clientDefinedData.data.find(({ key }) => key === 'EmployeeAddr1').value : '',
                            streetAddressln2: '',
                            city: employee.clientDefinedData.data.find(({ key }) => key === 'EmployeeCity') ? employee.clientDefinedData.data.find(({ key }) => key === 'EmployeeCity').value : '',
                            state: employee.clientDefinedData.data.find(({ key }) => key === 'State') ? employee.clientDefinedData.data.find(({ key }) => key === 'State').value : '',
                            zipCode: employee.clientDefinedData.data.find(({ key }) => key === 'Zip') ? employee.clientDefinedData.data.find(({ key }) => key === 'Zip').value : '',
                            ssn: dependentOne.ssn
                        })) || []
                    })
                : ""
            )
        )

        planObj["quote"] = existingPlan.planRate
        planObj["finalMonthlyPremium"] = existingPlan.planRate
        planObj["totalMonthlyPremium"] = existingPlan.planRate
        planObj["quoteFinal"] = existingPlan.planRate
        planObj['finalRates']["totalMonthlyPremium"] = existingPlan.planRate
  
        let byCensusRates = []
        if (empCensus) {
            if (renewalPlanType !== 'alternate') {
                empCensus.map(emp => {
                    if (renewalPlanType === 'current') {
                        let empId = emp.xref
                        let planRate = emp.electedPlans.plan.find(plan => plan.planID === planObj.planXREF && plan.coverageType !== 'WAV')
                        if (planRate) {
                            byCensusRates.push({
                                planCode: planObj.code,
                                employeeId: empId,
                                Total: planRate.rate
                            })
                        }
                    }
                    else if (renewalPlanType === 'renewing') {
                        let empId = emp.xref
                        let planRate = emp.renewingPlans.plan.find(plan => plan.planID === planObj.planXREF && plan.coverageType !== 'WAV')
                        if (planRate) {
                            byCensusRates.push({
                                planCode: planObj.code,
                                employeeId: empId,
                                Total: planRate.rate
                            })
                        }
                    }
                })
            }
            else {
                empCensus.map(emp => {
                    let empId = emp.xref
                    let planRate = emp.alternatePlans && emp.alternatePlans.plan && emp.alternatePlans.plan.find(plan => plan.planID === planObj.planXREF && plan.coverageType !== 'WAV')
                    if (planRate) {
                        byCensusRates.push({
                            planCode: planObj.code,
                            employeeId: empId,
                            Total: planRate.rate
                        })
                    }
                })
            }
        }
        planObj.employeeRates = byCensusRates
        planObj.byCensus.employeeRates = byCensusRates


        if (renewalPlanType === 'alternate') {
            alternate.push(planObj);
            alternatePlanCodes.push(planObj.code);
        } else {
            ibrPlans.push(planObj);
        }     
    }

    async function ibrToShortTermDisability(existingPlan, renewalPlanType, reviewData, empCensus, xrefData , subGroups){
        try{
        const existingPlanOptions = existingPlan.options.planOption;
        const existingPlanRates = existingPlan.rates.compositeRates || existingPlan.rates?.ageRates;
        const existingPlanClientDefinedData = existingPlan.clientDefinedData.data;

        let monthlyPremium = existingPlan?.rates?.compositeRates ? {
            employee: parseFloat(existingPlanRates?.tier1)
        } : null;

        // let rxComplete = ""
        let planObj = {
            planType: "STD",
            platform : 'PRIME',
            selectedClass : subGroups && subGroups.length > 0 && subGroups.find((group) => group.xref === 'ALLL02') ? subGroups.find((group) => group.planOfferingXref === xrefData)?.classDescription || '' : "",
            code: existingPlan.planID,
            piaPlanCode: existingPlan.planXREF,
            renewalPlanType: renewalPlanType === 'renewing' ? 'renewing' : renewalPlanType === 'current' ? 'current' : 'alternate',    
            renewalPlan: renewalPlanType === 'renewing',
            member:[],
            ageBands: [],
            selected: false,                 
            primePlanCode: existingPlan.planID,
            productType: existingPlan.productLineType.replace(/_/g, ' '),
            planXREF: existingPlan.planXREF,     
            finalRates : {},
            byCensus: { finalMonthlyPremium: parseFloat(existingPlan.planRate) },         
        }
        planObj.totalEligibleEmployees = empCensus.length

        
        planObj['recurrent'] = 'See Benefit Summary'; 
        planObj['rehab'] = 'See Benefit Summary'
        planObj.lumpSum = {
            survivorBen : 'See Benefit Summary'
        }
        planObj['maternity'] = 'See Benefit Summary'
        planObj['coverageType'] = 'See Benefit Summary'
        planObj['benifitDuration'] = 'See Benefit Summary'
        planObj['fundingType'] = 'See Benefit Summary'
        planObj['contributionType'] = 'See Benefit Summary'
        planObj['preEx'] = 'See Benefit Summary'
        planObj['coverageType'] = 'See Benefit Summary'
        planObj["rateGuarantee"] = "0"
        planObj['weeklyBenefitAmount'] = 'See Benefit Summary'
        planObj['finalRates']['maximumWeeklyBenefit'] = 'See Benefit Summary'
        planObj['eliminationPeriod'] = ''
        planObj['eliminationPeriodSickness'] = ''

        //existingPlan Options
        for (const planOption of existingPlanOptions) {
            //benefits
            if (planOption.planOptionID === 'PlanFundingDescription') {
                planObj['fundingType'] = planOption.planOptionValueID;
                planObj['contributionType'] = planOption.planOptionValueID
            }

            if (planOption.planOptionID === 'BenefitAmount') {
                planObj['weeklyBenefitAmount'] = planOption.planOptionValueID;
            }

            if (planOption.planOptionID === 'Duration') {
                planObj['benifitDuration'] = planOption.planOptionValueID;
            }

            if(planOption.planOptionID === 'EliminationPeriodAccident'){
                planObj['eliminationPeriod'] = planOption.planOptionValueID;
            }
            if(planOption.planOptionID === 'EliminationPeriodSickness'){
                planObj['eliminationPeriodSickness'] = planOption.planOptionValueID;
            }
            if(planOption.planOptionID === 'PreExistingCondition'){
                planObj['preEx'] = planOption.planOptionValueID;
            }

            if(planOption.planOptionID === 'MonthlyMaximum') {
                planObj['finalRates']['maximumWeeklyBenefit'] = planOption.planOptionValueID; 
            }
            if(planOption.planOptionID === 'RehabilitationServices'){
                planObj['rehab'] = planOption.planOptionValueID;
            }
            if(planOption.planOptionID === 'LumpSumSurvivorBenefit'){
                planObj['lumpSum']['survivorBen'] = planOption.planOptionValueID;
            }
            if(planOption.planOptionID === 'Maternity'){
                planObj['maternity'] = planOption.planOptionValueID;
            }
            if(planOption.planOptionID === 'CoverageType'){//added for MMR not in IBR
                planObj['coverageType'] = planOption.planOptionValueID;
            }
        }
        if(planObj['weeklyBenefitAmount'] === "See Benefit Summary" || planObj['finalRates']['maximumWeeklyBenefit'] === 'See Benefit Summary'){
            planObj['weeklyBenefitAmount'] = 'See Benefit Summary'
        }else{
            planObj['weeklyBenefitAmount'] = planObj['weeklyBenefitAmount'] + " Up to " + planObj['finalRates']['maximumWeeklyBenefit']
        }
        if(planObj['eliminationPeriod'] === "" || planObj['eliminationPeriodSickness'] === ""){
            planObj['eliminationPeriod'] = 'See Benefit Summary'
        }else{
            planObj['eliminationPeriod'] = planObj['eliminationPeriod'] + " Accident / " + planObj['eliminationPeriodSickness']+" Sickness";
        }

        //cdd
        for (const clientDefinedData of existingPlanClientDefinedData) {
            if (clientDefinedData.key === 'RateGuaranteePeriod') {
                planObj["rateGuarantee"] = mapRate(clientDefinedData.value)
            }
            if(clientDefinedData.key === "PlanCategory") {
                planObj.productType = clientDefinedData.value;
            }
            if (clientDefinedData.key === 'EmployeeEnrollCount') {
                planObj["enrolledCount"] = clientDefinedData.value;
            }
            if (clientDefinedData.key === 'DependentEnrollCount') {
                
            }
            if (clientDefinedData.key === 'StepwiseQuoteID') {
                
            }
            if (clientDefinedData.key === 'DiscountRatio') {
                
            }
            if (clientDefinedData.key === 'CostDifferentialPercentage' && renewalPlanType === 'renewing') {
                planObj["renewalChangePercent"] =  clientDefinedData.value;
            }
            if (clientDefinedData.key === 'EligibleEmployeeCount') {
                
            }
            if (clientDefinedData.key === 'MinimumWorkHours') {
                
            }
            if(clientDefinedData.key === 'PolicyNumber') {
                planObj["primePolicyNumber"] = clientDefinedData.value ? clientDefinedData.value : "";
            }
            if (clientDefinedData.key === 'CalculatedTotWklyBen') {
                planObj['finalRates']['totalWeeklyBenefit'] = clientDefinedData.value; 
            }
            if (clientDefinedData.key === 'STDplanCode') {
                planObj["code"] = clientDefinedData.value  
                planObj["codeDisplay"] = clientDefinedData.value          
            }
        }

        // planObj.rx["specialty"] = false
        planObj['rehab'] = planObj['rehab'] ? planObj['rehab'] : '';
        planObj['lumpSum'] = planObj['lumpSum'] ? planObj['lumpSum'] : '';
        planObj['maternity'] = planObj['maternity'] ? planObj['maternity'] : ''
        
        //planObj['rateType'] = '';
        //planObj.codeDisplay = existingPlan.planXREF

        const getBillType = (tierStructureId) => {
            switch (tierStructureId) {
                case 'A':
                case 'B':
                case 'C': 
                case '3': 
                case '4':
                case '6':
                case '7':
                case '8':
                case '9':
                return 'Tier';
                default:
                return 'Age';
            }
        };

        planObj['tierStructureId'] = existingPlan.tierStructureID;

        let ratingType = getBillType(existingPlan.tierStructureID)
        // planObj['rateType'] = ratingType === 'Age' ||  existingPlan.rates.ageRates ? 'Age-banded per $10 of Weekly Benefit' : 'Composite per $10 of Weekly Benefit';
        if (existingPlan?.rates?.ageRates) {
            planObj["ageBands"] = formatAgeBand(existingPlanRates.ageBand)
            planObj['finalRates']["ageBands"] = formatAgeBand(existingPlanRates.ageBand)
            planObj["isAgeBanded"] = true
            planObj['defaultRatingType'] = 'ageBands'
            planObj['ibrRatingType'] = 'ageBands'
            planObj['rateType'] = 'Age-banded per $10 of Weekly Benefit'
        } else {
            planObj["compositeRates"] = formatCompositeRates(existingPlanRates)
            planObj['finalRates']["compositeRates"] = formatCompositeRates(existingPlanRates)
            planObj['finalRates']['employee'] = monthlyPremium?.employee;
            planObj['finalRates']['monthlyPremium'] = monthlyPremium;
            planObj['compositeTypeName'] = 'Composite Rates'
            planObj["isComposite"] = true
            planObj['defaultRatingType'] = 'composite'
            planObj['ibrRatingType'] = 'composite'
            planObj['finalRates']['rateOver10WeeklyBenefit'] = existingPlan.rates?.compositeRates?.tier1 || '';
            planObj['rateType'] = 'Composite per $10 of Weekly Benefit'
        }
        
        empCensus.map(employee =>
            (
                checkBelonging(renewalPlanType,employee,existingPlan,false) ?
                    planObj.member.push({
                        employeeId: employee.xref,
                        ssn: employee.ssn,
                        zipCode: employee.homeZip ? employee.homeZip : '',
                        contactInfo: {
                            streetAddress: employee.clientDefinedData.data.find(({ key }) => key === 'EmployeeAddr1') ? employee.clientDefinedData.data.find(({ key }) => key === 'EmployeeAddr1').value : '',
                            city: employee.clientDefinedData.data.find(({ key }) => key === 'EmployeeCity') ? employee.clientDefinedData.data.find(({ key }) => key === 'EmployeeCity').value : '',
                            state: employee.clientDefinedData.data.find(({ key }) => key === 'State') ? employee.clientDefinedData.data.find(({ key }) => key === 'State').value : '',
                            zipCode: employee.clientDefinedData.data.find(({ key }) => key === 'Zip') ? employee.clientDefinedData.data.find(({ key }) => key === 'Zip').value : '',
                        },
                        firstName: employee.first,
                        lastName: employee.last,
                        outOfArea: 'No',
                        gender: employee.genderType === 'M'?'Male':'Female',
                        dob: formatDate(employee.dateOfBirth.month) + '/' + formatDate(employee.dateOfBirth.day) + '/' + employee.dateOfBirth.year,
                        age: getAge(formatDate(employee.dateOfBirth.month) + '/' + formatDate(employee.dateOfBirth.day) + '/' + employee.dateOfBirth.year, effectiveDate),
                        status: employee.status,
                        coverages: {
                            STD: 
                                planObj.renewalPlanType === "current" ? employee.electedPlans.plan.filter(plan => plan.planID === existingPlan.planID)[0]?.coverageType :
                                    planObj.renewalPlanType === "renewing" ? employee.renewingPlans.plan.filter(plan => plan.planID === existingPlan.planID)[0]?.coverageType :
                                        employee.alternatePlans.plan.filter(plan => plan.planId === existingPlan.planId)[0]?.coverageType
                        },
                        ooaPremium: 0,
                        total:
                            planObj.renewalPlanType === "current" ? employee.electedPlans.plan.filter(plan => plan.planID === existingPlan.planID)[0]?.rate :
                                planObj.renewalPlanType === "renewing" ? employee.renewingPlans.plan.filter(plan => plan.planID === existingPlan.planID)[0]?.rate  :
                                    employee.alternatePlans.plan.filter(plan => plan.planID === existingPlan.planID)[0]?.rate 
                            ,
                        dependents: employee?.dependents?.dependent.map(dependentOne =>
                        ({
                            firstName: dependentOne.first,
                            lastName: dependentOne.last,
                            relationship: dependentOne.depType === 'C'?'Child':'S'?'Spouse':"",
                            age: getAge(formatDate(dependentOne.dateOfBirth.month) + '/' + formatDate(dependentOne.dateOfBirth.day) + '/' + dependentOne.dateOfBirth.year, effectiveDate),
                            dob: formatDate(dependentOne.dateOfBirth.month) + '/' + formatDate(dependentOne.dateOfBirth.day) + '/' + dependentOne.dateOfBirth.year,
                            gender: employee.genderType === 'M'? 'Male' : 'Female',
                            streetAddress: employee.clientDefinedData.data.find(({ key }) => key === 'EmployeeAddr1') ? employee.clientDefinedData.data.find(({ key }) => key === 'EmployeeAddr1').value : '',
                            streetAddressln2: '',
                            city: employee.clientDefinedData.data.find(({ key }) => key === 'EmployeeCity') ? employee.clientDefinedData.data.find(({ key }) => key === 'EmployeeCity').value : '',
                            state: employee.clientDefinedData.data.find(({ key }) => key === 'State') ? employee.clientDefinedData.data.find(({ key }) => key === 'State').value : '',
                            zipCode: employee.clientDefinedData.data.find(({ key }) => key === 'Zip') ? employee.clientDefinedData.data.find(({ key }) => key === 'Zip').value : '',
                            ssn: dependentOne.ssn
                        })) || []
                    })
                : ""
            )
        )

        planObj["quote"] = existingPlan.planRate
        planObj["finalMonthlyPremium"] = existingPlan.planRate
        planObj["totalMonthlyPremium"] = existingPlan.planRate
        planObj["quoteFinal"] = existingPlan.planRate
        planObj['finalRates']["totalMonthlyPremium"] = existingPlan.planRate
        
        let byCensusRates = []
        if (empCensus) {
            if (renewalPlanType !== 'alternate') {
                empCensus.map(emp => {
                    if (renewalPlanType === 'current') {
                        let empId = emp.xref
                        let planRate = emp.electedPlans.plan.find(plan => plan.planID === planObj.planXREF && plan.coverageType !== 'WAV')
                        if (planRate) {
                            byCensusRates.push({
                                planCode: planObj.code,
                                employeeId: empId,
                                Total: planRate.rate
                            })
                        }
                    }
                    else if (renewalPlanType === 'renewing') {
                        let empId = emp.xref
                        let planRate = emp.renewingPlans.plan.find(plan => plan.planID === planObj.planXREF && plan.coverageType !== 'WAV')
                        if (planRate) {
                            byCensusRates.push({
                                planCode: planObj.code,
                                employeeId: empId,
                                Total: planRate.rate
                            })
                        }
                    }
                })
            }
            else {
                empCensus.map(emp => {
                    let empId = emp.xref
                    let planRate = emp.alternatePlans && emp.alternatePlans.plan && emp.alternatePlans.plan.find(plan => plan.planID === planObj.planXREF && plan.coverageType !== 'WAV')
                    if (planRate) {
                        byCensusRates.push({
                            planCode: planObj.code,
                            employeeId: empId,
                            Total: planRate.rate
                        })
                    }
                })
            }
        }
        planObj.employeeRates = byCensusRates
        planObj.byCensus.employeeRates = byCensusRates


        if (renewalPlanType === 'alternate') {
            alternate.push(planObj);
            alternatePlanCodes.push(planObj.code);
        } else {
            ibrPlans.push(planObj);
        }
        }
        catch(err){
            console.log("Error on 2308 : ",err)
        }
           
    }

  
    //Merge SP & CH Plans
    for (const spPlan of spPlans) {
        for (const chPlan of chPlans) {
            if (spPlan.code === chPlan.code && spPlan.renewalPlanType === chPlan.renewalPlanType) {
                for (const item in spPlan) {
                    if (spPlan[item] !== chPlan[item] && typeof spPlan[item] !== 'object') {
                        spPlan[item] = spPlan[item] + '/' + chPlan[item];
                    }
                }
                ibrPlans.push(spPlan);
            }
        }
    }

    //Create Current & Renewing Set//
    // for (const planOfferings of planOfferingData) {
    //     for (const item in planOfferings) {
    //         if (item === 'existingPlanPkg') {
    //             const existingPlans = planOfferings[item].plans.plan;

    
    const medicalSet = [];
    const dentalSet = [];
    const visionSet = [];
    const basicLifeSet = [];
    const dependentLifeSet = [];
    const shortTermDisabilitySet = []
    const ltdSet = [];
    let suppLifeSet = [];
    let grpTierStructureId = null;

    if(!generateAlternate) {
        for (const planSet of planMatch) {
            const set = []
            let currentAvailable = false, renewingAvailable = false;
            for (const ibrPlan of ibrPlans) {
                //Put a check for prime here
                if(situsState !== 'CA' || !isUhcRenewals() || ibrPlan.planType !== 'Medical' || isMmr() || !(ibrPlan.finalMonthlyPremium === '0.00' || ibrPlan.finalMonthlyPremium === '0' || ibrPlan.finalMonthlyPremium === 0)){
                    let planCodeField = ibrPlan.planType === 'Medical' ? "medicalPlanCode" : "planXREF"
                    if (planSet.currentPlanXref === ibrPlan[planCodeField] && ibrPlan.renewalPlanType === 'current' && !ibrPlan.included) {
                        ibrPlan.included = true;
                        set.push({ ...ibrPlan, id: planSet.id })
                        currentAvailable = true;
                    }
                    if (planSet.renewingPlanXref === ibrPlan[planCodeField] && ibrPlan.renewalPlanType === 'renewing' && !ibrPlan.included) {
                        if (!grpTierStructureId) grpTierStructureId = ibrPlan.tierStructureId;
                        ibrPlan.included = true;
                        set.push({ ...ibrPlan, id: planSet.id })
                        renewingAvailable = true;
                    }
                    if (currentAvailable && renewingAvailable) {
                        break;
                    }
                }
            }
            if (set.length > 0) {
                if (set[0].planType === 'Medical') {
                    medicalSet.push(set)
                }
                if (set[0].planType === 'Dental') {
                    dentalSet.push(set)
                }
                if (set[0].planType === 'Vision') {
                    visionSet.push(set)
                }
                if (set[0].planType === 'Life') {
                    basicLifeSet.push(set)
                }
                if (set[0].planType === 'LIFE_DEP_BASIC') {
                    dependentLifeSet.push(set)
                }
                if(set[0].planType === 'STD'){
                    shortTermDisabilitySet.push(set)
                }
                if (set[0].planType === 'LTD') {
                    ltdSet.push(set)
                }
                if (set[0].planType === 'Supplement Life') {
                    suppLifeSet.push(set)
                }
            }
        }
    }

    if(!generateAlternate) {
        Ibr.medical = medicalSet;
        Ibr.dental = dentalSet;
        Ibr.vision = visionSet;
        Ibr.basicLife = basicLifeSet;
        Ibr.dependentLife = dependentLifeSet;
        Ibr.shortTermDisability = shortTermDisabilitySet
        Ibr.ltd = ltdSet;
        Ibr.suppLife = suppLifeSet;
        if(isUhcRenewals()) Ibr.tierStructureId = grpTierStructureId;
    }
    Ibr.alternate = alternate;
    Ibr.alternatePlanCodes = alternatePlanCodes;
    if(alternate.length > 0) {
        Ibr.isAlternatePlanAvailable = true
    }
    return Ibr;
}

export const filterIBRCensus = (reviewData) => {
    // Convert company profile coverage type strings into summary census
    // coverage type strings
    const covTypes = {
        'EMP': 'employeeOnly',
        'ESP': 'employeeWithSpouse',
        'ECH': 'employeeWithChild',
        'FAM': 'employeeWithFamily',
    };
    // objects to keep track of each product's census counts
    let dentalCensus = {
        employeeOnly: 0,
        employeeWithSpouse: 0,
        employeeWithChild: 0,
        employeeWithFamily: 0,
    };
    let visionCensus = {
        employeeOnly: 0,
        employeeWithSpouse: 0,
        employeeWithChild: 0,
        employeeWithFamily: 0,
    };
    let lifeCensus = {
        employeeOnly: 0,
        employeeWithSpouse: 0,
        employeeWithChild: 0,
        employeeWithFamily: 0,
    };

    const ibrData = reviewData.renewalData.Ibr
    // Update the census counts for each product
    const planOffData = ibrData.quote.planOfferingData;
    // Check if Dental,Vision & Life have employees' coverage
    let isDentalCoveragePresent = false, isVisionCoveragePresent = false, isLifeCoveragePresent = false;

    // Only Medical
    const memberData = reviewData.renewalData.members
    if (ibrData) {
        if (ibrData.quote) {
            if (ibrData.quote.census) {
                if (ibrData.quote.census.employee) {
                    ibrData.quote.census.employee.forEach((employee) => {
                        employee.renewingPlans.plan.forEach((plan) => {
                            planOffData.map((planData) => {
                                planData.newPlanPkg && planData.newPlanPkg.plans && planData.newPlanPkg.plans.plan && planData.newPlanPkg.plans.plan.forEach((newPlan) => {
                                    if (newPlan.planID === plan.planID && plan.coverageType !== 'WAV') {
                                        if (newPlan.productLineType === "DENTAL") {
                                            dentalCensus[covTypes[plan.coverageType]] += 1;
                                            isDentalCoveragePresent = true;
                                        }
                                        if (newPlan.productLineType === "VISION") {
                                            visionCensus[covTypes[plan.coverageType]] += 1;
                                            isVisionCoveragePresent = true;
                                        }
                                        if (newPlan.productLineType === "BASIC_LIFE_EE") {
                                            lifeCensus[covTypes[plan.coverageType]] += 1;
                                            isLifeCoveragePresent = true;
                                        }
                                    }
                                })
                            })
                        })
                    })
                    let arr = [];
                    let onlyMedCensus = {
                        totalEmployeeOnly: 0,
                        totalEmployeeWithSpouse: 0,
                        totalEmployeeWithChild: 0,
                        totalEmployeeWithFamily: 0
                    }
                    memberData.map((member) => {
                        if (member.relationship === 'Self') {
                            let memberCensus = {
                                employeeId: member.employeeId,
                                employeeOnly: 0,
                                employeeWithSpouse: 0,
                                employeeWithChild: 0,
                                employeeWithFamily: 0,
                            }
                            switch (member.coverageLevel) {
                                case "EO":
                                    memberCensus.employeeOnly += memberCensus.employeeOnly;
                                case "EC":
                                    memberCensus.employeeWithChild += memberCensus.employeeWithChild;
                                case "ES":
                                    memberCensus.employeeWithSpouse += memberCensus.employeeWithSpouse;
                                case "EF":
                                    memberCensus.employeeWithFamily += memberCensus.employeeWithFamily;

                            }
                            arr.push(memberCensus)
                        }


                    })
                    if (arr.length > 0) {
                        arr.map(emp => {
                            onlyMedCensus.totalEmployeeOnly = onlyMedCensus.totalEmployeeOnly + emp.employeeOnly
                            onlyMedCensus.totalEmployeeWithChild = onlyMedCensus.totalEmployeeWithChild + emp.employeeWithChild
                            onlyMedCensus.totalEmployeeWithSpouse = onlyMedCensus.totalEmployeeWithSpouse + emp.employeeWithSpouse
                            onlyMedCensus.totalEmployeeWithFamily = onlyMedCensus.totalEmployeeWithFamily + emp.employeeWithFamily
                        })
                    }
                    let totalemployeeCensus = {
                        employeeOnly: dentalCensus.employeeOnly + visionCensus.employeeOnly + lifeCensus.employeeOnly,
                        employeeWithSpouse: dentalCensus.employeeWithSpouse + visionCensus.employeeWithSpouse + lifeCensus.employeeWithSpouse,
                        employeeWithChild: dentalCensus.employeeWithChild + visionCensus.employeeWithChild + lifeCensus.employeeWithChild,
                        employeeWithFamily: dentalCensus.employeeWithFamily + visionCensus.employeeWithFamily + lifeCensus.employeeWithFamily,
                    };
                    if (!isDentalCoveragePresent)
                        dentalCensus = totalemployeeCensus
                    if (!isVisionCoveragePresent)
                        visionCensus = totalemployeeCensus
                    if (!isLifeCoveragePresent)
                        lifeCensus = totalemployeeCensus
                }
            }
        }
    }
    return {
        dentalCensus,
        visionCensus,
        lifeCensus,
    };
}
export const findMaxCoverage = (medCov, ibrCov) => {
    const isMmrCase = isMmr();
    if (!medCov)
        return ibrCov
    else {
        switch (medCov) {
            case 'EO':
                return ibrCov
            case 'ES':
                if (ibrCov === 'EMP' || ibrCov === 'ECH')
                    return 'ESP'
                else
                    return ibrCov
            case 'EC':
                if (ibrCov === 'EMP')
                    return 'ECH'
                else
                    return ibrCov
            case 'EF':
                return isMmrCase ? 'EE/FAM' : 'FAM'
        }
    }

}
export const filterReconciledCensus = (reviewData) => {
    // Convert company profile coverage type strings into summary census
    // coverage type strings
    let totalemployeeCensus
    const covTypes = {
        'EMP': 'employeeOnly',
        'ESP': 'employeeWithSpouse',
        'ECH': 'employeeWithChild',
        'FAM': 'employeeWithFamily',
    };
    const mmrCovTypes = {
        'EE': 'employeeOnly',
        'EE/SP': 'employeeWithSpouse',
        'EE/CH': 'employeeWithChild',
        'EE/FAM': 'employeeWithFamily',
    };
    // objects to keep track of each product's census counts
    let medicalCensus = {
        employeeOnly: 0,
        employeeWithSpouse: 0,
        employeeWithChild: 0,
        employeeWithFamily: 0,
    };

    let dentalCensus = {
        employeeOnly: 0,
        employeeWithSpouse: 0,
        employeeWithChild: 0,
        employeeWithFamily: 0,
    };
    let visionCensus = {
        employeeOnly: 0,
        employeeWithSpouse: 0,
        employeeWithChild: 0,
        employeeWithFamily: 0,
    };
    let lifeCensus = {
        employeeOnly: 0,
        employeeWithSpouse: 0,
        employeeWithChild: 0,
        employeeWithFamily: 0,
    };
    let dependentLifeCensus = {
        employeeOnly: 0,
        employeeWithSpouse: 0,
        employeeWithChild: 0,
        employeeWithFamily: 0,
    };
    let alternateCensus = {
        employeeOnly: 0,
        employeeWithSpouse: 0,
        employeeWithChild: 0,
        employeeWithFamily: 0,
    };
    let stdCensus = {
        employeeOnly: 0,
        employeeWithSpouse: 0,
        employeeWithChild: 0,
        employeeWithFamily: 0,
    };
    let ltdCensus = {
        employeeOnly: 0,
        employeeWithSpouse: 0,
        employeeWithChild: 0,
        employeeWithFamily: 0,
    };
    const ibrData = reviewData && reviewData && reviewData.renewalData ? reviewData.renewalData.Ibr : null
    // Update the census counts for each product
    const planOffData = ibrData && ibrData.quote && ibrData.quote.planOfferingData ? ibrData.quote.planOfferingData : '';
    // Check if Dental,Vision & Life have employees' coverage
    let isMedicalCoveragePresent = false, isDentalCoveragePresent = false, isVisionCoveragePresent = false, isLifeCoveragePresent = false, isStdCoveragePresent = false;
    let coveredIBREmp = [];
    let employeesIBR = [];
    // Only Medical
    const memberData = reviewData && reviewData.renewalData && reviewData.renewalData.members ? reviewData.renewalData.members : []
    let totalEligibleEmployees = 0
    let onlyMedCensus = {
        employeeOnly: 0,
        employeeWithSpouse: 0,
        employeeWithChild: 0,
        employeeWithFamily: 0
    }
    const isMMRCase = isMmr()
    if (ibrData) {
        if (ibrData.quote) {
            if (ibrData.quote.census) {
                if (ibrData.quote.census.employee) {
                    ibrData.quote.census.employee.forEach((employee) => {
                        let medCoverage
                        let checkForMember
                        memberData.map((member) => {
                            let memberDOBArray = isoStringToMMDDYYYY(member.birthDate).split('/')
                            let month = parseInt(memberDOBArray[0])
                            let year = parseInt(memberDOBArray[2])
                            let day = parseInt(memberDOBArray[1])
                            if (member.socialSecurityNumber && member.socialSecurityNumber === employee.ssn) {
                                checkForMember = member
                            }
                            else if (member.firstName.toLowerCase() === employee.first.toLowerCase() && member.lastName.toLowerCase() === employee.last.toLowerCase() &&
                                month === employee.dateOfBirth.month &&
                                day === employee.dateOfBirth.day &&
                                year === employee.dateOfBirth.year) {
                                checkForMember = member
                            }
                        })
                        if (checkForMember) {
                            let memberDOBArray = isoStringToMMDDYYYY(checkForMember.birthDate).split('/')
                            let month = getZeroPaddedString(parseInt(memberDOBArray[0]))
                            let year = getZeroPaddedString(parseInt(memberDOBArray[2]))
                            let day = getZeroPaddedString(parseInt(memberDOBArray[1]))
                            medCoverage = checkForMember.coverageLevel
                            coveredIBREmp.push({
                                coverageLevel: medCoverage,
                                ssn: employee.ssn,
                                dob: month + '/' + day + '/' + year
                            })
                        }
                        let coverageLevelArray = []
                        if (isMMRCase) {
                            let products = ['medical', 'dental', 'vision']
                            products.forEach(product => {
                                if(reviewData && reviewData[product] && reviewData[product].selectedPlans) {
                                    reviewData[product].selectedPlans.forEach(plan => {
                                        let member = plan.member.find(member => member.employeeId === employee.xref)
                                        if (member && member.coverages[product] !== 'WAV') {
                                            if (product === "medical") {
                                                medicalCensus[mmrCovTypes[member.coverages[product]]] += 1;
                                                isMedicalCoveragePresent = true;
                                            } else if (product === "dental") {
                                                dentalCensus[mmrCovTypes[member.coverages[product]]] += 1;
                                                isDentalCoveragePresent = true;
                                            } else if (product === "vision") {
                                                visionCensus[mmrCovTypes[member.coverages[product]]] += 1;
                                                isVisionCoveragePresent = true;
                                            }
                                            coverageLevelArray.push(member.coverages[product])
                                        }
                                    })
                                }
                            })
                        } else {
                            employee.renewingPlans.plan.forEach((plan) => {
                                planOffData.map((planData) => {
                                    planData.newPlanPkg && planData.newPlanPkg.plans && planData.newPlanPkg.plans.plan && planData.newPlanPkg.plans.plan.forEach((newPlan) => {
                                        if (newPlan.planID === plan.planID && plan.coverageType !== 'WAV') {
                                            if (newPlan.productLineType === "MEDICAL") {
                                                medicalCensus[covTypes[findMaxCoverage(medCoverage, plan.coverageType)]] += 1;
                                                isMedicalCoveragePresent = true;
                                            }
                                            if (newPlan.productLineType === "DENTAL") {
                                                dentalCensus[covTypes[findMaxCoverage(medCoverage, plan.coverageType)]] += 1;
                                                isDentalCoveragePresent = true;
                                            }
                                            if (newPlan.productLineType === "VISION") {
                                                visionCensus[covTypes[findMaxCoverage(medCoverage, plan.coverageType)]] += 1;
                                                isVisionCoveragePresent = true;
                                            }
                                            if (newPlan.productLineType === "BASIC_LIFE_EE") {
                                                lifeCensus[covTypes[findMaxCoverage(medCoverage, plan.coverageType)]] += 1;
                                                isLifeCoveragePresent = true;
                                            }
                                            if (newPlan.productLineType === "STD") {
                                                stdCensus[covTypes[findMaxCoverage(medCoverage, plan.coverageType)]] += 1;
                                                isStdCoveragePresent = true;
                                            }
                                        }
                                        if(newPlan.productLineType === "BASIC_LIFE_SPOUSE") {
                                            if(employee?.dependents?.dependent)
                                            {
                                                employee.dependents.dependent.forEach((dependent)=>{
                                                    let depPlan=dependent.renewingCoverageElections.plan.find(depPlan => depPlan.planID === plan.planID && depPlan.coverageType !== 'WAV')
                                                    if(depPlan!==undefined&&depPlan!==""&&dependent.depType==='S')
                                                    {
                                                        if(dependent.depType==='S'){
                                                            dependentLifeCensus[covTypes["ESP"]]+=1;
                                                        }
                                                    }
                                                })
                                            }     
                                        }
                                        if(newPlan.productLineType === "BASIC_LIFE_CHILD") {
                                            if(employee?.dependents?.dependent){
                                                employee.dependents.dependent.forEach((dependent)=>{
                                                    let depPlan=dependent.renewingCoverageElections.plan.find(depPlan => depPlan.planID === plan.planID && depPlan.coverageType !== 'WAV')
                                                    if(depPlan!==undefined && depPlan!==""&&dependent.depType==='C')
                                                    {
                                                        dependentLifeCensus[covTypes["ECH"]]+=1;                                                        
                                                    }
                                                })
                                            }    
                                        }  
                                    })
                                })
                            })
                            coverageLevelArray = employee.renewingPlans.plan.map(plan => plan.coverageType)
                        }
                        let highestCov = findHighestCoverage(coverageLevelArray)
                        alternateCensus[covTypes[highestCov]] += 1
                    })
                    onlyMedCensus = {
                        employeeOnly: 0,
                        employeeWithSpouse: 0,
                        employeeWithChild: 0,
                        employeeWithFamily: 0
                    }
                    let j = 1;
                    memberData.map(memData => {
                        if (!coveredIBREmp.find(emp => (emp.ssn && memData.ssn && emp.ssn === memData.ssn) || emp.dob === isoStringToMMDDYYYY(memData.birthDate)) && memData.relationship === 'Self') {
                            switch (memData.coverageLevel) {
                                case 'EO':
                                    onlyMedCensus.employeeOnly += 1;
                                    break;
                                case 'EC':
                                    onlyMedCensus.employeeWithChild += 1;
                                    break;
                                case 'ES':
                                    onlyMedCensus.employeeWithSpouse += 1;
                                    break;
                                case 'EF':
                                    onlyMedCensus.employeeWithFamily += 1;
                                    break;
                            }
                        }
                    })
                    totalEligibleEmployees = ibrData.quote.census.employee.length
                    totalemployeeCensus = {
                        employeeOnly: alternateCensus.employeeOnly + onlyMedCensus.employeeOnly,
                        employeeWithSpouse: alternateCensus.employeeWithSpouse + onlyMedCensus.employeeWithSpouse,
                        employeeWithChild: alternateCensus.employeeWithChild + onlyMedCensus.employeeWithChild,
                        employeeWithFamily: alternateCensus.employeeWithFamily + onlyMedCensus.employeeWithFamily,
                    };
                    if (!isMedicalCoveragePresent)
                        medicalCensus = totalemployeeCensus
                    if (!isDentalCoveragePresent)
                        dentalCensus = totalemployeeCensus
                    if (!isVisionCoveragePresent)
                        visionCensus = totalemployeeCensus
                    if (!isLifeCoveragePresent)
                        lifeCensus = totalemployeeCensus
                    if (!isStdCoveragePresent)
                        stdCensus = totalemployeeCensus
                }
            }
        }
    } else {
        //const memberData = reviewData.renewalData.members
        let onlyMedCensus = {
            employeeOnly: 0,
            employeeWithSpouse: 0,
            employeeWithChild: 0,
            employeeWithFamily: 0
        }
        memberData.map(memData => {
            if (memData.relationship === 'Self') {
                switch (displayTieredCoverageTypeViaProducts(memData)) {
                    case 'EO':
                        onlyMedCensus.employeeOnly += 1;
                        break;
                    case 'EC':
                        onlyMedCensus.employeeWithChild += 1;
                        break;
                    case 'ES':
                        onlyMedCensus.employeeWithSpouse += 1;
                        break;
                    case 'EF':
                        onlyMedCensus.employeeWithFamily += 1;
                        break;
                }
            }
        })

        totalemployeeCensus = {
            employeeOnly: onlyMedCensus.employeeOnly,
            employeeWithSpouse: onlyMedCensus.employeeWithSpouse,
            employeeWithChild: onlyMedCensus.employeeWithChild,
            employeeWithFamily: onlyMedCensus.employeeWithFamily,
        };
        medicalCensus = totalemployeeCensus
        dentalCensus = totalemployeeCensus
        visionCensus = totalemployeeCensus
        lifeCensus = totalemployeeCensus
        stdCensus = totalemployeeCensus
        ltdCensus = totalemployeeCensus

    }
    let totalCount = totalemployeeCensus.employeeOnly
        + totalemployeeCensus.employeeWithSpouse
        + totalemployeeCensus.employeeWithChild
        + totalemployeeCensus.employeeWithFamily
    //let totalCount = totalEligibleEmployees + Object.keys(onlyMedCensus).map((key) => onlyMedCensus[key]).reduce((total, item) => total + item)

    return {
        totalCount: totalCount,
        census: totalemployeeCensus,
        medicalCensus: medicalCensus,
        dentalCensus: dentalCensus,
        visionCensus: visionCensus,
        lifeCensus: lifeCensus,
        dependentLifeCensus: dependentLifeCensus,
        stdCensus: stdCensus,
        ltdCensus: ltdCensus
    }
}

export const filterAlternateCensus = (reviewData, planCode) => {

    const ibrData = reviewData && reviewData.renewalData ? reviewData.renewalData.Ibr : null
    // Update the census counts for each product
    const planOffData = ibrData && ibrData.quote && ibrData.quote.planOfferingData ? ibrData.quote.planOfferingData : [];

    let numberOfEnrolled = 0;
    planOffData.map((planData) => {
        if(planData.alternatePlanPkg) {
            planData.alternatePlanPkg && planData.alternatePlanPkg.plans && planData.alternatePlanPkg.plans.plan && planData.alternatePlanPkg.plans.plan.forEach((newPlan) => {
                if (newPlan.planID === planCode) {
                    let count = 0;
                    count = newPlan?.clientDefinedData?.data?.find(data => data.key === 'EmployeeEnrollCount').value
                    numberOfEnrolled += parseInt(count)
                }
            })
        }
    })
    return numberOfEnrolled
}

export const getStepwisePlanDetails = (platform, stateCode, groupSize, effectiveDate, type, primePlanCode) => {
    return new Promise((resolve, reject) => {
        axios.get(`${apiRoutes.quickQuotes}/getStepWisePlan?platform=${platform}&stateCode=${stateCode}&groupSize=${groupSize}&effectiveDate=${effectiveDate}&type=${type}&planCode=${primePlanCode}`)
        .then(resp => {
            resolve(resp.data);
        })
        .catch(err => {
            store.dispatch({
                type: types.DISPLAY_ERROR_MESSAGE
            });
            const reqBody = {
                platform, stateCode, groupSize, effectiveDate, type, primePlanCode
            }
            logError(KEY_NAME, UHC_REN_APIS.SPECIALTY_PLAN, 'getStepwisePlanDetails', reqBody, err);
            resolve();
        })
    })
}

const getLifePlanDetails = (lifePlanDetails, stateCode, groupSize, effectiveDate, type, primePlanCode , isLife, code,selectedClass='') => {
    let planDetails = [];

    lifePlanDetails.forEach(item => {
        if(isLife && item.primePlanCode === primePlanCode && (selectedClass || item.planCode === code)) {
            planDetails.push(item);
        }
        else if(!isLife && item.primePlanCode === primePlanCode) {
            planDetails.push(item);
        }
    });

    if(planDetails.length !== 1) {
        store.dispatch({
            type: types.DISPLAY_ERROR_MESSAGE
        });

        const reqBody = {
            platform: 'prime', stateCode, groupSize, effectiveDate, type, primePlanCode
        }
        logError(KEY_NAME, UHC_REN_APIS.SPECIALTY_PLAN, 'getStepwisePlanDetails', reqBody, "No Life Plans or multiple life plans found!");
    } else {
        if(planDetails[0]) {
            return planDetails[0];
        }
    }
    return {};
}
export const getGiAmount = (ibr,plan)=>{
    let giAmount=plan.guaranteedIssue;
    if(ibr && plan.renewalPlanType === 'current'){
        ibr.quote.planOfferingData[0].existingPlanPkg.plans.plan.forEach(extPlan=>{
            if(extPlan.planXREF === plan.planXREF) {
                extPlan.clientDefinedData.data.forEach(item=>{
                    if(item.key === 'basicLifeGiAmount') {
                        giAmount = item.value;
                    }
                })
            }
        })
    }else if(ibr && plan.renewalPlanType === 'renewing'){
        ibr.quote.planOfferingData[0].newPlanPkg.plans.plan.forEach(newPlan=>{
            if(newPlan.planXREF === plan.planXREF) {
                newPlan.clientDefinedData.data.forEach(item=>{
                    if(item.key === 'basicLifeGiAmount') {
                        giAmount = item.value;
                    }
                })
            }
        })
    }
    return giAmount
}

