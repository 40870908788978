const employeeActiveCheck = (employee) => {
    // Checks if employee has a status of Active object exists or not and if it does it looks at the nested value for status
    const { employeeStatus } = employee;
    return employeeStatus === 'Active'
};

export const identifyMicroGroup = (employees, selectedProducts, stateCode) => {
    const allEmployees = employees.flat()
    let eligibleEmployees = 0
    let enrolledEmployees = 0
    let reqEligibleEmployees = stateCode === 'NV' ? 10 : 3
    allEmployees.forEach(employee => {
        const isActive = employeeActiveCheck(employee)
        if(isActive) {
            eligibleEmployees += 1
        }
        if((employee.waiveOption && (!employee.waiveOption.includes('medical') && !employee.waiveOption.includes('allProducts'))) || (employee.productSelection && employee.productSelection[0] && employee.productSelection[0].medical && employee.productSelection[0].medical !== 'waive')) {
            enrolledEmployees += 1
        }
    })
    //If case is Specialty ONLY it cannot be a MicroGroup
    return (enrolledEmployees <= 1 || eligibleEmployees < reqEligibleEmployees) && selectedProducts.medical
}