import * as types from './actionTypes';
import { axios } from '../utils/api/axiosConfig';
import { isEmpty } from '../utils/formatters/strings';
import { findValueFromMaxSinglePlanArray, findDaysTillEffDate, findNYPlanCutoffDate } from '../utils/formatters/dates';
import { checkZipObj } from '../utils/checkers/propTypes';
import sendLog from './logActions';
import { formComparePlanObject } from '../components/activities/Renewal/RenewalsCommonComponents/ComparePlans/RenewalComparePlans';
import { createHistory } from './historyAction';
import { isUhcRenewals, isOxfordRenewals } from '../utils/businessLogic/platformUtils';
import { logError } from '../utils/errorLogging/logError';
import { KEY_NAME, UHC_REN_APIS } from '../utils/errorLogging/logKeys';
import { setPlanInstallPlatforms } from './enrollmentActions';

const apiRoutes = require('../utils/api/apiRouteConfig');

export const getEnrollmentRules = (platform, zipObj, effectiveDate, groupSize) => (dispatch) => new Promise((resolve, reject) => {
    checkZipObj(zipObj);
    let isMultiChoiceZipCode = 'true';
    if (platform === 'CIRRUS') {
        isMultiChoiceZipCode = 'false';
    }

    dispatch({ type: types.REQUEST_ENROLLMENT_RULES });

    axios.get(`${apiRoutes.mongo}/shopping-rules?`
        + 'type=enrollment'
        + `&isMultiChoiceZipCode=${isMultiChoiceZipCode}`
        + `&state=${zipObj.stateCode}`
        + `&zip=${zipObj.zipCode}`
        + `&groupSize=${groupSize}`
        + `&platform=${platform}`
        + `${!isEmpty(zipObj.county) ? `&county=${zipObj.county.split(' County')[0]}` : ''}`)
        .then(async (resp) => {
            const { rules } = resp.data;
            // The rules are given to us sorted in priority order
            // We go down the list to try to fill every rule we need, favoring higher-priority rules
            const finalRules = {};
            const specialRules = {};   // for zip where both single and multi choice plans are present
            rules.forEach((rule) => {
                if (isEmpty(finalRules.maxSingleChoicePlans) && rule.maxSingleChoicePlans) {
                    if (typeof rule.maxSingleChoicePlans === 'object' && zipObj.stateCode !== "NY") {
                        const daysTilEffDate = findDaysTillEffDate(effectiveDate);
                        finalRules.maxSingleChoicePlans = findValueFromMaxSinglePlanArray(rule.maxSingleChoicePlans, daysTilEffDate);
                        finalRules.maxSingleChoicePlansArray = rule.maxSingleChoicePlans;
                    } else if (typeof rule.maxSingleChoicePlans === 'object' && zipObj.stateCode === "NY") {
                        const { newCutoffDate, currentDate } = findNYPlanCutoffDate(effectiveDate)
                        if (currentDate.isAfter(newCutoffDate)) {
                            finalRules.maxSingleChoicePlans = rule.maxSingleChoiceAfterEff || 3
                            finalRules.maxSingleChoicePlansArray = null;
                        } else {
                            finalRules.maxSingleChoicePlans = rule.maxSingleChoiceBeforeEff || 4;
                            finalRules.maxSingleChoicePlansArray = null;
                        }
                    } else {
                        finalRules.maxSingleChoicePlans = rule.maxSingleChoicePlans;
                        finalRules.maxSingleChoicePlansArray = null;
                    }
                }

                if (isEmpty(finalRules.maxMultiChoicePackages) && rule.maxMultiChoicePackages) {
                    finalRules.maxMultiChoicePackages = rule.maxMultiChoicePackages;
                }

                if (isEmpty(finalRules.maxAgeBandedPlans) && rule.maxAgeBandedPlans) {
                    finalRules.maxAgeBandedPlans = rule.maxAgeBandedPlans;
                }

                if (isEmpty(finalRules.maxRateDifferential) && rule.maxRateDifferential) {
                    finalRules.maxRateDifferential = rule.maxRateDifferential;
                }

                if (isEmpty(finalRules.maxDentalPlans) && rule.maxDentalPlans) {
                    finalRules.maxDentalPlans = rule.maxDentalPlans;
                }

                if (isEmpty(finalRules.maxVisionPlans) && rule.maxVisionPlans) {
                    finalRules.maxVisionPlans = rule.maxVisionPlans;
                }

                if (isEmpty(finalRules.maxLifePlans) && rule.maxLifePlans) {
                    finalRules.maxLifePlans = rule.maxLifePlans;
                }

                if (isEmpty(finalRules.maxDependentLifePlans) && rule.maxDependentLifePlans) {
                    finalRules.maxDependentLifePlans = rule.maxDependentLifePlans;
                }

                if (isEmpty(finalRules.maxSTDPlans) && rule.maxSTDPlans) {
                    finalRules.maxSTDPlans = rule.maxSTDPlans;
                }

                if (isEmpty(finalRules.maxLTDPlans) && rule.maxLTDPlans) {
                    finalRules.maxLTDPlans = rule.maxLTDPlans;
                }

                if (isEmpty(finalRules.maxSuppLifePlans) && rule.maxSuppLifePlans) {
                    finalRules.maxSuppLifePlans = rule.maxSuppLifePlans;
                }

                if (isEmpty(finalRules.maxDependentSuppLifePlans) && rule.maxDependentSuppLifePlans) {
                    finalRules.maxDependentSuppLifePlans = rule.maxDependentSuppLifePlans;
                }

                if (isEmpty(finalRules.platform) && rule.platform) {
                    finalRules.platform = rule.platform;
                }
            });
            // Extra call fpr standalone plans in PRIME platform
            if (isMultiChoiceZipCode === 'true') {
                const response = await axios.get(`${apiRoutes.mongo}/shopping-rules?`
                    + 'type=enrollment'
                    + `&isMultiChoiceZipCode=false`
                    + `&state=${zipObj.stateCode}`
                    + `&zip=${zipObj.zipCode}`
                    + `&groupSize=${groupSize}`
                    + `&platform=${platform}`
                    + `${!isEmpty(zipObj.county) ? `&county=${zipObj.county.split(' County')[0]}` : ''}`)

                const { rules } = response.data;
                rules.forEach((rule) => {
                    if (isEmpty(specialRules.maxStandalonePlans) && rule.maxSingleChoicePlans) {
                        if (typeof rule.maxSingleChoicePlans === 'object') {
                            const daysTilEffDate = findDaysTillEffDate(effectiveDate);
                            specialRules.maxStandalonePlans = findValueFromMaxSinglePlanArray(rule.maxSingleChoicePlans, daysTilEffDate);
                            specialRules.maxStandalonePlansArray = rule.maxSingleChoicePlans;
                        } else {
                            specialRules.maxStandalonePlans = rule.maxSingleChoicePlans;
                            specialRules.maxStandalonePlansArray = null;
                        }
                    }
                })
            }
            dispatch({
                type: types.RECEIVE_ENROLLMENT_RULES,
                stateName: zipObj.state,
                ...finalRules,
                specialRules,
            });
            resolve();
        })
        .catch((err) => {
            console.log(err);
            if (isUhcRenewals()) {
                const reqBody = {
                    type: 'enrollment',
                    isMultiChoiceZipCode, groupSize, platform,
                    zip: zipObj.zipCode,
                    state: zipObj.stateCode,
                    county: isEmpty(zipObj.county) ? zipObj.county.split(' County')[0] : ''
                }
                logError(KEY_NAME, UHC_REN_APIS.SHOPPING_RULES, 'rulesActions.getEnrollmentRules', reqBody, err);
            }
            dispatch({ type: types.ERROR_ENROLLMENT_RULES });
            reject(err);
        });
});

export const getEnrolledPlansRenewals = (renewAsIs = true, pageType, shoppingPlansDirect, enrolledDentalPlansDirect = [], enrolledLifePlansDirect = [], enrolledVisionPlansDirect = [], contractOptList = [], enrolledDependentLifePlansDirect = [],enrolledSTDPlansDir = [],enrolledLTDPlansDir = [],enrolledSuppLifePlansDir = []) => (dispatch, getState) => {
    const fullProps = getState();

    let shoppingPlans; let enrolledDentalPlans; let enrolledLifePlans; let
        enrolledVisionPlans; let enrolledDependentLifePlans; let enrolledMedicalPlans = {};
        let enrolledSTDPlans; let enrolledLTDPlans; let enrolledSuppLifePlans;
    if (pageType === 'review' && !renewAsIs) {
        shoppingPlans = fullProps.review.shoppingPlans || [];
        enrolledMedicalPlans = fullProps.review && fullProps.review.enrolledPlans || {};
        enrolledDentalPlans = fullProps.review.enrolledDentalPlans || [];
        enrolledLifePlans = fullProps.review.enrolledLifePlans || [];
        enrolledVisionPlans = fullProps.review.enrolledVisionPlans || [];
        enrolledDependentLifePlans = fullProps.review.enrolledDependentLifePlans || [];
        if(isUhcRenewals() || isOxfordRenewals()){
            enrolledSTDPlans = (fullProps.review.std && fullProps.review.std.selectedPlans) || fullProps.review.enrolledSTDPlans || [];
            enrolledLTDPlans = (fullProps.review.ltd && fullProps.review.ltd.selectedPlans) || fullProps.review.enrolledLTDPlans || [];
            enrolledSuppLifePlans = fullProps.review.enrolledSuppLifePlans || (fullProps.review.suppLife && fullProps.review.suppLife.selectedPlans) || [];
        }
    } else if (pageType === 'review' && renewAsIs) {
        const shoppingPlansArray = []; const shoppingPlansArrayDental = []; const shoppingPlansArrayVision = []; const
            shoppingPlansArrayLife = []; const shoppingPlansArrayDependentLife = [];let shoppingPlansArrayStd =[];
            let shoppingPlansArrayLtd = []; let shoppingPlansArraySuppLife = [];
        if (isUhcRenewals() && fullProps.review.medical && fullProps.review.medical.set && fullProps.review.medical.set.length > 0) {
            fullProps.review.medical.set.forEach((setPlan) => {
                shoppingPlansArray.push(setPlan[1]);
            });
        } else if (fullProps.review.renewalData && fullProps.review.renewalData.planRates && fullProps.review.renewalData.planRates.length > 0) {
            const renewalSelected = fullProps.review.renewalData.planRates.filter((plan) => (plan.renewalPlanType === 'Renewal' && plan.productType === "Medical"));
            renewalSelected.forEach((renewalPlan) => {
                shoppingPlansArray.push(formComparePlanObject(renewalPlan.planCode, fullProps.review.renewalData));
            });
        }
        if (fullProps.review.dental && fullProps.review.dental.set && fullProps.review.dental.set.length > 0) {
            fullProps.review.dental.set.forEach((setPlan) => {
                shoppingPlansArrayDental.push(setPlan[1]);
            });
        }
        if (fullProps.review.vision && fullProps.review.vision.set && fullProps.review.vision.set.length > 0) {
            fullProps.review.vision.set.forEach((setPlan) => {
                shoppingPlansArrayVision.push(setPlan[1]);
            });
        }
        if (fullProps.review.life && fullProps.review.life.set && fullProps.review.life.set.length > 0) {
            fullProps.review.life.set.forEach((setPlan) => {
                shoppingPlansArrayLife.push(setPlan[1]);
            });
        }
        if (fullProps.review.dependentLife && fullProps.review.dependentLife.set && fullProps.review.dependentLife.set.length > 0) {
            fullProps.review.dependentLife.set.forEach((setPlan) => {
                shoppingPlansArrayDependentLife.push(setPlan[1]);
            });
        }
        shoppingPlans = shoppingPlansArray;
        enrolledMedicalPlans = fullProps.review && fullProps.review.enrolledPlans || {};
        enrolledDentalPlans = shoppingPlansArrayDental;
        enrolledLifePlans = shoppingPlansArrayLife;
        enrolledVisionPlans = shoppingPlansArrayVision;
        enrolledDependentLifePlans = shoppingPlansArrayDependentLife
        if(isUhcRenewals() || isOxfordRenewals()){
            ["std","ltd","suppLife"].forEach(pro =>{
                if (fullProps.review[pro] && fullProps.review[pro].set && fullProps.review[pro].set.length > 0) {
                    fullProps.review[pro].set.forEach((setPlan) => {
                        if(pro === 'std'){
                            shoppingPlansArrayStd.push(setPlan[1]);
                        } else if(pro === 'ltd') {
                            shoppingPlansArrayLtd.push(setPlan[1]);
                        } else if(pro === 'suppLife') {
                            shoppingPlansArraySuppLife.push(setPlan[1]);
                        }
                    });
                }
            })
            enrolledSTDPlans = shoppingPlansArrayStd;
            enrolledLTDPlans = shoppingPlansArrayLtd;
            enrolledSuppLifePlans = shoppingPlansArraySuppLife;
        }
    } else if (pageType === 'decide' && !renewAsIs) {
        const enrolledPlans = [];
        fullProps.decide.shoppingPlans.map((plan) => {
            if (plan.selected) { enrolledPlans.push(plan); }
        });
        shoppingPlans = enrolledPlans;
        enrolledMedicalPlans = fullProps.rules.enrollment && fullProps.rules.enrollment.enrolledPlans || {};
        enrolledDentalPlans = fullProps.rules.enrollment.enrolledDentalPlans || [];
        enrolledLifePlans = fullProps.rules.enrollment.enrolledLifePlans || [];
        enrolledVisionPlans = fullProps.rules.enrollment.enrolledVisionPlans || [];
        enrolledDependentLifePlans = fullProps.rules.enrollment.enrolledDependentLifePlans || []
        if(isUhcRenewals() || isOxfordRenewals()){  
            enrolledSTDPlans = fullProps.rules.enrollment.enrolledSTDPlans || [];
            enrolledLTDPlans = fullProps.rules.enrollment.enrolledLTDPlans || [];
            enrolledSuppLifePlans = fullProps.rules.enrollment.enrolledSuppLifePlans || [];
        }
    } else if (pageType === 'decide' && renewAsIs) {
        const shoppingPlansArray = []; const shoppingPlansArrayDental = []; const shoppingPlansArrayVision = []; const
            shoppingPlansArrayLife = []; const shoppingPlansArrayDependentLife = []; const  shoppingPlansArrayStd =[];
            const shoppingPlansArrayLtd = []; const shoppingPlansArraySuppLife = [];
        if (isUhcRenewals() && fullProps.decide.medical && fullProps.decide.medical.set && fullProps.decide.medical.set.length > 0) {
            fullProps.decide.medical.set.forEach((setPlan) => {
                shoppingPlansArray.push(setPlan[1]);
            });
        } else if (fullProps.decide.renewalData && fullProps.decide.renewalData.planRates && fullProps.decide.renewalData.planRates.length > 0) {
            const renewalSelected = fullProps.decide.renewalData.planRates.filter((plan) => (plan.renewalPlanType === "Renewal" && plan.productType === "Medical"));
            renewalSelected.forEach((renewalPlan) => {
                shoppingPlansArray.push(formComparePlanObject(renewalPlan.planCode, fullProps.decide.renewalData));
            });
        }
        if (fullProps.decide.dental && fullProps.decide.dental.set && fullProps.decide.dental.set.length > 0) {
            fullProps.decide.dental.set.forEach((setPlan) => {
                shoppingPlansArrayDental.push(setPlan[1]);
            });
        }
        if (fullProps.decide.vision && fullProps.decide.vision.set && fullProps.decide.vision.set.length > 0) {
            fullProps.decide.vision.set.forEach((setPlan) => {
                shoppingPlansArrayVision.push(setPlan[1]);
            });
        }
        if (fullProps.decide.life && fullProps.decide.life.set && fullProps.decide.life.set.length > 0) {
            fullProps.decide.life.set.forEach((setPlan) => {
                shoppingPlansArrayLife.push(setPlan[1]);
            });
        }
        if (fullProps.decide.dependentLife && fullProps.decide.dependentLife.set && fullProps.decide.dependentLife.set.length > 0) {
            fullProps.decide.dependentLife.set.forEach((setPlan) => {
                shoppingPlansArrayDependentLife.push(setPlan[1]);
            });
        }
        shoppingPlans = shoppingPlansArray;
        enrolledMedicalPlans = fullProps.decide && fullProps.decide.enrolledPlans || {};
        enrolledDentalPlans = shoppingPlansArrayDental;
        enrolledLifePlans = shoppingPlansArrayLife;
        enrolledVisionPlans = shoppingPlansArrayVision;
        enrolledDependentLifePlans = shoppingPlansArrayDependentLife
        if(isUhcRenewals() || isOxfordRenewals()){
            ["std","ltd","suppLife"].forEach(pro =>{
                if (fullProps.decide[pro] && fullProps.decide[pro].set && fullProps.decide[pro].set.length > 0) {
                    fullProps.decide[pro].set.forEach((setPlan) => {
                        if(pro === 'std'){
                            shoppingPlansArrayStd.push(setPlan[1]);
                        } else if(pro === 'ltd') {
                            shoppingPlansArrayLtd.push(setPlan[1]);
                        } else if(pro === 'suppLife') {
                            shoppingPlansArraySuppLife.push(setPlan[1]);
                        }
                    });
                }
            })
            
            enrolledSTDPlans = shoppingPlansArrayStd;
            enrolledLTDPlans = shoppingPlansArrayLtd;
            enrolledSuppLifePlans = shoppingPlansArraySuppLife;
        }
    } else if (pageType === 'renewalShoppingHelper' && !renewAsIs) {
        shoppingPlans = fullProps.rules.enrollment.enrolledPlans.standalone || [];
        enrolledMedicalPlans = fullProps.rules.enrollment && fullProps.rules.enrollment.enrolledPlans || {};
        enrolledDentalPlans = fullProps.rules.enrollment.enrolledDentalPlans || [];
        enrolledLifePlans = fullProps.rules.enrollment.enrolledLifePlans || [];
        enrolledVisionPlans = fullProps.rules.enrollment.enrolledVisionPlans || [];
        enrolledDependentLifePlans = fullProps.rules.enrollment.enrolledDependentLifePlans || [];
        if(isUhcRenewals() || isOxfordRenewals()){
            enrolledSTDPlans = fullProps.rules.enrollment.enrolledSTDPlans || [];
            enrolledLTDPlans = fullProps.rules.enrollment.enrolledLTDPlans || [];
            enrolledSuppLifePlans = fullProps.rules.enrollment.enrolledSuppLifePlans || [];
        }
    } else if (pageType === 'renewalShoppingHelper' && renewAsIs) {
        const shoppingPlansArray = []; const shoppingPlansArrayDental = []; const shoppingPlansArrayVision = []; const
            shoppingPlansArrayLife = []; const shoppingPlansArrayDependentLife = [];let shoppingPlansArrayStd =[];
            let shoppingPlansArrayLtd = []; let shoppingPlansArraySuppLife = [];
        if (fullProps.renewalShoppingHelper.renewalData && fullProps.renewalShoppingHelper.renewalData.planRates && fullProps.renewalShoppingHelper.renewalData.planRates.length > 0) {
            const renewalSelected = fullProps.renewalShoppingHelper.renewalData.planRates.filter((plan) => (plan.renewalPlanType === "Renewal" && plan.productType === "Medical"));
            renewalSelected.forEach((renewalPlan) => {
                shoppingPlansArray.push(formComparePlanObject(renewalPlan.planCode, fullProps.renewalShoppingHelper.renewalData));
            });
        }
        if (fullProps.renewalShoppingHelper.dental && fullProps.renewalShoppingHelper.dental.set && fullProps.renewalShoppingHelper.dental.set.length > 0) {
            fullProps.review.dental.set.forEach((setPlan) => {
                shoppingPlansArrayDental.push(setPlan[1]);
            });
        }
        if (fullProps.renewalShoppingHelper.vision && fullProps.renewalShoppingHelper.vision.set && fullProps.renewalShoppingHelper.vision.set.length > 0) {
            fullProps.renewalShoppingHelper.vision.set.forEach((setPlan) => {
                shoppingPlansArrayVision.push(setPlan[1]);
            });
        }
        if (fullProps.renewalShoppingHelper.life && fullProps.renewalShoppingHelper.life.set && fullProps.renewalShoppingHelper.life.set.length > 0) {
            fullProps.renewalShoppingHelper.life.set.forEach((setPlan) => {
                shoppingPlansArrayLife.push(setPlan[1]);
            });
        }
        if (fullProps.renewalShoppingHelper.dependentLife && fullProps.renewalShoppingHelper.dependentLife.set && fullProps.renewalShoppingHelper.dependentLife.set.length > 0) {
            fullProps.renewalShoppingHelper.dependentLife.set.forEach((setPlan) => {
                shoppingPlansArrayDependentLife.push(setPlan[1]);
            });
        }
        shoppingPlans = shoppingPlansArray;
        enrolledMedicalPlans = fullProps.renewalShoppingHelper && fullProps.renewalShoppingHelper.enrolledPlans || {};
        enrolledDentalPlans = shoppingPlansArrayDental;
        enrolledLifePlans = shoppingPlansArrayLife;
        enrolledVisionPlans = shoppingPlansArrayVision;
        enrolledDependentLifePlans = shoppingPlansArrayDependentLife;
        if(isUhcRenewals() || isOxfordRenewals()){
            ["std","ltd","suppLife"].forEach(pro =>{
                if (fullProps.decide[pro] && fullProps.decide[pro].set && fullProps.decide[pro].set.length > 0) {
                    fullProps.decide[pro].set.forEach((setPlan) => {
                        if(pro === 'std'){
                            shoppingPlansArrayStd.push(setPlan[1]);
                        } else if(pro === 'ltd') {
                            shoppingPlansArrayLtd.push(setPlan[1]);
                        } else if(pro === 'suppLife') {
                            shoppingPlansArraySuppLife.push(setPlan[1]);
                        }
                    });
                }
            })
            
            enrolledSTDPlans = shoppingPlansArrayStd;
            enrolledLTDPlans = shoppingPlansArrayLtd;
            enrolledSuppLifePlans = shoppingPlansArraySuppLife;
        }
    } else if (pageType === 'renewal-dashboard' || pageType === 'renewalEnrollment') {
        shoppingPlans = shoppingPlansDirect || [];
        enrolledMedicalPlans = fullProps.rules.enrollment && fullProps.rules.enrollment.enrolledPlans || {}
        enrolledDentalPlans = enrolledDentalPlansDirect || [];
        enrolledLifePlans = enrolledLifePlansDirect || [];
        enrolledVisionPlans = enrolledVisionPlansDirect || [];
        enrolledDependentLifePlans = enrolledDependentLifePlansDirect || [];
        if(isUhcRenewals() || isOxfordRenewals()){
            enrolledSTDPlans = enrolledSTDPlansDir;
            enrolledLTDPlans = enrolledLTDPlansDir;
            enrolledSuppLifePlans = enrolledSuppLifePlansDir;
        }
    } else if (pageType === 'renewalFinalizeCase') {
        shoppingPlans = fullProps.renewalEnrollment.enrolledPlans || []
        enrolledMedicalPlans = fullProps.renewalEnrollment.enrolledPlansMed || []
        enrolledDentalPlans = fullProps.renewalEnrollment.enrolledDentalPlans || []
        enrolledVisionPlans = fullProps.renewalEnrollment.enrolledVisionPlans || []
        enrolledLifePlans = fullProps.renewalEnrollment.enrolledLifePlans || []
        enrolledDependentLifePlans = fullProps.renewalEnrollment.enrolledDependentLifePlans || [];
        if(isUhcRenewals() || isOxfordRenewals()){
            enrolledSTDPlans = fullProps.renewalEnrollment.enrolledSTDPlans || [];
            enrolledLTDPlans = fullProps.renewalEnrollment.enrolledLTDPlans || [];
            enrolledSuppLifePlans = fullProps.renewalEnrollment.enrolledSuppLifePlans || [];
        }
    }

    //Adding accumulator fields at plan level which goes inside med plans under rules.
    if (contractOptList && contractOptList.length > 0 && shoppingPlans && shoppingPlans.length > 0) {
        let renewalPlanList = shoppingPlans.filter(plan => plan.renewalPlan && plan.renewalPlan === true);
        let newPlanList = shoppingPlans.filter(plan => !plan.renewalPlan);
        renewalPlanList.forEach((plan) => {
            const matchedPlansWithContractList = contractOptList.find((contract) => contract.externalMarketingName === plan.codeDisplay)
            if (matchedPlansWithContractList) {
                plan.coverageOptionID = matchedPlansWithContractList.coverageOptionID;
                plan.planOptionID = matchedPlansWithContractList.planOptionID;
                plan.contractOptionID = matchedPlansWithContractList.contractOptionID;
                plan.contractOptID = matchedPlansWithContractList.contractOptID;
                plan.planYearMonth = matchedPlansWithContractList.planYearMonth;
                plan.medicalBenefitPlanOption = plan.medicalBenefitPlanOption ? plan.medicalBenefitPlanOption : matchedPlansWithContractList.planYearMonth === '00' || matchedPlansWithContractList.planYearMonth === '01' ? 'calenderYear' : 'policyYear';
                plan.disableAccumulator = true;
            }
        })
        let accumulatorChoice = '';
        contractOptList.forEach((contract) => {
            if (accumulatorChoice === '') {
                accumulatorChoice = contract.planYearMonth === '00' || contract.planYearMonth === '01' ? 'calenderYear' : 'policyYear';
            } else {
                const currentChoice = contract.planYearMonth === '00' || contract.planYearMonth === '01' ? 'calenderYear' : 'policyYear';
                if (accumulatorChoice !== currentChoice) {
                    accumulatorChoice = 'choose';
                }
            }
        })
        if (accumulatorChoice === 'policyYear' || accumulatorChoice === 'calenderYear') {
            newPlanList.forEach(plan => {
                if (!plan.medicalBenefitPlanOption) {
                    plan.medicalBenefitPlanOption = accumulatorChoice;
                    plan.disableAccumulator = true;
                }

            })
        }
        shoppingPlans = renewalPlanList.concat(newPlanList);
    }

    let plans = {
        enrolledMedicalPlans, shoppingPlans, enrolledDentalPlans, enrolledLifePlans, enrolledVisionPlans, enrolledDependentLifePlans
    };
    if(isUhcRenewals() || isOxfordRenewals()){
        plans.enrolledLTDPlans = enrolledLTDPlans;
        plans.enrolledSTDPlans = enrolledSTDPlans;
        plans.enrolledSuppLifePlans = enrolledSuppLifePlans;
    }
    dispatch(createHistory('Get Enrolled Plans - Renewal', pageType, 'Success', plans, true, 'RL'));
    sendLog({
        action: 'GET_ENROLLED_PLANS_RENEWALS',
    });
    dispatch({
        type: types.GET_ENROLLED_PLANS_RENEWALS,
        payload: plans,
        isUhcRenewals: isUhcRenewals(),
    });
    dispatch(setPlanInstallPlatforms(fullProps.decide.productTypeList || []));
};

export const updateEnrolledPlansRenewals = (query) => {
    const data = {
        query,
    };
    return (dispatch) => {
        sendLog({
            action: 'UPDATE_ENROLLED_PLANS_RENEWALS',
            query,
        });
        dispatch({
            type: types.UPDATE_ENROLLED_PLANS_RENEWALS,
            payload: data,
        });
    };
};

export const getRateAdjustmentRules = (state, groupSize, effectiveDate) => (dispatch) => new Promise((resolve, reject) => {
    dispatch({ type: types.REQUEST_RATE_ADJUSTMENT_RULES });
    const req = `${apiRoutes.quickQuotes}/rate-adjustment-rules?state=${state}&groupSize=${groupSize}&effectiveDate=${effectiveDate}`;
    axios.get(req)
        .then((res) => {
            const rules = res.data.products;
            dispatch({
                type: types.RECEIVE_RATE_ADJUSTMENT_RULES,
                rules,
            });
            resolve();
        })
        .catch((err) => {
            console.log('Error', err);
            if (isUhcRenewals()) {
                const reqBody = {
                    state, groupSize
                }
                logError(KEY_NAME, UHC_REN_APIS.RATE_ADJUSTMENT_RULES, 'rulesActions.getRateAdjustmentRules', reqBody, err);
            }
            dispatch({
                type: types.ERROR_RATE_ADJUSTMENT_RULES,
                error: err,
            });
            reject(err);
        });
});

export const autoEnrollInLifePlan = () => (dispatch) => {
    sendLog({
        action: 'AUTO_ENROLL_IN_LIFE',

    });
    dispatch({
        type: types.AUTO_ENROLL_IN_LIFE,

    });
};

export function getDentalPlan(id) {
    return axios.get(`${apiRoutes.mongo}/dentalPlan/${id}`)
        .then((response) => response.data)
        .catch((err) => {
            if (isUhcRenewals()) {
                logError(KEY_NAME, UHC_REN_APIS.DENTAL_PLAN, 'rulesActions.getDentalPlan', { id }, err);
            }
            return err.data
        });
}

export function getVisionPlan(id) {
    return axios.get(`${apiRoutes.mongo}/visionPlan/${id}`)
        .then((response) => response.data)
        .catch((err) => err.data);
}

export function getBasicLifePlan(id) {
    return axios.get(`${apiRoutes.mongo}/basicLifePlan/${id}`)
        .then((response) => response.data)
        .catch((err) => {
            if (isUhcRenewals()) {
                logError(KEY_NAME, UHC_REN_APIS.BASIC_LIFE_PLAN, 'rulesActions.getBasicLifePlan', { id }, err);
            }
            return err.data
        });
}

export function updateEnrolledMedicalPlans(plans) {
    return (dispatch) => {
        dispatch({
            type: 'UPDATE_ENROLLED_MEDICAL_PLANS',
            plans,
        });
    };
}

export function updateEnrolledDentalPlans(plans) {
    return (dispatch) => {
        dispatch({
            type: 'UPDATE_ENROLLED_DENTAL_PLANS',
            plans,
        });
    };
}

export function updateEnrolledVisionPlans(plans) {
    return (dispatch) => {
        dispatch({
            type: 'UPDATE_ENROLLED_VISION_PLANS',
            plans,
        });
    };
}
export function updateEnrolledStdPlans(plans) {
    return (dispatch) => {
        dispatch({
            type: 'UPDATE_ENROLLED_STD_PLANS',
            plans,
        });
    };
}
export function updateEnrolledLtdPlans(plans) {
    return (dispatch) => {
        dispatch({
            type: 'UPDATE_ENROLLED_LTD_PLANS',
            plans,
        });
    };
}

export function updateEnrolledBasicLifePlans(plans) {
    return (dispatch) => {
        dispatch({
            type: 'UPDATE_ENROLLED_BASIC_LIFE_PLANS',
            plans,
        });
    };
}
