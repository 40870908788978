import * as types from './actionTypes';
import { axios } from '../utils/api/axiosConfig';
import sendLog from './logActions';
import { getMarketType } from '../utils/businessLogic/platformUtils';

const apiRoutes = require('../utils/api/apiRouteConfig');

const lastModifiedPage = (val, payload = '') => (dispatch, getState) => {
    const { store } = require('../index');
    const fullProps = store?.getState() || getState();
    const req = {};
    //req.primeCustomerNumber = (fullProps && fullProps.review && fullProps.review.val && fullProps.review.val.primeCustomerNumber) ? fullProps.review.val.primeCustomerNumber : '';
    req.memberGroupId = (fullProps && fullProps.review && fullProps.review.val && fullProps.review.val.customerNumber) ? fullProps.review.val.customerNumber : fullProps.review?.renewalCaseData?.memberGroupId ? fullProps.review.renewalCaseData.memberGroupId : '';
    req.quoteTrackingNumber = fullProps.caseTracking.currentQuoteId;
    req.lastModified = val;
    req.payload = payload;
    req.marketType = getMarketType();
    req.priorYearDataSelected = fullProps.renewals.priorYearDataSelected ? fullProps.renewals.priorYearDataSelected: 'N';
    if(req.marketType === 'UHC' && req.priorYearDataSelected === 'Y') {
        req.effectiveDate = fullProps?.renewals?.renewalCaseData?.effectiveDate
    }
    const pageReq = {
        req,
    };
    sendLog({
        action: 'REQUEST_LAST_MODIFIED',
        ...pageReq,
    });

    dispatch({
        type: types.REQUEST_LAST_MODIFIED,
        payload: pageReq,
    });
    axios.post(`${apiRoutes.quickQuotes}/modifiedPageUpdate`, req)
        .then((res) => {
            dispatch({
                type: types.RECEIVE_LAST_MODIFIED,
                payload: res.data,
                pageReq,
                source: 'modifiedPage',
            });
        })
        .catch((err) => {
            dispatch({
                type: types.ERR_LAST_MODIFIED,
                payload: err,
            });
        });
};

export default lastModifiedPage;