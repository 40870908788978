import PropTypes from 'prop-types';
import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Icon, Checkbox } from 'semantic-ui-react';
import { auditUserAction } from '../../../../actions/actionHelpers/createHistoryHelper';
import OfficeIcon from '../../../../images/icon-office.svg';
import { 
    CASE_PROGRESS, 
    inSubmittedStatus, 
    getNBCaseProgress,
    getCaseIndexForNB, 
} from '../../../../reducers/products/helpers/companyProfileConsts';
import showFranchiseCode from '../../../../utils/businessLogic/showFranchiseCode';
import { findDaysTillEffDate, toMMDDYYYYFormatSlash } from '../../../../utils/formatters/dates';
import { getPrimaryLocation } from '../../../activities/FullShopping/Census/helpers/checkPrimaryLocation';
import { identifyPlatform } from '../../../activities/Renewal/Review/reviewUtils';
import { toggleInternalCase,paperCaseHistoryLog } from '../../../../actions/overallActions';
import { saveOrUpdateCaseTracking } from '../../../../actions/caseTrackAction';
import './reviewCards.css';
import { createHistory } from '../../../../actions/historyAction';
import { isUhcRenewals, isMmr } from '../../../../utils/businessLogic/platformUtils';

const dateFormater = (date) => {
    if (date) {
        date = date.split("-")
        return `${date[1]}/${date[2]}/${date[0]}`
    }
    else {
        return null;
    }
}

const ReviewCardCompanyInfoTop = ({
    history, pathname,
}) => {
    const dispatch = useDispatch();
     const isUhcRenewalFlow = isUhcRenewals();
    const fullProps = useSelector((state) => state);
    const renewalsFlow = useSelector((state) => state.overall.isRenewals || state.overall.source === 'renewal');
    const renewalData = useSelector((state) => state.renewals.renewalData || {});
    const products = useSelector((state) => (renewalsFlow
        ? state.companyProfile.userCardsSelectedProducts
        : Object.keys(state.companyProfile.selectedProducts).length > 0 ? state.companyProfile.selectedProducts
        : state.specialtyCriteria.viewSpecialty),
    shallowEqual);
    const locations = useSelector((state) => (renewalsFlow === true && !isUhcRenewalFlow ? '' : state.companyProfile.locations), shallowEqual);
    const companyName = useSelector((state) => state.companyProfile.companyName, shallowEqual);
    const store = useSelector((state) => state);
    const groupSize = useSelector((state) => state.overall.quickQuoteRequest.groupSize, shallowEqual);
    const qqSicCode = useSelector((state) => state.specialtyCriteria.sicCode, shallowEqual);
    const qqEffectiveDate = useSelector((state) => state.overall.quickQuoteRequest.effectiveDate, shallowEqual);
    const enrolledEligibleEmployees = useSelector((state) => isUhcRenewalFlow ? state?.enrollment?.application?.employer?.participation?.numEligibleEmployees : state?.enrollment?.application?.eligibility?.participation?.numEligibleEmployees);
    const sicCode = useSelector((state) => (state.companyProfile.sicCode ? state.companyProfile.sicCode : ''), shallowEqual);
    const franchiseCode = useSelector((state) => state.companyProfile.franchiseCode, shallowEqual);
    const effectiveDate = useSelector((state) => state.companyProfile.effectiveDate);
    const isCopyQuote = useSelector((state) => state.companyProfile.date);
    const dateObj = useSelector((state) => state.companyProfile.date, shallowEqual);
    const memberGroupIdNB = useSelector((state) => state.enrollment.application.installation.memGroupID, shallowEqual);
    const memberGroupIdRenewals = useSelector((state) => state.companyProfile?.memberGroupId || '-');
    const memberGroupId = renewalsFlow === true ? memberGroupIdRenewals : memberGroupIdNB;
    const isMigratedOrCirrusCase = useSelector((state) => state.renewals?.renewalCaseData?.samxInternalGrp === 'Y' || state.renewals?.rlCaseData?.platform === 'CIRRUS');
    const caseProgressofNB = useSelector((state) => state.companyProfile.caseProgress);
    const updateRLcaseProgress = useSelector((state) => state.renewals.rlCaseProgress);
    const niceCaseAcceptedStage = useSelector((state) => state.finalizeCase?.finalizeCaseSummary?.summaryData?.find((data) => data.caseStage === 'NCA') || null);
    const caseIdRenewals = useSelector((state) => (renewalsFlow ? isMmr()? store.caseTracking && store.caseTracking.caseTrack && store.caseTracking.caseTrack[0] && store.caseTracking.caseTrack[0].caseId || '' : state.renewals.renewalCaseData ? state.renewals.renewalCaseData.caseId ? state.renewals.renewalCaseData.caseId : null : null : null));
    const caseIdFullShopping = useSelector((state) => state.caseTracking.caseId);
    const permissions = useSelector((state) => state.permissions.fullShoppingEnrollment);
    const roleName = useSelector((state) => state.renewals.roleName);
    const installationPlatform = useSelector((state) => state.caseTracking.platform);
    // Used as platform indication only when one enrollment or finalize case when available
    const breadcrumbAccess = useSelector((state) => state.quotes.breadCrumbAccess);
    const isPaperCase = useSelector((state) => state.overall.isPaperCase);
    const finalizeCaseSummaryData = useSelector((state) => state.finalizeCase.finalizeCaseSummary);
    const caseAccepted = useSelector((state) => state.companyProfile.caseProgress === 'Case Accepted');
    const overallPlatform = useSelector((state) => state.overall.platform);
    const isInternalUser = useSelector((state) => state.user.userType) === 'Internal';
    const receivedPlatformFromIPPS = useSelector((state) => state.caseTracking.receivedPlatformFromIPPS);
    const pendingAcceptanceReason = useSelector((state) => state.companyProfile.pendingAcceptanceReason || '');
    const stepperAccess = useSelector((state) => state.renewals.stepperAccess);
    const sicCodeReview = useSelector((state) => (state.review?.renewalData?.memberGroup?.industryCode ? state.review.renewalData.memberGroup.industryCode : ''));
    const companyProfile = useSelector((state) => state.companyProfile);


    const selectedProducts = [];

    const primaryLocation = renewalsFlow === true ? '' : getPrimaryLocation(locations);
    const renderFranchiseCode = renewalsFlow === true ? '' : showFranchiseCode(primaryLocation);
    const daysLeft = renewalsFlow === true ? (findDaysTillEffDate(effectiveDate)) : null;
    const sign = renewalsFlow === true ? daysLeft && daysLeft < 0 ? 'past' : 'left' : null;

    let renewalsCaseProgress = ((renewalsFlow === true && !isMmr()) ? updateRLcaseProgress : store.caseTracking && store.caseTracking.caseTrack && store.caseTracking.caseTrack[0] && store.caseTracking.caseTrack[0].caseStatus)
    let idx = getCaseIndexForNB(store.caseTracking);
    
    const updateStatusClick = () => {
        history.push(`/enrollment/additional-required-information`, {
            title: 'Additional Required Information',
        });
    };

    let renewalPlan;
    if (store.renewals.globalSearchFlag) {
        renewalPlan = renewalsFlow === true ? store.renewals.renewalSearchCase : null;
    } else {
        renewalPlan = renewalsFlow === true ?store.renewals && store.renewals.renewals && store.renewals.renewals.find(plan => plan.memberGroupId === store.renewals.memberGroupId) : null
    }
    let actualCaseProgress = renewalsFlow === true && renewalPlan ? renewalPlan.caseProgressUI : null

    const caseProgress =
    renewalsFlow === true ?
        renewalsCaseProgress ?
            renewalsCaseProgress : // Renewal flow should use 'renewalsCaseProgress' when available
        actualCaseProgress : // Renewal flow should use 'actualCaseProgress' when 'renewalsCaseProgress' is unavailable
    store.caseTracking && store.caseTracking.caseTrack && store.caseTracking.caseTrack[idx] && store.caseTracking.caseTrack[idx].caseStatus ?
        store.caseTracking.platform && store.caseTracking.platform === "DUAL" && niceCaseAcceptedStage && niceCaseAcceptedStage.stageStatus !== "COMPLETED" && store.caseTracking.caseTrack[idx].caseStatus !== "Enrolling" ?
            "Pending Acceptance" : // DUAL cases with NICE stages incomplete should always display "Pending Acceptance" when past enrollment
        store.caseTracking.caseTrack[idx].caseStatus : // DUAL cases with NICE stages complete should display PRIME status
    caseProgressofNB; // When caseTracking.caseTrack[idx].caseStatus is not available, then companyProfile.caseProgress should be displayed

    const statusSubmitted = inSubmittedStatus(caseProgress);

    let eligibleEmployees;
    if (isUhcRenewalFlow) {
        const storePath = pathname == '/decideDetail' ? store.decide : 
            pathname == '/reviewDetail' ? store.review : 
            pathname == '/renewalShopping' ? store.renewalShopping :
            pathname == '/enrollmentManager/manager' || pathname == '/finalize' || pathname == '/renewal-finalize-case' ? 
            store.renewalEnrollment : {};
            
        let eligibilityCountsEntered = storePath && storePath.eligibilityCountsEntered ? storePath.eligibilityCountsEntered : false;
        
        eligibleEmployees = eligibilityCountsEntered ? 
            storePath.eligibilityCounts && storePath.eligibilityCounts.eligible ? 
                storePath.eligibilityCounts.eligible : 
                    renewalData?.Ibr?.group?.primaryLocation?.totalEligible || '' :
                    renewalData?.Ibr?.group?.primaryLocation?.totalEligible || '';
    } else {
        eligibleEmployees = locations && locations.length > 0 ? getPrimaryLocation(locations).totalEligEmps != '' ? getPrimaryLocation(locations).totalEligEmps : groupSize : '';
    }

    const qqDate = dateFormater(qqEffectiveDate)

    const zipCode = isUhcRenewalFlow
        ? store?.renewals?.renewalCaseData?.zipCode
            ? store.renewals.renewalCaseData.zipCode
            : ""
        : locations && locations.length > 0
            ? getPrimaryLocation(locations).zipData.zipCode
            : "";

    const platform = pathname == '/decideDetail'
    ? store.decide.renewalData
        ? identifyPlatform(store.decide.renewalData)
        : 'CIRRUS'
    : pathname == '/reviewDetail'
        ? store.review.renewalData
            ? identifyPlatform(store.review.renewalData)
            : 'CIRRUS'
        : (pathname == '/enrollmentManager/manager' || pathname == '/finalize' || pathname == '/renewal-finalize-case' )
            ? store.renewalEnrollment.renewalData
                ? identifyPlatform(store.renewalEnrollment.renewalData)
                : 'CIRRUS'
            : pathname == '/renewalShopping'
                ? store.renewalShoppingHelper.renewalData
                    ? identifyPlatform(store.renewalShoppingHelper.renewalData)
                    : 'CIRRUS'
                : 'CIRRUS';

    let productsDisplay = [];


    const uhcPlatformCode = store?.renewals?.renewalCaseData?.uhcPlatformCode || '';
    const custType = store?.renewals?.renewalCaseData?.custType || '';
    const platformBeforeIpps = custType === "NICE" ? "NICE" : custType === "DUAL" ? "DUAL" : uhcPlatformCode !== '' ? ((uhcPlatformCode === 'M' || uhcPlatformCode === 'U') ? 'CIRRUS' : 'PRIME') : '';

    const policyNo = platform !== 'CIRRUS' && renewalsFlow == true ?
        store.renewals.renewalCaseData ?
            store.renewals.renewalCaseData.primePolicyNumber ?
                store.renewals.renewalCaseData.primePolicyNumber[0]
                : 'N/A'
            : 'N/A'
        : 'N/A';
    const customerNo = platform !== 'CIRRUS' && renewalsFlow == true ?
        store.renewals.renewalCaseData ?
            store.renewals.renewalCaseData.primeCustomerNumber ?
                store.renewals.renewalCaseData.primeCustomerNumber
                : 'N/A'
            : 'N/A'
        : 'N/A';

    const mapProduct = (product) => {
        if (product === 'std') {
            return 'STD'
        } else if (product === 'ltd') {
            return 'LTD'
        } else if (product === 'suppLife'){
            return 'SL'
        }
        else if (product === 'life'){
            return 'BL'
        }
        else {
            return product.substring(0, 1).toUpperCase();
        }
    }

    for (const product in products) {
        if (products[product]) {
            selectedProducts.push(product);
        }
    }

    for (let i = 0; i < selectedProducts.length; i++) {
        if (i === selectedProducts.length - 1) {
            productsDisplay += mapProduct(selectedProducts[i]);
        } else {
            productsDisplay += mapProduct(selectedProducts[i]) + '/';
        }
    }
    
    const displayPAReason = overallPlatform === 'PRIME' &&  pathname === '/finalize-case' && !caseAccepted; 
    const state=isUhcRenewalFlow ? (store?.renewals?.renewalCaseData?.situsState ? store.renewals.renewalCaseData.situsState: '') : (locations &&locations.length> 0 ? getPrimaryLocation(locations)?.zipData?.stateCode : '');

    // Only certain roles should display installation platform
    const internalRoles = ['Production Support Admin', 'UHC Virtual Ops', 'OXHP Virtual Ops', 'OXHP Install Rep', 'UHC Install Rep', 'Install Supervisor'];
    let displayInstallationPlatform = false;
    if (internalRoles.includes(roleName)  && (breadcrumbAccess.enrollmanager || breadcrumbAccess.finalizecase)  && overallPlatform === 'PRIME') {
        displayInstallationPlatform = true;
    }

    const internalRolesRenewal = ['Production Support Admin', 'UW Representative', 'OXHP Install Rep', 'UHC Install Rep', 'Install Supervisor', 'Specialty Sales', 'Specialty Sales Consultant'];
    let displayInstallationPlatformRenewal = false;
    if (internalRolesRenewal.includes(roleName) && isUhcRenewalFlow && (stepperAccess.renewaldashboard || stepperAccess.decide || stepperAccess.enrollment || stepperAccess.renewalFinalizeCase || stepperAccess.finalize)) {
        displayInstallationPlatformRenewal = true;
    }

    const showUpdateProgress = overallPlatform === 'CIRRUS' && isInternalUser ;

    let niceGroupId = '';
    let clientDefinedData = state === 'CA' && custType === "DUAL" ? renewalData?.Ibr?.group?.clientDefinedData?.data || [] : [];
    for (let i = 0; i < clientDefinedData.length; i++) {
                if (clientDefinedData[i].key === "NICECustomerNbr")
                   niceGroupId = clientDefinedData[i].value;
            }

    const fullShoppingInfoCard = (
        <>
            <span className="reviewCardItem borderRight marginRightMedium">
                {' '}
                Zip Code:
                {' '}
                <strong>
                    {' '}
                    {zipCode}
                    {' '}
                </strong>
            </span>
            <span className="reviewCardItem borderRight marginRightMedium">
                {' '}
                State:
                {' '}
                <strong>
                    {' '}
                    {state}
                    {' '}
                </strong>
            </span>
            <span className="reviewCardItem borderRight marginRightMedium">
                {' '}
                # of Eligible Employees:
                {' '}
                <strong>
                    {' '}
                    {enrolledEligibleEmployees || eligibleEmployees}
                    {' '}
                </strong>
            </span>
            <span className="reviewCardItem borderRight marginRightMedium">
                {' '}
                Products:
                {' '}
                <strong>
                    {' '}
                    {productsDisplay || 'M'}
                    {' '}
                </strong>
            </span>
            <span className="reviewCardItem borderRight marginRightMedium">
                {' '}
                Effective Date:
                {' '}
                <strong>
                    {' '}
                    {(!effectiveDate || effectiveDate === '') ? qqDate : effectiveDate && effectiveDate.includes('-')? toMMDDYYYYFormatSlash(effectiveDate): effectiveDate}
                    {' '}
                </strong>
            </span>
            <span className="reviewCardItem borderRight marginRightMedium">
                {' '}
                SIC Code:
                {' '}
                <strong>
                    {' '}
                    {sicCode != '' ? sicCode : qqSicCode ? qqSicCode : ''}
                    {' '}
                </strong>
            </span>
            <span className="reviewCardItem borderRight marginRightMedium">
                {' '}
            Case ID:
                {' '}
                <strong>
                    {' '}
                    {caseIdFullShopping}
                    {' '}
                </strong>
            </span>
            {renderFranchiseCode && (
                <span className="reviewCardItem borderRight marginRightMedium">
                    {' '}
                    Franchise Code:
                    {' '}
                    <strong>
                        {' '}
                        {franchiseCode}
                        {' '}
                    </strong>
                </span>
            )}
            {memberGroupId && (
                <span className="reviewCardItem borderRight marginRightMedium">
                    {' '}
                    {installationPlatform === "PRIME" ? "Customer Number:" : "Member Group ID:"}
                    {' '}
                    <strong>
                        {' '}
                        {memberGroupId}
                        {' '}
                    </strong>
                </span>
            )}
            {displayInstallationPlatform &&
                <span className="reviewCardItem borderRight marginRightMedium">
                    {' '}
                    Installation Platform:
                    {' '}
                    <strong>
                        {' '}
                        {installationPlatform}
                        {' '}
                    </strong>
                </span>
            }
        </>
    );

    const renewalsInfoCard = (
        <>
            <span className="reviewCardItem borderRight marginRightMedium">
                {' '}
                Case ID:
                {' '}
                <strong>
                    {' '}
                    {caseIdRenewals}
                    {' '}
                </strong>
            </span>

            <span className="reviewCardItem borderRight marginRightMedium">
                {' '}
                Products:
                {' '}
                <strong>
                    {' '}
                    {productsDisplay || 'M'}
                    {' '}
                </strong>
            </span>

            <span className="reviewCardItem borderRight marginRightMedium">
                {' '}
                Renewal Effective Date:
                {' '}
                <strong>
                    {' '}
                    {effectiveDate}
                    {' '}
                </strong>
            </span>

            {isUhcRenewalFlow && <span className="reviewCardItem borderRight marginRightMedium">
                {' '}
                Zip Code:
                {' '}
                <strong>
                    {' '}
                    {zipCode}
                    {' '}
                </strong>
            </span>}

            {isUhcRenewalFlow && <span className="reviewCardItem borderRight marginRightMedium">
                {' '}
                State:
                {' '}
                <strong>
                    {' '}
                    {state}
                    {' '}
                </strong>
            </span>}

            {isUhcRenewalFlow && <span className="reviewCardItem borderRight marginRightMedium">
                {' '}
                # of Eligible Employees:
                {' '}
                <strong>
                    {' '}
                    {eligibleEmployees}
                    {' '}
                </strong>
            </span>}

            <span className="reviewCardItem borderRight marginRightMedium">
                {' '}
                SIC Code:
                {' '}
                <strong>
                    {' '}
                    {sicCode !== '' ? sicCode : sicCodeReview}
                    {' '}
                </strong>
            </span>

            {((isUhcRenewalFlow && isMigratedOrCirrusCase) || (platform !== 'PRIME' && renewalsFlow === true)) && <span className="reviewCardItem borderRight marginRightMedium">
                {' '}
                Member Group ID:
                {' '}
                <strong>
                    {' '}
                    {memberGroupId}
                    {' '}
                </strong>
            </span>}

            {platform !== 'CIRRUS' && renewalsFlow === true
                ? (
                    <span className="reviewCardItem borderRight marginRightMedium">
                        Customer No.:
                        <strong>
                            {' '}
                            {customerNo}
                            {' '}
                        </strong>
                    </span>
                )
                : null
            }
            {installationPlatform === 'DUAL' && renewalsFlow === true && companyProfile?.isNiceCirrusIndicator===true && companyProfile?.cirrusMemberGroupId
                ? (
                    <span className="reviewCardItem borderRight marginRightMedium">
                       Cirrus Member Group ID:
                        <strong>
                            {' '}
                            {companyProfile?.cirrusMemberGroupId}
                            {' '}
                        </strong>
                    </span>
                )
                : null
            }

            {platform !== 'CIRRUS' && renewalsFlow === true
                ? (
                    <span className="reviewCardItem borderRight marginRightMedium">
                        Policy No.:
                        <strong>
                            {' '}
                            {policyNo}
                            {' '}
                        </strong>
                    </span>
                )
                : null
            }

            {isUhcRenewalFlow && custType === 'DUAL' && state === 'CA'
                ? (
                    <span className="reviewCardItem borderRight marginRightMedium">
                        Nice Group Id:
                        <strong>
                            {' '}
                            {niceGroupId}
                            {' '}
                        </strong>
                    </span>
                )
                : null
            }

            <span className={`reviewCardItem ${displayInstallationPlatformRenewal ? 'borderRight' :''} marginRightMedium`}>
                {' '}
                <strong>
                    {daysLeft < 0 ? -daysLeft : daysLeft}
                    {' '}
                    Days
                    {' '}
                    {sign}
                    {' '}
                </strong>
            </span>

            {(isUhcRenewals() && displayInstallationPlatformRenewal) &&
                <span className="reviewCardItem  marginRightSmall">
                    {' '}
                    Installation Platform:
                    {' '}
                    <strong>
                        {' '}
                        {isMmr() ? 'CIRRUS' : (receivedPlatformFromIPPS ? installationPlatform : platformBeforeIpps)}
                        {' '}
                    </strong>
                </span>
            }
        </>
    );

    const handleEditGroupInfoClick = () => {
        if (!renewalsFlow) {
            dispatch(auditUserAction('Page Header', `Edit Group Info`, `Button Clicked`));
        }

        history.push('/full');
    };

    return (
        <div className="width100Percent">
            <div className="justifySpaceBetween flexRow alignCenter">
                <span className="flexRow flexFlowRowWrap">
                    <h3 className="blueFont noMarginTop marginLeftSmall marginRightLarge marginBottomExtraSmall">
                        <img src={OfficeIcon} className="smallIcon marginRightMedium noPaddingTop" alt="Icon"/>
                        {companyName || 'Group Information'}
                    </h3>

                    {renewalsFlow !== true
                        && (
                            <>
                                {!statusSubmitted && <h5 className="noMarginTop actionFont paddingTopExtraSmall marginBottomExtraSmall" onClick={() => handleEditGroupInfoClick()}>
                                    <Icon className="actionFont" name="edit" />
                                Edit Group Info
                                </h5>}
                                {internalRoles.includes(roleName) && 
                                    <Checkbox 
                                        className="marginTopExtraSmall marginLeftMedium" 
                                        checked={isPaperCase}
                                        disabled={statusSubmitted}
                                        label='Paper Case' 
                                        onChange={() => {
                                            dispatch(toggleInternalCase(!isPaperCase));
                                            dispatch(paperCaseHistoryLog(!isPaperCase));
                                            if (caseIdFullShopping && caseIdFullShopping !== '') {
                                                dispatch(saveOrUpdateCaseTracking(fullProps.caseTracking.caseTrack[getCaseIndexForNB(fullProps.caseTracking)].lastPage, getNBCaseProgress(fullProps)));
                                            }
                                        }}
                                    />
                                }
                            </>
                        )}
                </span>
                <div>
                    <span className={'caseStatus flexRow alignCenter marginRightSmall ' + (displayPAReason ? 'marginTopSmall noMarginBottom' : 'marginBottomSmall')}>
                        Case Progress:
                        {' '}
                        <strong className="marginLeftExtraSmall">
                            {' '}
                            {caseProgress && caseProgress.toLowerCase() === "inprogress" ? "In Progress" : caseProgress}
                            {' '}
                        </strong>
                        {renewalsFlow !== true && statusSubmitted && permissions.addUpdateAPend && showUpdateProgress && ![CASE_PROGRESS.ENROLLING,CASE_PROGRESS.QUOTING,CASE_PROGRESS.PENDING].includes(caseProgress)  // Should not see Update Progress button for Pending Submission, Enrolling, Quoting
                            && (
                                <h5 className="noMarginTop actionFont paddingTopExtraSmall marginLeftMedium" onClick={() => updateStatusClick()}>
                                    <Icon className="actionFont" name="edit" />
                                Update Progress
                                </h5>
                            )}
                    </span>
                    {(displayPAReason && pendingAcceptanceReason !== "") && <span className="caseStatus marginBottomSmall flexRow alignCenter marginRightSmall">
                        Pending Acceptance Reason:
                        {' '}
                        <strong className="marginLeftExtraSmall">
                            {' '}
                            {pendingAcceptanceReason}
                            {' '}
                        </strong>
                    </span>}
                </div>
            </div>

            <div className="justifyStart flexRow reviewCardInfoRow flexFlowRowWrap">
                {renewalsFlow ? renewalsInfoCard : fullShoppingInfoCard}
            </div>
        </div>
    );
};

ReviewCardCompanyInfoTop.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
};

export default ReviewCardCompanyInfoTop;
