import { workSheets } from '../../../uhcTemplate/templateParser/constants';
import {parseWorkbookMMR} from '../../../uhcTemplate/templateParser/uploadParser';
import { getDecideDetailQuery, getSelectedPlansFromIbrSets, saveReviewDetailQuery } from '../../activities/Renewal/RenewalsCommonComponents/saveDetails';
const apiRoutes = require('../../../utils/api/apiRouteConfig');
import { saveOrUpdateRLCaseTracking, resetRLCaseTrack } from '../../../actions/caseTrackAction';
import { logError } from '../../../utils/errorLogging/logError';
import { KEY_NAME, UHC_REN_APIS } from '../../../utils/errorLogging/logKeys';
import { getLatestUpdatedCaseData, updateStepperAccess } from '../../../actions/renewalActions';
import { doMmrReviewRateCall } from '../../../actions/mmrActions';
import { getRateAdjustmentRules } from '../../../actions/rulesActions';
import { getZipObjUhcRenewals } from '../../../utils/Renewal/getZipObjUhc';
import { decideRenewalDetails } from '../../../actions/decideActions';
import { updatePrevious } from '../../../actions/overallActions';
import { filterReconciledCensus } from '../../activities/Renewal/Review/reviewComponents/FilterIBR';
import axios from 'axios';
import { createHistory } from '../../../actions/historyAction';
import { finalRates, rateAdjustments } from '../../activities/FullShopping/QuoteReview/Subcomponents/RateAdjustment/rateAdjustmentConsts';
import { calculateProductTotalMonthlyPremium } from '../../../actions/rateAdjustmentActions';
import { setSpecialtyCriteria } from '../../../actions/quickQuotesActions';
import { adjustRenewalsRatesAndPremiumReview } from '../../../actions/reviewActions';

const SHEET_NAME = 'MMR template'

export function startUploadProcessMMR(wkbk, props) {

    if ((checkIfValidWorkSheet(wkbk)?.cirrusIdValid && checkIfValidWorkSheet(wkbk)?.effectiveDateValid) === true) {
        const parsingHandler = mmrParsingHandler(wkbk, props);
        return parsingHandler
    } else {
        let errorTracker = {};
        errorTracker.invalidWorkbook = true;
        setErrorModal(props, errorTracker);
        return null;
    }
}

/** Check if worksheet uploaded is valid for UHC market */
export const checkIfValidWorkSheet = (wkbk) => {
    // wkbk.eachSheet(function(worksheet, sheetId) {
    //     console.log('aaa  ', worksheet, sheetId);
    //   });

    const summaryWorkBook = wkbk.getWorksheet(SHEET_NAME);
    if (summaryWorkBook === undefined || summaryWorkBook === null) {
        return {
            cirrusIdValid: false,
            effectiveDateValid: false,
            error: new Error('Invalid Workbook')
        }
    }else{
        return {
            cirrusIdValid: true,
            effectiveDateValid: true,
            error: null
        }
    }
}

/* Function to check if the excel uploaded is correct or not.*/
export const checkIfCirrusIdValid = (mmrRecord) => {
    const cirrusIDValue = mmrRecord?.cirrusId;
    const effectiveDateValue = mmrRecord?.renewalDate;
    if (cirrusIDValue === '' || cirrusIDValue === null || cirrusIDValue === undefined) {
        return {
            cirrusIdValid: false,
            error: new Error('Invalid Cirrus Id')
        }
    }
    if (effectiveDateValue === '' || effectiveDateValue === null || effectiveDateValue === undefined) {
        return {
            effectiveDateValid: false,
            error: new Error('Invalid Effective Date')
        }
    }
    return {
        cirrusIdValid: true,
        effectiveDateValid: true,
        error: null
    }
}

/** Parsing on the Summary Page */
const mmrParsingHandler = (wkbk, props) => {

    const { parser, isMMRDataFilled } = getPaserAndEnrollmentDataFilled(wkbk);

    console.log('is MMR data filled', isMMRDataFilled);

    //setDimmer(props, true, "Validating Data");

    const mmrInfo = parser.parseWorkSheet(SHEET_NAME);

    if (mmrInfo.metaData.isError === true) {
        let errorsList = [];
        setErrorList(errorsList, "MMR Info", [...mmrInfo.metaData.errors]);
        setErrors(props, errorsList);
        //setDimmer(props, false, "");
        return;
    }
    parser.clearMemory();
    return mmrInfo;
}

const getPaserAndEnrollmentDataFilled = (wkbk) => {
    const parser = parseWorkbookMMR(wkbk);
    return {
        parser,
        isMMRDataFilled: parser.isWorksheetFilled(SHEET_NAME)
    }
}

const setDimmer = (props, loading = false, message = '') => {
    props.setImportTemplateLoader(loading, message);
}

const setErrorList = (errorList, heading, errors = []) => {
    errorList.push({
        heading,
        errors
    })
}

const setErrors = (props, errorsList, apiValidationError = false) => {
    let errorTracker = {};
    if (apiValidationError) {
        errorTracker.apiValidationError = true;
    } else {
        errorTracker.validationError = true;
    }
    errorTracker.errorList = errorsList;
    setErrorModal(props, errorTracker);
    return;
}

const setErrorModal = (props, errorTracker) => {
    props.setUploadErrorTracker(errorTracker);
    props.setUploadErrorModal(true);
    return;
}

export const fetchRenewalDataRecord = async (memberGroupId, effectiveDate) => {
    try{
        let resp = await axios.get(`${apiRoutes.quickQuotes}/renewalDataMMR?memberGroupId=${memberGroupId}&effectiveDate=${moment(effectiveDate).format('YYYY-MM-DD')}`)
        return resp.data;
    }catch(err){
        console.log('err : ', err)
        logError(KEY_NAME, UHC_REN_APIS.RENEWAL_DATA_MMR, 'UploadTemplateController.getIbrData', {memberGroupId}, err);
        throw err;
    }
}

export const renewPlansSelectedHandler = async (dispatch) =>{
    getLatestUpdatedCaseData();
    const renewalUpdateRoute = '/uhcRenewal/renewalUpdateUhc/';
    const { store } = require('../../../index');
    const fullProps = store.getState();

    //code for Rate adjustment start
    let memberGroupId = fullProps.review && fullProps.review.renewalCaseData && fullProps.review.renewalCaseData.memberGroupId ? fullProps.review.renewalCaseData.memberGroupId : null
    let requireRecord = fullProps.renewals?.automateMMR?.payload?.data?.find(rec => rec.cirrusId && rec.cirrusId.toString() === memberGroupId);
    if (requireRecord) {
        requireRecord.dentEmpOvrRtRen && requireRecord.dentEmpOvrRtRen !== '' ? finalRateOnBlur('ee', requireRecord.dentEmpOvrRtRen, 'dental', false, dispatch) : '';
        requireRecord.dentSpoOvrRtRen && requireRecord.dentSpoOvrRtRen !== '' ? finalRateOnBlur('es', requireRecord.dentSpoOvrRtRen, 'dental', false, dispatch) : '';
        requireRecord.dentChdOvrRtRen && requireRecord.dentChdOvrRtRen !== '' ? finalRateOnBlur('ec', requireRecord.dentChdOvrRtRen, 'dental', false, dispatch) : '';
        requireRecord.dentFamOvrRtRen && requireRecord.dentFamOvrRtRen !== '' ? finalRateOnBlur('ef', requireRecord.dentFamOvrRtRen, 'dental', false, dispatch) : '';

        requireRecord.ddentEmpOvrRtRen && requireRecord.ddentEmpOvrRtRen !== '' ? finalRateOnBlur('ee', requireRecord.ddentEmpOvrRtRen, 'dental', true, dispatch) : '';
        requireRecord.ddentSpoOvrRtRen && requireRecord.ddentSpoOvrRtRen !== '' ? finalRateOnBlur('es', requireRecord.ddentSpoOvrRtRen, 'dental', true, dispatch) : '';
        requireRecord.ddentChdOvrRtRen && requireRecord.ddentChdOvrRtRen !== '' ? finalRateOnBlur('ec', requireRecord.ddentChdOvrRtRen, 'dental', true, dispatch) : '';
        requireRecord.ddentChFamOvrRtRen && requireRecord.ddentChFamOvrRtRen !== '' ? finalRateOnBlur('ef', requireRecord.ddentChFamOvrRtRen, 'dental', true, dispatch) : '';

        requireRecord.visionEmpOvrRtRen && requireRecord.visionEmpOvrRtRen !== '' ? finalRateOnBlur('ee', requireRecord.visionEmpOvrRtRen, 'vision', false, dispatch) : '';
        requireRecord.visionSpoOvrRtRen && requireRecord.visionSpoOvrRtRen !== '' ? finalRateOnBlur('es', requireRecord.visionSpoOvrRtRen, 'vision', false, dispatch) : '';
        requireRecord.visionChdOvrRtRen && requireRecord.visionChdOvrRtRen !== '' ? finalRateOnBlur('ec', requireRecord.visionChdOvrRtRen, 'vision', false, dispatch) : '';
        requireRecord.visionFamOvrRtRen && requireRecord.visionFamOvrRtRen !== '' ? finalRateOnBlur('ef', requireRecord.visionFamOvrRtRen, 'vision', false, dispatch) : '';

        requireRecord.ovrRenewalLifeRate && requireRecord.ovrRenewalLifeRate !== '' ? finalRateOnBlur('basicLife', requireRecord.ovrRenewalLifeRate, 'life', false, dispatch) : '';
    }
    //code for Rate adjustment end

    const query = saveReviewDetailQuery(fullProps);
  
    doMmrReviewRateCall()

    if (fullProps.review && fullProps.review.renewalData && fullProps.review.renewalData.members) {
        const coverageCounts = filterReconciledCensus(fullProps.review);
        dispatch(setSpecialtyCriteria({
            ...fullProps.specialtyCriteria,
            dentalCensusSummary: coverageCounts.dentalCensus,
            visionCensusSummary: coverageCounts.visionCensus,
            lifeCensusSummary: coverageCounts.lifeCensus,
            dependentLifeCensusSummary: coverageCounts.dependentLifeCensus,
            platform: fullProps.overall.appConfigDetails.marketType.toUpperCase(),
        }));
    }

    axios.put(`${apiRoutes.quickQuotes}${renewalUpdateRoute}`, query)
        .then((res) => {

            if (res) {
                getRateAdjustmentRules(fullProps.review.renewalCaseData.situsState, fullProps.review.renewalData.memberGroup.locations.employeeCounts[0].count, fullProps.review.renewalCaseData.effectiveDate);
                const zipObj = getZipObjUhcRenewals(fullProps.review.renewalCaseData) 
                let employeeCounts = fullProps.review.renewalData.memberGroup.locations.employeeCounts[0].count != 0 ? fullProps.review.renewalData.memberGroup.locations.employeeCounts[0].count : fullProps.review.renewalData.memberGroup.locations.employeeCounts[1].count
                //getEnrollmentRules('PRIME', zipObj, fullProps.review.renewalCaseData.effectiveDate, employeeCounts);
                //dispatch(decideRenewalDetails(getDecideDetailQuery(fullProps)));
                createHistory('Renew Selected Plans::Footer', 'Review Page', 'Success', query, res);
            }
        })
        .catch((err) => {
            logError(KEY_NAME, UHC_REN_APIS.RENEWAL_UPDATE, 'UploadTemplateControllerMMR.renewPlansSelectedHandler', query, err);
            createHistory('Renew Selected Plans::Footer', 'Review Page', 'Error', query, err);
        });

    updateStepperAccess('reviewDetail');
    updatePrevious('/reviewDetail');
}

const setProductRate = (productType, plan) => {
    if (productType === 'life') {
        return plan.quote.basicLifeRateVol;
    } else if (productType === 'std') {
        return plan.quote.rateOver10WeeklyBenefit;
    } else {
        return plan.quote.timesRatesPer100ofMCP;
    }
}

const finalRateOnBlur = (tier, adjustedRate, productType, isDualDental = false, dispatch) => {
    const { store } = require('../../../index');
    const fullProps = store.getState();
    let requiredIndex = isDualDental ? 1 : 0;
    const plan = fullProps.review[productType]?.selectedPlans[requiredIndex];

    if(plan !== undefined && plan !== null){
        let finalRatesObj = finalRates(productType, plan);   
        if(finalRatesObj[tier] !== adjustedRate){
            finalRatesObj[tier] = adjustedRate;
            if(productType === 'life'){
                finalRatesObj.total = parseFloat(adjustedRate) + parseFloat(plan.quote.aDnDLifeRateVol);
            }
            let rateAdjustmentsObj = rateAdjustments(productType, plan);
            calculateRateAdjustmentFromFinalRate(tier, finalRatesObj, rateAdjustmentsObj, plan, productType, setAdjustedRatesAndPremium(productType, finalRatesObj, rateAdjustmentsObj, plan, dispatch) );
        }
    }     
};

const setAdjustedRatesAndPremium = (productType, finalRatesObj, rateAdjustmentsObj, plan, dispatch) => {
    
    const premium = plan.member?.length === 0 ? 0.00 : dispatch(calculateProductTotalMonthlyPremium(productType, plan, finalRatesObj, true));

    //this.setState({ totalMonthlyPremium: premium });

    const adjustments = {
        percentOrDollar: 'dollar',
        adjustments: rateAdjustmentsObj,
    };

    const newPremium = premium.toFixed(2);
    dispatch(adjustRenewalsRatesAndPremiumReview(productType.toUpperCase(), newPremium, finalRatesObj, adjustments, plan.code, true));
};

const calculateRateAdjustmentFromFinalRate = (tier, finalRatesObj, rateAdjustmentsObj, plan, productType, callback = () => { }) => {
    const isLife = productType === 'life';
    const isStd = productType === 'std';
    const isLtd = productType === 'ltd';
    let newRateAdjustment;

    const tierMap = {
        ee: 'employee',
        es: 'empAndSpouse',
        ec: 'empAndChild',
        ef: 'empAndFamily',
    };

    let finalRate = finalRatesObj[tier];
    if(finalRate && typeof finalRate === 'string'){
        finalRate = finalRate.charAt(0) === "." ? "0"+finalRate : finalRate;
        //event.target.value = finalRate;
        finalRatesObj[tier] = finalRate;
    }
    const originalRate = (!isLife && !isStd && !isLtd) ? plan.monthlyPremium[tierMap[tier]] : setProductRate(productType, plan);

    newRateAdjustment = parseFloat(originalRate) - parseFloat(finalRate);

    // Set new rate adjustments. If product is life, calculate and set new total rate.
    const newTotalRate = (!isLife) ? null : parseFloat(finalRate) + parseFloat(plan.quote.aDnDLifeRateVol);

    const newFinalRates = (!isLife) ? finalRatesObj :
        {
            ...finalRatesObj,
            total: newTotalRate.toFixed(2),
        };
    
    let rateAdjustmentFRobj = {...rateAdjustmentsObj}
    rateAdjustmentFRobj[tier] = newRateAdjustment.toFixed(2);

    rateAdjustmentsObj = rateAdjustmentFRobj,
    finalRatesObj = newFinalRates,

    callback();
};

export const createRenewalUpdateUhcPayload = async (Ibr) => {
    try {
        const renewalReviewURL = `/uhcRenewal/renewalReviewUhc?memberGroupId=${customerNumber}&quoteTrackingNumber=${quoteTrackingNumber}&platform=${platform}`

        let res = await axios.get(`${apiRoutes.quickQuotes}${renewalReviewURL}`)
            if (res && res.data) {

            }
        let ibrObject
        if (Ibr) {
            ibrObject = await FilterIBR(Ibr, review);
        }
    
        const medicalPlans = ibrObject.medical ? ibrObject.medical : [];
        const dentalPlans = ibrObject.dental ? ibrObject.dental : [];
        const visionPlans = ibrObject.vision ? ibrObject.vision : [];
        const lifePlans = ibrObject.basicLife ? ibrObject.basicLife : [];
        const dependentLifePlans = ibrObject.dependentLife ? ibrObject.dependentLife : [];
        const stdPlans = ibrObject.shortTermDisability ? ibrObject.shortTermDisability : [];
        const ltdPlans = ibrObject.ltd ? ibrObject.ltd : [];
        const suppLifePlans = ibrObject.suppLife ? ibrObject.suppLife : [];
    
        let medicalSelected = getSelectedPlansFromIbrSets(medicalPlans)
        let dentalSelected = getSelectedPlansFromIbrSets(dentalPlans)
        let visionSelected = getSelectedPlansFromIbrSets(visionPlans)
        let lifeSelected = getSelectedPlansFromIbrSets(lifePlans)
        let dependentLifeSelected = getSelectedPlansFromIbrSets(dependentLifePlans)
        let stdSelected = getSelectedPlansFromIbrSets(stdPlans)
        let ltdSelected = getSelectedPlansFromIbrSets(ltdPlans)
        let suppLifeSelected = getSelectedPlansFromIbrSets(suppLifePlans)
    
        const renewalDataCopy = {
            ...review.renewalData,
            isCompanyProfileUpdated: true,
        };
    
        const query = {
            mdvContribution: review.mdvContribution,
            salesRepData: review.salesRepData,
            agentData: review.agentData,
            memberGroupId: review.renewalCaseData && review.renewalCaseData.memberGroupId ? review.renewalCaseData.memberGroupId : null,
            primeCustomerNumber: review.val && review.val.primeCustomerNumber ? review.val.primeCustomerNumber : null,
            renewalCaseData: review.renewalCaseData,
            renewalData: renewalDataCopy,
            medical: { set: medicalPlans, selectedPlans: medicalSelected },
            dental: { set: dentalPlans, selectedPlans: dentalSelected },
            vision: { set: visionPlans, selectedPlans: visionSelected },
            life: { set: lifePlans, selectedPlans: lifeSelected },
            ltd: { set: ltdPlans, selectedPlans: ltdSelected },
            shoppingPlans: review.shoppingPlans,
            dependentLife: { set: dependentLifePlans, selectedPlans: dependentLifeSelected },
            std: {set: stdPlans, selectedPlans: stdSelected},
            suppLife: {set: suppLifePlans, selectedPlans: suppLifeSelected},
            alternate: alternate,
            caseSubmitted: (review && review.caseSubmitted) ? review.caseSubmitted : false,
            customPlanNames: review.customPlanNames ? review.customPlanNames : emptyCustomPlanState,
            enrolledPlansMed: review.enrolledPlansMed ? review.enrolledPlansMed : {},
            employeesEnrollStatus: review.employeesEnrollStatus ? review.employeesEnrollStatus : 'In Progress',
            isMMRCase: review.renewalCaseData.isMMRCase ? review.renewalCaseData.isMMRCase : false,
            enrolledPlans: medicalSelected,
            enrolledDentalPlans: dentalSelected,
            enrolledVisionPlans: visionSelected,
            enrolledLifePlans: lifeSelected,
            enrolledDependentLifePlans: dependentLifeSelected,
            enrolledStdPlans: stdSelected,
            enrolledLtdPlans : ltdSelected
        };
    
        query.renewalClassingOpted = review.renewalClassingOpted ? review.renewalClassingOpted : false;
        query.isMMRCase = true
        const groupTierStructureId = ibrObject.medical ? ibrObject.tierStructureId : null;
        query.groupTierStructureId = groupTierStructureId;
        query.isAlternatePlanAvailable = isAlternatePlanAvailable
    
        return query;
    }catch(err){

    }
}