import * as types from '../../actions/actionTypes';
import { axios } from '../api/axiosConfig';
import { getTransactionResult } from '../../actions/quickQuotesActions';
import { doMedicalRateCall } from '../../actions/renewalRateActions';
import { checkIsInternalUser } from '../../actions/fullQuotesActions';
import { logError } from '../errorLogging/logError';
import { KEY_NAME, UHC_REN_APIS } from '../errorLogging/logKeys';
import { isUhcRenewals } from '../businessLogic/platformUtils';
import { formatPlanOrRateCallError } from '../formatters/errorFormat';
import { setFetchingAlternateRatingMethod } from '../../actions/renewalShoppingActions';
const apiRoutes = require('../api/apiRouteConfig');

export const callValidateQuoteUhc = (zipCode, stateCode, groupSize, effectiveDate, franchiseCode, ratingCount, arqRequestInd, arqOi, arqSa, arqBit) => {
    return new Promise((resolve, reject) => {
        axios.get(`${apiRoutes.quickQuotes}/validate-quote?zipCode=${zipCode}&stateCode=${stateCode}&groupSize=${groupSize}&effectiveDate=${effectiveDate}${franchiseCode ? `&franchiseCode=${franchiseCode}` : ''}${ratingCount ? `&ratingCount=${ratingCount}` : ''}&arqRequestInd=${arqRequestInd}&arqOi=${arqOi}&arqSa=${arqSa}&arqBit=${arqBit}&busType=RL`)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}

export const getGroupChoiceIndicator = (ibrQuote) => {
    const quoteCDD = ibrQuote && ibrQuote.clientDefinedData ? ibrQuote.clientDefinedData.data : [];

    let groupChoiceIndicator = '';
    if(quoteCDD.length > 0) {
        for(let item of quoteCDD) {
            if(item.key === 'EEChoiceIndicator') {
                groupChoiceIndicator = item.value;
                break;
            }
        }
    }
    return groupChoiceIndicator;
}

export const checkAlternateRatingMethodUsed = (selectedPlans) => {
    if (selectedPlans && selectedPlans.length > 0) {
        for (let i = 0; i < selectedPlans.length; i++) {
            if (selectedPlans[i].ibrRatingType) {
                return (
                    selectedPlans[i].ibrRatingType !==
                    selectedPlans[i].defaultRatingType
                );
            }
        }
    }
    return false;
};

export const callGetPlansUhc = (plansRequestBody) => { 
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_IS_FETCHING_MEDICAL_PLANS,
            payload: true
        })
        const { totalEmployed, industryCode }  = getState().renewalShoppingHelper.renewalData ? getState().renewalShoppingHelper.renewalData.Ibr.group.primaryLocation : getState().review.renewalData.Ibr.group.primaryLocation;
        const { zipCode, effectiveDate, situsState } = getState().renewalShoppingHelper.renewalCaseData ? getState().renewalShoppingHelper.renewalCaseData : getState().review.renewalCaseData;
        const fullProps = getState();
        const { renewalShopping } = fullProps;
        const includeSpecialty = false;
        const selectedProducts = ["medical"];
        const viewSpecialty = {
            dental: false,
            life: false,
            ltd: false,
            medical: true,
            std: false,
            sul: false,
            vision: false
        }
        const otherDetails = {
            includeSpecialty: includeSpecialty,
            selectedProducts: selectedProducts,
            viewSpecialty: viewSpecialty,
            sicCode: industryCode.sicCode
        };
        const renewalShoppingRequest = {
            groupSize: totalEmployed,
            zipObj: zipCode,
            effectiveDate: effectiveDate,
            otherDetails: otherDetails,
            
        };
        // Add Internal User Indicator
        plansRequestBody.isInternal = checkIsInternalUser(fullProps.renewals.userRolesPermissions);

        if (isUhcRenewals()) {
            plansRequestBody.isUhcRenewals = true
        }

        axios.post(`${apiRoutes.quickQuotes}/primePlans/check`, plansRequestBody)
            .then(async (res) => {
                const planSearch = await getTransactionResult(res.data.transactionId);
                if (planSearch.statusCode === 400) {
                    throw await planSearch;
                }

                if(situsState==="CA" && effectiveDate ){
                    let coverageDate = new Date(effectiveDate).getUTCDate();
                    if(coverageDate === 15){
                        planSearch.medicalPlans = planSearch.medicalPlans.filter(pln => pln.platform !== 'NICE');
                    }
                }

                await dispatch({
                    type: types.SERVICE.RS.RECEIVE_REN_ADDITIONAL_PLANS,
                    payload: planSearch,
                    renewalShoppingRequest,
                    fullProps: fullProps
                });
                
                //when moving from review to shopping or decide to shopping
                if(situsState === "CA")
                {
                    dispatch(doMedicalRateCall({
                        medicalPlans: planSearch.medicalPlans.map((plan) => {
                            return {code: plan.code, packages: plan.packages, platform: plan.platform}
                        }),
                        getAlternateRatingMethod: checkAlternateRatingMethodUsed(renewalShopping.selectedPlans),
                        allMedicalPlans : planSearch.medicalPlans
                    }))
                }
                else{
                    dispatch(doMedicalRateCall({
                        medicalPlans: planSearch.medicalPlans.map((plan) => {
                            return {code: plan.code, packages: plan.packages}
                        }),
                        getAlternateRatingMethod: checkAlternateRatingMethodUsed(renewalShopping.selectedPlans),
                        allMedicalPlans : planSearch.medicalPlans
                    }));
                }               
            })
            .catch((err) => {
                dispatch({
                    type: types.SERVICE.RS.ERR_REN_ADDITIONAL_PLANS,
                    payload: formatPlanOrRateCallError(err, 'plan', false)
                });
                dispatch({
                    type: types.SET_IS_FETCHING_MEDICAL_PLANS,
                    payload: false
                })
                dispatch({
                    type: types.DISPLAY_ERROR_MESSAGE
                });
                logError(KEY_NAME, UHC_REN_APIS.MEDICAL_PLAN_CHECK, 'uhcServiceHelpers.callGetPlansUhc',plansRequestBody, err);
        });
    }
    
}

/** Rate API Call for getting medical rates */
export const callRateApi = async(requestBody,dispatch ) => {
    return new Promise(async(resolve, reject) => {
        try{
            await axios.post(`${apiRoutes.quickQuotes}/rates/startRates`, requestBody)
            .then(async (res) => {
                const results = await getTransactionResult(res.data.transactionId);
                if (results.statusCode === 400) {
                    throw await results;
                }
                resolve(results.medicalRates);
            })
            .catch(err => {
                dispatch({
                    type: types.DISPLAY_ERROR_MESSAGE
                });
                dispatch({
                    type: types.SET_IS_FETCHING_MEDICAL_PLANS,
                    payload: false
                })
                dispatch(setFetchingAlternateRatingMethod(false));
                logError(KEY_NAME, UHC_REN_APIS.START_RATES, 'uhcServiceHelpers.callRateApi',requestBody, err);
                reject(err);
            })
        }
        catch(err)
        {
            console.log("Error in /startRatesApi :",err)
        }
    })
}