import * as types from '../../actions/actionTypes';
import { handleUpdatePlanRenewal, handleMMRSpecialtyShopping, handleMMRSpecialtyLoader, handleAdjustRenewalsRatesAndPremium } from './helpers/filteringRenewalReducerHelper';
import { formatSalesRepRenewals } from '../../components/activities/Renewal/RenewalsCommonComponents/saveDetails';
import { handleRequestRates, handleRequestBenefitRiderRates } from './helpers/fetchingReducerHelpers';
import { handleReceiveBenefitRiderRates } from './helpers/filteringReducerHelpers';
import { calculatePercentage } from '../../actions/actionHelpers/mmrHelper';
import deepCopy from '../../utils/deepCopy';


const initialMDVContr = {
    medical: {
        eValue: '50',
        dValue: '0',
        activeIndex: 0
    },
    dental: {
        eValue: '0',
        dValue: '0',
        activeIndex: 1
    },
    vision: {
        eValue: '0',
        dValue: '0',
        activeIndex: 2
    },
    life: {
        eValue: '0',
        dValue: '0',
        activeIndex: 3
    }
}

let initialState = {
    caseProgressUI: '',
    mdvContribution: initialMDVContr,
    employerContribution: 0,
    errorMessage: '',
    numOfSelectedRenewPlans: 0,
    premiumToggle: true,
    customPlanNames: {
        medical: {},
        dental: {},
        vision: {},
        life: {},
        depLife: {},
        std: {},
        ltd: {},
        sul: {}
    },
    alternate: [],
    renewalRatingIds: {
        Shopping: {
            medical: '',
            dental: '',
            vision: '',
            life: ''
        },
        Proposed: {
            medical: '',
            dental: '',
            vision: '',
            life: ''
        },
        PreInstallCensusChange: {
            medical: '',
            dental: '',
            vision: '',
            life: ''
        },
        PostEnrollPreInstall: {
            medical: '',
            dental: '',
            vision: '',
            life: ''
        },
        Installation: {
            medical: '',
            dental: '',
            vision: '',
            life: ''
        },
        //UHC only
        MoveToEnroll: {
            medical: '',
            dental: '',
            vision: '',
            life: ''
        },
        CloseEnrollment: {
            medical: '',
            dental: '',
            vision: '',
            life: ''
        },
        FinalizeEnrollment: {
            medical: '',
            dental: '',
            vision: '',
            life: ''
        },
    },
    ratesApiSuccess: {
        medical: false,
        dental: false,
        vision: false,
        life: false
    },
    mmr: {
        plansToBeRateCalled: [],
        medicalPlans: [],
        dentalPlans: [],
        visionPlans: [],
        lifePlans: [],
        stdPlans: [],
        ltdPlans: [],
        sulPlans: [],
        suppLifePlans:[],
        dependentLifePlans: [],
        selectedMedicalPlans: [],
        selectedDentalPlans: [],
        selectedVisionPlans: [],
        selectedLifePlans: [],
        selectedDependentLifePlans: [],
        selectedSTDPlans: [],
        selectedLTDPlans: [],
        selectedSULPlans: [],
        selectedSUPPLIFEPlans:[],
        isFetchingMedical: false,
        isFetchingDental: false,
        isFetchingVision: false,
        isFetchingLife: false,
        isFetchingDependentLife: false,
        isFetchingSTD: false,
        isFetchingLTD: false,
        isFetchingSUL: false,
        isFetchingSUPPLIFE:false,
        isFetchingMedicalRates: false,
        isFetchingDentalRates: false,
        isFetchingVisionRates: false,
        isFetchingLifeRates: false,
        isFetchingDependentLifeRates: false,
        isFetchingSTDRates:false,
        isFetchingSULRates:false,
        isFetchingSUPPLIFERates:false,
    },
    disableSubmitBtn: false,
    renewalHwCheck: false,
    auditFlag: false,
};
export default function reviewReducer(state = initialState, action) {
    switch (action.type) {
        case types.REQUEST_MEMBER_ID:
            return {
                ...state,
                ...action.payload
            }

        case types.RECEIVE_REVIEW_DETAILS:
            return {
                ...state,
                ...action.payload
            }
        case types.ERR_REVIEW_DETAILS:
            return {
                ...state,
                ...action.payload
            }
        case types.CURRENT_CASE_STATUS:
            return {
                ...state,
                ...action.payload
            }
        case types.UPDATED_REVIEW_DETAILS:

            let newState = action.review.renewalData
            return {
                ...state,
                renewalData: newState
            }

        case types.UPDATE_PREMIUM_TOGGLE_VALUE_REVIEW:

            return {
                ...state,
                premiumToggle: action.payload
            }

        case types.UPDATE_MDV_CONTRIBUTION:
            const tempMDVCont = initialMDVContr;
            tempMDVCont.medical.eValue = action.payload?.medEValue ?
                action.payload.medEValue :
                state.mdvContribution.medical.eValue;
            tempMDVCont.medical.dValue = action.payload?.medDValue ?
                action.payload.medDValue :
                state.mdvContribution.medical.dValue;
            tempMDVCont.dental.eValue = action.payload?.denEValue ?
                action.payload.denEValue :
                state.mdvContribution.dental.eValue;
            tempMDVCont.dental.dValue = action.payload?.denDValue ?
                action.payload.denDValue :
                state.mdvContribution.dental.dValue;
            tempMDVCont.vision.eValue = action.payload?.visEValue ?
                action.payload.visEValue :
                state.mdvContribution.vision.eValue;
            tempMDVCont.vision.dValue = action.payload?.visDValue ?
                action.payload.visDValue :
                state.mdvContribution.vision.dValue;
            tempMDVCont.life.eValue = action.payload?.lifeEValue ?
                action.payload.lifeEValue :
                state.mdvContribution.life.eValue;

            return {
                ...state,
                mdvContribution: tempMDVCont,
                errorMessage: action.payload.errorMessage,
            }
            
        case types.RECEIVE_RENEWAL_PLAN_CODE:
            return {
                ...state,
                renewalPlanCode: action.payload
            }

        case types.PLAN_CHANGE_FIELD:
            return {
                ...state,
                planChange: action.payload
            }
        case types.UPDATE_NUM_OF_SELECTED_RENEW_PLANS:
            return {
                ...state,
                numOfSelectedRenewPlans: action.payload,

            }

        case types.ACTIVATE_SHOPPING_TAB:
            return {
                ...state,
                activeShoppingTab: action.payload,
            }
        case types.UPDATE_PLAN_ISCHECKED_VALUE:
            const newList = state.renewalData.planRates.map((item) => {
                if (item.planCode === action.payload.query.planCode && item.renewalPlanType === "Renewal") {
                    const updatedItem = {
                        ...item,
                        isChecked: !item.isChecked,
                    };
                    return updatedItem;
                }
                return item;
            });
            return {
                ...state,
                renewalData: {
                    ...state.renewalData,
                    planRates: newList
                }
            }
        case types.UPDATE_SHOW_MORE_LESS:
            return {
                ...state,
                showMoreLessArray: action.payload,

            }

        case types.UPDATED_ALLOWED_PLANS_COUNT:
            return {
                ...state,
                allowedSelectedPlansCount: action.payload
            }

        case types.REVIEW_INIT_IS_ELIGIBLE_TO_FINALIZE_FROM_DECIDE:
            return {
                ...state,
                renewalData: {
                    ...state.renewalData,
                    isEligibleToFinalizeFromDecide: action.value
                }

            }

        case types.REVIEW_INIT_UPDATE_BEEN_TO_FINALIZE:
            return {
                ...state,
                renewalData: {
                    ...state.renewalData,
                    beenToFinalize: action.payload
                }
            }
        case types.ADD_SHOPPING_PLANS_TO_REVIEW:
            return {
                ...state,
                shoppingPlans: action.payload
            }

        case types.ADD_MEDICAL_PLANS_TO_RENEW:
            let medicalSets = action.payload
            let medicalSelectedPlans = []
            for (let sets of medicalSets) {
                for (let plan of sets) {
                    if (plan.renewalPlanType === 'renewing') {
                        plan['selected'] = !plan['selected'];
                        plan.selectedPackages = plan.packages;
                        medicalSelectedPlans.push(plan)
                    }
                }
            }
            return {
                ...state,
                medical: {
                    set: medicalSets,
                    selectedPlans: medicalSelectedPlans
                }
            }

        case types.ADD_DENTAL_PLANS_TO_RENEW:
            let dentalSets = action.payload
            let dentalSelectedPlans = []
            for (let sets of dentalSets) {
                for (let plan of sets) {
                    if (plan.renewalPlanType === 'renewing') {
                        plan['selected'] = !plan['selected'];
                        plan.selectedPackages = [];
                        plan.selectedPackages.push('Single');
                        dentalSelectedPlans.push(plan)
                    }
                }
            }
            return {
                ...state,
                dental: {
                    set: dentalSets,
                    selectedPlans: dentalSelectedPlans
                }
            }
        case types.ADD_VISION_PLANS_TO_RENEW:
            let visionSets = action.payload
            let visionSelectedPlans = []
            for (let sets of visionSets) {
                for (let plan of sets) {
                    if (plan.renewalPlanType === 'renewing') {
                        plan['selected'] = !plan['selected'];
                        plan.selectedPackages = [];
                        plan.selectedPackages.push('Single');
                        visionSelectedPlans.push(plan)
                    }
                }
            }
            return {
                ...state,
                vision: {
                    set: visionSets,
                    selectedPlans: visionSelectedPlans
                }
            }

        case types.ADD_RENEWAL_CLASS_IN_REVIEW:
            return {
                ...state,
                renewalClassingOpted: true
            }
            
        case types.ADD_LIFE_PLANS_TO_RENEW:
            let lifeSets = action.payload
            let lifeSelectedPlans = []
            for (let sets of lifeSets) {
                for (let plan of sets) {
                    if (plan.renewalPlanType === 'renewing') {
                        plan['selected'] = !plan['selected'];
                        plan.selectedPackages = [];
                        plan.selectedPackages.push('Single');
                        lifeSelectedPlans.push(plan)
                    }
                }
            }
            return {
                ...state,
                life: {
                    set: lifeSets,
                    selectedPlans: lifeSelectedPlans
                }
            }

        case types.ADD_DEPENDENT_LIFE_PLANS_TO_RENEW:
            let depLifeSets = action.payload
            let depLifeSelectedPlans = []
            for (let sets of depLifeSets) {
                for (let plan of sets) {
                    if (plan.renewalPlanType === 'renewing') {
                        plan['selected'] = !plan['selected'];
                        plan.selectedPackages = [];
                        plan.selectedPackages.push('Single');
                        depLifeSelectedPlans.push(plan)
                    }
                }
            }
            return {
                ...state,
                dependentLife: {
                    set: depLifeSets,
                    selectedPlans: depLifeSelectedPlans
                }
            }
        
        case types.ADD_STD_PLANS_TO_RENEW:
            let stdSets = action.payload
            let stdSelectedPlans = []
            for (let sets of stdSets) {
                for (let plan of sets) {
                    if (plan.renewalPlanType.toLowerCase() === 'renewing') {
                        plan['selected'] = !plan['selected'];
                        plan.selectedPackages = [];
                        plan.selectedPackages.push('Single');
                        stdSelectedPlans.push(plan)
                    }
                }
            }
            return {
                ...state,
                std: {
                    set: stdSets,
                    selectedPlans: stdSelectedPlans
                }
            }
        case types.ADD_LTD_PLANS_TO_RENEW:
            let ltdSets = action.payload
            let ltdSelectedPlans = []
            for (let sets of ltdSets) {
                for (let plan of sets) {
                    if (plan.renewalPlanType.toLowerCase() === 'renewing') {
                        plan['selected'] = !plan['selected'];
                        plan.selectedPackages = [];
                        plan.selectedPackages.push('Single');
                        ltdSelectedPlans.push(plan)
                    }
                }
            }
            return {
                ...state,
                ltd: {
                    set: ltdSets,
                    selectedPlans: ltdSelectedPlans
                }
            } 
        case types.ADD_SUPP_LIFE_PLANS_TO_RENEW:
            let suppLifeSets = action.payload
            let suppLifeSelectedPlans = []
            for (let sets of suppLifeSets) {
                for (let plan of sets) {
                    if (plan.renewalPlanType.toLowerCase() === 'renewing') {
                        plan['selected'] = !plan['selected'];
                        plan.selectedPackages = [];
                        plan.selectedPackages.push('Single');
                        suppLifeSelectedPlans.push(plan)
                    }
                }
            }
            return {
                ...state,
                suppLife: {
                    set: suppLifeSets,
                    selectedPlans: suppLifeSelectedPlans
                }
        } 
        case types.ADD_ALTERNATE_PLANS_TO_RENEW:
            return {
                ...state,
                alternate: action.payload,
                alternatePlanCodes: action.alternatePlanCodes
            }

        case types.UPDATE_GROUP_TIER_STRUCTURE_ID:
            return {
                ...state,
                groupTierStructureId: action.payload
            }

        case types.RESET_REVIEW_FLAG:
            return {
                ...state,
                renewalReviewFlag: action.payload
            }
        case types.SET_ALTERNATE_PLANS_IND:
            return {
                ...state,
                isAlternatePlanAvailable: action.payload
            }
        case types.ENABLE_RENEWAL_STEPPER_FLAG:
            return {
                ...state,
                renewalStepperFlag: action.payload
            }
        case types.UPDATE_RENEWAL_PLAN_MEDICAL:
            return handleUpdatePlanRenewal(state, action, 'medical');
        case types.SERVICE.DENTAL.UPDATE_RENEWAL_PLAN:
            return handleUpdatePlanRenewal(state, action, 'dental');
        case types.SERVICE.VISION.UPDATE_RENEWAL_PLAN:
            return handleUpdatePlanRenewal(state, action, 'vision');
        case types.SERVICE.LIFE.UPDATE_RENEWAL_PLAN:
            return handleUpdatePlanRenewal(state, action, 'life');
        case types.SERVICE.LIFE_DEP_BASIC.UPDATE_RENEWAL_PLAN:
            return handleUpdatePlanRenewal(state, action, 'LIFE_DEP_BASIC');

        case types.RESET_TO_INITIALSTATE:
        case types.RESET_FLOW:
        case types.RESET_RENEWALS:
            return initialState

        case types.UPDATE_SHOW_MORE_LESS_MEDICAL:
            return {
                ...state,
                showMoreLessArrayMedical: action.payload,
            }

        case types.UPDATE_SHOW_MORE_LESS_DENTAL:
            return {
                ...state,
                showMoreLessArrayDental: action.payload,
            }

        case types.UPDATE_SHOW_MORE_LESS_VISION:
            return {
                ...state,
                showMoreLessArrayVision: action.payload,
            }

        case types.UPDATE_SHOW_MORE_LESS_LIFE:
            return {
                ...state,
                showMoreLessArrayLife: action.payload,
            }

        case types.UPDATE_SHOW_MORE_LESS_DEP_LIFE:
            return {
                ...state,
                showMoreLessArrayDepLife: action.payload,
            }
        
        case types.UPDATE_SHOW_MORE_LESS_STD:
            return {
                ...state,
                showMoreLessArrayStd: action.payload,
            }
        case types.UPDATE_SHOW_MORE_LESS_LTD:
            return {
                ...state,
                showMoreLessArrayLtd: action.payload,
            }
        case types.REMOVE_ALL_MEDICAL_PLANS:

            const updatedPlanRates = state.renewalData.planRates.map((item) => {
                if (item.renewalPlanType === "Renewal") {
                    const updatedItem = {
                        ...item,
                        isChecked: false,
                    };
                    return updatedItem;
                }
                return item;
            });
            return {
                ...state,
                renewalData: {
                    ...state.renewalData,
                    planRates: updatedPlanRates
                }
            }

        case types.UPDATE_RENEWAL_RATE_REFERENCE_ID:
            const {
                quoteStatus,
                product,
                platformRatingId,
                rateUrl,
                finalRateObj
            } = action.payload;
            return {
                ...state,
                renewalRatingIds: {
                    ...state.renewalRatingIds,
                    [quoteStatus]: {
                        ...state.renewalRatingIds[quoteStatus],
                        [product]: {
                            ...state.renewalRatingIds[quoteStatus][product],
                            platformRatingId,
                            rateUrl,
                            finalRateObj
                        }
                    }
                }
            }

        case types.UPDATE_RATES_API_SUCCESS:
            const {
                rateAPI,
                planProduct
            } = action.payload;
            return {
                ...state,
                ratesApiSuccess: {
                    ...state.ratesApiSuccess,
                    [planProduct]: {
                        ...state.ratesApiSuccess[planProduct],
                        rateAPI
                    }
                }
            }
        case types.UPDATE_SALES_REP_RENEWALS_REVIEW:
            let finalSalesRepData = formatSalesRepRenewals(action.salesRep, action.platform)
            return {
                ...state,
                salesRepData: finalSalesRepData
            }
        case types.UPDATE_AUDIT_STATUS_FLAG:
            return {
                ...state,
                auditFlag: action.payload
            }
        case types.RENEWAL_BTN_DISABLE_FLAG:
            return {
                ...state,
                disableSubmitBtn: action.payload
            }
        case types.RENEWAL_HW_DISABLE:
            return {
                ...state,
                renewalHwCheck: action.payload
            }
        case types.SET_MMR_MEDICAL_PLANS:
            return {
                ...state,
                mmr: {
                    ...state.mmr,
                    medicalPlans: action.payload
                }
            }
        case types.SET_MMR_IS_FETCHING_MEDICAL:
            return {
                ...state,
                mmr: {
                    ...state.mmr,
                    isFetchingMedical: action.payload
                }
            }

        //set speciality plans
        case types.SERVICE.DENTAL.SET_MMR_PLANS:
            return handleMMRSpecialtyShopping(state, action, 'dental');
        case types.SERVICE.VISION.SET_MMR_PLANS:
            return handleMMRSpecialtyShopping(state, action, 'vision');
        case types.SERVICE.LIFE.SET_MMR_PLANS:
            return handleMMRSpecialtyShopping(state, action, 'life');
        case types.SERVICE.LIFE_DEP_BASIC.SET_MMR_PLANS:
            return handleMMRSpecialtyShopping(state, action, 'LIFE_DEP_BASIC');
        case types.SERVICE.STD.SET_MMR_PLANS:
            return handleMMRSpecialtyShopping(state, action, 'std');
        case types.SERVICE.LTD.SET_MMR_PLANS:
            return handleMMRSpecialtyShopping(state, action, 'ltd');
        case types.SERVICE.SUL.SET_MMR_PLANS:
            return handleMMRSpecialtyShopping(state, action, 'sul');
        case types.SERVICE.SUPPLIFE.SET_MMR_PLANS:
            return handleMMRSpecialtyShopping(state, action, 'supplife');
        //set loader
        case types.SERVICE.DENTAL.SET_MMR_IS_FETCHING:
            return handleMMRSpecialtyLoader(state, action, 'dental');
        case types.SERVICE.VISION.SET_MMR_IS_FETCHING:
            return handleMMRSpecialtyLoader(state, action, 'vision');
        case types.SERVICE.LIFE.SET_MMR_IS_FETCHING:
            return handleMMRSpecialtyLoader(state, action, 'life');
        case types.SERVICE.LIFE_DEP_BASIC.SET_MMR_IS_FETCHING:
            return handleMMRSpecialtyLoader(state, action, 'LIFE_DEP_BASIC');
        case types.SERVICE.LTD.SET_MMR_IS_FETCHING:
            return handleMMRSpecialtyLoader(state, action, 'ltd'); 
        case types.SERVICE.STD.SET_MMR_IS_FETCHING:
            return handleMMRSpecialtyLoader(state, action, 'std');
        case types.SERVICE.SUPPLIFE.SET_MMR_PLANS:
            return handleMMRSpecialtyShopping(state, action, 'supplife');           
            
        case types.SERVICE.DENTAL.ADJUST_RENEWALS_RATE_REVIEW:
            return handleAdjustRenewalsRatesAndPremium(state, action, 'dental');

        case types.SERVICE.VISION.ADJUST_RENEWALS_RATE_REVIEW:
            return handleAdjustRenewalsRatesAndPremium(state, action, 'vision');

        case types.SERVICE.LIFE.ADJUST_RENEWALS_RATE_REVIEW:
            return handleAdjustRenewalsRatesAndPremium(state, action, 'life');    
            
        case types.SET_RENEWING_PLANS_MMR:
            let productPlanSet = JSON.parse(JSON.stringify(action.product==="supplife"?state['suppLife'].set :state[action.product].set))
            let productSelectedPlans = JSON.parse(JSON.stringify(action.product==="supplife"?state['suppLife'].selectedPlans:state[action.product].selectedPlans))
            let isSuppLifeDep = false
            action.payload.forEach((plan, planIndex) => {
                if (plan) {
                    //to set correct planType for dependentLife,earlier for depLife value was life
                    if(action.product==="dependentLife")
                    {
                        plan.planType = "LIFE_DEP_BASIC"
                    }
                    if(action.product==="supplife")
                    {
                        plan ={...plan, isSuppLifeDep: true,}
                    }
                    let currentProductPlan = productPlanSet[planIndex][0]
                    let renewingProductPlan = JSON.parse(JSON.stringify(plan))
                    renewingProductPlan.renewalPlanType = 'renewing'
                    renewingProductPlan.renewalPlan = true
                    renewingProductPlan.enrolledCount = renewingProductPlan.member.length
                    renewingProductPlan.totalEligibleEmployees = currentProductPlan.totalEligibleEmployees
                    renewingProductPlan.underwriterExceptionCode = currentProductPlan.underwriterExceptionCode
                    renewingProductPlan.legalName = currentProductPlan.legalName
                    renewingProductPlan.rateGuarantee = currentProductPlan.rateGuarantee || '12'
                    renewingProductPlan.selected = true
                    renewingProductPlan.isFetchingRates = true
                    renewingProductPlan.selectedPackages = ['ltd','std','supplife'].includes(action.product) ? [state.mmr[`selected${action.product.toUpperCase()}Plans`][planIndex]?.packageId] : [state.mmr[`selected${action.product[0].toUpperCase() + action.product.slice(1)}Plans`][planIndex]?.packageId]
                    renewingProductPlan.renewalChangePercent = ''
                    if (productPlanSet[planIndex].length === 2) {
                        productPlanSet[planIndex][1] = renewingProductPlan
                    } else {
                        productPlanSet[planIndex].push(renewingProductPlan)
                    }
                    let selectedPlanIndex = productSelectedPlans.findIndex(selectedPlan => selectedPlan.code === plan.code)
                    if(selectedPlanIndex > -1) {
                        productSelectedPlans[selectedPlanIndex] = renewingProductPlan
                    } else {
                        productSelectedPlans.push(renewingProductPlan)
                    }
                }
            })
            return {
                ...state,
                mmr: {
                    ...state.mmr,
                    plansToBeRateCalled: []
                },
                [action.product==="supplife"?"suppLife":action.product]: {
                    set: productPlanSet,
                    selectedPlans: productSelectedPlans
                }
            }
        case types.UPDATE_SUPPLIFEDEP_INDICATOR_MMR:
            let selectedSPlans = action.payload
            let productSelectedSPlans =[]

            selectedSPlans.forEach((plan)=>{
                 plan ={...plan, isSuppLifeDep:false}
                 let renewingProductSPlan = JSON.parse(JSON.stringify(plan))
                 productSelectedSPlans.push(renewingProductSPlan)
            })
           return {
            ...state,
            suppLife: {
                ...state.suppLife,
                selectedPlans: productSelectedSPlans
            }
           }


        case types.REMOVE_RENEWING_PLANS_MMR:
            let updatedPlanSet = JSON.parse(JSON.stringify(state[action.product].set))
            let updatedSelectedPlans = JSON.parse(JSON.stringify(state[action.product].selectedPlans))
            let memberArrayFromSet = updatedPlanSet[action.planIndexes[0]][0].member
            let planCodeField = action.product === 'medical' ? 'medicalPlanCode' : 'code'
            let memberArrayFromSelected = updatedSelectedPlans.find(plan => plan[planCodeField] === action.planCode)?.member || []
            let plansToBeRateCalled = []
            action.planIndexes.forEach(planIndex => {
                updatedPlanSet[planIndex].pop()
            })
            let isAnotherPlanExistWithSamePlanCode = false
            updatedPlanSet.forEach((planSet, setIndex) => {
                if (planSet.length > 1 && planSet[1][planCodeField] === action.planCode && !action.planIndexes.includes(setIndex)) {
                    isAnotherPlanExistWithSamePlanCode = true
                }
            })
            if(memberArrayFromSet.length!==memberArrayFromSelected.length || isAnotherPlanExistWithSamePlanCode)
            {
                for (let i = memberArrayFromSet.length -1; i >= 0; i--)
                {
                    memberArrayFromSelected.splice(memberArrayFromSet[i], 1);
                }
                updatedSelectedPlans.forEach((plan, i)=>{
                    if(plan[planCodeField] === action.planCode)
                    {
                        plan.member = JSON.parse(JSON.stringify(memberArrayFromSelected))
                        plansToBeRateCalled.push(plan[planCodeField])
                    }
                })
            }
            else
            {
                updatedSelectedPlans = updatedSelectedPlans.filter(selectedPlan => selectedPlan[planCodeField] !== action.planCode)            
            }
            return {
                ...state,
                mmr: {
                    ...state.mmr,
                    plansToBeRateCalled: plansToBeRateCalled
                },
                [action.product]: {
                    set: updatedPlanSet,
                    selectedPlans: updatedSelectedPlans
                }
            }
        case types.UPDATE_SELECTED_PLAN_MMR:
            let propertyName = ['ltd','std','supplife'].includes(action.product) ? `selected${action.product.toUpperCase()}Plans` : `selected${action.product[0].toUpperCase() + action.product.slice(1)}Plans`
            let selectedPlans = JSON.parse(JSON.stringify(state.mmr[propertyName]))
            while (selectedPlans.length < action.planIndex + 1) {
                selectedPlans.push({})
            }
            selectedPlans[action.planIndex] = {
                ...selectedPlans[action.planIndex],
                ...action.payload
            }
            return {
                ...state,
                mmr: {
                    ...state.mmr,
                    [propertyName]: selectedPlans
                }
            }
        case types.REQUEST_MMR_ADDITIONAL_RATES:
            let productRateFlag = action.product[0].toUpperCase() + action.product.slice(1)
            if(productRateFlag==="SuppLife"){
                productRateFlag="SUPPLIFE"
            }
            return {
                ...state,
                mmr: {
                    ...state.mmr,
                    [`isFetching${productRateFlag}Rates`]: true
                }
            }
        case types.RECEIVE_MMR_ADDITIONAL_RATES:
            let updatedProductPlanSet = ['shortTermDis','longTermDis','supplife'].includes(action.product) ? JSON.parse(JSON.stringify(state[action.productType].set)) : JSON.parse(JSON.stringify(state[action.product].set));
            let updatedProductSelectedPlans = ['shortTermDis','longTermDis', 'suppLife'].includes(action.product) ? JSON.parse(JSON.stringify(state[action.productType].selectedPlans)) : JSON.parse(JSON.stringify(state[action.product].selectedPlans));
            let productFlag1 = action.product[0].toUpperCase() + action.product.slice(1)
            if(productFlag1==="SuppLife") productFlag1="SUPPLIFE"
            let isAllProductPlanRatesFetched = true
            // Add the rates from this batch to their corresponding plans
            for (let i = 0; i < updatedProductPlanSet.length; i++) {
                if (updatedProductPlanSet[i].length > 1) {
                    const rate = action.rates.find(planRate => {
                        let planCode = action.product === "medical" ? planRate.medicalPlanCode : (action.product === "longTermDis" ? planRate.ltdPlanCode : (action.product === "shortTermDis" ? planRate.stdPlanCode : planRate.code))
                        if(action.product === "life" || action.product === "dependentLife")
                        {
                            //for life and DL compare with primePlanCode 
                            return updatedProductPlanSet[i][1].primePlanCode === planCode
                        }
                        return updatedProductPlanSet[i][1].code === planCode
                    })
                    if (rate) {
                        let selectedPlanIndex = updatedProductSelectedPlans.findIndex(plan => (action.product === "life" || action.product === "dependentLife" ? plan.primePlanCode : plan.code) === (action.product === "medical" ? rate.medicalPlanCode : (action.product === "longTermDis" ? rate.ltdPlanCode : (action.product === "shortTermDis" ? rate.stdPlanCode : rate.code)) ))
                        let defaultRatingType, tierStructureId;
                        let totalPremiumKey;
                        if (action.product === "medical" || ['shortTermDis','longTermDis','suppLife'].includes(action.product)) {
                            let allEmployees = action.allEmployees
                            let employeeRateKey
                            defaultRatingType = updatedProductPlanSet[i][0].defaultRatingType
                            const isMultipleRatesAvailable = rate.isAgeBanded && rate.isComposite // TODO: need to handle 2-tier rates
                            if (!isMultipleRatesAvailable) {
                                defaultRatingType = rate.defaultRatingType ? rate.defaultRatingType : defaultRatingType
                            }
                            totalPremiumKey = defaultRatingType === 'ageBands' ? 'premiumTotalAgeBandedMonthly' : 'premiumTotalCompositeMonthly';
                            employeeRateKey = defaultRatingType === 'ageBands' ? 'employeeRatesAgeBanded' : 'employeeRatesComposite';
                            if (isMultipleRatesAvailable) {
                                if (defaultRatingType === 'ageBands') {
                                    delete rate.compositeRates
                                    delete rate.compositeTypeName
                                    delete rate.isComposite
                                    delete rate.platformCompositeRatingID
                                    delete rate.premiumTotalCompositeAnnual
                                    delete rate.premiumTotalCompositeMonthly
                                } else {
                                    delete rate.ageBands
                                    delete rate.ageBandedTypeName
                                    delete rate.isAgeBanded
                                    delete rate.platformAgeBandedRatingID
                                    delete rate.premiumTotalAgeBandedAnnual
                                    delete rate.premiumTotalAgeBandedMonthly
                                }
                            }
                            rate.quote = 0.00 // rate[totalPremiumKey]
                            action.product === "medical" && updatedProductPlanSet[i][1].member.forEach(employee => {
                                rate[employeeRateKey].forEach((employeeRate, employeeRateIdx) => {
                                    if (employee.employeeId === employeeRate.employeeId || employee.employeeId === allEmployees[employeeRateIdx].employeeId) {
                                        rate.quote += employeeRate.total;
                                    }
                                })
                            })
                            if(['shortTermDis','longTermDis'].includes(action.product)){
                                rate.totalMonthlyPremium = updatedProductPlanSet[i][1].member?.length === 0 ? '0.00' :rate.totalMonthlyPremium;
                                rate.quote = rate.totalMonthlyPremium;
                            }
                            if (rate.quote == 0) {
                                rate.quote = '0.00'
                            }
                            if(action.product === "medical"){
                                updatedProductPlanSet[i][1].medicalPlanCode = rate.medicalPlanCode + '/' + updatedProductPlanSet[i][1].rxPlanCode
                            }   
                        }
                        else if(action.product === "dental" || action.product === "vision") {
                            rate.quote = updatedProductPlanSet[i][1].member?.length === 0 ? '0.00' : rate.byCensus.totalMonthlyPremium
                            rate.finalMonthlyPremium = rate.quote
                            rate.finalRates = rate.monthlyPremium
                            tierStructureId = action.tierStructureId
                        }
                        else{
                            if(action.product === "life" && updatedProductPlanSet[i][1].member?.length === 0){
                                rate.byCensus.totalMonthlyPremium = '0.00';
                                rate.aDndTotalPremium = '0.00';
                                rate.basicLifeTotalPremium = '0.00';
                                rate.totalMonthlyPremium = '0.00';
                                rate.totalAnnualPremium = '0.00';
                            }
                            if(rate.quote === undefined)
                            {
                                 //quote in life rate (from filterIBR)
                                rate.quote = {
                                    ADDTotVolAmount : rate.ADDTotVolAmount,
                                    BasicLifeTotVolAmount : rate.BasicLifeTotVolAmount,
                                    basicLifeRateVol : rate.basicLifeRateVol,
                                    aDnDLifeRateVol : rate.aDnDLifeRateVol,
                                    totalLifeRateVol : rate.totalLifeRateVol,
                                    aDndTotalPremium : rate.aDndTotalPremium,
                                    totalMonthlyPremium : rate.byCensus.totalMonthlyPremium, 
                                    depLifeChildRate : rate.depLifeChildRate,
                                    depLifeSpouseRate : rate.depLifeSpouseRate
                                }
                            }   
                            else {
                                rate.quote.ADDTotVolAmount = rate.ADDTotVolAmount,
                                rate.quote.BasicLifeTotVolAmount = rate.BasicLifeTotVolAmount
                                rate.quote.basicLifeRateVol = rate.basicLifeRateVol
                                rate.quote.aDnDLifeRateVol = rate.aDnDLifeRateVol
                                rate.quote.totalLifeRateVol = rate.totalLifeRateVol
                                rate.quote.aDndTotalPremium = rate.aDndTotalPremium
                                rate.quote.totalMonthlyPremium = rate.byCensus.totalMonthlyPremium
                                rate.quote.depLifeChildRate = rate.depLifeChildRate
                                rate.quote.depLifeSpouseRate = rate.depLifeSpouseRate
                            }                        
                            

                            
                            
                            //finalRates
                            if(rate.finalRates === undefined){
                                rate.finalRates={
                                    ADDTotVolAmount : rate.ADDTotVolAmount,
                                    BasicLifeTotVolAmount : rate.BasicLifeTotVolAmount,
                                    basicLifeRateVol : rate.basicLifeRateVol,
                                    aDnDLifeRateVol : rate.aDnDLifeRateVol,
                                    totalLifeRateVol : rate.totalLifeRateVol,
                                    basicLifeTotalPremium : rate.basicLifeTotalPremium,
                                    aDndTotalPremium : rate.aDndTotalPremium,
                                    totalLifeRateVol : rate.totalLifeRateVol,
                                    employeeRate :  rate.employeeRate,      
                                    totalMonthlyPremium : rate.byCensus.totalMonthlyPremium,
                                    depLifeChildRate : rate.depLifeChildRate,
                                    depLifeSpouseRate: rate.depLifeSpouseRate,
                                    byCensus:rate.byCensus
                                }
                            }
                            else {
                                    rate.finalRates.ADDTotVolAmount = rate.ADDTotVolAmount,
                                    rate.finalRates.BasicLifeTotVolAmount = rate.BasicLifeTotVolAmount,
                                    rate.finalRates.basicLifeRateVol = rate.basicLifeRateVol,
                                    rate.finalRates.aDnDLifeRateVol = rate.aDnDLifeRateVol,
                                    rate.finalRates.totalLifeRateVol = rate.totalLifeRateVol,
                                    rate.finalRates.basicLifeTotalPremium = rate.basicLifeTotalPremium,
                                    rate.finalRates.aDndTotalPremium = rate.aDndTotalPremium,
                                    rate.finalRates.totalLifeRateVol = rate.totalLifeRateVol
                                    rate.finalRates.employeeRate =  rate.employeeRate      
                                    rate.finalRates.depLifeChildRate = rate.depLifeChildRate,
                                    rate.finalRates.depLifeSpouseRate = rate.depLifeSpouseRate
                            }

                            rate.finalMonthlyPremium = updatedProductPlanSet[i][1].member?.length === 0 ? '0.00' : rate.byCensus.totalMonthlyPremium 
                        }
                        const getTotalRates=(obj)=> Object.keys(obj).filter(key => key !== "employeeId").reduce((acc, key)=> acc + parseFloat(obj[key]), 0)
                        updatedProductPlanSet[i][1] = {
                            ...rate,
                            ...updatedProductPlanSet[i][1],
                            member: updatedProductPlanSet[i][1].member.map((employee, employeeIndex) => ({
                                ...employee,
                                total: ['shortTermDis','longTermDis'].includes(action.product) ? (defaultRatingType === 'ageBands' ? rate.employeeRatesAgeBanded[employeeIndex] : rate.employeeRatesComposite[employeeIndex])
                                :action.product==="suppLife" ? getTotalRates(rate.byCensus.employeePremium[employeeIndex]) || null
                                : (action.product !== "medical" ? rate.byCensus.employeeRates.find(emp => employee.employeeId === emp.employeeId).Total : defaultRatingType === 'ageBands' ? rate.employeeRatesAgeBanded.find(emp => parseInt(employee.employeeId, 10)  === parseInt(emp.employeeId, 10) || parseInt(emp.employeeId,10) === employeeIndex + 1)?.total : rate.employeeRatesComposite.find(emp => parseInt(employee.employeeId, 10)  === parseInt(emp.employeeId, 10) || parseInt(emp.employeeId,10) === employeeIndex + 1)?.total)
                            })),
                            isFetchingRates: false,
                            defaultRatingType,
                            renewalChangePercent: (action.product === "life" || action.product === "dependentLife") ? calculatePercentage(updatedProductPlanSet[i][0].quote.totalMonthlyPremium, rate.quote.totalMonthlyPremium)
                                                    : calculatePercentage(updatedProductPlanSet[i][0].quote, rate.quote),
                            tierStructureId
                        }
                        if(['shortTermDis','longTermDis'].includes(action.product)){
                            updatedProductPlanSet[i][1]['finalRates'] = {};
                            updatedProductPlanSet[i][1]['quote'] = {}
                            updatedProductPlanSet[i][1]['finalRates']['totalMCP'] = rate.totalMCP;
                            updatedProductPlanSet[i][1]['finalRates']['maximumWeeklyBenefit'] = rate.maximumWeeklyBenefit;
                            updatedProductPlanSet[i][1]['finalRates']['totalWeeklyBenefit'] = rate.totalWeeklyBenefit
                            updatedProductPlanSet[i][1]['finalRates']['totalMonthlyPremium'] = rate.totalMonthlyPremium;
                            updatedProductPlanSet[i][1]['finalRates']['timesRatesPer100ofMCP'] = rate.timesRatesPer100ofMCP;
                            updatedProductPlanSet[i][1]['finalRates']['rateOver10WeeklyBenefit'] = rate.rateOver10WeeklyBenefit;
                            updatedProductPlanSet[i][1]['finalRates']['censusRates'] = rate.censusRates;

                            updatedProductPlanSet[i][1]['quote']['totalMCP'] = rate.totalMCP;
                            updatedProductPlanSet[i][1]['quote']['maximumWeeklyBenefit'] = rate.maximumWeeklyBenefit;
                            updatedProductPlanSet[i][1]['quote']['totalWeeklyBenefit'] = rate.totalWeeklyBenefit
                            updatedProductPlanSet[i][1]['quote']['totalMonthlyPremium'] = rate.totalMonthlyPremium;
                            updatedProductPlanSet[i][1]['quote']['timesRatesPer100ofMCP'] = rate.timesRatesPer100ofMCP;
                            updatedProductPlanSet[i][1]['quote']['rateOver10WeeklyBenefit'] = rate.rateOver10WeeklyBenefit;
                            updatedProductPlanSet[i][1]['quote']['censusRates'] = rate.censusRates;
                            
                            if(defaultRatingType === 'ageBands'){
                                updatedProductPlanSet[i][1]['finalRates']['ageBands'] = rate.ageBands
                                updatedProductPlanSet[i][1]['quote']['ageBands'] = rate.ageBands
                                updatedProductPlanSet[i][1]['rateType'] = ['longTermDis'].includes(action.product) ? 'Age-banded per $100 of monthly covered payroll' : 'Age-banded per $10 of Weekly Benefit'
                            }else{
                                updatedProductPlanSet[i][1]['rateType'] = ['longTermDis'].includes(action.product) ? 'Composite per $100 of monthly covered payroll' : 'Composite per $10 of Weekly Benefit'
                            }
                        }
                        if(['suppLife'].includes(action.product)){
                            updatedProductPlanSet[i][1]['quote']={
                                ADDTotVolAmount:"0",  
                                aDndTotalPremium:"0",
                                totalMonthlyPremium:parseFloat(rate.byCensus.totalEEMonthlyPremium),
                                totalMonthlyPremiumDep:parseFloat(rate.byCensus.totalEEMonthlyPremium)+parseFloat(rate.byCensus.totalCHMonthlyPremium)+parseFloat(rate.byCensus.totalSPMonthlyPremium)
                            }
                            updatedProductPlanSet[i][1]['finalMonthlyPremium'] = parseFloat(rate.byCensus.totalEEMonthlyPremium)+parseFloat(rate.byCensus.totalCHMonthlyPremium)+parseFloat(rate.byCensus.totalSPMonthlyPremium)

                            updatedProductPlanSet[i][1]['finalRates'] = {
                                employeeRate: [],
                                totalAnnualPremium: parseFloat(rate.byCensus.totalEEMonthlyPremium) * 12,
                                totalMonthlyPremium: parseFloat(rate.byCensus.totalEEMonthlyPremium),
                                ADDTotVolAmount:"",
                                ...rate
                            }
                            updatedProductPlanSet[i][1]['byCensus']={
                                employeeRates:[],
                                finalMonthlyPremium:parseFloat(rate.byCensus.totalEEMonthlyPremium)
                            }
                        }
                        if (selectedPlanIndex >= 0) {
                            updatedProductSelectedPlans[selectedPlanIndex] = updatedProductPlanSet[i][1]
                        }
                    }
                    isAllProductPlanRatesFetched = isAllProductPlanRatesFetched && !updatedProductPlanSet[i][1].isFetchingRates
                } else {
                    isAllProductPlanRatesFetched = false
                }
            }
            return {
                ...state,
                [['shortTermDis','longTermDis','suppLife'].includes(action.product) ? action.productType : action.product]: {
                    selectedPlans: updatedProductSelectedPlans,
                    set: updatedProductPlanSet
                },
                mmr: {
                    ...state.mmr,
                    [`isFetching${productFlag1}Rates`]: !isAllProductPlanRatesFetched
                }
            };
        case types.ERROR_MMR_ADDITIONAL_RATES:
            let productFlag = action.product[0].toUpperCase() + action.product.slice(1)
            if(productFlag==="SuppLife") productFlag="SUPPLIFE"
            let finalSelectedPlans = ['shortTermDis','longTermDis'].includes(action.product) ? JSON.parse(JSON.stringify(state[action.productType]?.selectedPlans)) : JSON.parse(JSON.stringify(state[action.product]?.selectedPlans))
            let finalSet = ['shortTermDis','longTermDis'].includes(action.product) ? JSON.parse(JSON.stringify(state[action.productType].set)) : JSON.parse(JSON.stringify(state[action.product].set))
            finalSelectedPlans = finalSelectedPlans.filter(plan => plan.code !== action.planCode) // remove failed rate call plan from selectedPlans
            finalSet = finalSet.map(set => {  // remove failed rate call plan from set
                if (set.length > 1 && set[1].code === action.planCode) {
                    return [set[0]]
                } else {
                    return set
                }
            })
            return {
                ...state,
                mmr: {
                    ...state.mmr,
                    [`isFetching${productFlag}Rates`]: false
                },
                [action.product]: {
                    set: finalSet,
                    selectedPlans: finalSelectedPlans
                }
            }
        // Remove it if not used
        case types.REQUEST_MMR_BENEFIT_RIDER_RATES:
            return handleRequestBenefitRiderRates(state)
        case types.RECEIVE_MMR_BENEFIT_RIDER_RATES:
            let newSelectedPlans = JSON.parse(JSON.stringify(state.medical.selectedPlans));
            let newSet = JSON.parse(JSON.stringify(state.medical.set));

            action.rates.forEach((rate) => {
                const setIdx = newSet.findIndex((set) => set.length > 1 && set[1].code === rate.medicalPlanCode)
                const selectedPlanIdx = newSelectedPlans.findIndex(
                    (plan) => plan.code === rate.medicalPlanCode,
                );
                if (setIdx > -1) {
                    newSet[setIdx][1] = {
                        ...newSet[setIdx][1],
                        benefitRiderRates: rate.benefitRiderRates,
                    }
                }
                if (selectedPlanIdx > -1) {
                    newSelectedPlans[selectedPlanIdx] = {
                        ...newSelectedPlans[selectedPlanIdx],
                        benefitRiderRates: rate.benefitRiderRates,
                    };
                }
            });

            return {
                ...state,
                medical: {
                    set: newSet,
                    selectedPlans: newSelectedPlans,
                }
            }
        case types.REMOVE_ALL_DENTAL_PLANS:
            return {
                ...state,
                dental: {
                    ...state.dental,
                    selectedPlans: []
                }
            }
        case types.REMOVE_ALL_VISION_PLANS:
            return {
                ...state,
                vision: {
                    ...state.vision,
                    selectedPlans: []
                }
            }
        case types.REMOVE_ALL_LIFE_PLANS:
            return {
                ...state,
                life: {
                    ...state.life,
                    selectedPlans: []
                }
            }
        case types.SET_MMR_INSTALL_ONLY_PLANS:
            return {
                ...state,
                mmr: {
                    ...state.mmr,
                    installOnlyPlans: action.payload
                }
            } 
        case types.SET_MMR_INSTALL_ONLY_PLANS_DETAILS:
            return {
                ...state,
                mmr: {
                    ...state.mmr,
                    installOnlyPlansDetails: state.mmr.installOnlyPlansDetails && state.mmr.installOnlyPlansDetails.length > 0
                        ? [...state.mmr.installOnlyPlansDetails, ...action.payload] : action.payload
                }
            } 
            case types.UPDATE_RENEWAL_PARENT_PLAN_CODE_LIFE_DEP_BASIC:
                const updatedDepLifeSelectedPlans = deepCopy(state.dependentLife && state.dependentLife.selectedPlans||[]);
                const index = updatedDepLifeSelectedPlans.findIndex(plan => plan.code === action.planCode);
                if(index > -1){
                    if(updatedDepLifeSelectedPlans[index].parentPlanCode){
                        updatedDepLifeSelectedPlans[index].parentPlanCode = action.parentPlanCode;
                    }
                }
                return {
                    ...state,
                    dependentLife:{
                        selectedPlans : updatedDepLifeSelectedPlans
                    }
                   
                }    
        default:
            return state;
    }
}

