import * as types from '../../actions/actionTypes';
import deepCopy from '../../utils/deepCopy';
import { findErrors } from './enrollmentRulesHelper';
import hasAtLeastOneOOA from '../../utils/businessLogic/atLeastOneOOA';
import { handleAdjustRatesAndPremium } from '../products/helpers/rateAdjustmentHelper';
import { handleChangeCustomPlanName, handleUpdateGIAmountInLifePlans } from '../products/helpers/filteringReducerHelpers';
import { setPlanDefaultType } from '../../utils/Renewal/uhcUpdateRates';

const initialState = {
    // Async rule fetch status
    isFetchingEnrollmentRules: false,
    enrollmentRulesFetchError: false,

    // Rule values
    maxSingleChoicePlans: null,
    maxSingleChoicePlansArray: null, // for when max single choice plans is an array of objects, e.g., diff rules for diff. effective dates
    maxMultiChoicePackages: null,
    maxAgeBandedPlans: null,
    maxRateDifferential: null,
    maxDentalPlans: null,
    maxVisionPlans: null,
    maxLifePlans: null,
    maxSTDPlans: null,
    maxLTDPlans: null,
    maxDependentLifePlans: null,
    platform: null,

    // Application state that affects rule violation status
    enrolledPlans: {},
    enrolledDentalPlans: [],
    enrolledVisionPlans: [],
    // enrolledSTDPlans: [{
    //     "code": "STD134",

    // },
    // {
    //     "code": "STD122223",

    // }],
    // enrolledLTDPlans: [{
    //     "code": "LTD302",
    // },
    // {
    //     "code": "LTD134",

    // }],
    enrolledLifePlans: [],
    enrolledLTDPlans: [],
    enrolledSTDPlans: [],
    enrolledDependentLifePlans: [],
    enrolledSuppLifeDepPlans: [],
    enrolledSuppLifePlans: [],
    numEnrolledPlans: 0,
    atLeastOneOutOfAreaEmployee: false,
    numReviewConsiderPlans: 0,
    numRenewalsAdditionalSelectedPlans: 0,
    // Rule violation status
    errors: {},
    numErrors: 0,
    finalRatesError: null,
    finalRatesDiff: false,
    fetchingfinalRates: false,
    fetchingFinalMedicalRates: false,
    fetchingFinalDentalRates: false,
    fetchingFinalVisionRates: false,
    fetchingFinalLifeRates: false,
    fetchingFinalStdRates: false,
    fetchingFinalLtdRates: false,
    finalRatesDiffMedical: false,
    finalRatesDiffDental: false,
    finalRatesDiffVision: false,
    finalRatesDiffLife: false,
    finalRatesDiffDependentLife: false,
    finalRatesDiffSuppLife: false,
    finalRatesDiffSuppLifeSpouse: false,
    finalRatesDiffSuppLifeChild: false,
    finalRatesDiffStd: false,
    finalRatesDiffLtd: false,
    pcpAutoAssignemntRules: [],
    enableFinalizeEnrollment: {
        medical : false,
        dental : false,
        vision : false,
        life : false
    }
};

export default function resize(state = initialState, action) {

    switch (action.type) {
        case types.REQUEST_ENROLLMENT_RULES:
            return {
                ...state,
                isFetchingEnrollmentRules: true,
                enrollmentRulesFetchError: false,
            };
        case types.RECEIVE_ENROLLMENT_RULES:
            return {
                ...state,
                isFetchingEnrollmentRules: false,
                ...action,
                ...action.specialRules
            };

        case types.SERVICE.FI.CLEAR_ENROLLED_PLANS:
            return {
                ...state,
                enrolledPlans: {},
            };
        case types.SERVICE.DENTAL.CLEAR_ENROLLED_PLANS:
            return {
                ...state,
                enrolledDentalPlans: [],
            };
        case types.SERVICE.VISION.CLEAR_ENROLLED_PLANS:
            return {
                ...state,
                enrolledVisionPlans: [],
            };
        case types.SERVICE.LIFE.CLEAR_ENROLLED_PLANS:
            return {
                ...state,
                enrolledLifePlans: [],
                enrolledDependentLifePlans: [],
            };
        case types.SERVICE.SUPPLIFE.CLEAR_ENROLLED_PLANS:
            return {
                ...state,
                enrolledSuppLifePlans: [],
                };
        case types.SERVICE.STD.CLEAR_ENROLLED_PLANS:
            return {
                ...state,
                enrolledSTDPlans: [],
            };
        case types.SERVICE.LTD.CLEAR_ENROLLED_PLANS:
            return {
                ...state,
                enrolledLTDPlans: [],
            };
        case types.ERROR_ENROLLMENT_RULES:
            return {
                ...state,
                isFetchingEnrollmentRules: false,
                enrollmentRulesFetchError: true,
            };

        case types.SERVICE.MEDICAL.TOGGLE_PLAN_ENROLL:
        case types.SERVICE.FI.TOGGLE_PLAN_ENROLL: {
            const pkg = action.withinPackage;
            const packageKeyExists = state.enrolledPlans[pkg] !== undefined;

            let enrolledIdx = -1;
            if (packageKeyExists) {
                enrolledIdx = state.enrolledPlans[pkg].findIndex(
                    (plan) => plan.code === action.plan.code,
                );
            }

            const ep = deepCopy(state.enrolledPlans);
            let newNumEnrolled = state.numEnrolledPlans;

            // For renewals Decide Page error validation
            let { numReviewConsiderPlans } = state;
            let { numRenewalsAdditionalSelectedPlans } = state;

            // Add or remove the plan from enrolledPlans array
            if (enrolledIdx === -1) {
                newNumEnrolled += 1;
                if (!packageKeyExists) {
                    ep[pkg] = [action.plan];
                } else {
                    ep[pkg] = ep[pkg].concat(action.plan);
                }
                if (action.isRenewalsFlow) {
                    if (action.isRenewalsAdditionalMedical) numRenewalsAdditionalSelectedPlans += 1;
                    else numReviewConsiderPlans += 1;
                }
            } else {
                newNumEnrolled -= 1;
                if (action.isRenewalsFlow) {
                    if (action.isRenewalsAdditionalMedical) numRenewalsAdditionalSelectedPlans -= 1;
                    else numReviewConsiderPlans -= 1;
                }

                ep[pkg] = ep[pkg]
                    .slice(0, enrolledIdx)
                    .concat(ep[pkg].slice(enrolledIdx + 1));
            }

            return {
                ...state,
                enrolledPlans: ep,
                numEnrolledPlans: newNumEnrolled,
                numReviewConsiderPlans,
                numRenewalsAdditionalSelectedPlans,
                ...findErrors(state, ep, action.usingAgeBandedRating, 'medical', null, action.employeeClassing, action.uhcRenewalsFlag,
                action.stateCode, action.totalEmp, action.groupSize),//
            };
        }
        case types.SERVICE.DENTAL.TOGGLE_PLAN_ENROLL:
        case types.SERVICE.VISION.TOGGLE_PLAN_ENROLL:
        case types.SERVICE.LIFE.TOGGLE_PLAN_ENROLL:
        case types.SERVICE.STD.TOGGLE_PLAN_ENROLL:
        case types.SERVICE.LTD.TOGGLE_PLAN_ENROLL:
        case types.SERVICE.LIFE_DEP_BASIC.TOGGLE_PLAN_ENROLL: {
            const { planType } = action.plan;
            let type;

            if (planType === 'LIFE_DEP_BASIC' || planType === 'life_dep_basic') {
                type = 'DependentLife';
            } else if (planType === 'SUPPLIFE' || planType === 'suppLife') {
                type = 'SuppLife';
            } else if (planType === 'SUPP_DEP_LIFE' || planType === 'supp_dep_life') {
                type = 'SuppLifeDep';
            } else {
                type = planType;
            }

            const enrolledIndex = (action.serviceType === "LIFE" || action.serviceType === "STD" || action.serviceType === "LTD" || action.serviceType === "SUPPLIFE") && action.employeeClassing.classingOpted ?
                state[`enrolled${type}Plans`].findIndex(plan => plan.code === action.plan.code && action.withinPackage === plan.selectedClass) :
                state[`enrolled${type}Plans`].findIndex(plan => plan.code === action.plan.code);
            let enrolledPlans = deepCopy(state[`enrolled${type}Plans`]);
            let { numEnrolledPlans } = state;

            // Add or remove the plan from enrolledPlans array
            if (enrolledIndex === -1 && !action.remove) {
                numEnrolledPlans += 1;
                enrolledPlans = enrolledPlans.concat(action.plan);
            } else if (enrolledIndex !== -1) {
                numEnrolledPlans -= 1;
                enrolledPlans = enrolledPlans
                    .slice(0, enrolledIndex)
                    .concat(enrolledPlans.slice(enrolledIndex + 1));
            }

            return {
                ...state,
                [`enrolled${type}Plans`]: enrolledPlans,
                numEnrolledPlans,
                ...findErrors(
                    state, state.enrolledPlans,
                    action.usingAgeBandedRating, type, enrolledPlans, action.employeeClassing,
                    action.uhcRenewalsFlag, null, null, action.groupSize
                ),
            };
        }
        case types.SERVICE.SUPPLIFE.TOGGLE_PLAN_ENROLL:
        case types.SERVICE.SUPPDEPLIFE.TOGGLE_PLAN_ENROLL:
            let type;
            if (action.serviceType=== "SUPPDEPLIFE") {
                type = 'suppDepLife';
            } else {
                type ="suppLife"
            }
            const enrolledIndex = state.enrolledSuppLifePlans.findIndex(plan => plan.code === action.plan.code);
            let enrolledPlans = deepCopy(state.enrolledSuppLifePlans);
            let { numEnrolledPlans } = state;
            if (enrolledIndex !== -1 && !action.remove) {
                if (type === "suppDepLife") {
                     //toggle enrolled flag  in the enrolled plan array.
                    if (action.plan.SLSpPlan.enrolled) {
                        numEnrolledPlans += 2;
                        enrolledPlans[enrolledIndex] = action.plan;
                    } else {
                        numEnrolledPlans -= numEnrolledPlans;
                        // Remove Dep Supp Life plan if It's EE Plan is not selected.
                        if (!action.plan.enrolled) {
                            enrolledPlans = enrolledPlans
                            .slice(0, enrolledIndex)
                            .concat(enrolledPlans.slice(enrolledIndex + 1));
                        } else {
                            enrolledPlans[enrolledIndex] = action.plan;
                        }
                    }
                } else if ( type === "suppLife") {
                    if (action.plan.enrolled) {
                        numEnrolledPlans += 1;
                    } else if ( action.plan.SLSpPlan.enrolled) {
                        numEnrolledPlans -= 1;
                    }

                    enrolledPlans[enrolledIndex] = action.plan;
                    if (!action.plan.enrolled && !action.plan.SLSpPlan.enrolled) {
                        numEnrolledPlans -= 1;
                        enrolledPlans = enrolledPlans
                            .slice(0, enrolledIndex)
                            .concat(enrolledPlans.slice(enrolledIndex + 1));
                    }
                }
            }
            // Add plan into enrolledPlans array
            else if (enrolledIndex === -1 && !action.remove) {
                numEnrolledPlans = action.serviceType=== "SUPPDEPLIFE"? numEnrolledPlans+2:numEnrolledPlans+1;
                enrolledPlans = enrolledPlans.concat(action.plan);
            } 
            return {
                ...state,
                enrolledSuppLifePlans: enrolledPlans,
                numEnrolledPlans,
                ...findErrors(
                state, state.enrolledPlans,
                action.usingAgeBandedRating, type, enrolledPlans, action.employeeClassing,
                action.uhcRenewalsFlag, null, null, action.groupSize
                ),
            };

        case types.SERVICE.FI.TOGGLE_PACKAGE_ENROLL: {
            const ep = deepCopy(state.enrolledPlans);
            let newNumEnrolled = state.numEnrolledPlans;

            const plansInPackage = action.selectedPlans
                .filter((plan) => plan.packages.includes(action.packageCode));
            const numPlansInPackage = plansInPackage.length;
            const numPlansInPackageEnrolled = (ep[action.packageCode] || []).length;
            const allEnrolled = numPlansInPackageEnrolled === numPlansInPackage;

            // Update the plans stored in the enrollment array
            if (allEnrolled) {
                newNumEnrolled -= numPlansInPackageEnrolled;
                ep[action.packageCode] = [];
            } else {
                newNumEnrolled += numPlansInPackage - numPlansInPackageEnrolled;
                ep[action.packageCode] = plansInPackage;
            }

            return {
                ...state,
                enrolledPlans: ep,
                numEnrolledPlans: newNumEnrolled,
                ...findErrors(state, ep, action.usingAgeBandedRating, null, null, action.employeeClassing,
                    action.uhcRenewalsFlag),
            };
        }
        case types.PROFILE_UPDATED: {
            let atLeastOneOutOfAreaEmployee = false;
            const errors=state.errors
            if (action.useSimple) {
                atLeastOneOutOfAreaEmployee = action.companyProfile.simpleCensus.ooa;
            } else if (action.companyProfile.employees) {
                atLeastOneOutOfAreaEmployee = hasAtLeastOneOOA(
                    action.companyProfile.employees,
                );
            }
            const numErrors = Object.keys(state.errors).filter((keyName) => state.errors[keyName] === true).length;
            return {
                ...state,
                atLeastOneOutOfAreaEmployee,
                errors,
                numErrors
            };
        }

        case types.SERVICE.FI.ERROR_FINALIZED_QUOTE_RATES:
            return { ...state, finalRatesError: 'Something Went Wrong Fetching Rates', fetchingfinalRates: false };
        case types.SERVICE.FI.FETCHING_FINALIZED_QUOTE_RATES:
            return { ...state, finalRatesError: null, fetchingfinalRates: true };

        // Configures the finalized rates response into the
        // 'plans' array of objects (note that rates come in batches of 10)
        //
        // Parameters
        //  rates:          Array of rates - either age-banded or tiered
        case types.SERVICE.FI.RECEIVE_FINALIZED_QUOTE_RATES: {
            const finalEnrollPlans = deepCopy(state.enrolledPlans);
            let hasDifferentPremium = false;
            let hasDifferentPremiumMedical = false;
            let hasDifferentPremiumDental = false;
            let hasDifferentPremiumVision = false;
            let hasDifferentPremiumLife = false;
            let hasDifferentPremiumDependentLife = false;
            let hasDifferentPremiumStd = false;
            let hasDifferentPremiumLtd = false;
            let hasDifferentPremiumSuppLife = false;
            let hasDifferentPremiumSuppLifeSpouse = false;
            let hasDifferentPremiumSuppLifeChild = false;
            // TODO this assumes that the finalized plans are cirrus/standalone
            if (action.rates && action.rates.length > 0) {
                action.rates.forEach((rate) => {
                    const packageIdx = Object.keys(finalEnrollPlans).findIndex(key => {
                        return finalEnrollPlans[key].findIndex(plan => plan.code === rate.medicalPlanCode) > -1 ? true : false;
                    })
                    const packageKey = Object.keys(finalEnrollPlans)[packageIdx];
                    const planIdx = finalEnrollPlans[packageKey].findIndex(plan => plan.code === rate.medicalPlanCode);

                    let totalPremiumKey;
                    let employeeRateType;
                    if (action.ratingMethod) {
                        totalPremiumKey = action.ratingMethod === 'ageBands' || action.ratingMethod.age
                            ? 'premiumTotalAgeBandedMonthly'
                            : 'premiumTotalCompositeMonthly';
                        employeeRateType = action.ratingMethod === 'ageBands' || action.ratingMethod.age
                            ? 'employeeRatesAgeBanded'
                            : 'employeeRatesComposite'
                    } else {
                        totalPremiumKey = rate.defaultRatingType === 'ageBands'
                            ? 'premiumTotalAgeBandedMonthly'
                            : 'premiumTotalCompositeMonthly';
                        employeeRateType = rate.defaultRatingType === 'ageBands'
                            ? 'employeeRatesAgeBanded'
                            : 'employeeRatesComposite'
                    }
                    const updatedEmployeeRates = rate.employeeRatesAgeBanded && rate.employeeRatesComposite ? rate[employeeRateType] : rate.employeeRates

                    finalEnrollPlans[packageKey][planIdx] = {
                        ...finalEnrollPlans[packageKey][planIdx],
                        ...rate,
                        employeeRates: updatedEmployeeRates,
                        quote: action.isAfterCloseEnrollmentQuoting ? rate[totalPremiumKey] : finalEnrollPlans[packageKey][planIdx].quote,
                        quoteFinal: rate[totalPremiumKey],
                        hasDifferentPremium: finalEnrollPlans[packageKey][planIdx].quote !== rate[totalPremiumKey]
                    };

                    if(action.isUhcRenewal && action.ratingMethod) {
                        finalEnrollPlans[packageKey][planIdx] = {
                            ...finalEnrollPlans[packageKey][planIdx],
                            defaultRatingType: action.ratingMethod
                        }
                    }
                    hasDifferentPremium = parseFloat(finalEnrollPlans[packageKey][planIdx].quote).toFixed(2) !== parseFloat(finalEnrollPlans[packageKey][planIdx].quoteFinal).toFixed(2);
                    if (parseFloat(finalEnrollPlans[packageKey][planIdx].quote).toFixed(2) !== parseFloat(rate[totalPremiumKey]).toFixed(2))
                        hasDifferentPremiumMedical = true
                });
            }

            const finalDentalPlans = deepCopy(state.enrolledDentalPlans || []);
            if (action.dentalRates && action.dentalRates.length > 0) {
                action.dentalRates.forEach((rate, index) => {
                    const planIdx = finalDentalPlans.findIndex(
                        (plan) => plan.code === rate.code,
                    );
                    finalDentalPlans[planIdx] = {
                        ...finalDentalPlans[planIdx],
                        platformRatingID: rate.platformRatingID,
                        byCensus: rate.byCensus,
                        monthlyPremium: rate.monthlyPremium,
                        finalMonthlyPremium: rate.byCensus.totalMonthlyPremium,
                        finalRates: rate.monthlyPremium,
                        quote: action.isAfterCloseEnrollmentQuoting ? rate.byCensus.totalMonthlyPremium : finalDentalPlans[planIdx].quote,
                        quoteFinal: rate.byCensus.totalMonthlyPremium,
                        hasDifferentPremium: parseFloat(finalDentalPlans[planIdx].quote).toFixed(2) !== parseFloat(rate.byCensus.totalMonthlyPremium).toFixed(2),
                    };
                    if (parseFloat(finalDentalPlans[planIdx].quote).toFixed(2) !== parseFloat(rate.byCensus.totalMonthlyPremium).toFixed(2)) {
                        hasDifferentPremiumDental = true;
                    }
                });
            }

            const finalVisionPlans = deepCopy(state.enrolledVisionPlans || []);
            if (action.visionRates && action.visionRates.length > 0) {
                action.visionRates.forEach((rate, index) => {
                    const planIdx = finalVisionPlans.findIndex(
                        (plan) => plan.code === rate.code,
                    );
                    finalVisionPlans[planIdx] = {
                        ...finalVisionPlans[planIdx],
                        platformRatingID: rate.platformRatingID,
                        byCensus: rate.byCensus,
                        monthlyPremium: rate.monthlyPremium,
                        finalMonthlyPremium: rate.byCensus.totalMonthlyPremium,
                        finalRates: rate.monthlyPremium,
                        quote: action.isAfterCloseEnrollmentQuoting ? rate.byCensus.totalMonthlyPremium : finalVisionPlans[planIdx].quote,
                        quoteFinal: rate.byCensus.totalMonthlyPremium,
                        hasDifferentPremium: parseFloat(finalVisionPlans[planIdx].quote).toFixed(2) !== parseFloat(rate.byCensus.totalMonthlyPremium).toFixed(2),
                    };
                    if (parseFloat(finalVisionPlans[planIdx].quote).toFixed(2) !== parseFloat(rate.byCensus.totalMonthlyPremium).toFixed(2)) {
                        hasDifferentPremiumVision = true;
                    }
                });
            }

            //NOTE: Probably not correct mapping. Will need to revisit
            let finalStdPlans = deepCopy(state.enrolledSTDPlans || []);
            if (action.stdRates && action.stdRates.length > 0) {
                action.stdRates.forEach((rate, index) => {
                    let planIdx;
                    if (action.classingOpted && action.empClass !== '') {
                        planIdx = finalStdPlans.findIndex(
                            plan => ((plan.piaPlanCode === rate.stdPlanCode || plan.code == rate.stdPlanCode) && (plan.selectedClass === action.empClass))
                        )
                        if (planIdx > -1) {
                            finalStdPlans[planIdx].classRates[action.empClass] = {
                                ...finalStdPlans[planIdx].classRates[action.empClass],
                                quote: finalStdPlans[planIdx].classRates[action.empClass]?.quote,
                                finalMonthlyPremium: rate.totalMonthlyPremium,
                                finalRates: rate,
                            }
                        }
                    } else {
                        planIdx = finalStdPlans.findIndex(
                            plan => (plan.piaPlanCode === rate.stdPlanCode || plan.code == rate.stdPlanCode)
                        )
                    }
                    if (planIdx > -1) {
                    finalStdPlans[planIdx] = {
                        ...finalStdPlans[planIdx],
                        platformRatingID: rate.quoteId,
                        monthlyPremium: rate.monthlyPremium,
                        finalMonthlyPremium: rate.totalMonthlyPremium,
                        finalRates: rate,
                        quote: action.isAfterCloseEnrollmentQuoting ? rate.totalMonthlyPremium : finalStdPlans[planIdx]?.quote,
                        quoteFinal: rate.totalMonthlyPremium,
                        hasDifferentPremium: parseFloat(finalStdPlans[planIdx].quote.totalMonthlyPremium).toFixed(2) !== parseFloat(rate.totalMonthlyPremium).toFixed(2),
                        employeeRatesAgeBanded: rate.employeeRatesAgeBanded,
                        employeeRatesComposite: rate.employeeRatesComposite,
                    };
                    if (parseFloat(finalStdPlans[planIdx].quote.totalMonthlyPremium).toFixed(2) !== parseFloat(rate.totalMonthlyPremium).toFixed(2)) {
                        hasDifferentPremiumStd = true;
                    }
                }
                });
                 // Has different premium should be calculated based on all the std rate calls in case of classing.
                 if (action.classingOpted) {
                    hasDifferentPremiumStd = state.finalRatesDiffStd || hasDifferentPremiumStd
                }
            }

            let finalLtdPlans = deepCopy(state.enrolledLTDPlans || []);
            if (action.ltdRates && action.ltdRates.length > 0) {
                action.ltdRates.forEach((rate, index) => {
                    let planIdx;
                    if (action.classingOpted && action.empClass !== '') {
                        planIdx = finalLtdPlans.findIndex(
                            plan => ((plan.piaPlanCode === rate.ltdPlanCode || plan.code == rate.ltdPlanCode ) && (plan.selectedClass === action.empClass))
                        )
                        if (planIdx > -1) {
                            finalLtdPlans[planIdx].classRates[action.empClass] = {
                                ...finalLtdPlans[planIdx].classRates[action.empClass],
                                quote: finalLtdPlans[planIdx].classRates[action.empClass]?.quote,
                                finalMonthlyPremium: rate.totalMonthlyPremium,
                                finalRates: rate,
                               
                            }
                        }
                    } else {
                        planIdx = finalLtdPlans.findIndex(
                            plan => (plan.piaPlanCode === rate.ltdPlanCode || plan.code == rate.ltdPlanCode)
                        )
                    }
                    if (planIdx > -1) {
                    finalLtdPlans[planIdx] = {
                        ...finalLtdPlans[planIdx],
                        platformRatingID: rate.quoteId,
                        monthlyPremium: rate.monthlyPremium,
                        finalMonthlyPremium: rate.totalMonthlyPremium,
                        finalRates: rate,
                        quote: action.isAfterCloseEnrollmentQuoting ? rate.totalMonthlyPremium : finalLtdPlans[planIdx]?.quote,
                        quoteFinal: rate.totalMonthlyPremium,
                        hasDifferentPremium: parseFloat(finalLtdPlans[planIdx].quote.totalMonthlyPremium).toFixed(2) !== parseFloat(rate.totalMonthlyPremium).toFixed(2),
                        employeeRatesAgeBanded: rate.employeeRatesAgeBanded,
                        employeeRatesComposite: rate.employeeRatesComposite,
                    };
                    if (parseFloat(finalLtdPlans[planIdx].quote.totalMonthlyPremium).toFixed(2) !== parseFloat(rate.totalMonthlyPremium).toFixed(2)) {
                        hasDifferentPremiumLtd = true;
                    }
                }
                });
                 // Has different premium should be calculated based on all the ltd rate calls in case of classing.
                 if (action.classingOpted) {
                    hasDifferentPremiumLtd = state.finalRatesDiffLtd || hasDifferentPremiumLtd
                }
            }

            const finalLifePlans = deepCopy(state.enrolledLifePlans || []);
            const finalDependentLifePlans = deepCopy(state.enrolledDependentLifePlans || []);
            if (action.lifeRates.length > 0) {

                action.lifeRates.forEach((rate) => {
                    let planIdx;
                    if (action.classingOpted && action.empClass !== '') {
                        planIdx = finalLifePlans.findIndex(
                            plan => ((plan.code === rate.code || plan.primePlanCode === rate.code) && (plan.selectedClass === action.empClass))
                        )
                        if (planIdx > -1) {
                            finalLifePlans[planIdx].classRates[action.empClass] = {
                                ...finalLifePlans[planIdx].classRates[action.empClass],
                                quote: rate,
                                finalMonthlyPremium: rate.byCensus.totalMonthlyPremium,
                                finalRates: rate,
                                byCensus: rate.byCensus
                            }
                        }
                    } else {
                        planIdx = finalLifePlans.findIndex(
                            plan => (plan.code === rate.code || plan.primePlanCode === rate.code)
                        )
                    }
                    if (planIdx > -1) {
                        finalLifePlans[planIdx] = {
                            ...finalLifePlans[planIdx],
                            platformRatingID: rate.platformRatingID ? rate.platformRatingID : '',
                            quoteId: rate.quoteId ? rate.quoteId : '',
                            quote: action.isAfterCloseEnrollmentQuoting ? rate.byCensus.totalMonthlyPremium : finalLifePlans[planIdx].quote,
                            quoteFinal: rate.byCensus.totalMonthlyPremium ? rate.byCensus.totalMonthlyPremium : '',
                            finalMonthlyPremium: rate.byCensus.totalMonthlyPremium ? rate.byCensus.totalMonthlyPremium : '',
                            finalRates: rate,
                            byCensus: rate.byCensus,
                            hasDifferentPremium: parseFloat(finalLifePlans[planIdx].quote.totalMonthlyPremium).toFixed(2) !== parseFloat(rate.byCensus.totalMonthlyPremium).toFixed(2)
                        }
                        if (parseFloat(finalLifePlans[planIdx].quote.totalMonthlyPremium).toFixed(2) !== parseFloat(rate.byCensus.totalMonthlyPremium).toFixed(2)) {
                            hasDifferentPremiumLife = true
                        }
                    }
                })

                // TODO: dependent life needs to be refactored for classing
                // for dependent life
                action.lifeRates.forEach((rate) => {
                    const planIdx = finalDependentLifePlans.findIndex(
                        plan => (plan.code === rate.code || plan.primePlanCode === rate.code)
                    );
                    if (planIdx > -1) {
                        const totalMonthlyPremium = calculateDependentLifeRate(action.flatEmployees, rate, finalDependentLifePlans[planIdx])
                        finalDependentLifePlans[planIdx] = {
                            ...finalDependentLifePlans[planIdx],
                            quoteId: rate.quoteId ? rate.quoteId : '',
                            quote: action.isAfterCloseEnrollmentQuoting ? rate.byCensus.totalMonthlyPremium : finalDependentLifePlans[planIdx].quote,
                            quoteFinal: totalMonthlyPremium ? totalMonthlyPremium : '0.00',
                            finalMonthlyPremium: totalMonthlyPremium ? totalMonthlyPremium : '0.00',
                            finalRates: rate,
                            hasDifferentPremium: parseFloat(finalDependentLifePlans[planIdx].prevFinalMonthlyPremium).toFixed(2) !== parseFloat(totalMonthlyPremium).toFixed(2)
                        }
                        if (parseFloat(finalDependentLifePlans[planIdx].prevFinalMonthlyPremium).toFixed(2) !== parseFloat(totalMonthlyPremium).toFixed(2)) {
                            hasDifferentPremiumDependentLife = true
                        }
                    }
                })

                // Has different premium should be calculated based on all the life rate calls in case of classing.
                if (action.classingOpted) {
                    hasDifferentPremiumLife = state.finalRatesDiffLife || hasDifferentPremiumLife
                }
            }

            const finalSuppLifeDepPlans = deepCopy(state.enrolledSuppLifeDepPlans || []);

            const finalSuppLifePlans = deepCopy(state.enrolledSuppLifePlans || []);
        
            if (action.suppLifeRates && action.suppLifeRates.length > 0) {
                action.suppLifeRates.forEach((rate, index) => {
                    const planIdx = finalSuppLifePlans.findIndex(
                        (plan) => plan.platform === 'PRIME' ? plan.piaPlanCode === rate.slEEPlanCode : plan.code === rate.slEEPlanCode
                    );
                    if(planIdx > -1){
                        const childPremium = parseFloat(finalSuppLifePlans[planIdx].quote.byCensus.totalCHMonthlyPremium);
                        const spousePremium = parseFloat(finalSuppLifePlans[planIdx].quote.byCensus.totalSPMonthlyPremium);
                    finalSuppLifePlans[planIdx] = {
                        ...finalSuppLifePlans[planIdx],
                        platformRatingID: rate.quoteId,
                        byCensus: rate.byCensus,
                        monthlyPremium: rate.monthlyPremium,
                        finalMonthlyPremium: rate.byCensus.totalEEMonthlyPremium,
                        finalRates: rate,
                        quote: action.isAfterCloseEnrollmentQuoting ? rate.byCensus.totalEEMonthlyPremium : finalSuppLifePlans[planIdx].quote,
                        quoteFinal: rate.byCensus.totalEEMonthlyPremium,
                        quoteDepFinal: rate.byCensus.totalEEDepMonthlyPremium,
                        hasDifferentPremium: parseFloat(finalSuppLifePlans[planIdx].quote.byCensus.totalEEMonthlyPremium).toFixed(2) !== parseFloat(rate.byCensus.totalEEMonthlyPremium).toFixed(2),
                        hasDifferentSpousePremium: parseFloat(spousePremium).toFixed(2) !== parseFloat(rate.byCensus.totalSPMonthlyPremium).toFixed(2),
                        hasDifferentChildPremium: parseFloat(childPremium).toFixed(2) !== parseFloat(rate.byCensus.totalCHMonthlyPremium).toFixed(2),
                    };
                    if (parseFloat(finalSuppLifePlans[planIdx].quote.byCensus.totalEEMonthlyPremium).toFixed(2) !== parseFloat(rate.byCensus.totalEEMonthlyPremium).toFixed(2)) {
                        hasDifferentPremiumSuppLife = true;
                    }
                    if (parseFloat(spousePremium).toFixed(2) !== parseFloat(rate.byCensus.totalSPMonthlyPremium) .toFixed(2)) {
                        hasDifferentPremiumSuppLifeSpouse = true;
                    }
                    if (parseFloat(childPremium).toFixed(2) !== parseFloat(rate.byCensus.totalCHMonthlyPremium) .toFixed(2)) {
                        hasDifferentPremiumSuppLifeChild = true;
                    }
                }
                });
            }

            return {
                ...state,
                enrolledPlans: finalEnrollPlans,
                enrolledDentalPlans: finalDentalPlans,
                enrolledVisionPlans: finalVisionPlans,
                enrolledLifePlans: finalLifePlans,
                enrolledSuppLifeDepPlans: finalSuppLifeDepPlans,
                enrolledSuppLifePlans: finalSuppLifePlans,
                enrolledDependentLifePlans: finalDependentLifePlans,
                enrolledSTDPlans: finalStdPlans,
                enrolledLTDPlans:finalLtdPlans,
                finalRatesError: null,
                finalRatesDiff: hasDifferentPremium,
                fetchingfinalRates: false,
                finalRatesDiffMedical: action.rates && action.rates.length > 0 ? hasDifferentPremiumMedical : state.finalRatesDiffMedical,
                finalRatesDiffDental: action.dentalRates && action.dentalRates.length > 0 ? hasDifferentPremiumDental : state.finalRatesDiffDental,
                finalRatesDiffVision: action.visionRates && action.visionRates.length > 0 ? hasDifferentPremiumVision : state.finalRatesDiffVision,
                finalRatesDiffLife: action.lifeRates && action.lifeRates.length > 0 ? hasDifferentPremiumLife : state.finalRatesDiffLife,
                finalRatesDiffDependentLife: action.lifeRates && action.lifeRates.length > 0 ? hasDifferentPremiumDependentLife : state.finalRatesDiffDependentLife,
                finalRatesDiffStd: action.stdRates && action.stdRates.length > 0 ? hasDifferentPremiumStd : state.finalRatesDiffStd,
                finalRatesDiffLtd: action.ltdRates && action.ltdRates.length > 0 ? hasDifferentPremiumLtd : state.finalRatesDiffLtd,
                finalRatesDiffSuppLife: action.suppLifeRates && action.suppLifeRates.length > 0 ? hasDifferentPremiumSuppLife : state.finalRatesDiffSuppLife,
                finalRatesDiffSuppLifeSpouse: action.suppLifeRates && action.suppLifeRates.length > 0 ? hasDifferentPremiumSuppLifeSpouse : state.finalRatesDiffSuppLifeSpouse,
                finalRatesDiffSuppLifeChild: action.suppLifeRates && action.suppLifeRates.length > 0 ? hasDifferentPremiumSuppLifeChild : state.finalRatesDiffSuppLifeChild,
            };
        }

        case types.GET_ENROLLED_PLANS_RENEWALS:
            if(action.isUhcRenewals) {
                let enrolledPlans = {...state.enrolledPlans};
                const enrolledPlansList = [].concat(...Object.values(enrolledPlans));
                let shoppingPlans = action.payload.shoppingPlans;
                shoppingPlans.forEach(plan => {
                    for (let key in enrolledPlans) {
                        enrolledPlans[key].forEach(enrolledPlan => {
                            if (plan.code === enrolledPlan.code) {
                                plan.hiosplnID = enrolledPlan.hiosplnID;
                            }
                        });
                    }
                });
                console.log(shoppingPlans);
                let newNumEnrolled = state.numEnrolledPlans;
                let { numReviewConsiderPlans } = state;
                for(let i=0; i<shoppingPlans.length; i++) {
                    const pkg =
                        shoppingPlans[i].selectedPackages &&
                        shoppingPlans[i].selectedPackages.length > 0 &&
                        shoppingPlans[i].selectedPackages[0] &&
                        shoppingPlans[i].selectedPackages[0].toLowerCase() !==
                            "single"
                            ? shoppingPlans[i].selectedPackages[0]
                            : "standalone";
                    const packageKeyExists = enrolledPlans[pkg] !== undefined;
                    let enrolledIdx = -1;
                    if (packageKeyExists) {
                        enrolledIdx = enrolledPlans[pkg].findIndex(
                            (plan) => plan.code === shoppingPlans[i].code,
                        );
                    }
                    // Add or remove the plan from enrolledPlans array
                    if (enrolledIdx === -1) {
                        newNumEnrolled += 1;
                        if (!packageKeyExists) {
                            enrolledPlans[pkg] = [shoppingPlans[i]];
                        } else {
                            enrolledPlans[pkg] = enrolledPlans[pkg].concat(shoppingPlans[i]);
                        }
                        numReviewConsiderPlans += 1;
                    } else {
                        numReviewConsiderPlans += 1;
                        enrolledPlans[pkg] = enrolledPlans[pkg]
                            .slice(0, enrolledIdx)
                            .concat(enrolledPlans[pkg].slice(enrolledIdx + 1))
                            .concat(shoppingPlans[i]);
                    }
                }
                return {
                    ...state,
                    enrolledPlans: enrolledPlans,
                    enrolledDentalPlans: action.payload.enrolledDentalPlans,
                    enrolledDependentLifePlans: action.payload.enrolledDependentLifePlans || [],
                    enrolledSuppLifeDepPlans: action.payload.enrolledSuppLifeDepPlans || [],
                    enrolledSuppLifePlans: action.payload.enrolledSuppLifePlans || [],
                    enrolledLifePlans: action.payload.enrolledLifePlans,
                    enrolledVisionPlans: action.payload.enrolledVisionPlans,
                    enrolledLTDPlans: action.payload.enrolledLTDPlans || [],
                    enrolledSTDPlans:action.payload.enrolledSTDPlans || [],
                    numEnrolledPlans: newNumEnrolled,
                    numReviewConsiderPlans,
                }
            } else {
                return {
                    ...state,
                    enrolledPlans: {
                        ...state.enrolledPlans,
                        standalone: action.payload.shoppingPlans,
                    },
                    enrolledDentalPlans: action.payload.enrolledDentalPlans,
                    enrolledDependentLifePlans: action.payload.enrolledDependentLifePlans || [],
                    enrolledSuppLifeDepPlans: action.payload.enrolledSuppLifeDepPlans || [],
                    enrolledSuppLifePlans: action.payload.enrolledSuppLifePlans || [],
                    enrolledLifePlans: action.payload.enrolledLifePlans,
                    enrolledVisionPlans: action.payload.enrolledVisionPlans,
                    enrolledLTDPlans: action.payload.enrolledLTDPlans || [],
                    enrolledSTDPlans:action.payload.enrolledSTDPlans || [],
                    enrolledSuppLifeDepPlans: action.payload.enrolledSuppLifeDepPlans || [],
                    enrolledSuppLifePlans: action.payload.enrolledSuppLifePlans || [],
                };
            }

        case types.UPDATE_ENROLLED_PLANS_RENEWALS: {
            const updatedPlans = [];
            state.enrolledPlans.standalone.forEach((item) => {
                if (item.code !== action.payload.query.code) {
                    updatedPlans.push(item);
                }
            });

            return {
                ...state,
                enrolledPlans: {
                    ...state.enrolledPlans,
                    standalone: updatedPlans,
                },
            };
        }
        case types.UPDATE_QUOTE_REVIEW_ERRORS: {
            return {
                ...state,
                ...action.errors
            };
        }
        case types.RESET_ENROLLMENT_RULES:
            return {
                ...state,
                enrolledPlans: {},
                enrolledDentalPlans: [],
                enrolledVisionPlans: [],
                enrolledSTDPlans:[],
                enrolledLTDPlans:[],
                enrolledLifePlans: [],
                enrolledSuppLifeDepPlans: [],
                enrolledSuppLifePlans: [],
                enrolledDependentLifePlans: [],
                numEnrolledPlans: 0,
                numReviewConsiderPlans: 0,
                numRenewalsAdditionalSelectedPlans: 0,
                errors: {},
                numErrors: 0,
            };
        case types.HARD_RESET_ENROLLMENT_RULES:
            return initialState;
        case types.SERVICE.DENTAL.ADJUST_RATE:
        case types.SERVICE.VISION.ADJUST_RATE:
        case types.SERVICE.LIFE.ADJUST_RATE:
        case types.SERVICE.STD.ADJUST_RATE:
        case types.SERVICE.LTD.ADJUST_RATE:
            return handleAdjustRatesAndPremium(state, action, 'enrolled');
        case types.AUTO_ENROLL_IN_LIFE: {
            const newEnrolledLifePlans = state.enrolledLifePlans.map((plan) => {
                const updatedItem = {
                    ...plan,
                    autoenroll: true,
                };
                return updatedItem;
            });
            return {
                ...state,
                enrolledLifePlans: newEnrolledLifePlans,
            };
        }

        case types.SERVICE.FI.CHANGE_CUSTOM_PLAN_NAME:
        case types.SERVICE.DENTAL.CHANGE_CUSTOM_PLAN_NAME:
            return handleChangeCustomPlanName(state, action, 'enrolled');
        case types.UPDATE_ENROLLED_MEDICAL_PLANS:
            return {
                ...state,
                enrolledPlans: {
                    ...state.enrolledPlans,
                    standalone: action.plans,
                },
            }
        case types.UPDATE_PLANS_HIOS_DATA:
            return {
                ...state,
                enrolledPlans: action.plans.medical,
                enrolledDentalPlans: action.plans.dental,
                enrolledVisionPlans: action.plans.vision,
                enrolledLifePlans: action.plans.life,
                enrolledDependentLifePlans: action.plans.dependentLife
            }
        case types.UPDATE_ENROLLED_DENTAL_PLANS:
            return {
                ...state,
                enrolledDentalPlans: action.plans,
            };
        case types.UPDATE_ENROLLED_VISION_PLANS:
            return {
                ...state,
                enrolledVisionPlans: action.plans,
            };
        case types.UPDATE_ENROLLED_BASIC_LIFE_PLANS:
            return {
                ...state,
                enrolledLifePlans: action.plans,
            };
        case types.UPDATE_ENROLLED_STD_PLANS:
            return {
                ...state,
                enrolledSTDPlans: action.plans
            };
        case types.UPDATE_ENROLLED_LTD_PLANS:
            return {
                ...state,
                enrolledLTDPlans: action.plans,
            };
        case types.UPDATE_ENROLLED_STD_PLANS_RATES:
            const updatedSTDPlans = state.enrolledSTDPlans.map((plan, index) => {
                return {
                    ...plan,
                    finalMonthlyPremium: action.plans[index].totalMonthlyPremium,
                    finalRates: action.plans[index],
                    quoteFinal: action.plans[index].totalMonthlyPremium,
                    employeeRatesAgeBanded: action.plans[index].employeeRatesAgeBanded,
                    employeeRatesComposite: action.plans[index].employeeRatesComposite,
                }
            })
            return {
                ...state,
                enrolledSTDPlans: updatedSTDPlans
            };
        case types.UPDATE_ENROLLED_LTD_PLANS_RATES:
            const updatedLTDPlans = state.enrolledLTDPlans.map((plan, index) => {
                return {
                    ...plan,
                    finalMonthlyPremium: action.plans[index].totalMonthlyPremium,
                    finalRates: action.plans[index],
                    quoteFinal: action.plans[index].totalMonthlyPremium,
                    employeeRatesAgeBanded: action.plans[index].employeeRatesAgeBanded,
                    employeeRatesComposite: action.plans[index].employeeRatesComposite,
                }
            })
                return {
                    ...state,
                    enrolledLTDPlans: updatedLTDPlans
                };
        case types.UPDATE_ENROLLED_SUPP_DEP_LIFE_PLANS:
            return {
                ...state,
                enrolledSuppLifeDepPlans: action.plans,
            };
        case types.UPDATE_ENROLLED_SUPPLIFE_PLANS:
            const updatedSuppLifePlans = state.enrolledSuppLifePlans.map((plan, index) => {
                return {
                    ...plan,
                    quoteFinal: action.plans[index],
                }
            })
            return {
                ...state,
                enrolledSuppLifePlans: updatedSuppLifePlans,
            };
        case types.UPDATE_DEPENDENT_LIFE_ENROLLED_PLANS:
            return {
                ...state,
                enrolledDependentLifePlans: action.plans,
            };
        case types.UPDATE_GUARANTEED_ISSUE_AMT_IN_LIFE_PLANS:
            return handleUpdateGIAmountInLifePlans(state, action);
        case types.UPDATE_NO_OF_ENROLLED_PLANS_DECIDE:
            return {
                ...state,
                numEnrolledPlans: action.payload.numEnrolledPlan,
                numReviewConsiderPlans: action.payload.numReviewConsiderPlans,
                numRenewalsAdditionalSelectedPlans: action.payload.numAdditionalPlans,
            };
        case types.CHANGE_ENROLLED_RATING_METHOD:
            const updatedEnrollPlans = deepCopy(state.enrolledPlans);
            const packages = Object.keys(updatedEnrollPlans);

            packages.forEach(pkg => {
                updatedEnrollPlans[pkg].forEach(plan => {
                    if (plan.employeeRatesAgeBanded && plan.employeeRatesComposite) {
                        const employeeRateType = action.ratingMethod === "age" ? 'employeeRatesAgeBanded' : 'employeeRatesComposite'
                        const totalPremiumKey = action.ratingMethod === "age" ? 'premiumTotalAgeBandedMonthly' : 'premiumTotalCompositeMonthly'
                        plan.employeeRates = plan[employeeRateType]
                        plan.quote = plan[totalPremiumKey]
                        if (action.isUhcRenewals) {
                            // update default rating field for uhc renewals(rate default is determined by these fields)
                            setPlanDefaultType(plan, action.ratingMethod === "age");
                        }
                    }
                })
            })

            return {
                ...state,
                enrolledPlans: updatedEnrollPlans
            }
        case types.RESET_FLOW:
        case types.RESET_TO_INITIALSTATE_REN_PRIME_ANC:
        case types.RESET_TO_INITIALSTATE:
        case types.RESET_FLOW:
            return initialState;
        case types.REMOVE_DESELECTED_ENROLLED_PLANS:
        case types.UPDATE_MEDICAL_ENROLLED_PLANS:
            return {
                ...state,
                enrolledPlans: action.enrolledPlans
            }
        case types.SAVE_PCP_AUTO_ASSIGNMENT_RULES:
            return {
                ...state,
                pcpAutoAssignemntRules: action.rules
            }
        case types.ENABLE_FINALIZE_ENROLLMENT:
            return {
                ...state,
                enableFinalizeEnrollment: {
                    ...state.enableFinalizeEnrollment,
                    [action.product]: action.payload
                }
            }
        case types.SET_PLAN_INSTALL_PLATFORM:
            const {
                planCode,
                product,
                installPlatform
            } = action;

            if (product === 'Medical') {
                let newEnrolledPlans = {};
                Object.keys(state.enrolledPlans).forEach(pkg => {
                    newEnrolledPlans[pkg] = deepCopy(state.enrolledPlans[pkg]);
                    const planIndex = newEnrolledPlans[pkg].findIndex(plan => plan.code === planCode);
                    if (planIndex > -1) {
                        //newEnrolledPlans[planIndex].installPlatform = installPlatform;
                        newEnrolledPlans[pkg][planIndex].installPlatform = newEnrolledPlans[pkg][planIndex].platform === 'NICE' ? 'NICE' : installPlatform;
                    }
                });
                return {
                    ...state,
                    enrolledPlans: newEnrolledPlans,
                }
            } else {
                let newEnrolledPlans = [];
                newEnrolledPlans = deepCopy(state[`enrolled${product}Plans`]);
                let planIndx = newEnrolledPlans.findIndex(plan => plan.code === planCode);
                if (planIndx > -1) {
                    newEnrolledPlans[planIndx].installPlatform = installPlatform;
                }
                return {
                    ...state,
                    [`enrolled${product}Plans`]: newEnrolledPlans,
                };
            }
        case types.SERVICE.MEDICAL.FETCHING_FINALIZED_QUOTE_RATES:
        case types.SERVICE.DENTAL.FETCHING_FINALIZED_QUOTE_RATES:
        case types.SERVICE.VISION.FETCHING_FINALIZED_QUOTE_RATES:
        case types.SERVICE.STD.FETCHING_FINALIZED_QUOTE_RATES:
        case types.SERVICE.LTD.FETCHING_FINALIZED_QUOTE_RATES:
        case types.SERVICE.LIFE.FETCHING_FINALIZED_QUOTE_RATES:
            return {
                ...state,
                [`fetchingFinal${action.product}Rates`]: true
            }
        case types.SERVICE.MEDICAL.RECEIVE_FINALIZED_QUOTE_RATES:
        case types.SERVICE.MEDICAL.ERROR_FINALIZED_QUOTE_RATES:
        case types.SERVICE.DENTAL.RECEIVE_FINALIZED_QUOTE_RATES:
        case types.SERVICE.DENTAL.ERROR_FINALIZED_QUOTE_RATES:
        case types.SERVICE.VISION.RECEIVE_FINALIZED_QUOTE_RATES:
        case types.SERVICE.VISION.ERROR_FINALIZED_QUOTE_RATES:
        case types.SERVICE.STD.RECEIVE_FINALIZED_QUOTE_RATES:
        case types.SERVICE.STD.ERROR_FINALIZED_QUOTE_RATES:
        case types.SERVICE.LTD.RECEIVE_FINALIZED_QUOTE_RATES:
        case types.SERVICE.LTD.ERROR_FINALIZED_QUOTE_RATES:
        case types.SERVICE.LIFE.RECEIVE_FINALIZED_QUOTE_RATES:
        case types.SERVICE.LIFE.ERROR_FINALIZED_QUOTE_RATES:
            return {
                ...state,
                [`fetchingFinal${action.product}Rates`]: false
            }
        default:
            return state;
    }
}

const calculateDependentLifeRate = (employees, rate, plan) => {
    let totalRate = 0;
    employees.forEach((employee) => {
        const selectedProducts = employee.productSelection || []
        const dependentLifeProductSelection = selectedProducts.filter(product => product.life === plan.code)
        if (dependentLifeProductSelection.length > 0 && employee.employeeStatus !== '1099 Independent Contractors') {
            if (rate && rate.depLifeChildRate) {
                totalRate += parseFloat(rate.depLifeChildRate)
            }
        }
    })
    return totalRate
}
