import * as types from '../actions/actionTypes';
import { getMembersMappingFromIBR } from '../utils/Renewal/uhcRenewalDataHelper';
import { callRateApi, checkAlternateRatingMethodUsed } from '../utils/Renewal/uhcServiceHelpers';
import { getAllPlansRiders } from './benefitRiderRatesActions';
import { isUhcRenewals } from '../utils/businessLogic/platformUtils';
import { filterReconciledCensus } from '../components/activities/Renewal/Review/reviewComponents/FilterIBR';
import { automateMmrUploadReport, isAutomateMmrUploadInProgress, setMedicalCensusSummary } from './renewalActions';
import {getPlanCodeBatchesDetails} from './fullQuotesActions'
import deepCopy from '../utils/deepCopy';
import { renewPlansSelectedHandler } from '../components/Dashboard/MMR/UploadTemplateControllerMMR';
import lastModifiedPage from './modifiedPageAction';

const requestRateBatch = (requestBody, medicalPlanCodes, getAlternateRatingMethod = false, OISABit = {}, isAutomated=false, props = {} ) => async (dispatch, getState) => {
    let fullProps = getState()
    let  memberGroupId = fullProps?.review?.renewalCaseData?.memberGroupId
    const setErrorModal = (props, errorTracker) => {
        props.setUploadErrorTracker(errorTracker);
        props.setUploadErrorModal(true);
        return;
    }

    try {
        let isMMRCase = fullProps.overall.source === 'mmr'
        let situsState = fullProps?.companyProfile?.locations[0]?.zipData?.stateCode || ""
        if(isMMRCase) {
            dispatch({
                type: types.REQUEST_MMR_ADDITIONAL_RATES,
                product: 'medical'
            })
        } else {
            dispatch({
                type: types.SERVICE.RS.REQUEST_REN_ADDITIONAL_RATES
            })
        }
        if(situsState === "CA")
        {
            requestBody.medicalPlanCodes = medicalPlanCodes.map(plan => {return {code :plan.code, platform:plan.platform}});
        }
        else
        {
            requestBody.medicalPlanCodes = medicalPlanCodes.map(plan => plan.code);
        }  
        if(OISABit && Object.keys(OISABit).length > 0){
            requestBody.OISABit =  {...OISABit};
        }
        const rates = await callRateApi(requestBody,dispatch );
        if(isMMRCase) {
            dispatch({
                type: types.RECEIVE_MMR_ADDITIONAL_RATES,
                rates,
                product: 'medical',
                allEmployees: requestBody.employees
            })
        } else {
            let ibrPlanKey = 'review';
            if(fullProps.review.medical) {
                ibrPlanKey = 'review';
            } else if(fullProps.decide.medical) {
                ibrPlanKey = 'decide';
            }
            dispatch({
                type: types.SERVICE.RS.RECEIVE_REN_ADDITIONAL_RATES,
                rates,
                isUhcRenewal: isUhcRenewals(),
                tierStructureId: isUhcRenewals()
                    ? fullProps.review.groupTierStructureId
                    : null,
                getAlternateRatingMethod,
                alternatePlanCodes:
                    fullProps[ibrPlanKey].alternatePlanCodes &&
                    fullProps[ibrPlanKey].alternatePlanCodes.length > 0
                        ? fullProps[ibrPlanKey].alternatePlanCodes
                        : [],
            });

            if(getAlternateRatingMethod) {
                const ratingMethodFilterCriteria = fullProps.renewalShopping.filterCriteria.ratingMethod;

                let defaultRatingType, selectedPlans = fullProps.renewalShopping.selectedPlans;

                if(ratingMethodFilterCriteria.age || ratingMethodFilterCriteria.tier) {
                    defaultRatingType = ratingMethodFilterCriteria.age === true;
                } else {
                    const renewingPlan = (selectedPlans || []).find(plan => plan.renewalPlanType === 'renewing');
                    defaultRatingType = renewingPlan.defaultRatingType;
                }
                
                dispatch({
                    type: types.UPDATE_ALTERNATE_RATES_MEDICAL_REN_SHOP,
                    rates,
                    defaultTypeAge: defaultRatingType
                })
            }
        }
    } catch (err) {
        let isMMRCase = fullProps.overall.source === 'mmr'
        if(isMMRCase) {
            if(isAutomated)
            {
                dispatch(isAutomateMmrUploadInProgress(false));
                if(props && props.setUploadErrorTracker && props.setUploadErrorModal)
                {
                    let errorsList = [];
                    errorsList.push({
                        heading:"Rate Call Getting Failed : Error occured while fetching rates for first batch",
                        errors:[{error:err?.message || err?.error || "Some error occured while fetching rates for first batch"}]
                    })
                    let errorTracker = {}
                    errorTracker.validationError = true;
                    errorTracker.errorList = errorsList;
                    //setErrorModal(props, errorTracker);
                    dispatch(automateMmrUploadReport({
                        memberGroupId:memberGroupId,
                        status:"Failed",
                        error:errorsList
                    }))
                }
                dispatch(lastModifiedPage('Review', getState));
                throw err
            }
            dispatch({
                type: types.ERROR_MMR_ADDITIONAL_RATES,
                planCode: requestBody.medicalPlanCodes.length > 0 ? requestBody.medicalPlanCodes[0] : '',
                product: 'medical'
            })
        } else {
            dispatch({
                type: types.SERVICE.RS.ERROR_RENEWAL_RATES,
                payload: err.response && err.response.data && err.response.data.message ? err.response.data.message : 'Something went wrong while fetching medical rates',
                source: 'RS',
            });
        }
    }
}

const getGroupSizeForRateCall = (renewalShopping, empCount) => {
    if(renewalShopping && renewalShopping.eligibilityCountsEntered) {
        if(renewalShopping.eligibilityCounts && renewalShopping.eligibilityCounts.eligible) {
            return parseInt(renewalShopping.eligibilityCounts.eligible);
        }
    }
    return parseInt(empCount);
}

const getRatingCountForRateCall = (renewalShopping, empCount) => {
    if(renewalShopping && renewalShopping.eligibilityCountsEntered) {
        if(renewalShopping.eligibilityCounts && renewalShopping.eligibilityCounts.fte) {
            return renewalShopping.eligibilityCounts.fte.toString();
        } else if(renewalShopping.eligibilityCounts && renewalShopping.eligibilityCounts.atne) {
            return renewalShopping.eligibilityCounts.atne.toString();
        }
    }
    return empCount[0].count ? empCount[0].count.toString() : empCount[2].count.toString();
}

export const getMedicalRatesRequestBody = (members = [], ratingMethod = '', getAlternateRatingMethod) => {

    const { store } = require('../index');
    const fullProps = store.getState();
    const { companyProfile, renewalShopping } = fullProps;
    const renewalCaseData = fullProps.renewalShoppingHelper.renewalCaseData ? fullProps.renewalShoppingHelper.renewalCaseData : fullProps.review.renewalCaseData;
    const renewalData = fullProps.renewalShoppingHelper.renewalData ? fullProps.renewalShoppingHelper.renewalData : fullProps.review.renewalData;
    const isMMRCase = fullProps.overall.source === 'mmr'
    let groupSize = getGroupSizeForRateCall(renewalShopping, renewalData.memberGroup.locations.employeeCounts[2].count);
    let ratingCount = getRatingCountForRateCall(renewalShopping, renewalData.memberGroup.locations.employeeCounts);

    let employees
    if (isMMRCase) {
        employees = members.map(member => {
            let finalObj = {
                ...member,
                employeeStatus: member.status || 'ACTIVE'
            }
            delete finalObj.ooaPremium
            delete finalObj.total
            delete finalObj.outOfArea
            delete finalObj.status
            return finalObj
        })
    } else {
        employees = getMembersMappingFromIBR(renewalData.Ibr)
    }
    let staticRequestBody = {
        groupSize,
        stateCode: renewalData.memberGroup.locations.location.state,
        zipCode: renewalData.memberGroup.locations.location.zipCode,
        effectiveDate: renewalData.memberGroup.renewalEffectiveDate,
        companyName: renewalData.memberGroup.memberGroupName,
        sicCode: renewalData.memberGroup.industryCode,
        franchiseCode: renewalData.memberGroup.franchiseCode,
        platform: "PRIME",
        selectedOptionalRiders: {},
        isRenewals: true,
        quoteStatus: "Shopping",
        memberGroupId: companyProfile.memberGroupId,
        isUhcRenewals: isUhcRenewals(),
        ratingCount,
        renewalProcessedDate: renewalData.memberGroup.renewalProcessedDate,
        customerNumber: renewalCaseData.primeCustomerNumber,
        policyNumber: renewalCaseData.primePolicyNumber[0],
        employees,
        OISABit: renewalData.memberGroup.locations.location.OISABit[0],
        medicalRatingMethod: isMMRCase ? ratingMethod : '',
        isMMRCase,
        isMMRCaseCompleted: renewalCaseData.isMMRCaseCompleted,
    }
    //let getAlternateRatingMethod = checkAlternateRatingMethodUsed(renewalShopping.selectedPlans)
    if (!isMMRCase && !getAlternateRatingMethod) {
        let medicalIBRPlans = [], ibrPlanKey = 'review';
        if (fullProps.review.medical) {
            ibrPlanKey = 'review';
        } else if (fullProps.decide.medical) {
            ibrPlanKey = 'decide';
        }
        if (fullProps[ibrPlanKey].medical && fullProps[ibrPlanKey].medical.set && fullProps[ibrPlanKey].medical.set.length) {
            medicalIBRPlans = fullProps[ibrPlanKey].medical.set.map(planSet => planSet[1])
        }

        if (medicalIBRPlans && medicalIBRPlans.length > 0) {
            if (medicalIBRPlans[0].ibrRatingType === "ageBands" && medicalIBRPlans[0].platformAgeBandedRatingID) {
                staticRequestBody.renewalRateId = medicalIBRPlans[0].platformAgeBandedRatingID;
                staticRequestBody.altQuoteInd = "Y";
            } else if (medicalIBRPlans[0].ibrRatingType === "composite" && medicalIBRPlans[0].platformCompositeRatingID) {
                staticRequestBody.renewalRateId = medicalIBRPlans[0].platformCompositeRatingID;
                staticRequestBody.altQuoteInd = "Y";
            }
            staticRequestBody.tierStructureId = medicalIBRPlans[0].tierStructureId
        }
    }      
    return staticRequestBody
}

const formatRates = (plan) => {
    let planRate = plan.defaultRatingType === 'ageBands' ? {
        ageBandedTypeName: 'AGE BANDED FOR ACR',
        ageBands: plan.ageBands.map(ageBand => {
            ageBand.rate = ageBand.total
            return ageBand
        }),
        benefitRiderRates: [],
        defaultRatingType: plan.defaultRatingType,
        ibrRatingType: plan.defaultRatingType,
        employeeRates: plan.employeeRates,
        employeeRatesAgeBanded: plan.employeeRates,
        isAgeBanded: true,
        medicalPlanCode: plan.code,
        platformAgeBandedRatingID: plan.platformAgeBandedRatingID,
        premiumTotalAgeBandedAnnual: parseFloat(plan.finalMonthlyPremium) * 12,
        premiumTotalAgeBandedMonthly: parseFloat(plan.finalMonthlyPremium),
        renewalPlanType: plan.renewalPlanType,
        id: plan.id
    } : {
        compositeTypeName: "4 TIER COMPOSITE ACR",
        compositeRates: plan.compositeRates,
        benefitRiderRates: [],
        defaultRatingType: plan.defaultRatingType,
        ibrRatingType: plan.defaultRatingType,
        employeeRates: plan.employeeRates,
        employeeRatesComposite: plan.employeeRates,
        isComposite: true,
        medicalPlanCode: plan.code,
        platformCompositeRatingID: plan.platformCompositeRatingID,
        premiumTotalCompositeAnnual: parseFloat(plan.finalMonthlyPremium) * 12,
        premiumTotalCompositeMonthly: parseFloat(plan.finalMonthlyPremium),
        renewalPlanType: plan.renewalPlanType,
        id: plan.id
    }
    return planRate
}

const addRenewingMedicalPlansForRates = (fullProps, filteredMedicalPlans) => {
    let medicalIBRPlans = [], ibrPlanKey = 'review', shoppingMedicalPlans = deepCopy(filteredMedicalPlans);
    if(fullProps.review.medical) {
        ibrPlanKey = 'review';
    } else if(fullProps.decide.medical) {
        ibrPlanKey = 'decide';
    }

    if(fullProps[ibrPlanKey].medical && fullProps[ibrPlanKey].medical.set && fullProps[ibrPlanKey].medical.set.length) {
        medicalIBRPlans = fullProps[ibrPlanKey].medical.set.map(planSet => planSet[1])
    }

    let newPlans = [];
    medicalIBRPlans.forEach(ibrPlan => {
        let planFound = false
        shoppingMedicalPlans.forEach(plan => {
            if (plan.code === ibrPlan.code) {
                planFound = true
            }
        })
        if (!planFound) {
            newPlans.push(ibrPlan)
        }
    });

    return newPlans;
}

export const doMedicalRateCall = ({ medicalPlans, members = [], ratingMethod = '', getAlternateRatingMethod = false, allMedicalPlans = {}, isAutomated, props={}}) => async (dispatch, getState) => {
    const staticRequestBody = getMedicalRatesRequestBody(members, ratingMethod, getAlternateRatingMethod);
    const batchSize = 10;
    let rateBatches = [], benefitRiderRateBatches = [];
    const fullProps = getState()
    const isMMRCase = fullProps.overall.source === 'mmr';
    let filteredMedicalPlans = medicalPlans;
    let platform = '';
    if (medicalPlans && medicalPlans[0]) {
        platform = medicalPlans[0].platform === 'CIRRUS' ? 'CIRRUS' : 'PRIME';
    }
    const isCirrus = platform === 'CIRRUS';
    const optionalBenRiders = fullProps.renewalShopping && fullProps.renewalShopping.optionalBenefitRiders ? fullProps.renewalShopping.optionalBenefitRiders : [];
    const memberGroupId = fullProps?.review?.renewalCaseData?.memberGroupId

    const setErrorModal = (props, errorTracker) => {
        props.setUploadErrorTracker(errorTracker);
        props.setUploadErrorModal(true);
        return;
    }

    if (!isMMRCase && !getAlternateRatingMethod) {
        let medicalIBRPlans = [], ibrPlanKey = 'review';
        if(fullProps.review.medical) {
            ibrPlanKey = 'review';
        } else if(fullProps.decide.medical) {
            ibrPlanKey = 'decide';
        }

        if(fullProps[ibrPlanKey].medical && fullProps[ibrPlanKey].medical.set && fullProps[ibrPlanKey].medical.set.length) {
            medicalIBRPlans = fullProps[ibrPlanKey].medical.set.map(planSet => planSet[1])
        }

        if(medicalIBRPlans && medicalIBRPlans.length > 0) {
            if (
                fullProps[ibrPlanKey].renewalCaseData &&
                fullProps[ibrPlanKey].renewalCaseData.situsState === 'CA' && 
                !fullProps[ibrPlanKey].renewalCaseData.hasPrimeAndNiceMedPlans
            ) {
                if(fullProps[ibrPlanKey].renewalCaseData.custType === 'NICE') {
                    staticRequestBody.customerNumber = medicalIBRPlans[0].niceCustomerNumber
                }
                if(fullProps[ibrPlanKey].renewalCaseData.custType !== 'PRIME' && fullProps[ibrPlanKey].renewalCaseData.custType !== 'USP') {
                    staticRequestBody.policyNumber = medicalIBRPlans[0].nicePolicyNumber
                }
            }

            if (medicalIBRPlans[0].ibrRatingType === "ageBands" && medicalIBRPlans[0].platformAgeBandedRatingID) {
                staticRequestBody.renewalRateId =
                    medicalIBRPlans[0].platformAgeBandedRatingID;
                staticRequestBody.altQuoteInd = "Y";
            } else if (medicalIBRPlans[0].ibrRatingType === "composite" && medicalIBRPlans[0].platformCompositeRatingID) {
                staticRequestBody.renewalRateId =
                    medicalIBRPlans[0].platformCompositeRatingID;
                staticRequestBody.altQuoteInd = "Y";
            }

            staticRequestBody.tierStructureId = medicalIBRPlans[0].tierStructureId

        }

        if(fullProps?.renewals?.renewalCaseData?.custType === 'NICE'){
            staticRequestBody.altQuoteInd = "N";
            staticRequestBody.renewalRateId = ""
        }   
        
        if(fullProps[ibrPlanKey].alternate) {
            medicalIBRPlans.push(...fullProps[ibrPlanKey].alternate)
        }
        let ibrRates = [];
        medicalIBRPlans.forEach(ibrPlan => {
            let planFound = false
            filteredMedicalPlans = filteredMedicalPlans.filter(plan => {
                if (plan.code === ibrPlan.code && plan.packages.includes(ibrPlan.packages[0])) {
                    planFound = true
                }
                return plan.code !== ibrPlan.code || (plan.code === ibrPlan.code && !plan.packages.includes(ibrPlan.packages[0]))
            })
            if (planFound) {
                ibrRates.push(formatRates(ibrPlan))
            }
        });
        if (ibrRates.length > 0) {
            dispatch({
                type: types.SERVICE.RS.RECEIVE_REN_ADDITIONAL_RATES,
                rates: ibrRates,
                isUhcRenewal: isUhcRenewals(),
                tierStructureId: isUhcRenewals() ? fullProps.review.groupTierStructureId : null
            })

        }
    }

    if(getAlternateRatingMethod) {
        filteredMedicalPlans = [...filteredMedicalPlans, ...addRenewingMedicalPlansForRates(fullProps, filteredMedicalPlans)];
    }

    const batchOISABit = [];
    const { situsState } = fullProps && fullProps.renewalShoppingHelper && fullProps.renewalShoppingHelper.renewalCaseData ? fullProps.renewalShoppingHelper.renewalCaseData : fullProps.review.renewalCaseData;
    if(!isCirrus && filteredMedicalPlans && filteredMedicalPlans.length > 0 && (isUhcRenewals() || isMMRCase) && ['MD','WV','VA','DE'].includes(situsState)){
        allMedicalPlans.forEach(x=>{
            if(!x.OISABit || Object.keys(x.OISABit).length === 0){
                x.OISABit = staticRequestBody.OISABit;
            }
        });

        let medicalPlansObj = {
            quotes: {
                plans: allMedicalPlans
            },
        }
        //let codes = allMedicalPlans.map((plan) => plan.code)
        rateBatches = getPlanCodeBatchesDetails(medicalPlansObj,filteredMedicalPlans,batchOISABit);
    }else{
        for (let i = 0; i < filteredMedicalPlans.length; i += batchSize) {
            rateBatches.push(filteredMedicalPlans.slice(i, i + batchSize));
        }
    }

    if(rateBatches.length > 0) {
        let OISABit = batchOISABit && batchOISABit[0] ? batchOISABit[0] : {};
        await dispatch(requestRateBatch(staticRequestBody, rateBatches[0], getAlternateRatingMethod, OISABit, isAutomated, props))
            .then(() => {
                if(isAutomated){
                    if(rateBatches.length === 1){
                        renewPlansSelectedHandler(dispatch)
                        dispatch(isAutomateMmrUploadInProgress(false));
                    }
                }
                for (let i = 1; i < rateBatches.length; i++) {
                    OISABit = batchOISABit && batchOISABit[i] ? batchOISABit[i] : {};
                    dispatch(requestRateBatch(staticRequestBody, rateBatches[i], getAlternateRatingMethod, OISABit, isAutomated, props))
                        .then(() => {
                            if(isAutomated){ 
                                if((rateBatches.length-1) === i){
                                    renewPlansSelectedHandler(dispatch)
                                    dispatch(isAutomateMmrUploadInProgress(false));     
                                }  
                            }
                        })
                        .catch(err => {
                            console.log(`Error occured while fetching rates for batch ${i + 1}`);
                            console.log(err);
                        });
                }
            })
            .then(() => {
                if(!isMMRCase) { // TODO : Not sure we need to do benefit rider rate call or not 
                    // This call needs to be done only for Shopping to bring benefit rider rates of all the plans.
                    let benefitRiderOISABIT = [];
                    if(!isCirrus && medicalPlans && medicalPlans.length > 0 && isUhcRenewals() && ['MD','WV','VA','DE'].includes(situsState)){
                        let medicalPlansObj = {
                            quotes: {
                                plans: allMedicalPlans
                            },
                        }
                        benefitRiderRateBatches = getPlanCodeBatchesDetails(medicalPlansObj,medicalPlans,benefitRiderOISABIT);
                    }else{
                        for (let i = 0; i < medicalPlans.length; i += batchSize) {
                            benefitRiderRateBatches.push(medicalPlans.slice(i, i + batchSize));
                        }
                    }
                    
                    if(benefitRiderRateBatches.length > 0) {
                        let OISABit = benefitRiderOISABIT && benefitRiderOISABIT[0] ? benefitRiderOISABIT[0] : {};
                        dispatch(bringBenefitRiderRates({ medicalPlanCodes: benefitRiderRateBatches[0], firstCall: true, staticRequestBody, OISABit }))
                        .then(() => {
                            for (let i = 1; i < benefitRiderRateBatches.length; i++) {
                                let OISABit = benefitRiderOISABIT && benefitRiderOISABIT[i] ? benefitRiderOISABIT[i] : {};
                                dispatch(bringBenefitRiderRates({ medicalPlanCodes: benefitRiderRateBatches[i], firstCall: false, staticRequestBody, OISABit }))
                                    .catch(err => {
                                        console.log(`Error occured while fetching benefit rider rates for  batch ${i + 1}`);
                                        console.log(err);
                                    });
                            }
                        })
                    }
                } else {
                    const coverageCounts = filterReconciledCensus(getState().review);
                    dispatch(setMedicalCensusSummary({
                        medicalCensusSummary: coverageCounts.medicalCensus 
                    }));
                }
            })
            .catch(err => {
                if(isAutomated)
                {
                    dispatch(isAutomateMmrUploadInProgress(false));
                    if(props && props.setUploadErrorTracker && props.setUploadErrorModal)
                    {
                        let errorsList = [];
                        errorsList.push({
                            heading:"Rate Call Getting Failed : Error occured while fetching rates for first batch",
                            errors:[{error:err?.message || "Some error occured while fetching rates"}]
                        })
                        let errorTracker = {}
                        errorTracker.validationError = true;
                        errorTracker.errorList = errorsList;
                         //setErrorModal(props, errorTracker);
                         dispatch(automateMmrUploadReport({
                            memberGroupId:memberGroupId,
                            status:"Failed",
                            error:errorsList
                        }))
                    }
                    dispatch(lastModifiedPage('Review', getState));
                }
                
                console.log("Error occured while fetching rates for first batch");
                console.log(err);
            })
        console.log("Await of doMedicalRate Call")
    }else{
        if(!isMMRCase && optionalBenRiders.length > 0) { // TODO : Not sure we need to do benefit rider rate call or not 
            // This call needs to be done only for Shopping to bring benefit rider rates of all the plans.
            let benefitRiderOISABIT = [];
            if(!isCirrus && medicalPlans && medicalPlans.length > 0 && isUhcRenewals() && ['MD','WV','VA','DE'].includes(situsState)){
                let medicalPlansObj = {
                    quotes: {
                        plans: allMedicalPlans
                    },
                }
                benefitRiderRateBatches = getPlanCodeBatchesDetails(medicalPlansObj,medicalPlans,benefitRiderOISABIT);
            }else{
                for (let i = 0; i < medicalPlans.length; i += batchSize) {
                    benefitRiderRateBatches.push(medicalPlans.slice(i, i + batchSize));
                }
            }
            
            if(benefitRiderRateBatches.length > 0) {
                let OISABit = benefitRiderOISABIT && benefitRiderOISABIT[0] ? benefitRiderOISABIT[0] : {};
                dispatch(bringBenefitRiderRates({ medicalPlanCodes: benefitRiderRateBatches[0], firstCall: true, staticRequestBody, OISABit }))
                .then(() => {
                    for (let i = 1; i < benefitRiderRateBatches.length; i++) {
                        let OISABit = benefitRiderOISABIT && benefitRiderOISABIT[i] ? benefitRiderOISABIT[i] : {};
                        dispatch(bringBenefitRiderRates({ medicalPlanCodes: benefitRiderRateBatches[i], firstCall: false, staticRequestBody, OISABit }))
                            .catch(err => {
                                console.log(`Error occured while fetching benefit rider rates for  batch ${i + 1}`);
                                console.log(err);
                            });
                    }
                })
                .catch(err => {
                    console.log("Error occured while fetching benefit rider rates for first batch");
                    console.log(err);
                })
            }
        }
    }
}


/** Function to bring all plans benefit rider rates after rate call on shopping. */
const bringBenefitRiderRates = ({ medicalPlanCodes, staticRequestBody, firstCall, OISABit }) => async (dispatch, getState) => {
    try {
        const fullProps = getState();
        const isMMR = fullProps.overall.source === 'mmr'
        const situsState = fullProps?.companyProfile?.locations[0]?.zipData?.stateCode || ""
        let { renewalShopping: { plans } } = fullProps;
        if(isMMR) {
            plans = fullProps.review.medical.selectedPlans
        }
        if(situsState === "CA")
        {
            staticRequestBody.medicalPlanCodes = medicalPlanCodes.map(plan => {return {code :plan.code, platform:plan.platform}});
        }
        else{
            staticRequestBody.medicalPlanCodes = medicalPlanCodes.map(plan => plan.code);
        }
        if(OISABit && Object.keys(OISABit).length > 0){
            staticRequestBody.OISABit =  {...OISABit};
        }
        staticRequestBody.selectedOptionalRiders = getAllPlansRiders(medicalPlanCodes, plans);
        if (firstCall) {
            dispatch({
                type: isMMR ? types.REQUEST_MMR_BENEFIT_RIDER_RATES : types.REQUEST_BENEFIT_RIDER_RATES_RS
            })
        }
        const rates = await callRateApi(staticRequestBody, dispatch);

        dispatch({
            type: isMMR ? types.RECEIVE_MMR_BENEFIT_RIDER_RATES : types.RECEIVE_BENEFIT_RIDER_RATES_RS,
            rates,
        })

    } catch (err) {
        dispatch({
            type: types.SERVICE.RS.ERROR_RENEWAL_RATES,
            payload: err.response && err.response.data && err.response.data.message ? err.response.data.message : 'Something went wrong while fetching medical rates',
            source: 'RS',
        });
    }
}

/** Get Rates for selected riders when save button is clicked on the modal. */
export const getRatesWithSelectedRidersRenewals = (selectedOptionalRiders) => (dispatch, getState) => {

    const requestRateBatch = (requestBody, medicalPlanCodes) => async (dispatch, getState) => {
        requestBody.medicalPlanCodes = medicalPlanCodes;
        try {
            const rates = await callRateApi(requestBody, dispatch);
            dispatch({
                type: types.RECEIVE_PLANS_RATES_WITH_SELECTED_RIDERS_RS,
                rates
            })
        } catch (err) {
            dispatch({
                type: types.SERVICE.RS.ERROR_RENEWAL_RATES,
                payload: err.response && err.response.data && err.response.data.message ? err.response.data.message : 'Something went wrong while fetching medical rates',
                source: 'RS',
            });
        }

    }

    try {
        const medicalPlanCodes = Object.keys(selectedOptionalRiders);
        dispatch({
            type: types.SAVE_SELECTED_RIDERS_IN_PLANS_RS,
            selectedBenefitRiders: selectedOptionalRiders
        })
        dispatch({
            type: types.REQUEST_PLAN_RATES_WITH_SELECTED_RIDERS_RS,
            numRatesToBeFetched: medicalPlanCodes.length
        })
        const batchSize = 10;
        const batches = [];
        for (let i = 0; i < medicalPlanCodes.length; i += batchSize) {
            batches.push(medicalPlanCodes.slice(i, i + batchSize));
        }

        const staticRequestBody = getMedicalRatesRequestBody();
        staticRequestBody.selectedOptionalRiders = selectedOptionalRiders;

        dispatch(requestRateBatch(staticRequestBody, batches[0]))
            .then(() => {
                for (let i = 1; i < batches.length; i++) {
                    delete staticRequestBody.medicalPlanCodes;
                    dispatch(requestRateBatch(staticRequestBody, batches[i]))
                        .catch(err => {
                            console.log(`Error occured while fetching rates for batch ${i + 1}`);
                            console.log(err);
                        });
                }
            })
            .catch(err => {
                console.log("Error occured while fetching rates for first batch");
                console.log(err);
            })

    } catch (err) {
        console.log("Error occured while fetching rates for first batch");
        console.log(err);
    }
}

/** Get old rates of medical plans back after they are deselected. Deselection means the benefit rider rate has to be excluded from premium.*/
export const getPlansOldRatesRenewals = (medicalPlanCodes, OISABit = {}) => async (dispatch, getState) => {
    const requestBody = getMedicalRatesRequestBody();
    if(OISABit && Object.keys(OISABit).length > 0){
        requestBody.OISABit =  {...OISABit};
    }
    requestBody.medicalPlanCodes = medicalPlanCodes;
    try {
        const rates = await callRateApi(requestBody, dispatch);
        dispatch({
            type: types.RESTORE_PREMIUM_ON_DESELECT_RS,
            rates
        })
    } catch (err) {
        dispatch({
            type: types.SERVICE.RS.ERROR_RENEWAL_RATES,
            payload: err.response && err.response.data && err.response.data.message ? err.response.data.message : 'Something went wrong while fetching medical rates',
            source: 'RS',
        });
    }
}


